@charset "utf-8";
/* カードレイアウト
------------------------------------------------------------- */
.commonCardLayout {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .card {
    background-color: #ffffff;
    width: 31.2962%;
    min-height: 275px;
    margin-right: 3.05555%;
    margin-bottom: 3.7%;
    &:nth-child(3n) {
      margin-right: 0;
    }
    @include mq(md) {
      width: 100%;
      margin-right: 0;
      min-height: inherit;
    }
    a {
      display: block;
      border: solid 1px #c4c4c4;
      padding: 8.9552% 7.46268% 14.3283%;
      position: relative;
      height: 100%;
      @include mq(md) {
        padding: 7.22535% 4.22535% 20.2676%;
      }
      &.notCursor {
        cursor: default;
      }
    }
    .column {
      display: block;
      .typeColumn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p.type {
          font-size: 1.8rem;
          line-height: 1;
          letter-spacing: 1.8px;
          @include mq(md) {
            font-size: 1.4rem;
            letter-spacing: 1.4px;
          }
          &.seminar {
            color: #b59738;
          }
          &.topic {
            color: #1f9a9a;
          }
          &.tax {
            color: #b59738;
          }
          &.juku {
            color: #4f96b1;
          }
          &.annual {
            color: #b54a1b;
          }
        }
        time {
          display: block;
          font-size: 1.4rem;
          line-height: 1;
          letter-spacing: 1.4px;
          color: #8d8d8d;
          @include mq(md) {
            font-size: 1.2rem;
          }
        }
      }
      h2 {
        padding-top: 10px;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 1.8px;
        text-align: left;
        color: #4b4b4b;
        @include mq(md) {
          font-size: 1.4rem;
          padding-top: 15px;
        }
      }
      .txt {
        padding-top: 20px;
        line-height: 1.5;
        letter-spacing: 1.6px;
        text-align: left;
        color: #4b4b4b;
        font-size: 1.4rem;
      }
      hr {
        text-align: right;
        display: block;
        width: 18px;
        height: 3px;
        background-color: #8D8D8D;
        border-top: 0;
        position: absolute;
        bottom: 0;
        right: 10px;
        margin: 30px;
        @include mq(md) {
          width: 20px;
          height: 1px;
          margin: 15px 15px 30px;
        }
      }
    }
  }
}