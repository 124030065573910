@charset "utf-8";
/* ================================================================================
事業内容
================================================================================ */
#serviceIdx {
  overflow: hidden;
  .eachPageLinkTit {
    position: relative;
    border-bottom: solid 1px #a2a5a7;
    margin-bottom: 150px;
    @include mq(md) {
      margin: 0 20px 100px;
    }
    h2 {
      font-family: $NotoSerifJP;
      font-size: 4.8rem;
      line-height: 1.17;
      letter-spacing: 4.8px;
      color: #000000;
      display: inline-block;
      position: relative;
      padding-bottom: 20px;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        border-bottom: solid 1px #02377b;
      }
      @include mq(md) {
        font-size: 2.6rem;
      }
    }
    span {
      position: absolute;
      left: 0;
      top: 100%;
      transform: translateY(20px);
      font-size: 1.8rem;
      line-height: 1.33;
      letter-spacing: 0.9px;
      color: #4b4b4b;
      @include mq(md) {
        font-size: 1.6rem;
      }
    }
    &.corporate {
      h2 {
        &::after {
          border-bottom-color: #0076a2;
        }
      }
    }
  }
  .eachPageLinkColumn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    counter-reset: number 0;
    position: relative;
    margin-bottom: 220px;
    @include mq(md) {
      display: block;
      padding: 0 20px;
      margin-bottom: 120px;
    }
    &::before {
      content: "";
      background-color: #f8f9fb;
      width: 100vw;
      height: calc(100% + 30px);
      position: absolute;
      top: 50px;
      left: 75px;
      z-index: 0;
    }
    &:after {
      content: "";
      display: block;
      flex-basis: 31%;
      height: 0;
    }
    &.corporate {
      &::before {
        left: inherit;
        right: 75px;
      }
      .eachPageLinkColumnBox {
        &::before {
          color: #0076a2;
        }
        &::after {
          border-bottom-color: #0076a2;
        }
      }
    }
    &Box {
      flex-basis: 31%;
      background-color: #fff;
      border: solid 1px #e1e5e8;
      position: relative;
      margin-top: 100px;
      z-index: 10;
      @include mq(md) {
        margin-top: 0;
        margin-bottom: 80px;
      }
      &::before {
        counter-increment: number;
        content: counter(number, decimal-leading-zero);
        font-family: $NotoSerifJP;
        font-size: 4.8rem;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 4.8px;
        color: #004092;
        position: absolute;
        top: -30px;
        right: 7%;
        @include mq(md) {
          font-size: 4.2rem;
        }
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        border-bottom: solid 2px #004092;
        width: 100%;
      }
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      a {
        padding: 40px 25px 30px;
      }
      h3 {
        font-family: $NotoSerifJP;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 2.18;
        color: #4b4b4b;
        position: relative;
        padding-bottom: 35px;
        @include mq(md) {
          font-size: 1.8rem;
        }
        &::after {
          content: "";
          width: 28%;
          border-bottom: solid 1px #b59738;
          position: absolute;
          bottom: 15px;
          left: -40px;
        }
      }
      p {
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 1.4px;
        color: #4b4b4b;
        padding-bottom: 50px;
      }
      .moreBtn {
        font-size: 1.3rem;
        color: #707070;
        position: absolute;
        bottom: 30px;
        right: 25px;
        padding: 0;
        padding-right: 40px;
        @include mq(md) {
          font-size: 1.2rem;
          right: 6%;
          bottom: 8.2746%;
          padding-right: 20px;
        }
      }
    }
  }
}
//ファンド・コーポレート共通
#serviceFund,
#serviceCorporate {
  .serviceContent {
    max-width: 1320px;
    padding: 0 20px;
    margin: auto;
    position: relative;
    @include mq(md) {
      overflow: hidden;
    }
    &.cancell {
      overflow: visible;
    }
    &Layout {
      max-width: 1080px;
      margin: auto;
      padding-right: 350px;
      @include mq(fixed) {
        padding-right: 280px;
      }
      @include mq(tab) {
        padding-right: 0;
      }
      @include mq(md) {
        padding-right: 0;
      }
    }
    h1 {
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      line-height: 1.75;
      letter-spacing: 3.2px;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 2rem;
        letter-spacing: 4px;
      }
      span {
        font-size: 2.8rem;
        line-height: 2;
        letter-spacing: 2.8px;
        color: #b59738;
        @include mq(md) {
          font-size: 1.4rem;
        }
      }
    }
    h2 {
      font-family: $NotoSerifJP;
      font-size: 2.4rem;
      line-height: 1.67;
      letter-spacing: 2.4px;
      color: #000000;
      @include mq(md) {
        font-size: 1.7rem;
      }
    }
    .introTxt {
      font-family: $NotoSerifJP;
      font-size: 1.6rem;
      line-height: 2;
      letter-spacing: 1.2px;
      color: #4b4b4b;
    }
  }
  .sideBar {
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;
    z-index: 1;
    @include mq(tab) {
      position: static;
      margin-bottom: 60px;
    }
    @include mq(md) {
      position: static;
      margin-bottom: 60px;
    }
    .sideNav {
      width: 320px;
      border: solid 1px #eaeff3;
      background-color: #ffffff;
      position: sticky;
      top: 0;
      padding-left: 30px;
      @include mq(tab) {
        border: solid 1px #b7b7b7;
        width: 100%;
        max-width: 310px;
        padding-left: 0;
        margin: auto;
        margin-right: 0;
      }
      @include mq(md) {
        border: solid 1px #b7b7b7;
        width: 100%;
        max-width: 310px;
        padding-left: 0;
        margin: auto;
        margin-right: 0;
      }
      dt {
        display: none;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.46;
        color: #000;
        padding: 15px 40px;
        position: relative;
        &::before {
          content: "";
          background-color: #b59738;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 20px;
          margin: auto;
          width: 3px;
          height: 14px;
        }
        &::after {
          content: "";
          background-image: url(/images/icons/icon_toggle_btn.svg);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 15px;
          margin: auto;
          width: 12px;
          height: 6px;
          transition: .5s;
        }
        &.open {
          &:after {
            transform: rotate(180deg);
          }
        }
        @include mq(tab) {
          display: block;
        }
        @include mq(md) {
          display: block;
        }
      }
      dd {
        margin-top: 30px;
        @include mq(tab) {
          display: none;
          margin-top: 0;
        }
        @include mq(md) {
          display: none;
          margin-top: 0;
        }
        a {
          display: block;
          font-size: 1.5rem;
          line-height: 1.47;
          color: #8d8d8d;
          padding-left: 15px;
          margin-bottom: 30px;
          @include mq(tab) {
            padding-left: 40px;
            margin-bottom: 15px;
          }
          @include mq(md) {
            padding-left: 40px;
            margin-bottom: 15px;
          }
          &.currentOn {
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.5;
            color: #000000;
            border-left: solid 3px #b59738;
            @include mq(tab) {
              display: none;
            }
            @include mq(md) {
              display: none;
            }
          }
        }
      }
    }
  }
}