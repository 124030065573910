@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP&:400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Cormorant:400,500,600&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/*! Type: YakuHanJP_Noto - Based on Noto Sans CJK JP */
@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 100;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Thin.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Thin.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Thin.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 300;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Light.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 400;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Regular.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Regular.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 500;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Medium.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Medium.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Medium.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 700;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Bold.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 900;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Black.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Black.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Black.woff") format("woff");
}

/* EXTEND
------------------------------------------------------------- */
/* ======================================================================

リセット

====================================================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* ================================================================================

印刷時の定義

================================================================================ */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブ チェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

.tabOnly {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tabOnly {
    display: block !important;
  }
}

.originalBr {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1320px) {
  .originalBr {
    display: block;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
}

.fixedBody {
  position: fixed !important;
  width: 100%;
  height: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.notPointer {
  cursor: default;
}

main {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 97px;
}

@media screen and (max-width: 767px) {
  main {
    padding-top: 80px;
  }
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

main article section p {
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  main article section p {
    font-size: 1.6rem;
  }
}

main article section.test h5 {
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
}

a,
span {
  color: #555;
}

a.windowIcon,
span.windowIcon {
  position: relative;
}

a.windowIcon:before,
span.windowIcon:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -20px;
  margin: auto;
  width: 13px;
  height: 12px;
  background-image: url("/images/icons/icon_out_link_blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

a.windowIcon.black::before,
span.windowIcon.black::before {
  background-image: url("/images/icons/icon_out_link_black.png");
}

a.chainLink::after,
span.chainLink::after {
  content: "";
  width: 19px;
  height: 14px;
  display: inline-block;
  margin: 0 3px;
  background-image: url(/images/icons/icon_chain_link.svg);
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}

button {
  outline: none;
}

.devMode {
  position: fixed;
  z-index: 999999;
  font-size: 1.8rem;
  font-weight: bold;
  color: navy;
  top: 30px;
}

.xdebug-var-dump {
  position: relative;
  z-index: 9999999;
}

/* 共通デザイン
------------------------------------------------------------- */
/*各ページ見出しキービジュアル*/
section.eachPagePrimeG {
  width: 100%;
  max-width: 1366px;
  padding: 0 3.14787% 20px;
  margin: auto;
}

@media screen and (min-width: 1366px) {
  section.eachPagePrimeG {
    padding: 0 42px 20px;
  }
}

@media screen and (max-width: 767px) {
  section.eachPagePrimeG {
    display: none;
  }
}

section.eachPagePrimeG a {
  display: flex;
  max-width: 330px;
  align-items: flex-start;
  margin-left: auto;
}

section.eachPagePrimeG a img {
  width: 100px;
  height: auto;
}

section.eachPagePrimeG a p {
  flex: 1;
  padding-left: 10px;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  color: #8d8d8d;
}

section.bassHeadVisual {
  position: relative;
  padding-top: 19.54612%;
}

@media screen and (min-width: 1366px) {
  section.bassHeadVisual {
    padding-top: 267px;
  }
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual {
    padding-top: 74%;
  }
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual.notImg {
    padding-top: 67.3333%;
  }
}

section.bassHeadVisual .mainVisual {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

section.bassHeadVisual .mainVisual .photo {
  width: 59.80966%;
}

section.bassHeadVisual .mainVisual .photo.notImg {
  opacity: 0.54;
  background-image: linear-gradient(to left, #eaeff3 82%, rgba(246, 248, 250, 0.81) 46%, rgba(250, 250, 250, 0.68) 21%);
  padding-top: 19.54612%;
}

@media screen and (min-width: 1366px) {
  section.bassHeadVisual .mainVisual .photo.notImg {
    padding-top: 267px;
  }
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .mainVisual .photo.notImg {
    padding: 67.3333% 0 0 0;
    background-image: linear-gradient(to right, #FFFFFF, #eaeff3 50%);
  }
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .mainVisual .photo {
    width: 94.66666%;
  }
}

section.bassHeadVisual .mainVisual .titBox {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  color: #02377b;
  text-align: left;
  width: 40.19034%;
  position: relative;
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .mainVisual .titBox {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.65);
    width: 66%;
    margin-bottom: 8.9333%;
  }
}

section.bassHeadVisual .mainVisual .titBox h1,
section.bassHeadVisual .mainVisual .titBox .serviceTit {
  font-size: 3.2rem;
  line-height: 1.3;
  letter-spacing: 3.2px;
  padding-left: 14.5985%;
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .mainVisual .titBox h1,
  section.bassHeadVisual .mainVisual .titBox .serviceTit {
    font-size: 2rem;
    letter-spacing: 2px;
    padding: 6.46464% 0 0 8.08080%;
  }
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .mainVisual .titBox .sdgTit {
    line-height: 1.4;
    letter-spacing: -0.4px;
  }
}

section.bassHeadVisual .mainVisual .titBox hr {
  width: 72.9927%;
  display: block;
  margin: 15px 0;
  position: relative;
  left: -29.19708%;
  border-top: solid 1px #b59738;
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .mainVisual .titBox hr {
    margin: 11px 0;
    width: 60.6060%;
    left: -10.5050%;
  }
}

section.bassHeadVisual .mainVisual .titBox p {
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 1.8px;
  color: #02377b;
  padding-left: 14.5985%;
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .mainVisual .titBox p {
    font-size: 1.5rem;
    letter-spacing: 1.5px;
    padding: 0 0 4.4444% 8.08080%;
  }
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .mainVisual .titBox.center {
    top: 23.76237%;
    bottom: inherit;
    right: 0;
    margin: auto;
  }
}

section.bassHeadVisual .mainVisual .titBox.notletS h1 {
  letter-spacing: 0;
}

section.bassHeadVisual .posWaveWrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

section.bassHeadVisual .posWaveWrap object {
  width: 100%;
}

section.bassHeadVisual .waveMax {
  width: 89.60468%;
  opacity: 0;
  right: -9.88286%;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  section.bassHeadVisual .waveMax {
    right: inherit;
    left: -40.53333%;
    width: 203.733333%;
    bottom: -8.64864%;
  }
}

section.bassHeadVisual #waveVueAnime1 {
  position: absolute;
  z-index: 0;
}

section.bassHeadVisual #waveVueAnime2 {
  position: absolute;
  z-index: 0;
}

section.bassHeadVisual #waveVueAnime3 {
  position: absolute;
  z-index: 0;
}

/*サブナビゲーション*/
nav.eachPageSubNavigation {
  display: block;
  width: 100%;
  max-width: 1406px;
  margin: 0 auto 80px;
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  nav.eachPageSubNavigation {
    margin-bottom: 8%;
  }
}

nav.eachPageSubNavigation dl.eachPageBox {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  nav.eachPageSubNavigation dl.eachPageBox {
    flex-wrap: wrap;
    height: auto;
  }
}

nav.eachPageSubNavigation dl.eachPageBox.service {
  max-width: 445px;
}

nav.eachPageSubNavigation dl.eachPageBox.about {
  max-width: 685px;
}

nav.eachPageSubNavigation dl.eachPageBox.group {
  max-width: 820px;
}

nav.eachPageSubNavigation dl.eachPageBox.privacy {
  max-width: 980px;
}

nav.eachPageSubNavigation dl.eachPageBox dt {
  display: none;
  width: 100%;
  margin-bottom: 2px;
}

@media screen and (max-width: 767px) {
  nav.eachPageSubNavigation dl.eachPageBox dt {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.46;
    letter-spacing: 1.4px;
    text-align: center;
    color: #004092;
    padding: 15px 0;
    border-bottom: 1px solid #B7B7B7;
    border-top: 1px solid #B7B7B7;
    position: relative;
  }
  nav.eachPageSubNavigation dl.eachPageBox dt::after {
    content: "";
    background-image: url(/images/icons/icon_toggle_btn.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 16px;
    height: 8px;
    transition: .5s transform;
  }
  nav.eachPageSubNavigation dl.eachPageBox dt.open::after {
    transform: rotate(180deg);
  }
}

nav.eachPageSubNavigation dl.eachPageBox dd {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  nav.eachPageSubNavigation dl.eachPageBox dd {
    padding: 0 10px;
  }
}

@media screen and (max-width: 767px) {
  nav.eachPageSubNavigation dl.eachPageBox dd {
    display: none;
    width: 100%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  nav.eachPageSubNavigation dl.eachPageBox dd a {
    text-align: center;
  }
  nav.eachPageSubNavigation dl.eachPageBox dd a.currentOn {
    display: none;
  }
}

nav.eachPageSubNavigation dl.eachPageBox a {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.47;
  letter-spacing: 1.5px;
  color: #707070;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  nav.eachPageSubNavigation dl.eachPageBox a {
    font-size: 1.2rem;
    letter-spacing: 1.5px;
  }
}

nav.eachPageSubNavigation dl.eachPageBox a.currentOn {
  color: #004092;
}

@media screen and (max-width: 767px) {
  nav.eachPageSubNavigation dl.eachPageBox a {
    font-size: 1.4rem;
    padding: 15px 0;
  }
}

.eachPageLink {
  max-width: 1120px;
  padding: 0 20px;
  margin: auto;
  margin-bottom: 150px;
}

@media screen and (max-width: 767px) {
  .eachPageLink {
    padding: 0;
    margin-bottom: 20%;
  }
}

.eachPageLinkIntro {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 1.73;
  letter-spacing: 1.8px;
  color: #000000;
  padding: 0 65px;
  margin-bottom: 95px;
}

@media screen and (max-width: 767px) {
  .eachPageLinkIntro {
    font-size: 1.5rem;
    padding: 0 20px;
    margin-bottom: 14.666%;
  }
}

.eachPageLink a {
  display: block;
}

.eachPageLinkBox {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: solid 1px #d3d3d3;
}

@media screen and (max-width: 767px) {
  .eachPageLinkBox {
    display: block;
    margin-bottom: 15%;
    padding-bottom: 28.84%;
    border: none;
  }
}

.eachPageLinkImg {
  flex-basis: 55%;
}

@media screen and (max-width: 767px) {
  .eachPageLinkImg {
    margin-left: 20px;
  }
}

.eachPageLinkImg img {
  width: 100%;
}

.eachPageLinkTxt {
  flex-basis: 45%;
  background-color: #fff;
  padding-left: 50px;
}

@media screen and (max-width: 767px) {
  .eachPageLinkTxt {
    position: absolute;
    bottom: 0;
    right: 8%;
    width: 78%;
    height: 42%;
    padding-top: 5.6%;
    padding-left: 4.8%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
}

.eachPageLinkTxt h2 {
  font-family: "Libre Baskerville";
  font-size: 3.6rem;
  line-height: 1.12;
  letter-spacing: 0.9px;
  color: #004092;
  position: relative;
  padding-bottom: 12.5px;
}

@media screen and (max-width: 767px) {
  .eachPageLinkTxt h2 {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .eachPageLinkTxt h2 {
    line-height: 1.4;
  }
}

.eachPageLinkTxt h2.ampA::after {
  transform: scale(1);
}

.eachPageLinkTxt hr {
  position: relative;
  left: -130px;
  width: 100%;
  max-width: 260px;
  margin: 0;
  border-top: 0px;
}

.eachPageLinkTxt hr.ampA::before, .eachPageLinkTxt hr.ampA::after {
  width: 50%;
}

.eachPageLinkTxt hr::before, .eachPageLinkTxt hr::after {
  position: absolute;
  bottom: 0;
  content: "";
  width: 0%;
  border-top: solid 1px #b59738;
  transition: 1s width;
}

.eachPageLinkTxt hr::before {
  left: 50%;
}

.eachPageLinkTxt hr::after {
  right: 50%;
}

.eachPageLinkTxt p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 1.43;
  letter-spacing: 1.8px;
  color: #4b4b4b;
  padding-top: 12.5px;
}

@media screen and (max-width: 767px) {
  .eachPageLinkTxt p {
    font-size: 1.4rem;
  }
}

.eachPageLinkTxt .moreBtn {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  letter-spacing: 1.4px;
  position: absolute;
  bottom: 30px;
  right: 0;
  padding-top: 0;
  padding-right: 40px;
}

@media screen and (max-width: 767px) {
  .eachPageLinkTxt .moreBtn {
    font-size: 1.2rem;
    right: 6%;
    bottom: 8.2746%;
    padding-right: 20px;
  }
}

/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
.mostBottomLayout {
  display: block;
  text-align: center;
  max-width: 1175px;
  margin: 0 auto;
}

.mostBottomLayout #topmove {
  display: inline-block;
  margin-bottom: 16.66666%;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .mostBottomLayout #topmove {
    margin-bottom: 16%;
  }
}

.mostBottomLayout #topmove hr {
  margin: 0 auto;
  width: 1px;
  height: 75px;
  border: none;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 767px) {
  .mostBottomLayout #topmove hr {
    height: 40px;
  }
}

.mostBottomLayout #topmove hr::after {
  content: "";
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #707070;
  animation: scrollTop 5s infinite normal;
}

.mostBottomLayout #topmove p {
  padding-top: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: center;
  color: #707070;
}

@media screen and (max-width: 767px) {
  .mostBottomLayout #topmove p {
    padding-top: 20px;
    font-size: 1.2rem;
    line-height: 1;
  }
}

@keyframes scrollTop {
  0% {
    transform: translateY(100%);
  }
  15%,
  85% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto 20px;
  clear: both;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .breadcrumb {
    padding: 0 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  .breadcrumb {
    padding: 0 45px;
  }
}

.breadcrumb ol {
  position: relative;
  width: 100%;
  padding-left: 2em;
  word-break: break-all;
  text-align: left;
  line-height: 1;
  color: #acabab;
}

.breadcrumb ol::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  .breadcrumb ol {
    max-width: initial;
    padding: 0 0 0 2em;
    margin-bottom: 13px;
  }
}

.breadcrumb ol::before {
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
  width: 16px;
  height: 13px;
  margin: auto;
  content: "";
  background-image: url("/images/icons/icon_home.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .breadcrumb ol::before {
    top: 0;
    width: 12px;
    height: 12px;
  }
}

.breadcrumb ol > li {
  display: inline;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: left;
  color: #8d8d8d;
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 1.1rem;
  }
}

.breadcrumb ol > li a {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: left;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 1.1rem;
  }
}

.breadcrumb ol > li + li::before {
  padding: 0 7px 0 5px;
  color: #ACABAB;
  content: ">";
}

/* ================================================================
ヘッダー
================================================================ */
header#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  transition: 2s opacity, 2s background;
  opacity: 0;
}

@media screen and (max-width: 767px) {
  header#header {
    transition: 1s opacity, 1s background;
  }
}

header#header.visible {
  opacity: 1;
}

header#header.nonTop {
  transition: 0s opacity, 0s background;
}

header#header.topDer .enclosingWrap #spNavBtn span {
  background-color: #fff;
}

header#header.topDer .enclosingWrap #spNavBtn.open span {
  background-color: #4b4b4b;
}

header#header.addBgCor {
  background: rgba(255, 255, 255, 0.9);
}

header#header.addBgCor .enclosingWrap #spNavBtn span {
  background-color: #4b4b4b;
}

header#header.addBgCor .enclosingWrap .trackingMoveContact .langeBox a,
header#header.addBgCor .enclosingWrap .trackingMoveContact .langeBox span {
  color: #4b4b4b;
}

header#header.addBgCor .enclosingWrap .trackingMoveContact .langeBox .notHover {
  cursor: default;
}

header#header.addBgCor .enclosingWrap .trackingMoveContact.topDer .contactMoveBox a::before {
  width: 14px;
  height: 10px;
}

@media screen and (min-width: 1366px) {
  header#header.addBgCor .enclosingWrap .trackingMoveContact.topDer .contactMoveBox a::before {
    width: 12px;
    height: 16px;
  }
}

header#header.addBgCor .enclosingWrap .trackingMoveContact .contactMoveBox a {
  color: #4b4b4b;
}

header#header.addBgCor .enclosingWrap .trackingMoveContact .contactMoveBox a::before {
  background-image: url(/images/icons/icon_mail.png);
  width: 14px;
  height: 10px;
}

header#header .enclosingWrap {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  opacity: 0;
}

header#header.visible .enclosingWrap {
  opacity: 1;
}

header#header .enclosing {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.19619% 3.14787% 1.59619%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header .enclosing {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 767px) {
  header#header .enclosing {
    padding: 6% 5.33333% 7.06666% 3.33333%;
  }
}

header#header .enclosing .partnerbox {
  width: 100%;
  text-align: right;
}

header#header .enclosing .partnerbox a {
  transition: .5s color;
  color: #4b4b4b;
  font-size: 1.2rem;
}

header#header .enclosing .partnerbox a svg {
  margin-right: 6px;
}

header#header .enclosing .partnerbox a svg path {
  transition: .5s fill;
  fill: #4b4b4b;
}

header#header .enclosing .partnerbox a.topCol {
  color: #fff;
}

header#header .enclosing .partnerbox a.topCol svg path {
  fill: #fff;
}

header#header .enclosing .partnerbox a:hover {
  color: #004092;
}

header#header .enclosing .partnerbox a:hover svg path {
  fill: #004092;
}

header#header .enclosing .logoBox {
  display: block;
  width: 151px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header .enclosing .logoBox {
    width: 151px;
  }
}

@media screen and (max-width: 767px) {
  header#header .enclosing .logoBox {
    position: relative;
    z-index: 100;
    width: 143px;
  }
}

header#header .enclosing .logoBox a {
  display: block;
  width: 100%;
}

header#header .enclosing .logoBox a img {
  width: 100%;
}

header#header nav#globalNavigations {
  display: block;
  width: 62.954612%;
  max-width: 700px;
  margin-left: auto;
  margin-top: 7px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header nav#globalNavigations {
    width: 63.609375%;
    padding-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations {
    width: 100%;
    max-width: none;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #fff;
    padding-top: 14.39999%;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations .navWrap {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
}

header#header nav#globalNavigations ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul {
    display: block;
    padding-bottom: 15px;
    position: relative;
    z-index: 101;
    padding-top: 16%;
    background-color: #fff;
  }
}

header#header nav#globalNavigations ul li {
  padding: 0 5px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header nav#globalNavigations ul li {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li {
    padding: 0 20px 9.33333% 14.00000%;
    opacity: 0;
  }
}

header#header nav#globalNavigations ul li:first-child {
  padding-left: 0;
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li:first-child {
    padding: 0 20px 9.33333% 14.00000%;
  }
}

header#header nav#globalNavigations ul li:last-child {
  padding: 0;
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li:last-child {
    padding: 0;
  }
}

header#header nav#globalNavigations ul li:nth-child(4) {
  padding: 0 25px 0 8px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header nav#globalNavigations ul li:nth-child(4) {
    padding: 0 0 0 0;
  }
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li:nth-child(4) {
    padding: 0 20px 9.33333% 14%;
  }
}

header#header nav#globalNavigations ul li a {
  display: block;
  font-size: 1.5rem;
  line-height: 1.47;
  color: #4b4b4b;
  font-weight: 500;
  transition: .5s color;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header nav#globalNavigations ul li a {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li a {
    display: inline-block;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: normal;
    color: #333333;
    position: relative;
  }
}

header#header nav#globalNavigations ul li a.uaJs:hover {
  color: #004092;
}

header#header nav#globalNavigations ul li a.topCol {
  color: #fff;
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li a.topCol {
    color: #333333;
  }
}

header#header nav#globalNavigations ul li a.topCol.windowIcon::before {
  background-image: url(/images/icons/icon_out_link_white.png);
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li a.topCol.windowIcon::before {
    background-image: url(/images/icons/icon_out_link_black.svg);
  }
}

header#header nav#globalNavigations ul li a.current {
  color: #004092;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header nav#globalNavigations ul li a.windowIcon {
    padding-right: 10px;
  }
}

header#header nav#globalNavigations ul li a.windowIcon::before {
  transition: .5s;
  width: 13px;
  height: 13px;
  right: -18px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header nav#globalNavigations ul li a.windowIcon::before {
    width: 11px;
    height: 11px;
    right: -3px;
  }
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li.navEnd {
    padding-bottom: 9.33333%;
  }
}

@media screen and (max-width: 767px) {
  header#header nav#globalNavigations ul li.spOnly {
    padding: 0 20px;
  }
}

header#header nav#globalNavigations ul li .partnerContact {
  width: 100%;
}

header#header nav#globalNavigations ul li .partnerContact a {
  display: flex;
  justify-content: center;
  border-top: 1px solid #C4C4C4;
  padding: 15px 0;
  text-align: center;
}

header#header nav#globalNavigations ul li .partnerContact a::before {
  content: "";
  background: url(/images/common/header_partner_icon_sp.svg);
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  margin: auto 13px auto 0;
  width: 24.8px;
  height: 13px;
}

header#header nav#globalNavigations ul li .partnerContact a .box {
  display: block;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

header#header nav#globalNavigations ul li .partnerContact a .box p {
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 1.5px;
  text-align: left;
  color: #000000;
  padding-bottom: 8px;
}

header#header nav#globalNavigations ul li .partnerContact a .box span {
  display: block;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 1px;
  color: #4b4b4b;
  text-align: left;
}

header#header nav#globalNavigations ul li .navContact {
  width: 100%;
}

header#header nav#globalNavigations ul li .navContact a {
  display: block;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  padding: 15px 0;
  text-align: center;
}

header#header nav#globalNavigations ul li .navContact a .box {
  display: block;
  width: 100%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

header#header nav#globalNavigations ul li .navContact a .box p {
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 1.5px;
  text-align: center;
  color: #000000;
  padding-bottom: 8px;
}

header#header nav#globalNavigations ul li .navContact a .box span {
  display: block;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 1px;
  color: #4b4b4b;
  text-align: center;
}

header#header nav#globalNavigations ul li .lang {
  display: block;
  padding-top: 6.66666%;
  padding-bottom: 6.66666%;
  text-align: center;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #666666;
}

header#header nav#globalNavigations ul li .lang a {
  display: inline-block;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #666666;
}

header#header nav#globalNavigations ul li .primeGlobalColumn {
  display: block;
  width: 100%;
}

header#header nav#globalNavigations ul li .primeGlobalColumn a {
  display: flex;
  align-items: center;
  background-color: #efefef;
  padding: 15px;
}

header#header nav#globalNavigations ul li .primeGlobalColumn a img {
  width: 36.5671%;
}

header#header nav#globalNavigations ul li .primeGlobalColumn a p {
  flex: 1;
  padding-left: 7.46268%;
  font-size: 1rem;
  text-align: left;
  line-height: 1.4;
}

header#header .trackingMoveContact {
  width: 100%;
  padding-right: 10px;
  padding-top: 1%;
  bottom: -20%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header .trackingMoveContact {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1366px) {
  header#header .trackingMoveContact.topDer {
    position: absolute;
    top: 200px;
    right: inherit;
    left: 33px;
    writing-mode: vertical-rl;
    height: 100vh;
    width: auto;
    flex-direction: row;
    padding-top: 0;
  }
}

@media screen and (min-width: 1366px) {
  header#header .trackingMoveContact.topDer .langeBox {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1366px) {
  header#header .trackingMoveContact.topDer .langeBox a {
    color: #707070;
    font-size: 1.9rem;
  }
}

@media screen and (min-width: 1366px) {
  header#header .trackingMoveContact.topDer .langeBox span {
    color: #707070;
    padding: .5em 0;
  }
}

@media screen and (min-width: 1366px) {
  header#header .trackingMoveContact.topDer .contactMoveBox {
    padding-right: 0;
  }
}

@media screen and (min-width: 1366px) {
  header#header .trackingMoveContact.topDer .contactMoveBox a {
    color: #707070;
    padding-top: 28px;
    font-size: 1.9rem;
  }
  header#header .trackingMoveContact.topDer .contactMoveBox a::after {
    width: 1px;
    height: 90px;
    content: '';
    position: absolute;
    bottom: -120px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #707070;
  }
}

@media screen and (min-width: 1366px) {
  header#header .trackingMoveContact.topDer .contactMoveBox a::before {
    left: 0;
    right: 0;
    bottom: inherit;
    background-image: url(/images/icons/icon_mail_black.svg) !important;
    width: 12px;
    height: 16px;
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  header#header .trackingMoveContact {
    display: none;
  }
}

header#header .trackingMoveContact .langeBox {
  display: flex;
  align-items: center;
}

header#header .trackingMoveContact .langeBox a {
  display: block;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: 1.9px;
  color: #fff;
  transition: .5s color;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header .trackingMoveContact .langeBox a {
    font-size: 1.3rem;
  }
}

header#header .trackingMoveContact .langeBox span {
  display: inline-block;
  padding: 0 .5em;
  color: #fff;
  transition: .5s color;
}

header#header .trackingMoveContact .contactMoveBox {
  padding-right: 45px;
}

header#header .trackingMoveContact .contactMoveBox a {
  position: relative;
  display: block;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: 1.9px;
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header .trackingMoveContact .contactMoveBox a {
    font-size: 1.3rem;
  }
}

header#header .trackingMoveContact .contactMoveBox a::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -24px;
  margin: auto;
  content: '';
  background-image: url(/images/icons/icon_mail_white.png);
  width: 14px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: .5s;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header .trackingMoveContact .contactMoveBox a::before {
    right: -18px;
  }
}

header#header #spNavBtn {
  display: none;
}

@media screen and (max-width: 767px) {
  header#header #spNavBtn {
    display: block;
    width: 33px;
    height: 21px;
    position: relative;
  }
  header#header #spNavBtn .box {
    position: relative;
    z-index: 9999999;
    width: 100%;
    height: 100%;
  }
  header#header #spNavBtn .box span {
    width: 100%;
    background-color: #4B4B4B;
    position: absolute;
    right: 0;
    height: 1px;
    transition: 1s;
  }
  header#header #spNavBtn .box span:nth-child(1) {
    top: 0;
    width: 20px;
  }
  header#header #spNavBtn .box span:nth-child(2) {
    top: 0;
    bottom: 0;
    margin: auto;
    width: 26px;
  }
  header#header #spNavBtn .box span:nth-child(3) {
    bottom: 0;
  }
  header#header #spNavBtn .closeBox {
    position: absolute;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
  header#header #spNavBtn .closeBox span {
    width: 100%;
    background-color: #4B4B4B;
    position: absolute;
    right: 0;
    height: 1px;
    transition: 1s;
  }
  header#header #spNavBtn .closeBox span:nth-child(1) {
    top: -10px;
    width: 100%;
    transform: translateY(20px) rotate(-45deg) scale(0);
  }
  header#header #spNavBtn .closeBox span:nth-child(2) {
    top: 40px;
    bottom: 0;
    margin: auto;
    width: 100%;
    transform: translateY(-20px) rotate(45deg) scale(0);
  }
  header#header #spNavBtn.open .box span {
    width: 0;
  }
  header#header #spNavBtn.open .closeBox span:nth-child(1) {
    transform: translateY(20px) rotate(-45deg) scale(1);
  }
  header#header #spNavBtn.open .closeBox span:nth-child(2) {
    transform: translateY(-20px) rotate(45deg) scale(1);
  }
}

/* ================================================================================
フッター
================================================================================ */
footer#footer .footerBg {
  background-color: #f2f2f2;
  padding: 60px 0 100px;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg {
    padding: 30px 0;
  }
}

footer#footer .footerBg .sitemapLayout {
  max-width: 1175px;
  padding: 0 20px 30px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout {
    display: block;
    padding-bottom: 5px;
  }
}

footer#footer .footerBg .sitemapLayout .listTr {
  display: table-row;
  width: 100%;
}

footer#footer .footerBg .sitemapLayout .listBox {
  flex-basis: 25%;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout .listBox {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout .listBox:last-child {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  footer#footer .footerBg .sitemapLayout .listBox.oneColumn {
    flex-basis: 23%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  footer#footer .footerBg .sitemapLayout .listBox.oneColumn {
    flex-basis: 23%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  footer#footer .footerBg .sitemapLayout .listBox.twoColumn {
    flex-basis: 22%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  footer#footer .footerBg .sitemapLayout .listBox.twoColumn {
    flex-basis: 20%;
  }
}

footer#footer .footerBg .sitemapLayout .listBox.treeColumn {
  flex-basis: 23%;
  padding: 0 2% 30px 0;
}

@media screen and (min-width: 1366px) {
  footer#footer .footerBg .sitemapLayout .listBox.treeColumn {
    flex-basis: 25%;
    padding: 0 0 30px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  footer#footer .footerBg .sitemapLayout .listBox.treeColumn {
    flex-basis: 29%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  footer#footer .footerBg .sitemapLayout .listBox.treeColumn {
    flex-basis: 28%;
    padding: 0 3% 30px;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout .listBox.treeColumn {
    padding: 0 0 10px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  footer#footer .footerBg .sitemapLayout .listBox.fourColumn {
    flex-basis: 26%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  footer#footer .footerBg .sitemapLayout .listBox.fourColumn {
    flex-basis: 22%;
  }
}

footer#footer .footerBg .sitemapLayout .listBox dl dt {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 1.4px;
  color: #000000;
  margin-bottom: 15px;
  position: relative;
}

footer#footer .footerBg .sitemapLayout .listBox dl dt span {
  color: #000;
  display: inline-block;
}

footer#footer .footerBg .sitemapLayout .listBox dl dd ul li a {
  display: block;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #767676;
  padding-left: 17px;
  margin-bottom: 10px;
  position: relative;
}

footer#footer .footerBg .sitemapLayout .listBox dl dd ul li a::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 1px;
  background-color: #b7b7b7;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout .listBox dl dd ul li a {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout .listBox .accordionSp dt::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    width: 12px;
    height: 12px;
    background: url(/images/icons/icon_footer_toggle.svg) no-repeat;
    background-size: contain;
    background-position: center;
    transition: .3s transform;
  }
  footer#footer .footerBg .sitemapLayout .listBox .accordionSp dt span {
    display: inline-block;
    position: relative;
    color: #000;
  }
  footer#footer .footerBg .sitemapLayout .listBox .accordionSp dt span::before {
    top: 5px;
  }
  footer#footer .footerBg .sitemapLayout .listBox .accordionSp a {
    overflow: hidden;
    line-height: 0;
    margin-bottom: 0;
    transition: .3s;
  }
  footer#footer .footerBg .sitemapLayout .listBox .accordionSp.open dt::after {
    transform: rotate(-180deg);
  }
  footer#footer .footerBg .sitemapLayout .listBox .accordionSp.open a {
    overflow: visible;
    line-height: 1.5;
    margin-bottom: 15px;
  }
}

footer#footer .footerBg .sitemapLayout .listBox .primeLink a {
  display: block;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout .listBox .primeLink a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
  }
}

footer#footer .footerBg .sitemapLayout .listBox .primeLink a img {
  max-width: 130px;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout .listBox .primeLink a img {
    max-width: 120px;
    margin: auto 0;
    padding: 10px;
  }
}

footer#footer .footerBg .sitemapLayout .listBox .primeLink a p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #707070;
  padding-top: 5px;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .sitemapLayout .listBox .primeLink a p {
    font-size: 1rem;
    padding: 0;
  }
}

footer#footer .footerBg .topLinkLayout {
  max-width: 1040px;
  margin: auto;
  padding: 0 20px 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout {
    display: block;
    padding-bottom: 40px;
  }
}

footer#footer .footerBg .topLinkLayout > div {
  background-color: #fff;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  footer#footer .footerBg .topLinkLayout > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

footer#footer .footerBg .topLinkLayout > div a {
  display: block;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-align: center;
  color: #4b4b4b;
  padding: 20px 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  footer#footer .footerBg .topLinkLayout > div a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout > div a {
    font-size: 1.5rem;
  }
}

footer#footer .footerBg .topLinkLayout > div a span {
  display: block;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  font-size: 1rem;
}

footer#footer .footerBg .topLinkLayout .jukuBox {
  flex-basis: 34%;
  background-color: inherit;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  footer#footer .footerBg .topLinkLayout .jukuBox {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout .jukuBox {
    margin-bottom: 15px;
  }
}

footer#footer .footerBg .topLinkLayout .jukuBox a {
  background-color: #fff;
  display: flex;
  justify-content: center;
}

footer#footer .footerBg .topLinkLayout .jukuBox a::before {
  content: "";
  width: 28px;
  height: 15px;
  background: url(/images/common/partner_icon.svg);
  background-size: contain;
  display: inline-block;
  margin: auto 15px auto 0;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout .jukuBox a::before {
    margin: auto 20px auto 0;
    width: 34.5px;
    height: 18.47px;
  }
}

footer#footer .footerBg .topLinkLayout .jukuBox a p {
  color: #004092;
}

footer#footer .footerBg .topLinkLayout .jukuBox a span {
  color: #004092;
  font-size: 1rem;
}

footer#footer .footerBg .topLinkLayout hr {
  height: 82px;
  width: 1px;
  border: none;
  border-right: solid 1px #d3d3d3;
  margin: 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  footer#footer .footerBg .topLinkLayout hr {
    height: 76px;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout hr {
    height: auto;
  }
}

footer#footer .footerBg .topLinkLayout .companyInfoBox,
footer#footer .footerBg .topLinkLayout .contactBox {
  flex-basis: 28%;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#footer .footerBg .topLinkLayout .companyInfoBox::before,
footer#footer .footerBg .topLinkLayout .contactBox::before {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  padding-right: 15px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  footer#footer .footerBg .topLinkLayout .companyInfoBox,
  footer#footer .footerBg .topLinkLayout .contactBox {
    max-height: 76px;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout .companyInfoBox {
    margin-bottom: 15px;
  }
}

footer#footer .footerBg .topLinkLayout .companyInfoBox .small {
  display: inline-block;
  font-size: 1.4rem;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  footer#footer .footerBg .topLinkLayout .companyInfoBox .small {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout .companyInfoBox .small {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  footer#footer .footerBg .topLinkLayout .companyInfoBox a {
    padding: 13px 0;
  }
}

footer#footer .footerBg .topLinkLayout .companyInfoBox::before {
  width: 16px;
  height: 20px;
  background-image: url(/images/icons/icon_pdf.svg);
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout .companyInfoBox::before {
    width: 25px;
    height: 31px;
  }
}

footer#footer .footerBg .topLinkLayout .contactBox::before {
  width: 17px;
  height: 13px;
  background-image: url(/images/icons/icon_mail.svg);
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .topLinkLayout .contactBox::before {
    width: 27.6px;
    height: 20px;
  }
}

footer#footer .footerBg .bottomLinkLayout {
  max-width: 600px;
  margin: auto;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .bottomLinkLayout {
    display: block;
  }
}

footer#footer .footerBg .bottomLinkLayout > div {
  padding: 0 15px;
}

footer#footer .footerBg .bottomLinkLayout > div a {
  display: block;
}

footer#footer .footerBg .bottomLinkLayout > div img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .bottomLinkLayout > div img {
    width: 18.1%;
    margin: auto;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBg .bottomLinkLayout .firmBox {
    padding-bottom: 30px;
  }
}

footer#footer .copy {
  padding: 25px 0;
}

@media screen and (max-width: 767px) {
  footer#footer .copy {
    padding: 10px 0;
  }
}

footer#footer .copy p {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #707070;
  text-align: center;
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  footer#footer .copy p {
    text-align: left;
  }
}

/* 見出し装飾
------------------------------------------------------------- */
h2.titTop {
  color: #b59738;
  font-size: 9rem;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: "Cormorant";
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  h2.titTop {
    font-size: 4rem;
    letter-spacing: 4px;
  }
}

h4.sideDecoration {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  color: #555;
  text-align: center;
  letter-spacing: 1.8px;
}

@media screen and (max-width: 767px) {
  h4.sideDecoration {
    font-size: 1.6rem;
  }
}

h4.sideDecoration span {
  position: relative;
  display: inline-block;
  padding: 0 2.9em 0 3.2em;
}

@media screen and (max-width: 767px) {
  h4.sideDecoration span {
    padding: 0 1.9em 0 2.3em;
  }
}

h4.sideDecoration span::before, h4.sideDecoration span::after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 41px;
  height: 42px;
  margin: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  h4.sideDecoration span::before, h4.sideDecoration span::after {
    width: 20px;
    height: 20px;
  }
}

h4.sideDecoration span::before {
  left: 0;
  transform: scale(-1, 1);
}

h4.sideDecoration span::after {
  right: 0;
}

h4.sideDecoration span.black::before, h4.sideDecoration span.black::after {
  background-image: url("/asset/images/icons/icon_line_black.svg");
}

h4.sideDecoration span.pink::before, h4.sideDecoration span.pink::after {
  background-image: url("/asset/images/icons/icon_line_pink.svg");
}

h4.sideDecoration span.green::before, h4.sideDecoration span.green::after {
  background-image: url("/asset/images/icons/icon_line_green.svg");
}

.underlineTitCommon {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 4px;
  text-align: left;
  color: #424648;
  position: relative;
}

@media screen and (max-width: 767px) {
  .underlineTitCommon {
    font-size: 1.6rem;
  }
}

.underlineTitCommon:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 344px;
  padding-top: 2px;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% 100%;
}

.underlineTitCommon.orange:before {
  background-image: url("/images/icons/icon_shar_underline_orange.svg");
}

/* アニメーション関連
------------------------------------------------------------- */
.uaJsIcon:hover .inner .titBox .posLinkBtn::before {
  right: 13px !important;
}

.uaJsIcon:hover::before {
  right: 13px !important;
}

.uaJsIcon:hover p::after {
  right: -60px !important;
}

.uaJsIcon:hover .LearnMoreLayout .moreBtn:before {
  right: 13px;
}

.uaJsIcon:hover .moveColumn:before {
  right: 13px;
}

.uaJsIcon:hover .cardColumn .txtDetailCard:before {
  right: 13px !important;
}

.uaJsIcon:hover .moveCard .LearnMoreLayout .moreBtn:before {
  right: 13px;
}

.uaJsIcon:hover .moveBtn span:before {
  right: 13px !important;
}

.uaJsIcon:hover .joiningY:before {
  right: -13px !important;
}

.uaJsIcon:hover.linkBox:hover a::before {
  right: 10px;
}

.uaJsIcon:hover.subNavUl li:hover a::after {
  right: -13px;
}

.uaJsIcon:hover.news a::after {
  right: 10px !important;
}

.uaJsIcon:hover.blog .blogBox::after {
  right: 5px !important;
}

.uaJsIcon:hover .archiveContent::after {
  right: 5px !important;
}

.uaJsIcon:hover.blogLinkBtn a::after {
  right: 20px !important;
}

.uaPageTop:hover::before {
  animation: arrow 0.5s 0s infinite alternate;
}

@keyframes arrow {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0.5;
    top: -13px;
  }
}

/* ボタン装飾
------------------------------------------------------------- */
.LearnMoreLayout {
  display: block;
  width: 100%;
}

.LearnMoreLayout a, .LearnMoreLayout .moreBtn {
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 1.4px;
  color: #4b4b4b;
  position: relative;
  padding-right: 40px;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .LearnMoreLayout a, .LearnMoreLayout .moreBtn {
    font-size: 1.2rem;
    padding-right: 20px;
  }
}

.LearnMoreLayout a.topMore, .LearnMoreLayout .moreBtn.topMore {
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .LearnMoreLayout a.topMore, .LearnMoreLayout .moreBtn.topMore {
    font-size: 1.2rem;
  }
}

.moreBar {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 20px;
  height: 1px;
}

@media screen and (max-width: 767px) {
  .moreBar {
    width: 10px;
  }
}

.moreBar::after {
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #4b4b4b;
  animation: scroll 3s infinite normal;
}

@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  15%, 85% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.btn {
  display: block;
  max-width: 450px;
  width: 100%;
  text-align: center;
  padding-top: 60px;
  margin: 0 auto;
}

.btn a {
  display: block;
  font-size: 1.4rem;
  line-height: 1.3;
  letter-spacing: .7px;
  padding: 25px 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  .btn a {
    font-size: 1.2rem;
  }
}

.btn .moreBar {
  right: 13%;
}

@media screen and (max-width: 767px) {
  .btn .moreBar {
    right: 8%;
  }
}

.backToPageBtn {
  padding-top: 120px;
}

@media screen and (max-width: 767px) {
  .backToPageBtn {
    padding: 120px 20px 0;
  }
}

.backToPageBtn a {
  color: #4b4b4b;
  background: #fff;
  border: solid 1px #707070;
}

@media screen and (max-width: 767px) {
  .backToPageBtn a {
    font-size: 1.4rem;
  }
}

.backToPageBtn a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 13%;
  margin: auto;
  width: 24px;
  height: 24px;
  background-image: url("/images/icons/icon_back_page.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 767px) {
  .backToPageBtn a::before {
    width: 18px;
    height: 18px;
    left: 8%;
  }
}

@media screen and (max-width: 767px) {
  .schoolBtn {
    padding: 40px 20px 0;
  }
}

.schoolBtn a {
  color: #fff;
  background-color: #02377b;
}

.schoolBtn .moreBar::after {
  background-color: #fff;
}

.topicLinkBtn {
  display: inline-block;
  border: solid .5px #707070;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.topicLinkBtn a {
  font-size: 1.4rem;
  padding: 6px 35px !important;
  display: block !important;
  position: relative;
  text-decoration: none !important;
}

.topicLinkBtn .moreBar {
  right: 15px;
  width: 10px;
}

.archiveBtnLayout {
  max-width: none;
  background-color: #f8f9fb;
  padding: 5.555%;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .archiveBtnLayout {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .archiveBtnLayout {
    flex-direction: column;
    padding: 20px;
  }
}

.archiveBtnLayout p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 4.6rem;
  letter-spacing: 4.6px;
  color: #02377b;
  border-right: solid 1px #8d8d8d;
  padding-right: 8%;
  min-width: 64%;
}

.archiveBtnLayout p span {
  font-size: 3.6rem;
  letter-spacing: 3.6px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  .archiveBtnLayout p span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .archiveBtnLayout p {
    padding-right: 0;
    border: none;
    border-bottom: solid 1px #8d8d8d;
    padding-bottom: 5.555%;
    margin-bottom: 5.555%;
  }
}

@media screen and (max-width: 767px) {
  .archiveBtnLayout p {
    font-size: 2.5rem;
    padding-right: 0;
    border: none;
    border-bottom: solid 1px #8d8d8d;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.archiveBtnLayout a {
  width: 30%;
  font-size: 1.6rem;
  line-height: 2;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  background-color: #02377b;
  padding: 20px 0;
  margin-left: 6%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .archiveBtnLayout a {
    margin: auto;
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .archiveBtnLayout a {
    font-size: 1.4rem;
    padding: 15px 0;
    margin: auto;
    width: 80%;
  }
}

.archiveBtnLayout a .moreBar::after {
  background-color: #fff;
}

/* カードレイアウト
------------------------------------------------------------- */
.commonCardLayout {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.commonCardLayout .card {
  background-color: #ffffff;
  width: 31.2962%;
  min-height: 275px;
  margin-right: 3.05555%;
  margin-bottom: 3.7%;
}

.commonCardLayout .card:nth-child(3n) {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .commonCardLayout .card {
    width: 100%;
    margin-right: 0;
    min-height: inherit;
  }
}

.commonCardLayout .card a {
  display: block;
  border: solid 1px #c4c4c4;
  padding: 8.9552% 7.46268% 14.3283%;
  position: relative;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .commonCardLayout .card a {
    padding: 7.22535% 4.22535% 20.2676%;
  }
}

.commonCardLayout .card a.notCursor {
  cursor: default;
}

.commonCardLayout .card .column {
  display: block;
}

.commonCardLayout .card .column .typeColumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commonCardLayout .card .column .typeColumn p.type {
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 1.8px;
}

@media screen and (max-width: 767px) {
  .commonCardLayout .card .column .typeColumn p.type {
    font-size: 1.4rem;
    letter-spacing: 1.4px;
  }
}

.commonCardLayout .card .column .typeColumn p.type.seminar {
  color: #b59738;
}

.commonCardLayout .card .column .typeColumn p.type.topic {
  color: #1f9a9a;
}

.commonCardLayout .card .column .typeColumn p.type.tax {
  color: #b59738;
}

.commonCardLayout .card .column .typeColumn p.type.juku {
  color: #4f96b1;
}

.commonCardLayout .card .column .typeColumn p.type.annual {
  color: #b54a1b;
}

.commonCardLayout .card .column .typeColumn time {
  display: block;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 1.4px;
  color: #8d8d8d;
}

@media screen and (max-width: 767px) {
  .commonCardLayout .card .column .typeColumn time {
    font-size: 1.2rem;
  }
}

.commonCardLayout .card .column h2 {
  padding-top: 10px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-align: left;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  .commonCardLayout .card .column h2 {
    font-size: 1.4rem;
    padding-top: 15px;
  }
}

.commonCardLayout .card .column .txt {
  padding-top: 20px;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: left;
  color: #4b4b4b;
  font-size: 1.4rem;
}

.commonCardLayout .card .column hr {
  text-align: right;
  display: block;
  width: 18px;
  height: 3px;
  background-color: #8D8D8D;
  border-top: 0;
  position: absolute;
  bottom: 0;
  right: 10px;
  margin: 30px;
}

@media screen and (max-width: 767px) {
  .commonCardLayout .card .column hr {
    width: 20px;
    height: 1px;
    margin: 15px 15px 30px;
  }
}

/* ================================================================
波アニメーション
================================================================ */
.testUrl {
  display: block;
}

.testUrl h2 {
  display: block;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 3em;
}

.testUrl ul li a {
  display: block;
  text-align: center;
  font-size: 2rem;
}

.scrTest {
  width: 100%;
  height: 2500px;
  background-color: beige;
}

.scrTest .box1,
.scrTest .box2 {
  height: 600px;
  width: 80%;
  margin: 0 auto;
}

.scrTest .box1 h2,
.scrTest .box2 h2 {
  color: #fff;
  font-size: 5rem;
}

.scrTest .box1 hr,
.scrTest .box2 hr {
  margin: 1em;
  max-width: 90%;
  margin: 3em 0;
  border-top: 0;
  background-color: #000;
  transition: 1.5s;
  width: 0;
}

.scrTest .box1 hr.animate,
.scrTest .box2 hr.animate {
  width: 100%;
}

.scrTest .box1 {
  background-color: forestgreen;
}

.scrTest .box2 {
  background-color: darkgoldenrod;
}

/************************************
トップページ
***********************************/
/*トップページ*/
/* ================================================================================
トップページ
================================================================================ */
#indexTop {
  padding-top: 0;
  /*プライムグローバル*/
  /*infoBnr*/
  /*事業内容*/
  /*会社情報 & グループ企業・提携企業*/
  /*採用情報*/
  /*トピックス*/
  /*青山綜合経営塾（青山塾）*/
}

#indexTop .loaderBg {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 999;
}

#indexTop .loaderBg .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

#indexTop .loaderBg .loader img {
  width: 188px;
  display: inline-block;
}

#indexTop .loaderBg .loader span {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 50%;
  height: 2px;
  border-radius: 2px;
}

#indexTop .loaderBg .loader span::after {
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #02377b;
  animation: scroll 2.4s infinite normal;
}

#indexTop .mainVisualLayout {
  display: block;
  width: 100%;
  padding-top: 60.76134699%;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainVisualLayout {
    height: 830px;
    padding-top: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout {
    padding-top: 58.3125%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout {
    padding-top: 95.3125%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout {
    padding-top: 161.7333%;
    margin-bottom: 44.6666%;
  }
}

#indexTop .mainVisualLayout .setPosTxt {
  position: absolute;
  top: 0;
  left: 0;
  width: 50vw;
  z-index: 100;
  padding-right: 7.754612%;
  display: none;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainVisualLayout .setPosTxt {
    width: 100%;
    max-width: 1366px;
    margin: auto;
    right: 0;
    padding: 257px 0 0 157px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1320px) {
  #indexTop .mainVisualLayout .setPosTxt {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .setPosTxt {
    max-width: 250px;
    margin-top: 20.833333%;
    padding-right: 15px;
    left: 35px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .setPosTxt {
    padding-right: 0;
    width: 57.866666%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
  }
}

#indexTop .mainVisualLayout .txtBox {
  box-sizing: inherit;
  width: 100%;
  max-width: 335px;
  margin-left: auto;
  margin-right: 14.641288%;
  margin-top: 36.603221%;
}

@media screen and (min-width: 1366px) {
  #indexTop .mainVisualLayout .txtBox {
    margin: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .txtBox {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .txtBox {
    max-width: none;
    margin: 42.6666% 0 0 6%;
  }
}

#indexTop .mainVisualLayout .txtBox h1 {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 6rem;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 6px;
  color: #004092;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .txtBox h1 {
    font-size: 4.2rem;
    letter-spacing: 4.2px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .txtBox h1 {
    font-size: 4rem;
  }
}

#indexTop .mainVisualLayout .txtBox p {
  padding-top: 50px;
  max-width: 320px;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #000000;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .txtBox p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .txtBox p {
    font-size: 1.6rem;
    line-height: 1.75;
    letter-spacing: 1.5px;
    max-width: 390px;
    padding-top: 6.22119%;
    padding-right: 30px;
  }
}

#indexTop .mainVisualLayout .keyVisualPos {
  position: absolute;
  top: 0;
  right: 0;
  width: 56.954612%;
  height: 100%;
  transform: translateY(30%);
  z-index: 2;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .keyVisualPos {
    width: 62.109375%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .keyVisualPos {
    width: 85.73333%;
  }
}

#indexTop .mainVisualLayout .keyVisualPos .swiper-container {
  width: 100%;
  height: 100%;
}

#indexTop .mainVisualLayout .keyVisualPos .slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
}

#indexTop .mainVisualLayout .keyVisualPos .slide.bg2 {
  background-image: url("/images/index/index_img_main_02@2x.jpg");
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .keyVisualPos .slide.bg2 {
    background-image: url("/images/index/index_img_main_sp_02.jpg");
  }
}

#indexTop .mainVisualLayout .keyVisualPos .slide.bg3 {
  background-image: url("/images/index/index_img_main_03@2x.jpg");
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .keyVisualPos .slide.bg3 {
    background-image: url("/images/index/index_img_main_sp_03.jpg");
  }
}

#indexTop .mainVisualLayout .postSeminarBox {
  position: absolute;
  max-width: 476px;
  width: 100%;
  bottom: 0;
  transform: translateY(50%);
  right: 0;
  z-index: 101;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  opacity: 0;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .postSeminarBox {
    max-width: 385px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .mainVisualLayout .postSeminarBox {
    max-width: 385px;
    bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .postSeminarBox {
    width: 82.66666%;
    max-width: none;
    margin-left: auto;
    bottom: 20px;
  }
}

#indexTop .mainVisualLayout .postSeminarBox a {
  display: flex;
  padding: 15px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .postSeminarBox a {
    padding: 3.636363%;
  }
}

#indexTop .mainVisualLayout .postSeminarBox a .thumb {
  width: 110px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .postSeminarBox a .thumb {
    width: 90px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .mainVisualLayout .postSeminarBox a .thumb {
    width: 90px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .postSeminarBox a .thumb {
    width: 27.6292%;
  }
}

#indexTop .mainVisualLayout .postSeminarBox a .titBox {
  padding: 4px 4px 4px 15px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox {
    align-content: center;
    padding: 0 12px 0;
  }
}

#indexTop .mainVisualLayout .postSeminarBox a .titBox .postTime {
  width: 100%;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox .postTime {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
  }
}

#indexTop .mainVisualLayout .postSeminarBox a .titBox .postTime span {
  border: solid 1px #004092;
  display: inline-block;
  padding: 2px 10px;
  color: #004092;
  font-size: 1.4rem;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox .postTime span {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox .postTime span {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox .postTime span {
    font-size: 1.1rem;
    padding: 2px 5px;
  }
}

#indexTop .mainVisualLayout .postSeminarBox a .titBox .postTime time {
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #767676;
  padding-left: 10px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox .postTime time {
    padding-left: 11px;
  }
}

#indexTop .mainVisualLayout .postSeminarBox a .titBox h2 {
  width: 100%;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.8px;
  color: #004092;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox h2 {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox h2 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .postSeminarBox a .titBox h2 {
    font-size: 1.2rem;
  }
}

#indexTop .mainVisualLayout .posWaveWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .posWaveWrap {
    z-index: 2;
  }
}

#indexTop .mainVisualLayout .posWaveWrap .waveMax {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 156.5885%;
  left: -30.014641%;
  opacity: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisualLayout .posWaveWrap .waveMax {
    top: 0;
    margin-top: 108%;
    width: 285.2%;
    left: -80.93333%;
    bottom: inherit;
  }
}

#indexTop .mainVisualLayout .posWaveWrap .waveMax object {
  width: 100%;
}

#indexTop .setSeminarLayout {
  max-width: 1366px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .setSeminarLayout {
    margin-bottom: 25.33333%;
    margin-top: -14.000%;
    z-index: 100;
  }
}

#indexTop .setSeminarLayout .primeLink {
  max-width: 680px;
  width: 42.4908424%;
  padding-left: 5.85651%;
  margin-bottom: 15.37335%;
  opacity: 0;
  transition: 2s opacity;
}

#indexTop .setSeminarLayout .primeLink.visible {
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 1366px) {
  #indexTop .setSeminarLayout .primeLink {
    width: 100%;
    max-width: none;
    margin-bottom: 210px;
    margin-left: auto;
    padding-left: 157px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .setSeminarLayout .primeLink {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .setSeminarLayout .primeLink {
    padding: 30px 0 0 50px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .setSeminarLayout .primeLink {
    display: none;
  }
}

#indexTop .setSeminarLayout .primeLink a {
  display: flex;
  align-items: center;
  padding: 14px 0 0;
}

#indexTop .setSeminarLayout .primeLink a img {
  max-width: 140px;
}

#indexTop .setSeminarLayout .primeLink a p {
  padding-left: 14px;
  font-size: 1.2rem;
  line-height: 1;
  color: #707070;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .setSeminarLayout .primeLink a p {
    font-size: 1.1rem;
  }
}

#indexTop .infoBnrLayout {
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  #indexTop .infoBnrLayout {
    padding: 0;
  }
}

#indexTop .infoBnrLayout .infoBnr {
  max-width: 859px;
  width: 100%;
  margin: 0 auto 15.37335%;
  background-color: #f8f9fb;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoBnrLayout .infoBnr {
    margin: 0 auto 185px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .infoBnrLayout .infoBnr {
    margin: 0 auto 20.37335%;
  }
}

#indexTop .infoBnrLayout .infoBnr a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
}

@media screen and (max-width: 767px) {
  #indexTop .infoBnrLayout .infoBnr a {
    padding: 16px;
  }
}

#indexTop .infoBnrLayout .infoBnr .moreBar {
  right: 32px;
}

@media screen and (max-width: 767px) {
  #indexTop .infoBnrLayout .infoBnr .moreBar {
    right: 8px;
  }
}

#indexTop .infoBnrLayout .infoBnrTit {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  line-height: 1;
  color: #fff;
  padding: 15px 16px;
  margin: 0 32px 0 0;
  background-color: #02377b;
}

@media screen and (max-width: 767px) {
  #indexTop .infoBnrLayout .infoBnrTit {
    font-size: 1rem;
    padding: 8px;
    margin: 0 12px 0 0;
    max-width: 100%;
  }
}

#indexTop .infoBnrLayout .infomation {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .infoBnrLayout .infomation {
    padding: 0 32px 0 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .infoBnrLayout .infomation {
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 8px 0 0;
    max-width: 100%;
  }
}

#indexTop .infoBnrLayout .infomation .date {
  font-size: 1.4rem;
  letter-spacing: 1.4px;
  line-height: 1.4;
  color: #8d8d8d;
  padding: 0 16px 0 0;
  margin: 0 16px 0 0;
  border-right: solid 1px #c4c4c4;
}

@media screen and (max-width: 767px) {
  #indexTop .infoBnrLayout .infomation .date {
    font-size: 1rem;
    border: none;
    margin: 0 0 5px;
    width: 100%;
    max-width: 100%;
  }
}

#indexTop .infoBnrLayout .infomation .content {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  line-height: 1.4;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #indexTop .infoBnrLayout .infomation .content {
    font-size: 1.4rem;
    width: 100%;
    max-width: 100%;
  }
}

#indexTop .serviceMovesLayout:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 90.04392%;
  height: 100%;
  background-color: #e9eef5;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout:before {
    width: 90.221642%;
  }
}

#indexTop .serviceMovesLayout h2,
#indexTop .serviceMovesLayout .businessContentTxt {
  position: relative;
  z-index: 1;
}

#indexTop .serviceMovesLayout .heading {
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout .heading {
    max-width: 1200px;
    margin: 0 auto;
  }
}

#indexTop .serviceMovesLayout .heading h2 {
  position: absolute;
  line-height: 1;
  letter-spacing: 9px;
  top: -64px;
  left: 8.41874%;
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout .heading h2 {
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .heading h2 {
    font-size: 4rem;
    top: -.6em;
    left: 5.21512%;
    letter-spacing: 5px;
    font-weight: 500;
  }
}

#indexTop .serviceMovesLayout hr.headingTop {
  position: absolute;
  top: 50px;
  max-width: 500px;
  border-top: 0;
  margin: 0;
  border-bottom: 1px solid #c4c4c4;
  transition: 3s all;
  width: 100%;
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout hr.headingTop {
    max-width: none;
    width: 36.60322%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout hr.headingTop {
    top: 22px;
    max-width: 54.75880%;
  }
}

#indexTop .serviceMovesLayout .businessContentTxt {
  max-width: 1190px;
  padding: 60px 20px 0;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 769px) and (max-width: 1110px) {
  #indexTop .serviceMovesLayout .businessContentTxt {
    padding: 8% 0 0 8.41874%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .businessContentTxt {
    padding: 6.66666% 20px 0;
  }
}

#indexTop .serviceMovesLayout .businessContentTxt .contentTxt {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 769px) and (max-width: 1110px) {
  #indexTop .serviceMovesLayout .businessContentTxt .contentTxt {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .businessContentTxt .contentTxt {
    display: block;
  }
}

#indexTop .serviceMovesLayout .businessContentTxt .contentTxt h3 {
  font-size: 3.2rem;
  line-height: 1.75;
  letter-spacing: 2.2px;
  color: #02377b;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .serviceMovesLayout .businessContentTxt .contentTxt h3 {
    padding-left: 22px;
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .businessContentTxt .contentTxt h3 {
    font-size: 1.8rem;
    letter-spacing: 1.3px;
    line-height: 1.7;
    padding-bottom: 10.5633%;
    padding-left: 2.81690%;
  }
}

#indexTop .serviceMovesLayout .businessContentTxt .contentTxt p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 2.22;
  letter-spacing: 1.8px;
  color: #000000;
  max-width: 913px;
  padding: 25px 20px 0;
}

@media screen and (min-width: 769px) and (max-width: 1110px) {
  #indexTop .serviceMovesLayout .businessContentTxt .contentTxt p {
    padding-left: 80px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .serviceMovesLayout .businessContentTxt .contentTxt p {
    max-width: 740px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .serviceMovesLayout .businessContentTxt .contentTxt p {
    max-width: 500px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .businessContentTxt .contentTxt p {
    padding: 0 0 0 14.0845%;
    font-size: 1.5rem;
  }
}

#indexTop .serviceMovesLayout .businessContentTxt .LearnMoreLayout {
  text-align: right;
  padding-top: 20px;
}

#indexTop .serviceMovesLayout .posWaveWrap {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  padding-top: 8.78477%;
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout .posWaveWrap {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .posWaveWrap {
    padding-top: 30.6666%;
  }
}

#indexTop .serviceMovesLayout .posWaveWrap .waveMax {
  position: absolute;
  bottom: -131.71303%;
  left: -10%;
  width: 166.5885%;
  opacity: 0;
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout .posWaveWrap .waveMax {
    bottom: -160px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .posWaveWrap .waveMax {
    width: 203.3333%;
    bottom: inherit;
    top: 0;
    margin-top: 10.6666%;
  }
}

#indexTop .serviceMovesLayout .posWaveWrap .waveMax object {
  width: 100%;
}

#indexTop .serviceMovesLayout .transitionEachPage {
  display: block;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage {
    padding-top: 53.33333%;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage.fund {
  background-image: url("/images/index/service_bg1_pc.jpg");
}

@media only screen and (min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #indexTop .serviceMovesLayout .transitionEachPage.fund {
    background-image: url("/images/index/service_bg1_pc@2x.jpg");
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage.fund {
    background-image: url("/images/index/service_bg1_sp@2x.jpg");
  }
}

#indexTop .serviceMovesLayout .transitionEachPage.corporate {
  background-image: url("/images/index/service_bg2_pc@2x.jpg");
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage.corporate {
    background-image: url("/images/index/service_bg2_sp@2x.jpg");
  }
}

#indexTop .serviceMovesLayout .transitionEachPage.corporate .setPosLayout {
  margin-left: auto;
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout {
  width: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout {
    width: 100%;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .sizeAdjustment {
    max-width: 600px;
    margin-left: auto;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .sizeAdjustment.corpS {
  max-width: 512px;
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .sizeAdjustment.corpS {
    margin-left: 85px;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox {
  padding: 7.32064% 11.71303% 0 12.44509%;
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox {
    padding: 7.32064% 11.71303% 0 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox {
    padding: 5.33333% 5.33333% 0 8%;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox h3 {
  font-size: 3.6rem;
  line-height: 1;
  letter-spacing: 3.6px;
  color: #ffffff;
  font-family: "Libre Baskerville";
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox h3 {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox h3 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox h3 {
    font-size: 2rem;
    letter-spacing: 2px;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox span {
  padding-top: 8px;
  display: block;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 1.8px;
  color: #ffffff;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox span {
    padding-top: 10px;
    font-size: 1.2rem;
    letter-spacing: 1.2px;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .titBox hr {
  margin: 13px 0 0;
  border-top: 2px solid #b59738;
  max-width: 185px;
  width: 21.5384%;
  display: block;
  padding: 0;
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList {
  padding: 3.66032% 11.71303% 12.44509% 12.44509%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media screen and (min-width: 1366px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList {
    padding: 3.66032% 11.71303% 12.44509% 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList {
    padding: 3.66032% 11.71303% 10.06032% 12.44509%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList {
    padding: 3.66032% 8.71303% 10.06032% 10.44509%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList {
    display: block;
    padding: 7.333333% 5.33333% 13.33333% 8%;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList p.txt {
  width: 100%;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList p.txt {
    line-height: 2;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul {
  padding-top: 9.6525%;
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul {
    padding-top: 10.76923%;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li {
  position: relative;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.6px;
  transform: translateY(0);
  margin-bottom: 20px;
  opacity: 0;
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li:last-child {
  margin-bottom: 0;
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li a {
  display: block;
  color: #fff;
  padding: 0 105px 0 0;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li a {
    padding: 0 85px 0 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li a {
    padding-right: 0;
    font-size: 1.4rem;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li a::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 18px;
  height: 1px;
  background: #fff;
  margin: auto;
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li a .moreBar {
  width: 18px;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li a .moreBar {
    width: 11px;
  }
}

#indexTop .serviceMovesLayout .transitionEachPage .setPosLayout .txtList ul li a .moreBar::after {
  background-color: #fff;
}

#indexTop .aboutAndGroupMovesLayout {
  width: 100%;
  background-image: url("/images/index/decoration_background.jpg");
  /*会社情報*/
  /*グループ企業・提携企業*/
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout {
    padding-bottom: 14.6666%;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox {
  position: relative;
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth {
  width: 100%;
  padding: 13.54319% 0 0 0;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth {
    padding: 185px 0 0 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth {
    padding: 25.33333% 0 0;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap {
  display: flex;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap {
    flex-wrap: wrap;
  }
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap::before {
    content: '';
    position: absolute;
    left: 0;
    top: 25px;
    background-color: #e9eef5;
    width: 92%;
    padding-top: 100%;
    z-index: 0;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .photo {
  width: 58.56515%;
  padding-top: 41.36163%;
  background-image: url("/images/index/about_thumbnail@2x.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .photo {
    height: 565px;
    padding-top: 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .photo {
    background-image: url("/images/index/about_thumbnail_sp.jpg");
    width: 100%;
    padding-top: 70.66666%;
    order: 2;
    position: relative;
    z-index: 1;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn {
  display: block;
  flex: 1;
  flex-wrap: wrap;
  padding: 3.6603221% 45px 0 6.5885797%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn {
    flex: none;
    width: 100%;
    padding: 0 0 17.3333% 20px;
    order: 1;
    position: relative;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn h2 {
  font-family: "Cormorant";
  line-height: 1;
  font-weight: 500;
  letter-spacing: 5px;
  color: #b59738;
  width: 100%;
  text-align: right;
  padding-bottom: 6.557377%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn h2 {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn h2 {
    font-size: 7.5rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn h2 {
    padding-bottom: 3.52112%;
    padding-right: 20px;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn hr {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 11.71303%;
  width: 49.78038%;
  transition: 2s all;
  border-top: 1px solid #c4c4c4;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn hr {
    width: 59.78038%;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn hr {
    margin-top: 170px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1110px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn hr {
    margin-top: 140px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn hr {
    margin-top: 13.71303%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn hr {
    margin-top: 14.71303%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn hr {
    position: relative;
    margin: 0 0 3.52112% auto;
    width: 36.66666%;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn h3 {
  width: 100%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1.75;
  letter-spacing: 3.2px;
  text-align: right;
  color: #02377b;
  padding-bottom: 5.46448%;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn h3 {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn h3 {
    font-size: 1.8rem;
    padding-bottom: 9.85915%;
    padding-right: 20px;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn p.txt {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 2.22;
  letter-spacing: 1.8px;
  color: #000000;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn p.txt {
    font-size: 1.7rem;
    line-height: 2;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn p.txt {
    font-size: 1.5rem;
    line-height: 2;
    width: 100%;
    margin: 0 auto;
    padding-right: 40px;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn p.learnSp {
  display: none;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn p.learnSp {
    display: block;
    text-align: right;
    padding-top: 8.45070%;
    width: 97.46478%;
  }
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn p.learnSp a {
    font-size: 1.4rem;
    color: #707070;
    position: relative;
    display: inline-block;
    margin-right: 8.670520%;
    padding-right: 1.5em;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .setMaxWidth .posSetWrap .txtColumn p.learnSp a {
    font-size: 1.2rem;
    letter-spacing: 1.4px;
    margin-right: 14.67052%;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .LearnMoreLayout {
  width: 100%;
  text-align: right;
  padding-right: 8.0527086%;
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .LearnMoreLayout a:before {
  width: 0;
  transition: 1s;
}

#indexTop .aboutAndGroupMovesLayout .aboutBox .LearnMoreLayout a.ampA:before {
  width: 20px;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .LearnMoreLayout {
    margin-top: 1em;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .LearnMoreLayout {
    margin-top: 2em;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox .LearnMoreLayout {
    display: none;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap {
  width: 100%;
  position: relative;
  padding-top: 12.44509%;
  bottom: 0;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap {
    padding-top: 9.51683%;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap .waveMax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}

#indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap .waveMax object {
  width: 100%;
}

#indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap #waveVueAnimeBetaA {
  margin-top: -4.392386%;
}

#indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap #waveVueAnimeBetaB {
  margin-top: -2.9282%;
}

#indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap #waveVueAnimeBetaC {
  margin-top: -30px;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap #waveVueAnimeBetaC {
    margin-top: -30px;
  }
}

#indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap #waveVueAnimeBetaD {
  margin-top: -45px;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .aboutBox #beta.posWaveWrap #waveVueAnimeBetaD {
    margin-top: -45px;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox {
  padding-bottom: 14.6412%;
  padding-right: 80px;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox {
    padding: 0;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup {
  background: rgba(255, 255, 255, 0.6);
  padding: 22px 40px 75px 0;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup {
    padding: 22px 20px 75px 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup {
    padding: 0 0;
    background: rgba(255, 255, 255, 0);
    position: relative;
  }
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup::before {
    position: absolute;
    content: '';
    width: 90.66666%;
    height: calc(100% - 22px);
    z-index: 0;
    background-color: #fff;
    top: 22px;
    right: 0;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .titBox {
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .titBox {
    max-width: 1115px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .titBox {
    padding-bottom: 12px;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .titBox h2 {
  font-family: "Cormorant";
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 9px;
  color: #b59738;
  width: 100%;
  padding-left: 8.942457%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .titBox h2 {
    padding-left: 35px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .titBox h2 {
    font-size: 6.5rem;
    padding-left: 45px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .titBox h2 {
    padding: 0;
    font-size: 4rem;
    letter-spacing: 5px;
    font-weight: 500;
    padding-left: 20px;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup hr {
  max-width: 849px;
  margin: 30px 0 0;
  border-top: 1px solid #c4c4c4;
  width: 100%;
  display: block;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup hr {
    max-width: none;
    width: 62.225475%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup hr {
    width: 91%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup hr {
    width: 92%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup hr {
    border-top: 1px solid #c4c4c4;
    margin: 2px 0 12px;
    position: relative;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox {
  display: flex;
  justify-content: space-between;
  padding-left: 8.942457%;
  padding-top: 30px;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox {
    max-width: 1366px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox {
    padding-left: 45px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox {
    padding: 0;
    position: relative;
    display: block;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea {
  width: 51.101321%;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea {
    width: 100%;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1.2;
  letter-spacing: 3.2px;
  color: #02377b;
  padding-bottom: 6.89655%;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea h3 {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea h3 {
    font-size: 1.8rem;
    padding: 0 0 9.33333% 5.33333%;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 2;
  letter-spacing: 1.8px;
  color: #000000;
  max-width: 473px;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea p {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea p {
    max-width: none;
    width: 78.6666%;
    margin-left: auto;
    padding-right: 20px;
    font-size: 1.5rem;
    line-height: 2;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea .LearnMoreLayout {
  max-width: 473px;
  text-align: right;
  padding-top: 7.75862%;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .txtArea .LearnMoreLayout {
    max-width: none;
    margin-left: auto;
    padding: 9.33333% 11.6000% 0 0;
    width: 100%;
  }
}

#indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .photo {
  padding-top: 10px;
  width: 45.81497%;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .photo {
    padding-top: 11.3333%;
    width: 100%;
    padding: 11.3333% 20px 15.33333%;
  }
  #indexTop .aboutAndGroupMovesLayout .groupAndPartnersBox .setMaxWidthGroup .txtBox .photo img {
    width: 83.0985%;
    margin-left: auto;
  }
}

#indexTop .recruitMovesLayout .setFlexBox {
  position: relative;
  z-index: 10;
  display: flex;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox {
    display: block;
    padding-bottom: 25.6666%;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox,
#indexTop .recruitMovesLayout .setFlexBox .moveBox {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox,
  #indexTop .recruitMovesLayout .setFlexBox .moveBox {
    width: 100%;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox {
  padding: 8.0527% 6.588579% 0 8.0527%;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox {
    padding: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox {
    padding: 8.0527% 2.588579% 0 45px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox {
    padding: 25px 0 0;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox .sizeAdjustment {
    max-width: 684px;
    margin-left: auto;
    padding: 110px 85px 0 111px;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox h2 {
  font-family: "Cormorant";
  line-height: 0.7;
  font-weight: 700;
  letter-spacing: 9px;
  color: #b59738;
  width: 100%;
  padding-bottom: 14.49275%;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox h2 {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox h2 {
    padding-bottom: 10.49275%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox h2 {
    font-size: 7.2rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox h2 {
    padding-bottom: 25px;
    padding-left: 20px;
    font-weight: 500;
    letter-spacing: 5px;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1;
  letter-spacing: 3.2px;
  color: #02377b;
  padding-bottom: 8.281573%;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox h3 {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox h3 {
    padding-left: 20px;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    padding-bottom: 9.33333%;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 2.22;
  letter-spacing: 1.8px;
  color: #000000;
  padding-bottom: 17.598343%;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox p {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox p {
    max-width: none;
    padding-left: 10%;
    margin-left: auto;
    padding-right: 20px;
    font-size: 1.5rem;
    line-height: 2;
    padding-bottom: 0;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout {
  display: block;
  text-align: right;
  position: relative;
  padding-top: 18px;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout {
    flex: 1;
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout {
    padding: 36.26666% 8.93333% 13.33333% 0;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout:before {
  content: "";
  position: absolute;
  width: 76.6045%;
  padding-top: 36.23188%;
  background-image: url("/images/index/logo_img@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  left: 0;
  top: 0;
  margin-left: -15.5279%;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout:before {
    width: 55.73333%;
    padding-top: 26.4000%;
    margin: 5.33333% 0 0 0;
    left: 12px;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout a {
  display: inline-block;
  line-height: 1.5;
  letter-spacing: 1.6px;
  color: #707070;
  position: relative;
  padding-right: 2em;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout a {
    font-size: 1.3rem;
    padding-right: 25px;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout a.windowIcon:before {
  width: 21px;
  height: 18px;
  top: 3px;
  right: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .txtBox .moveBoxLayout a.windowIcon:before {
    width: 15px;
    height: 14px;
    top: 0px;
  }
}

#indexTop .recruitMovesLayout .setFlexBox hr.recruitTop {
  max-width: 440px;
  margin: 15.7467% 0 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-top: 1px solid #c4c4c4;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruitMovesLayout .setFlexBox hr.recruitTop {
    margin: 200px 0 0;
    left: 0;
    width: 32.2108%;
    max-width: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1110px) {
  #indexTop .recruitMovesLayout .setFlexBox hr.recruitTop {
    margin-top: 170px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .recruitMovesLayout .setFlexBox hr.recruitTop {
    margin: 175px 0 0;
    width: 80%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .recruitMovesLayout .setFlexBox hr.recruitTop {
    width: 40%;
    margin: 130px 0 0;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox hr.recruitTop {
    margin: 66px 0 0;
    border-top: 1px solid #c4c4c4;
    max-width: 375px;
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .moveBox .thumbnailMoveBox {
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .moveBox .thumbnailMoveBox:first-child {
    padding-bottom: 15px;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .moveBox a {
  position: relative;
  display: block;
}

#indexTop .recruitMovesLayout .setFlexBox .moveBox a .txtArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 20.4978% 0 0 0;
}

#indexTop .recruitMovesLayout .setFlexBox .moveBox a .txtArea h4 {
  font-size: 2.2rem;
  line-height: 1.5;
  letter-spacing: 2.2px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .moveBox a .txtArea h4 {
    font-size: 1.4rem;
    padding-bottom: 5px;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .moveBox a .txtArea hr {
  max-width: 215px;
  margin: 0 auto 4.6852%;
  transition: 2s;
  width: 100%;
  border-top: 2px solid #fff;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .moveBox a .txtArea hr {
    max-width: none;
    width: 38.0281690%;
    border-top: 1px solid #fff;
    margin-bottom: 5px;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .moveBox a .txtArea p {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout .setFlexBox .moveBox a .txtArea p {
    font-size: 1.2rem;
    letter-spacing: 1.2px;
    line-height: 1;
  }
}

#indexTop .recruitMovesLayout .setFlexBox .moveBox img {
  width: 100%;
}

#indexTop .recruitMovesLayout #gamma.posWaveWrap {
  width: 100%;
  position: relative;
  padding-top: 14.64128%;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruitMovesLayout #gamma.posWaveWrap {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout #gamma.posWaveWrap {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: inherit;
    padding-top: 28.0000%;
    margin-top: 100%;
  }
}

#indexTop .recruitMovesLayout #gamma.posWaveWrap #waveVueAnimeGammaA {
  width: 41.72767%;
  margin-bottom: 6.22254%;
}

@media screen and (min-width: 1366px) {
  #indexTop .recruitMovesLayout #gamma.posWaveWrap #waveVueAnimeGammaA {
    margin-bottom: 85px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout #gamma.posWaveWrap #waveVueAnimeGammaA {
    left: -140px;
  }
}

#indexTop .recruitMovesLayout #gamma.posWaveWrap #waveVueAnimeGammaB {
  width: 100%;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout #gamma.posWaveWrap #waveVueAnimeGammaB {
    margin-bottom: 0;
  }
}

#indexTop .recruitMovesLayout #gamma.posWaveWrap .waveMax {
  position: absolute;
  bottom: 0;
  left: 0%;
  opacity: 0;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .recruitMovesLayout #gamma.posWaveWrap .waveMax {
    transform: rotate(0deg);
    bottom: -20px;
  }
}

#indexTop .recruitMovesLayout #gamma.posWaveWrap .waveMax object {
  width: 100%;
}

#indexTop .topicsMovesLayout {
  max-width: 1120px;
  padding: 0 20px;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .topicsMovesLayout {
    padding: 0 50px 0 8.0527%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout {
    padding: 0 20px 0 45px;
  }
}

#indexTop .topicsMovesLayout .titBox {
  position: relative;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .titBox {
    padding-bottom: 12.0140%;
  }
}

#indexTop .topicsMovesLayout .titBox h2 {
  font-family: "Cormorant";
  font-size: 7.2rem;
  font-weight: normal;
  line-height: 1.3;
  letter-spacing: 7.2px;
  text-align: center;
  color: #02377b;
  padding-bottom: 50px;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout .titBox h2 {
    font-size: 5.2rem;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .titBox h2 {
    font-size: 3rem;
    line-height: 1;
    letter-spacing: 3px;
    padding-bottom: 9px;
  }
}

#indexTop .topicsMovesLayout .titBox hr {
  max-width: 534px;
  margin: 0 0 0;
  position: absolute;
  left: 0;
  top: 60px;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: 2s;
  width: 0;
  border-top: 1px solid #c4c4c4;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout .titBox hr {
    top: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .titBox hr {
    position: static;
    border-top: 1px solid #c4c4c4;
    margin-bottom: 9px;
  }
}

#indexTop .topicsMovesLayout .titBox hr.ampA {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .titBox hr.ampA {
    width: 75.0704%;
  }
}

#indexTop .topicsMovesLayout .titBox h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 2.4px;
  text-align: center;
  color: #02377b;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout .titBox h3 {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .titBox h3 {
    font-size: 1.6rem;
    letter-spacing: 1.5px;
  }
}

#indexTop .topicsMovesLayout .postInfoList {
  display: block;
  width: 100%;
  padding-top: 5.555555%;
  padding-bottom: 23.148148%;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList {
    padding-top: 0;
    padding-bottom: 30.333333%;
  }
}

#indexTop .topicsMovesLayout .postInfoList ul {
  display: flex;
  width: 100%;
  margin-bottom: 7.407407%;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList ul {
    display: block;
    margin-bottom: 14.08450%;
  }
}

#indexTop .topicsMovesLayout .postInfoList ul li {
  width: 31.296296%;
  margin-right: 3.05555%;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList ul li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

#indexTop .topicsMovesLayout .postInfoList ul li:last-child {
  margin-right: 0;
}

#indexTop .topicsMovesLayout .postInfoList ul li:last-child:last-child {
  margin-bottom: 0;
}

#indexTop .topicsMovesLayout .postInfoList ul li a {
  border: solid 1px #c4c4c4;
  display: block;
  width: 100%;
  height: 100%;
  padding: 8.92857% 8.92857% 13.39285%;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a {
    padding: 8.92857% 3.92857% 13.39285%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a {
    padding: 5.63380% 5.63380% 11.97183%;
  }
}

#indexTop .topicsMovesLayout .postInfoList ul li a.notCursor {
  cursor: default;
}

#indexTop .topicsMovesLayout .postInfoList ul li a.notCursor:before {
  display: none;
}

#indexTop .topicsMovesLayout .postInfoList ul li a:before {
  position: absolute;
  content: "";
  width: 19px;
  height: 3px;
  background-color: #8d8d8d;
  bottom: 25px;
  right: 24px;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a:before {
    width: 15px;
    height: 1px;
    right: 5.63380%;
  }
}

#indexTop .topicsMovesLayout .postInfoList ul li a .typeBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

#indexTop .topicsMovesLayout .postInfoList ul li a .typeBox p {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 1.8px;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .typeBox p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .typeBox p {
    font-size: 1.4rem;
    letter-spacing: 1.4px;
    line-height: 1;
  }
}

#indexTop .topicsMovesLayout .postInfoList ul li a .typeBox p.seminar {
  color: #b59738;
}

#indexTop .topicsMovesLayout .postInfoList ul li a .typeBox p.topic {
  color: #1f9a9a;
}

#indexTop .topicsMovesLayout .postInfoList ul li a .typeBox time {
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: 1.4px;
  color: #8d8d8d;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .typeBox time {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .typeBox time {
    font-size: 1.2rem;
    letter-spacing: 1.2px;
    line-height: 1;
  }
}

#indexTop .topicsMovesLayout .postInfoList ul li a .postTxtColumn h4 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.8px;
  color: #4b4b4b;
  padding-bottom: 12px;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .postTxtColumn h4 {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .postTxtColumn h4 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .postTxtColumn h4 {
    font-size: 1.4rem;
    padding-bottom: 14px;
  }
}

#indexTop .topicsMovesLayout .postInfoList ul li a .postTxtColumn p {
  letter-spacing: 1.6px;
  color: #767676;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .postTxtColumn p {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .postTxtColumn p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList ul li a .postTxtColumn p {
    font-size: 1.3rem;
  }
}

#indexTop .topicsMovesLayout .postInfoList .moveTopicsPage {
  display: block;
  max-width: 550px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList .moveTopicsPage {
    width: 77.46478%;
  }
}

#indexTop .topicsMovesLayout .postInfoList .moveTopicsPage a {
  display: block;
  border: solid 1px rgba(2, 55, 123, 0.85);
  padding: 30px 0;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 1.8px;
  color: #02377b;
  position: relative;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList .moveTopicsPage a {
    padding: 13px;
    font-size: 1.2rem;
    letter-spacing: 1.4px;
    line-height: 1;
  }
}

#indexTop .topicsMovesLayout .postInfoList .moveTopicsPage a::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 145px;
  margin: auto;
  width: 30px;
  height: 2px;
  background-color: #02377b;
}

@media screen and (max-width: 767px) {
  #indexTop .topicsMovesLayout .postInfoList .moveTopicsPage a::after {
    right: 50px;
    width: 15px;
    height: 1px;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn {
  width: 100%;
  max-width: 1120px;
  padding: 0 20px 125px;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .jukuMoveLayout .setMaxColumn {
    padding: 0 50px 125px 8.0527%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .jukuMoveLayout .setMaxColumn {
    padding: 0 20px 125px 45px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn {
    padding: 0 0 52%;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .titBox {
  display: block;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  color: #4b4b4b;
  padding-bottom: 4.16666666%;
  width: 96.2962%;
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .titBox {
    width: 100%;
    padding-bottom: 5.3333%;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .titBox h2 {
  font-size: 4.2rem;
  line-height: 1;
  letter-spacing: 4.2px;
  padding-bottom: 13px;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .jukuMoveLayout .setMaxColumn .titBox h2 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .titBox h2 {
    font-size: 2.1rem;
    line-height: 1;
    letter-spacing: 2.1px;
    padding-left: 20px;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .titBox h3 {
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 2px;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .jukuMoveLayout .setMaxColumn .titBox h3 {
    font-size: 1.6rem;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .titBox h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #707070;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .titBox h3::after {
    top: .5em;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .titBox h3 span {
  display: inline-block;
  position: relative;
  line-height: 1.4;
  padding-right: 5.09259%;
  background-color: #fff;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .titBox h3 span {
    font-size: 1.1rem;
    line-height: 1;
    letter-spacing: 1.1px;
    padding-left: 20px;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .moveColumn a {
  display: flex;
  background-color: rgba(189, 211, 240, 0.21);
  box-shadow: 6px 3px 6px 0 rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn a {
    box-shadow: 3px 1px 3px 0 rgba(0, 0, 0, 0.08);
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .moveColumn .thumb {
  width: 62.5925%;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .thumb {
    width: 57.4075%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .thumb {
    width: 50%;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .moveColumn .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox {
  width: 37.4075%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox {
    width: 42.5925%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox {
    width: 50%;
    justify-content: center;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox p {
  padding: 0 4.95049%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.2rem;
  line-height: 1.64;
  letter-spacing: 2px;
  color: #4b4b4b;
  text-align: center;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox p {
    font-size: 1.8rem;
    padding: 0 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox p {
    font-size: 1.6rem;
    line-height: 1.54;
    letter-spacing: 1px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox p {
    font-size: 1.4rem;
    letter-spacing: 1.4px;
    padding: 10.66% 8.4507% 0 8.4507%;
    text-align: left;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .workCall {
  font-size: 1.8rem;
  color: #B59738;
  padding: 0 4.95049%;
  line-height: 1.4;
  display: block;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 4.95%;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .workCall {
    font-size: 1.6rem;
    padding: 0 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .workCall {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .workCall {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .workCall {
    font-size: 1.3rem;
    text-align: left;
    margin-top: 8%;
    padding: 0 8.4507% 10.66%;
  }
}

#indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .LearnMoreLayout {
  width: 100%;
  text-align: right;
  padding-right: 4.95049%;
  padding-top: 17.32%;
  padding-bottom: 9.9009%;
}

@media screen and (min-width: 1366px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .LearnMoreLayout {
    padding-top: 70px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .LearnMoreLayout {
    padding-top: 9.9009%;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .jukuMoveLayout .setMaxColumn .moveColumn .txtBox .LearnMoreLayout {
    padding: 0 6.33802% 9.85915%;
  }
}

/************************************
事業内容
***********************************/
/*事業内容*/
/* ================================================================================
事業内容
================================================================================ */
#serviceIdx {
  overflow: hidden;
}

#serviceIdx .eachPageLinkTit {
  position: relative;
  border-bottom: solid 1px #a2a5a7;
  margin-bottom: 150px;
}

@media screen and (max-width: 767px) {
  #serviceIdx .eachPageLinkTit {
    margin: 0 20px 100px;
  }
}

#serviceIdx .eachPageLinkTit h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 4.8rem;
  line-height: 1.17;
  letter-spacing: 4.8px;
  color: #000000;
  display: inline-block;
  position: relative;
  padding-bottom: 20px;
}

#serviceIdx .eachPageLinkTit h2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  border-bottom: solid 1px #02377b;
}

@media screen and (max-width: 767px) {
  #serviceIdx .eachPageLinkTit h2 {
    font-size: 2.6rem;
  }
}

#serviceIdx .eachPageLinkTit span {
  position: absolute;
  left: 0;
  top: 100%;
  transform: translateY(20px);
  font-size: 1.8rem;
  line-height: 1.33;
  letter-spacing: 0.9px;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #serviceIdx .eachPageLinkTit span {
    font-size: 1.6rem;
  }
}

#serviceIdx .eachPageLinkTit.corporate h2::after {
  border-bottom-color: #0076a2;
}

#serviceIdx .eachPageLinkColumn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  counter-reset: number 0;
  position: relative;
  margin-bottom: 220px;
}

@media screen and (max-width: 767px) {
  #serviceIdx .eachPageLinkColumn {
    display: block;
    padding: 0 20px;
    margin-bottom: 120px;
  }
}

#serviceIdx .eachPageLinkColumn::before {
  content: "";
  background-color: #f8f9fb;
  width: 100vw;
  height: calc(100% + 30px);
  position: absolute;
  top: 50px;
  left: 75px;
  z-index: 0;
}

#serviceIdx .eachPageLinkColumn:after {
  content: "";
  display: block;
  flex-basis: 31%;
  height: 0;
}

#serviceIdx .eachPageLinkColumn.corporate::before {
  left: inherit;
  right: 75px;
}

#serviceIdx .eachPageLinkColumn.corporate .eachPageLinkColumnBox::before {
  color: #0076a2;
}

#serviceIdx .eachPageLinkColumn.corporate .eachPageLinkColumnBox::after {
  border-bottom-color: #0076a2;
}

#serviceIdx .eachPageLinkColumnBox {
  flex-basis: 31%;
  background-color: #fff;
  border: solid 1px #e1e5e8;
  position: relative;
  margin-top: 100px;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  #serviceIdx .eachPageLinkColumnBox {
    margin-top: 0;
    margin-bottom: 80px;
  }
}

#serviceIdx .eachPageLinkColumnBox::before {
  counter-increment: number;
  content: counter(number, decimal-leading-zero);
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 4.8px;
  color: #004092;
  position: absolute;
  top: -30px;
  right: 7%;
}

@media screen and (max-width: 767px) {
  #serviceIdx .eachPageLinkColumnBox::before {
    font-size: 4.2rem;
  }
}

#serviceIdx .eachPageLinkColumnBox::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  border-bottom: solid 2px #004092;
  width: 100%;
}

#serviceIdx .eachPageLinkColumnBox:nth-child(-n+3) {
  margin-top: 0;
}

#serviceIdx .eachPageLinkColumnBox a {
  padding: 40px 25px 30px;
}

#serviceIdx .eachPageLinkColumnBox h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 2.18;
  color: #4b4b4b;
  position: relative;
  padding-bottom: 35px;
}

@media screen and (max-width: 767px) {
  #serviceIdx .eachPageLinkColumnBox h3 {
    font-size: 1.8rem;
  }
}

#serviceIdx .eachPageLinkColumnBox h3::after {
  content: "";
  width: 28%;
  border-bottom: solid 1px #b59738;
  position: absolute;
  bottom: 15px;
  left: -40px;
}

#serviceIdx .eachPageLinkColumnBox p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
  padding-bottom: 50px;
}

#serviceIdx .eachPageLinkColumnBox .moreBtn {
  font-size: 1.3rem;
  color: #707070;
  position: absolute;
  bottom: 30px;
  right: 25px;
  padding: 0;
  padding-right: 40px;
}

@media screen and (max-width: 767px) {
  #serviceIdx .eachPageLinkColumnBox .moreBtn {
    font-size: 1.2rem;
    right: 6%;
    bottom: 8.2746%;
    padding-right: 20px;
  }
}

#serviceFund .serviceContent,
#serviceCorporate .serviceContent {
  max-width: 1320px;
  padding: 0 20px;
  margin: auto;
  position: relative;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceContent,
  #serviceCorporate .serviceContent {
    overflow: hidden;
  }
}

#serviceFund .serviceContent.cancell,
#serviceCorporate .serviceContent.cancell {
  overflow: visible;
}

#serviceFund .serviceContentLayout,
#serviceCorporate .serviceContentLayout {
  max-width: 1080px;
  margin: auto;
  padding-right: 350px;
}

@media screen and (min-width: 1366px) {
  #serviceFund .serviceContentLayout,
  #serviceCorporate .serviceContentLayout {
    padding-right: 280px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serviceFund .serviceContentLayout,
  #serviceCorporate .serviceContentLayout {
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceContentLayout,
  #serviceCorporate .serviceContentLayout {
    padding-right: 0;
  }
}

#serviceFund .serviceContent h1,
#serviceCorporate .serviceContent h1 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1.75;
  letter-spacing: 3.2px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceContent h1,
  #serviceCorporate .serviceContent h1 {
    font-size: 2rem;
    letter-spacing: 4px;
  }
}

#serviceFund .serviceContent h1 span,
#serviceCorporate .serviceContent h1 span {
  font-size: 2.8rem;
  line-height: 2;
  letter-spacing: 2.8px;
  color: #b59738;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceContent h1 span,
  #serviceCorporate .serviceContent h1 span {
    font-size: 1.4rem;
  }
}

#serviceFund .serviceContent h2,
#serviceCorporate .serviceContent h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 1.67;
  letter-spacing: 2.4px;
  color: #000000;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceContent h2,
  #serviceCorporate .serviceContent h2 {
    font-size: 1.7rem;
  }
}

#serviceFund .serviceContent .introTxt,
#serviceCorporate .serviceContent .introTxt {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2;
  letter-spacing: 1.2px;
  color: #4b4b4b;
}

#serviceFund .sideBar,
#serviceCorporate .sideBar {
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  z-index: 1;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serviceFund .sideBar,
  #serviceCorporate .sideBar {
    position: static;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #serviceFund .sideBar,
  #serviceCorporate .sideBar {
    position: static;
    margin-bottom: 60px;
  }
}

#serviceFund .sideBar .sideNav,
#serviceCorporate .sideBar .sideNav {
  width: 320px;
  border: solid 1px #eaeff3;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  padding-left: 30px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serviceFund .sideBar .sideNav,
  #serviceCorporate .sideBar .sideNav {
    border: solid 1px #b7b7b7;
    width: 100%;
    max-width: 310px;
    padding-left: 0;
    margin: auto;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  #serviceFund .sideBar .sideNav,
  #serviceCorporate .sideBar .sideNav {
    border: solid 1px #b7b7b7;
    width: 100%;
    max-width: 310px;
    padding-left: 0;
    margin: auto;
    margin-right: 0;
  }
}

#serviceFund .sideBar .sideNav dt,
#serviceCorporate .sideBar .sideNav dt {
  display: none;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.46;
  color: #000;
  padding: 15px 40px;
  position: relative;
}

#serviceFund .sideBar .sideNav dt::before,
#serviceCorporate .sideBar .sideNav dt::before {
  content: "";
  background-color: #b59738;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  margin: auto;
  width: 3px;
  height: 14px;
}

#serviceFund .sideBar .sideNav dt::after,
#serviceCorporate .sideBar .sideNav dt::after {
  content: "";
  background-image: url(/images/icons/icon_toggle_btn.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
  width: 12px;
  height: 6px;
  transition: .5s;
}

#serviceFund .sideBar .sideNav dt.open:after,
#serviceCorporate .sideBar .sideNav dt.open:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serviceFund .sideBar .sideNav dt,
  #serviceCorporate .sideBar .sideNav dt {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  #serviceFund .sideBar .sideNav dt,
  #serviceCorporate .sideBar .sideNav dt {
    display: block;
  }
}

#serviceFund .sideBar .sideNav dd,
#serviceCorporate .sideBar .sideNav dd {
  margin-top: 30px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serviceFund .sideBar .sideNav dd,
  #serviceCorporate .sideBar .sideNav dd {
    display: none;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  #serviceFund .sideBar .sideNav dd,
  #serviceCorporate .sideBar .sideNav dd {
    display: none;
    margin-top: 0;
  }
}

#serviceFund .sideBar .sideNav dd a,
#serviceCorporate .sideBar .sideNav dd a {
  display: block;
  font-size: 1.5rem;
  line-height: 1.47;
  color: #8d8d8d;
  padding-left: 15px;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serviceFund .sideBar .sideNav dd a,
  #serviceCorporate .sideBar .sideNav dd a {
    padding-left: 40px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  #serviceFund .sideBar .sideNav dd a,
  #serviceCorporate .sideBar .sideNav dd a {
    padding-left: 40px;
    margin-bottom: 15px;
  }
}

#serviceFund .sideBar .sideNav dd a.currentOn,
#serviceCorporate .sideBar .sideNav dd a.currentOn {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  color: #000000;
  border-left: solid 3px #b59738;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serviceFund .sideBar .sideNav dd a.currentOn,
  #serviceCorporate .sideBar .sideNav dd a.currentOn {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #serviceFund .sideBar .sideNav dd a.currentOn,
  #serviceCorporate .sideBar .sideNav dd a.currentOn {
    display: none;
  }
}

/*ファンドサービス*/
/* ================================================================================
ファンドサービス
================================================================================ */
#serviceFund h1 {
  color: #02377b;
}

#serviceFund .serviceProcess {
  margin-top: 80px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serviceFund .serviceProcess {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess {
    margin-top: 40px;
  }
}

#serviceFund .serviceProcess h2 {
  padding-left: 40px;
  margin-bottom: 40px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess h2 {
    padding-left: 30px;
    margin-bottom: 20px;
  }
}

#serviceFund .serviceProcess h2::before {
  content: "";
  background-image: url(/images/service/fund/text_number_01.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 4%;
  height: 117.5%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2%;
  margin: auto;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess h2::before {
    width: 6%;
  }
}

#serviceFund .serviceProcess h2:nth-of-type(2)::before {
  background-image: url(/images/service/fund/text_number_02.svg);
  height: 123.25%;
}

#serviceFund .serviceProcess h2:nth-of-type(3)::before {
  background-image: url(/images/service/fund/text_number_03.svg);
  height: 205%;
}

#serviceFund .serviceProcess dl {
  background-color: #f8f9fb;
  padding: 25px;
  margin-left: 45px;
  margin-bottom: 80px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess dl {
    padding: 20px;
    margin-left: 20px;
    margin-bottom: 40px;
  }
}

#serviceFund .serviceProcess dl::before, #serviceFund .serviceProcess dl::after {
  content: "";
  background-color: #d3d3d3;
  width: 1px;
  position: absolute;
  left: -45px;
  top: 0;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess dl::before, #serviceFund .serviceProcess dl::after {
    left: -20px;
  }
}

#serviceFund .serviceProcess dl::before {
  height: calc(100% + 40px);
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess dl::before {
    height: calc(100% + 30px);
  }
}

#serviceFund .serviceProcess dl::after {
  height: 4vw;
  max-height: 50px;
  top: inherit;
  bottom: -40px;
  transform: rotate(23deg);
  transform-origin: bottom;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess dl::after {
    bottom: -30px;
  }
}

#serviceFund .serviceProcess dl:last-of-type::after {
  height: 1px;
  width: calc(100% + 45px);
  transform: none;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess dl:last-of-type::after {
    width: calc(100% + 20px);
  }
}

#serviceFund .serviceProcess dl dt, #serviceFund .serviceProcess dl dd {
  padding-left: 25px;
}

#serviceFund .serviceProcess dl dt {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.5rem;
  line-height: 2.67;
  letter-spacing: 1.5px;
  color: #000000;
  position: relative;
}

#serviceFund .serviceProcess dl dt::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #4f96b1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

#serviceFund .serviceProcess dl dd {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  margin-bottom: 20px;
}

#serviceFund .serviceProcess dl dd:last-of-type {
  margin-bottom: 0;
}

#serviceFund .serviceProcess dl dd span {
  display: block;
  font-size: 1.3rem;
  line-height: 1.85;
  letter-spacing: 1.3px;
  color: #8d8d8d;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess dl dd span {
    font-size: 1.2rem;
  }
}

#serviceFund .serviceProcess a {
  display: block;
  font-size: 1.3rem;
  line-height: 1.5;
  text-align: right;
  color: #1e51a4;
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceProcess a {
    text-align: left;
  }
}

#serviceFund .plusService {
  border: solid 1px #d3d3d3;
  padding: 40px 35px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 210px 0 120px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #serviceFund .plusService {
    display: block;
    padding: 30px 20px 0;
    margin: 150px 0 60px;
  }
}

#serviceFund .plusService::before, #serviceFund .plusService::after {
  content: "";
  background-color: #8d8d8d;
  width: 2px;
  height: 60px;
  position: absolute;
  top: -120px;
  left: 0;
  right: 0;
  margin: auto;
}

@media screen and (max-width: 767px) {
  #serviceFund .plusService::before, #serviceFund .plusService::after {
    width: 1px;
    height: 30px;
    top: -70px;
  }
}

#serviceFund .plusService::after {
  transform: rotate(90deg);
}

#serviceFund .plusService dl {
  flex-basis: 45%;
  margin-bottom: 50px;
  padding-right: 5%;
}

@media screen and (max-width: 767px) {
  #serviceFund .plusService dl {
    margin-bottom: 30px;
  }
}

#serviceFund .plusService dl dt {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: 2.4px;
  color: #000;
  margin-bottom: 10px;
  padding-left: 20px;
  border-left: solid 1px #b59738;
}

@media screen and (max-width: 767px) {
  #serviceFund .plusService dl dt {
    font-size: 1.6rem;
    padding-left: 10px;
  }
}

#serviceFund .plusService dl dt span {
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 1.6px;
  color: #000;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  #serviceFund .plusService dl dt span {
    font-size: 1.2rem;
  }
}

#serviceFund .plusService dl dd {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  #serviceFund .plusService dl dd {
    padding-left: 10px;
  }
}

#serviceFund .serviceDetail {
  overflow: hidden;
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail {
    overflow: visible;
    margin-bottom: 60px;
  }
}

#serviceFund .serviceDetail h2 {
  position: relative;
  display: inline-block;
  margin: 80px 0 20px;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail h2 {
    margin: 50px 0 40px;
  }
}

#serviceFund .serviceDetail h2::before, #serviceFund .serviceDetail h2::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail h2::before, #serviceFund .serviceDetail h2::after {
    top: inherit;
    bottom: -15px;
    left: 0;
  }
}

#serviceFund .serviceDetail h2::before {
  width: 100px;
  transform: translate(20px, -3px);
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail h2::before {
    transform: translate(0, -3px);
  }
}

#serviceFund .serviceDetail h2::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail h2::after {
    transform: translate(0, 3px);
  }
}

#serviceFund .serviceDetail h2 span {
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  color: #000000;
}

#serviceFund .serviceDetail h2 + ul {
  margin-top: 0;
}

#serviceFund .serviceDetail h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2.5;
  letter-spacing: 1.6px;
  color: #02377b;
  margin: 60px 0 30px;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail h3 {
    font-size: 1.5rem;
    margin: 50px 0 25px;
  }
}

#serviceFund .serviceDetail p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail p {
    padding-left: 0;
  }
}

#serviceFund .serviceDetail p span {
  color: #004092;
  text-decoration: underline;
}

#serviceFund .serviceDetail p.attention {
  margin-top: 80px;
}

#serviceFund .serviceDetail .annotationTxt {
  font-size: 1.3rem;
  line-height: 1.8;
  color: #707070;
  margin-top: 20px;
  display: block;
}

#serviceFund .serviceDetail .annotationTxt a {
  color: #1e51a4;
}

#serviceFund .serviceDetail .annotationTxt a::after {
  content: "";
  width: 14px;
  height: 11px;
  display: inline-block;
  margin-left: 4px;
  background-image: url(/images/icons/icon_out_link_blue.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

#serviceFund .serviceDetail a {
  color: #004092;
  text-decoration: underline;
}

#serviceFund .serviceDetail ul {
  background-color: #f8f9fb;
  padding: 20px 30px;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail ul {
    padding: 20px;
  }
}

#serviceFund .serviceDetail ul li {
  margin-bottom: 5px;
}

#serviceFund .serviceDetail ul li:last-child {
  margin-bottom: 0;
}

#serviceFund .serviceDetail ul li p {
  padding-left: 15px;
  text-indent: -15px;
}

#serviceFund .serviceDetail ul li p::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #4f96b1;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail .imgBox {
    width: 100vw;
    margin: 0 -20px;
  }
}

#serviceFund .serviceDetail .imgBox .zoomBtn {
  display: none;
  padding: 0 20px;
  margin: 10px 0 40px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #serviceFund .serviceDetail .imgBox .zoomBtn {
    display: block;
  }
}

#serviceFund .serviceDetail .imgBox .zoomBtn p {
  padding-right: 25px;
  position: relative;
  display: inline-block;
}

#serviceFund .serviceDetail .imgBox .zoomBtn p::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  background-image: url(/images/icons/icon_zoomin.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

#serviceFund .serviceDetail .imgBox.open {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  background-color: #fff;
  z-index: 99999999;
  overflow-x: scroll;
  transition: background-color .5s;
}

#serviceFund .serviceDetail .imgBox.open .modalImg {
  width: 200vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: .5s;
}

#serviceFund .serviceDetail .imgBox.open .modalImg img {
  width: 100%;
}

#serviceFund .serviceDetail .imgBox.open .modalImg::before, #serviceFund .serviceDetail .imgBox.open .modalImg::after {
  content: "";
  width: 1px;
  height: 37px;
  background-color: #004092;
  position: fixed;
  right: 33px;
  top: 20px;
}

#serviceFund .serviceDetail .imgBox.open .modalImg::before {
  transform: rotate(45deg);
}

#serviceFund .serviceDetail .imgBox.open .modalImg::after {
  transform: rotate(-45deg);
}

#serviceFund .serviceDetail .imgBox.open .zoomBtn {
  display: none;
}

/*コーポレートサービス*/
/* ================================================================================
コーポレートサービス
================================================================================ */
#serviceCorporate h1 {
  color: #0076a2;
}

#serviceCorporate .introTxt a {
  color: #1e51a4;
}

#serviceCorporate .introTxt a::after {
  content: "";
  width: 14px;
  height: 11px;
  display: inline-block;
  margin-left: 4px;
  background-image: url(/images/icons/icon_out_link_blue.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

#serviceCorporate .serviceDetail {
  overflow: hidden;
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail {
    overflow: inherit;
    margin-bottom: 60px;
  }
}

#serviceCorporate .serviceDetail h2 {
  position: relative;
  display: inline-block;
  margin: 80px 0 20px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail h2 {
    margin: 50px 0 40px;
  }
}

#serviceCorporate .serviceDetail h2::before, #serviceCorporate .serviceDetail h2::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail h2::before, #serviceCorporate .serviceDetail h2::after {
    top: inherit;
    bottom: -15px;
    left: 0;
  }
}

#serviceCorporate .serviceDetail h2::before {
  width: 100px;
  transform: translate(20px, -3px);
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail h2::before {
    transform: translate(0, -3px);
  }
}

#serviceCorporate .serviceDetail h2::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail h2::after {
    transform: translate(0, 3px);
  }
}

#serviceCorporate .serviceDetail h2 span {
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  color: #000000;
}

#serviceCorporate .serviceDetail h2.notIntro {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail h2.notIntro {
    margin-top: 20px;
  }
}

#serviceCorporate .serviceDetail h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2;
  letter-spacing: 1.6px;
  color: #0076a2;
  margin-bottom: 10px;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail h3 {
    font-size: 1.5rem;
    padding: 0;
  }
}

#serviceCorporate .serviceDetail h3 + h2 {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail h3 + h2 {
    margin-top: 40px;
  }
}

#serviceCorporate .serviceDetail p {
  font-family: inherit;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail p {
    padding-left: 0;
  }
}

#serviceCorporate .serviceDetail p.bold {
  font-weight: 600;
  margin-bottom: 10px;
}

#serviceCorporate .serviceDetail p span {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #707070;
  margin-top: 20px;
  display: block;
}

#serviceCorporate .serviceDetail ul {
  background-color: #f8f9fb;
  padding: 20px 30px;
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail ul {
    padding: 20px;
  }
}

#serviceCorporate .serviceDetail ul li {
  font-size: 1.4rem;
  line-height: 2.5;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 15px;
  text-indent: -15px;
}

#serviceCorporate .serviceDetail ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #004092;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#serviceCorporate .serviceDetail ul.personnel li::before {
  background-color: #46887a;
}

#serviceCorporate .serviceDetail .serviceTxtBox {
  background-color: #f8f9fb;
  padding: 15px 0;
  padding-right: 20px;
  margin-bottom: 30px;
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .serviceTxtBox {
    padding: 20px;
    margin-left: 0;
  }
}

#serviceCorporate .serviceDetail .serviceTxtBox + h2 {
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .serviceTxtBox + h2 {
    margin-top: 20px;
  }
}

#serviceCorporate .serviceDetail .serviceColumn {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .serviceColumn {
    display: block;
  }
}

#serviceCorporate .serviceDetail .serviceColumnBox {
  flex-basis: 32%;
  border: solid 1px #d3d3d3;
  margin-bottom: 40px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .serviceColumnBox {
    margin-bottom: 20px;
  }
}

#serviceCorporate .serviceDetail .serviceColumnBox::after {
  content: "";
  background-image: url(/images/icons/icon_next_gold.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 27%;
  height: 5px;
  position: absolute;
  bottom: 30px;
  right: -17%;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .serviceColumnBox::after {
    transform: rotate(90deg);
    width: 20%;
    right: 0;
    bottom: -10px;
  }
}

#serviceCorporate .serviceDetail .serviceColumnBox:last-of-type::after {
  display: none;
}

#serviceCorporate .serviceDetail .serviceColumnBox dt {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #0076a2;
  border-bottom: solid 1px #d3d3d3;
  background-color: #f4f9f9;
  padding: 20px 0;
}

#serviceCorporate .serviceDetail .serviceColumnBox ul {
  margin: 0;
  padding: 20px 15px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .serviceColumnBox ul {
    padding: 20px;
  }
}

#serviceCorporate .serviceDetail .serviceColumnBox ul li:last-child {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .serviceColumnBox ul li:last-child {
    margin-bottom: 0;
  }
}

#serviceCorporate .serviceDetail .serviceColumn + p {
  padding-left: 0;
}

#serviceCorporate .serviceDetail .secretaryBox {
  background-color: #f8f9fb;
  padding: 30px;
  margin-bottom: 30px;
  display: flex;
}

#serviceCorporate .serviceDetail .secretaryBox:first-child {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .secretaryBox:first-child {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .secretaryBox {
    padding: 20px;
    display: block;
  }
}

#serviceCorporate .serviceDetail .secretaryBox dt {
  flex-basis: 17%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2.5;
  letter-spacing: 1.6px;
  text-align: center;
  color: #000000;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .secretaryBox dt {
    padding-left: 20px;
    text-align: left;
  }
}

#serviceCorporate .serviceDetail .secretaryBox dd {
  flex-basis: 83%;
}

#serviceCorporate .serviceDetail .secretaryBox ul {
  padding: 0 20px;
  margin: 0;
  border-left: solid 1px #b59738;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .secretaryBox ul {
    padding-top: 10px;
    border: none;
    border-top: solid 1px #b59738;
  }
}

#serviceCorporate .serviceDetail .secretaryBox + p {
  padding: 0;
}

#serviceCorporate .serviceDetail.asian h3 {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail.asian h3 {
    margin-top: 30px;
  }
}

#serviceCorporate .serviceDetail.asian ul {
  margin-top: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail.asian ul {
    margin-top: 10px;
    margin-left: 0;
  }
}

#serviceCorporate .serviceDetail .countryList thead {
  border: solid 1px #cbe0e0;
  background-color: #cbe0e0;
}

#serviceCorporate .serviceDetail .countryList thead th {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: normal;
  text-align: center;
  color: #000000;
}

#serviceCorporate .serviceDetail .countryList thead th:nth-child(2), #serviceCorporate .serviceDetail .countryList thead th:nth-child(3) {
  border-left: dashed 1px #707070;
}

#serviceCorporate .serviceDetail .countryList tbody {
  border: solid 1px #d3d3d3;
}

#serviceCorporate .serviceDetail .countryList tbody tr {
  border-top: solid 1px #d3d3d3;
}

#serviceCorporate .serviceDetail .countryList tbody td {
  text-align: center;
  color: #333333;
}

#serviceCorporate .serviceDetail .countryList tbody td:first-of-type {
  background-color: #f4f9f9;
}

#serviceCorporate .serviceDetail .countryList tbody td:nth-child(2), #serviceCorporate .serviceDetail .countryList tbody td:nth-child(3) {
  color: #0076a2;
  border-left: dashed 1px #707070;
  font-size: 1.2rem;
}

#serviceCorporate .serviceDetail .countryList tr {
  height: 50px;
  font-size: 1.4rem;
  letter-spacing: 1.4px;
}

@media screen and (max-width: 767px) {
  #serviceCorporate .serviceDetail .countryList tr {
    font-size: 1.3rem;
    letter-spacing: 0;
  }
}

/************************************
会社情報
***********************************/
/*会社概要*/
/* ================================================================================
会社情報
================================================================================ */
#aboutIdx {
  overflow: hidden;
}

#aboutIdx .companyInfo {
  max-width: 1120px;
  padding: 0 20px;
  margin: 0 auto 120px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #aboutIdx .companyInfo {
    display: block;
    padding: 0 30px;
    margin-bottom: 12%;
  }
}

#aboutIdx .companyInfoImg {
  flex-basis: 37.5%;
  z-index: 1;
}

#aboutIdx .companyInfoImg img {
  width: 100%;
  padding: 0 17.5% 0 22.5%;
}

#aboutIdx .companyInfoTxt {
  flex-basis: 62.5%;
}

#aboutIdx .companyInfoTxt h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 4rem;
  line-height: 1;
  letter-spacing: 4px;
  color: #02377b;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #aboutIdx .companyInfoTxt h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
}

#aboutIdx .companyInfoTxt h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 1.8px;
  color: #4b4b4b;
  padding: 15px 0 25px;
}

@media screen and (max-width: 767px) {
  #aboutIdx .companyInfoTxt h3 {
    font-size: 1.2rem;
    padding-top: 0;
  }
}

#aboutIdx .companyInfoTxt p {
  font-size: 1.8rem;
  line-height: 2;
  letter-spacing: 0.9px;
  color: #4b4b4b;
  margin-left: 60px;
  padding-left: 40px;
  border-left: solid 1px #b59738;
  margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
  #aboutIdx .companyInfoTxt p {
    font-size: 1.4rem;
    margin: 20px 0;
    padding-left: 0;
    border: none;
  }
}

@media screen and (max-width: 767px) {
  #aboutIdx .companyInfoTxt img {
    padding: 0 30px;
  }
}

#aboutIdx .companyInfoTxt .downloadBtn {
  padding-bottom: 30px;
  text-align: right;
}

#aboutIdx .companyInfoTxt .downloadBtn:last-of-type {
  padding-bottom: 80px;
}

#aboutIdx .companyInfoTxt .downloadBtn a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #c4c4c4;
  background-color: #fff;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding: 25px 45px;
  width: 375px;
}

#aboutIdx .companyInfoTxt .downloadBtn a::before, #aboutIdx .companyInfoTxt .downloadBtn a::after {
  content: "";
  width: 18px;
  height: 1px;
  background: #707070;
  display: inline-block;
  margin: 0 19px;
}

@media screen and (max-width: 767px) {
  #aboutIdx .companyInfoTxt .downloadBtn a {
    font-size: 1.2rem;
    padding: 20px 0;
    width: 100%;
  }
}

#aboutIdx .companyInfoTxt .bg {
  position: relative;
}

#aboutIdx .companyInfoTxt .bg::after {
  content: "";
  width: 200%;
  height: 100%;
  background-color: #f8f9fb;
  position: absolute;
  bottom: 0;
  left: calc(-60% - 70px);
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #aboutIdx .companyInfoTxt .bg::after {
    height: 110%;
  }
}

/* ================================================================================
会社概要
================================================================================ */
#companyProfile .profileDetail {
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 100px;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail {
    margin-bottom: 14.085%;
  }
}

#companyProfile .profileDetail .profileImg {
  margin: 0 auto 75px;
  width: 19.653%;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail .profileImg {
    width: 46.845%;
    margin-bottom: 40px;
  }
}

#companyProfile .profileDetail .profileImg img {
  width: 100%;
  height: auto;
}

#companyProfile .profileDetail dl {
  display: flex;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl {
    display: block;
  }
}

#companyProfile .profileDetail dl:first-of-type dt {
  border-top: solid 1px #004092;
}

#companyProfile .profileDetail dl:first-of-type dd {
  border-top: solid 1px #d3d3d3;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl:first-of-type dd {
    border-top: none;
  }
}

#companyProfile .profileDetail dl dt,
#companyProfile .profileDetail dl dd {
  padding: 30px 0;
  border-bottom: solid 1px;
}

#companyProfile .profileDetail dl dt {
  flex-basis: 20%;
  border-color: #004092;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyProfile .profileDetail dl dt {
    flex-basis: 28.5%;
  }
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dt {
    padding: 30px 0 10px;
    border-bottom: none;
  }
}

#companyProfile .profileDetail dl dd {
  flex-basis: 80%;
  border-color: #d3d3d3;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyProfile .profileDetail dl dd {
    flex-basis: 71.5%;
  }
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd {
    padding: 0 0 40px;
    border-bottom: solid 1px #004092;
  }
}

#companyProfile .profileDetail dl dd p {
  font-size: 1.4rem;
  color: #000;
}

#companyProfile .profileDetail dl dd span {
  display: block;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  color: #02377b;
}

#companyProfile .profileDetail dl dd a {
  color: #02377b;
  position: relative;
  padding-right: 20px;
}

#companyProfile .profileDetail dl dd a::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 1px;
  background-color: #02377b;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

#companyProfile .profileDetail dl dd .chui {
  text-decoration: underline;
}

#companyProfile .profileDetail dl dd .chui::after {
  content: none;
}

#companyProfile .profileDetail dl dd .accessBox span {
  color: #004092;
  padding-left: 15px;
  margin-bottom: 10px;
  position: relative;
}

#companyProfile .profileDetail dl dd .accessBox span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 50%;
  width: 2px;
  background-color: #02377b;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd .accessBox span::before {
    width: 1px;
  }
}

#companyProfile .profileDetail dl dd .accessBox p {
  color: #4b4b4b;
}

#companyProfile .profileDetail dl dd .accessBox .moveMap {
  width: 63%;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd .accessBox .moveMap {
    width: 100%;
    margin: 15px 0 0;
  }
}

#companyProfile .profileDetail dl dd .accessBox .moveMap p {
  text-align: right;
}

#companyProfile .profileDetail dl dd .accessBox .moveMap p a {
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 1.2px;
  color: #004092;
  padding-right: 19px;
  position: relative;
}

#companyProfile .profileDetail dl dd .accessBox .moveMap p a::after {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: transparent;
  background-image: url("/images/icons/icon_scale_blue.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

#companyProfile .profileDetail dl dd .accessBox .zoomBtn {
  display: none;
  width: 63%;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd .accessBox .zoomBtn {
    display: block;
    width: 100%;
    margin: 0 0 10px;
  }
}

#companyProfile .profileDetail dl dd .accessBox .zoomBtn p {
  text-align: right;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 1.2px;
  color: #004092;
  padding-right: 19px;
  position: relative;
}

#companyProfile .profileDetail dl dd .accessBox .zoomBtn p::after {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: transparent;
  background-image: url("/images/icons/icon_scale_blue.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail {
  display: block;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd .accessBox .accessDetail {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyProfile .profileDetail dl dd .accessBox .accessDetail {
    display: block;
  }
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .map {
  height: 0;
  overflow: hidden;
  padding-bottom: 36%;
  position: relative;
  flex-basis: 63%;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd .accessBox .accessDetail .map {
    margin-bottom: 15px;
    padding-bottom: 56%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #companyProfile .profileDetail dl dd .accessBox .accessDetail .map {
    margin-bottom: 30px;
    padding-bottom: 56%;
  }
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox {
  margin: 30px 0 17px;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox {
    margin: 20px 0 15px;
  }
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox .hide {
  display: none;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox .attention {
  display: none;
  font-size: 1.2rem;
  line-height: 1.67;
  letter-spacing: 1.2px;
  position: relative;
  padding: 12px 20px;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox .attention span {
  display: inline;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 1.2px;
  text-decoration: underline;
  position: relative;
  color: #4b4b4b;
  padding: 0;
  cursor: pointer;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox .attention span::before {
  display: none;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox .backMapBtn {
  width: 100%;
  padding: 12px 20px;
  font-size: 1.2rem;
  line-height: 1.67;
  letter-spacing: 1.2px;
  position: relative;
  cursor: pointer;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox .backMapBtn::after {
  content: "";
  display: inline-block;
  width: 17px;
  height: 1px;
  background-color: #004092;
  margin: 0 0 4px 5px;
  vertical-align: middle;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  z-index: 99999999;
  overflow-x: scroll;
  transition: background-color .5s;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .modalImg {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  transition: .5s;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .modalImg img {
  width: 100%;
  flex: 0 0 auto;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .modalImg::before, #companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .modalImg::after {
  content: "";
  width: 1px;
  height: 37px;
  background-color: #004092;
  position: fixed;
  right: 33px;
  top: 20px;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .modalImg::before {
  transform: rotate(45deg);
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .modalImg::after {
  transform: rotate(-45deg);
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .zoomBtn {
  display: none;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .attention {
  width: 100%;
  display: block;
  align-self: baseline;
  flex: 0 0 auto;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgBox.open .attention.hide {
  display: none;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox .modalImg02 {
  display: none;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  z-index: 99999999;
  overflow-x: scroll;
  transition: background-color .5s;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open .modalImg02 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: .5s;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open .modalImg02 img {
  max-width: 750px;
  width: 100%;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open .modalImg02::before, #companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open .modalImg02::after {
  content: "";
  width: 1px;
  height: 37px;
  background-color: #004092;
  position: fixed;
  right: 33px;
  top: 20px;
  cursor: pointer;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open .modalImg02::before {
  transform: rotate(45deg);
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open .modalImg02::after {
  transform: rotate(-45deg);
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open .modalBtn,
#companyProfile .profileDetail dl dd .accessBox .accessDetail .imgModalBox.open p {
  display: none;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .station {
  flex-basis: 34%;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .station span {
  font-size: 1.2rem;
  padding-left: 8px;
  margin-bottom: 0;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .station span::before {
  width: 1px;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .station p {
  font-size: 1.2rem;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .station p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: solid 1px #d3d3d3;
  width: 60px;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .station p:last-child {
  padding: 0;
  margin: 0;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .station p:last-child::after {
  display: none;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .attention {
  margin: 30px 0 0;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd .accessBox .accessDetail .attention {
    margin: 12px 0 0;
  }
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .attention p {
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 1.2px;
  position: relative;
  margin-bottom: 12px;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .attention p:last-child {
  padding: 0;
  margin: 0;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .attention p span {
  display: inline;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 1.2px;
  text-decoration: underline;
  position: relative;
  color: #4b4b4b;
  padding: 0;
  cursor: pointer;
}

#companyProfile .profileDetail dl dd .accessBox .accessDetail .attention p span::before {
  display: none;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl dd .accessBox .accessDetail .attention p {
    line-height: 1.67;
    margin-bottom: 6px;
  }
  #companyProfile .profileDetail dl dd .accessBox .accessDetail .attention p span {
    line-height: 1.67;
  }
}

#companyProfile .profileDetail dl.access dt {
  border-color: #d3d3d3;
}

@media screen and (max-width: 767px) {
  #companyProfile .profileDetail dl.access dd {
    border-bottom-color: #d3d3d3;
  }
}

/*役員・パートナー紹介 顧問紹介*/
/* ================================================================================
役員紹介、顧問紹介
================================================================================ */
#partners .memberDetail,
#advisors .memberDetail {
  max-width: 1020px;
  padding: 0 20px;
  margin: auto;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail,
  #advisors .memberDetail {
    margin-bottom: 14.085%;
  }
}

#partners .memberDetail h2,
#advisors .memberDetail h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 4rem;
  line-height: 1.4;
  letter-spacing: 4px;
  color: #02377b;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail h2,
  #advisors .memberDetail h2 {
    font-size: 2.4rem;
    letter-spacing: 2.4px;
    margin-bottom: 4.226%;
  }
}

#partners .memberDetail h2 span,
#advisors .memberDetail h2 span {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  letter-spacing: normal;
  margin-left: 18px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail h2 span,
  #advisors .memberDetail h2 span {
    margin-left: 4.226%;
  }
}

#partners .memberDetail .jobtitles,
#advisors .memberDetail .jobtitles {
  font-size: 1.5rem;
  line-height: 1.87;
  letter-spacing: 1.5px;
  color: #000000;
  margin-bottom: 35px;
}

#partners .memberDetail .post,
#advisors .memberDetail .post {
  font-size: 1.5rem;
  line-height: 1.87;
  letter-spacing: 1.5px;
  color: #000000;
  border-left: solid 1px #004092;
  padding-left: 20px;
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail .post,
  #advisors .memberDetail .post {
    margin-bottom: 4.226%;
    padding-left: 2.817%;
  }
}

#partners .memberDetail p,
#advisors .memberDetail p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
}

#partners .memberDetail h3,
#advisors .memberDetail h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 2.22;
  letter-spacing: 1.8px;
  color: #004092;
  margin: 30px 0 20px;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail h3,
  #advisors .memberDetail h3 {
    font-size: 1.4rem;
    margin: 5.635% 0 2.82%;
  }
}

#partners .memberDetail .book li,
#advisors .memberDetail .book li {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
  padding-left: 27px;
  margin-bottom: 10px;
  position: relative;
}

#partners .memberDetail .book li::before,
#advisors .memberDetail .book li::before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #b59738;
  position: absolute;
  top: 11px;
  left: 10px;
}

#partners .memberDetail .careerToggle,
#advisors .memberDetail .careerToggle {
  display: none;
  border: solid 1px #d3d3d3;
  border-top: none;
}

#partners .memberDetail .careerToggleBtn,
#advisors .memberDetail .careerToggleBtn {
  margin-top: 40px;
  padding: 33px 0;
  background-color: #eaeff3;
  width: 100%;
  text-align: center;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 1.44;
  letter-spacing: 1.8px;
  color: #004092;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail .careerToggleBtn,
  #advisors .memberDetail .careerToggleBtn {
    margin-top: 5.635%;
    padding: 20px 0;
    font-size: 1.4rem;
  }
}

#partners .memberDetail .careerToggleBtn::after,
#advisors .memberDetail .careerToggleBtn::after {
  content: "";
  background-image: url("/images/icons/icon_toggle_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  margin: auto;
  width: 28px;
  height: 13px;
  transition: .5s;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail .careerToggleBtn::after,
  #advisors .memberDetail .careerToggleBtn::after {
    width: 14px;
    height: 6.5px;
  }
}

#partners .memberDetail .careerToggleBtn.open::after,
#advisors .memberDetail .careerToggleBtn.open::after {
  transform: rotate(180deg);
}

#partners .memberDetail .careerToggle dl,
#advisors .memberDetail .careerToggle dl {
  background-color: #f4f7fa;
  padding: 30px 70px;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail .careerToggle dl,
  #advisors .memberDetail .careerToggle dl {
    padding: 5.635% 4.226%;
    font-size: 1.3rem;
  }
}

#partners .memberDetail .careerToggle dl dt,
#partners .memberDetail .careerToggle dl dd,
#advisors .memberDetail .careerToggle dl dt,
#advisors .memberDetail .careerToggle dl dd {
  padding-bottom: 25px;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail .careerToggle dl dt,
  #partners .memberDetail .careerToggle dl dd,
  #advisors .memberDetail .careerToggle dl dt,
  #advisors .memberDetail .careerToggle dl dd {
    padding-bottom: 3.732%;
  }
}

#partners .memberDetail .careerToggle dl dt:last-of-type,
#partners .memberDetail .careerToggle dl dd:last-of-type,
#advisors .memberDetail .careerToggle dl dt:last-of-type,
#advisors .memberDetail .careerToggle dl dd:last-of-type {
  padding-bottom: 0;
}

#partners .memberDetail .careerToggle dl dt,
#advisors .memberDetail .careerToggle dl dt {
  flex-basis: 24%;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail .careerToggle dl dt,
  #advisors .memberDetail .careerToggle dl dt {
    flex-basis: 100px;
  }
}

#partners .memberDetail .careerToggle dl dd,
#advisors .memberDetail .careerToggle dl dd {
  flex-basis: 76%;
}

@media screen and (max-width: 767px) {
  #partners .memberDetail .careerToggle dl dd,
  #advisors .memberDetail .careerToggle dl dd {
    flex-basis: calc(100% - 100px);
  }
}

#partners .otherMenber,
#advisors .otherMenber {
  background: #fbfcfd;
  padding-top: 100px;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  #partners .otherMenber,
  #advisors .otherMenber {
    background: none;
    padding: 0;
    margin-bottom: 14.085%;
  }
}

#partners .otherMenber .columnLayout,
#advisors .otherMenber .columnLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #partners .otherMenber .columnLayout,
  #advisors .otherMenber .columnLayout {
    display: block;
  }
}

#partners .otherMenber .columnLayout .memberDetail,
#advisors .otherMenber .columnLayout .memberDetail {
  width: 47%;
  background: #fff;
  padding: 30px;
  margin: 0 0 80px;
}

@media screen and (max-width: 767px) {
  #partners .otherMenber .columnLayout .memberDetail,
  #advisors .otherMenber .columnLayout .memberDetail {
    width: 100%;
    padding: 0;
    margin-bottom: 14.085%;
  }
}

#partners .otherMenber .columnLayout hr,
#advisors .otherMenber .columnLayout hr {
  border-top-color: #a5c5d6;
  width: 100%;
  margin: 0 0 40px;
  transform: translateY(-20px);
}

/*経営理念・サステナビリティ方針*/
/* ================================================================================
経営理念
================================================================================ */
#corporatePhilosophy .philosophyDetail {
  overflow: hidden;
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 100px;
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail {
    margin-bottom: 14.085%;
  }
}

#corporatePhilosophy .philosophyDetail h1 {
  position: relative;
  display: inline-block;
  margin: 60px 0 40px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 3.2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail h1 {
    font-size: 2.5rem;
    letter-spacing: 4px;
  }
}

#corporatePhilosophy .philosophyDetail h1::before, #corporatePhilosophy .philosophyDetail h1::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail h1::before, #corporatePhilosophy .philosophyDetail h1::after {
    top: inherit;
    bottom: -15px;
    left: 0;
  }
}

#corporatePhilosophy .philosophyDetail h1::before {
  width: 100px;
  transform: translate(20px, -3px);
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail h1::before {
    transform: translate(0, -3px);
  }
}

#corporatePhilosophy .philosophyDetail h1::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail h1::after {
    transform: translate(0, 3px);
  }
}

#corporatePhilosophy .philosophyDetail h1 span {
  font-size: 2.4rem;
  color: #004092;
  letter-spacing: 2.5px;
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail h1 span {
    font-size: 2rem;
  }
}

#corporatePhilosophy .philosophyDetail h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 1.6px;
  color: #004092;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail h3 {
    font-size: 1.6rem;
    padding: 0;
    margin-bottom: 35px;
  }
}

#corporatePhilosophy .philosophyDetail p {
  font-family: inherit;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail p {
    padding-left: 0;
  }
}

#corporatePhilosophy .philosophyDetail p.bold {
  font-weight: 600;
  margin-bottom: 10px;
}

#corporatePhilosophy .philosophyDetail p b {
  font-weight: 500;
  color: #000;
}

#corporatePhilosophy .philosophyDetail p span {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #707070;
  margin-top: 20px;
  display: block;
}

#corporatePhilosophy .philosophyDetail ul {
  background-color: #f8f9fb;
  padding: 20px 30px;
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #corporatePhilosophy .philosophyDetail ul {
    padding: 20px;
  }
}

#corporatePhilosophy .philosophyDetail ul li {
  font-size: 1.4rem;
  line-height: 2.5;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 15px;
  text-indent: -15px;
}

#corporatePhilosophy .philosophyDetail ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #004092;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#corporatePhilosophy .philosophyDetail ul.personnel li::before {
  background-color: #46887a;
}

#corporatePhilosophy .philosophyDetail .signture {
  text-align: right;
  line-height: 2.5;
  margin-top: 80px;
}

#sustainability .philosophyDetail {
  overflow: hidden;
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 100px;
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail {
    margin-bottom: 14.085%;
  }
}

#sustainability .philosophyDetail h1 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1.75;
  letter-spacing: 3.2px;
  color: #004092;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail h1 {
    font-size: 2rem;
    letter-spacing: 4px;
  }
}

#sustainability .philosophyDetail h2 {
  position: relative;
  display: inline-block;
  margin: 20px 0 20px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 2.4px;
  color: #000;
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail h2 {
    font-size: 1.7rem;
    margin: 20px 0 40px;
  }
}

#sustainability .philosophyDetail h2::before, #sustainability .philosophyDetail h2::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail h2::before, #sustainability .philosophyDetail h2::after {
    top: inherit;
    bottom: -15px;
    left: 0;
  }
}

#sustainability .philosophyDetail h2::before {
  width: 100px;
  transform: translate(20px, -3px);
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail h2::before {
    transform: translate(0, -3px);
  }
}

#sustainability .philosophyDetail h2::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail h2::after {
    transform: translate(0, 3px);
  }
}

#sustainability .philosophyDetail h2 span {
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  color: #000000;
}

#sustainability .philosophyDetail h2.notIntro {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail h2.notIntro {
    margin-top: 20px;
  }
}

#sustainability .philosophyDetail h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: 2px;
  color: #004092;
  margin-top: 60px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail h3 {
    font-size: 1.6rem;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

#sustainability .philosophyDetail h3 + h2 {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail h3 + h2 {
    margin-top: 40px;
  }
}

#sustainability .philosophyDetail p {
  font-family: inherit;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #sustainability .philosophyDetail p {
    padding-left: 0;
  }
}

#sustainability .philosophyDetail p.bold {
  font-weight: 600;
  margin-bottom: 10px;
}

#sustainability .philosophyDetail p b {
  font-weight: 500;
  color: #000;
}

#sustainability .philosophyDetail p span {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #707070;
  margin-top: 20px;
  display: block;
}

#sustainability .philosophyDetail p.philosophyBox {
  padding: 20px;
  margin-bottom: 40px;
  background-color: #f8f9fb;
}

#sustainability .philosophyDetail p.philosophyBox.last {
  margin-bottom: 0;
}

/*SDG（Smart Data Guideway）計画*/
/* ================================================================================
SDG（Smart Data Guideway）計画
================================================================================ */
#smartDataGuideway .philosophyDetail {
  overflow: hidden;
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 100px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail {
    margin-bottom: 14.085%;
  }
}

#smartDataGuideway .philosophyDetail h1 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1.75;
  letter-spacing: 3.2px;
  color: #004092;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h1 {
    font-size: 2rem;
    letter-spacing: 1px;
  }
}

#smartDataGuideway .philosophyDetail .introTxt {
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: .7px;
  color: #000;
}

#smartDataGuideway .philosophyDetail .introTxt b {
  font-weight: 500;
}

#smartDataGuideway .philosophyDetail h2 {
  position: relative;
  display: inline-block;
  margin: 60px 0 20px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 2.4px;
  color: #000;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h2 {
    font-size: 1.7rem;
    margin: 50px 0 40px;
  }
}

#smartDataGuideway .philosophyDetail h2::before, #smartDataGuideway .philosophyDetail h2::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h2::before, #smartDataGuideway .philosophyDetail h2::after {
    top: inherit;
    bottom: -15px;
    left: 0;
  }
}

#smartDataGuideway .philosophyDetail h2::before {
  width: 100px;
  transform: translate(20px, -3px);
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h2::before {
    transform: translate(0, -3px);
  }
}

#smartDataGuideway .philosophyDetail h2::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h2::after {
    transform: translate(0, 3px);
  }
}

#smartDataGuideway .philosophyDetail h2 span {
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  color: #000000;
}

#smartDataGuideway .philosophyDetail h2.notIntro {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h2.notIntro {
    margin-top: 20px;
  }
}

#smartDataGuideway .philosophyDetail h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: 2px;
  color: #004092;
  margin-top: 60px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h3 {
    font-size: 1.6rem;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

#smartDataGuideway .philosophyDetail h3:first-of-type {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h3:first-of-type {
    margin-top: 40px;
  }
}

#smartDataGuideway .philosophyDetail h3 + h2 {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h3 + h2 {
    margin-top: 40px;
  }
}

#smartDataGuideway .philosophyDetail h4 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: 2px;
  color: #4b4b4b;
  text-align: center;
  padding: 0 20px;
  margin-top: 60px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail h4 {
    font-size: 1.6rem;
    letter-spacing: 0;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 5px;
  }
  #smartDataGuideway .philosophyDetail h4.mb {
    margin-bottom: 20px;
  }
}

#smartDataGuideway .philosophyDetail p {
  font-family: inherit;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail p {
    padding-left: 0;
  }
}

#smartDataGuideway .philosophyDetail p.bold {
  font-weight: 600;
  margin-bottom: 10px;
}

#smartDataGuideway .philosophyDetail p b {
  font-weight: 500;
  color: #000;
}

#smartDataGuideway .philosophyDetail p span {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #707070;
  margin-top: 20px;
  display: block;
}

#smartDataGuideway .philosophyDetail p.philosophyBox {
  padding: 20px;
  margin-bottom: 40px;
  background-color: #f8f9fb;
}

#smartDataGuideway .philosophyDetail p.philosophyBox.last {
  margin-bottom: 0;
}

#smartDataGuideway .philosophyDetail ul {
  background-color: #f8f9fb;
  padding: 20px 30px;
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail ul {
    padding: 20px;
  }
}

#smartDataGuideway .philosophyDetail ul li {
  font-size: 1.4rem;
  line-height: 2.5;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 15px;
  text-indent: -15px;
}

#smartDataGuideway .philosophyDetail ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #004092;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#smartDataGuideway .philosophyDetail ul.personnel li::before {
  background-color: #46887a;
}

#smartDataGuideway .philosophyDetail .secretaryBox {
  background-color: #f8f9fb;
  padding: 30px;
  margin-top: 30px;
  display: flex;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail .secretaryBox {
    padding: 20px;
    display: block;
  }
}

#smartDataGuideway .philosophyDetail .secretaryBox dt {
  flex: 1 1 auto;
  max-width: 19%;
  width: 100%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 0.56px;
  color: #004092;
  text-align: left;
  word-wrap: break-word;
  padding-right: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #smartDataGuideway .philosophyDetail .secretaryBox dt {
    max-width: 25%;
  }
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail .secretaryBox dt {
    max-width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
}

#smartDataGuideway .philosophyDetail .secretaryBox dd {
  flex: 1 1 auto;
  max-width: 81%;
  width: 100%;
  border-left: solid 1px #b59738;
}

#smartDataGuideway .philosophyDetail .secretaryBox dd p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 1.6px;
  text-align: left;
  color: #004092;
  padding: 0 16px;
  margin-bottom: 7px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #smartDataGuideway .philosophyDetail .secretaryBox dd {
    max-width: 75%;
  }
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail .secretaryBox dd {
    max-width: 100%;
    border: none;
    border-top: solid 1px #b59738;
    padding-top: 15px;
  }
  #smartDataGuideway .philosophyDetail .secretaryBox dd p {
    padding: 0;
    margin-bottom: 0;
  }
}

#smartDataGuideway .philosophyDetail .secretaryBox ul {
  padding: 0 20px;
  margin: 0;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail .secretaryBox ul {
    padding: 10px 0 0;
  }
}

#smartDataGuideway .philosophyDetail .secretaryBox + p {
  padding: 0;
}

#smartDataGuideway .philosophyDetail .philosophyImg {
  max-width: 980px;
  width: 100%;
  padding: 0 20px;
  margin: 0px auto 20px;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail .philosophyImg {
    max-width: 100%;
    padding: 0;
    margin: 0 auto 10px;
  }
}

#smartDataGuideway .philosophyDetail .philosophyImg .zoomBtn {
  display: none;
  margin: 10px 0 40px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #smartDataGuideway .philosophyDetail .philosophyImg .zoomBtn {
    display: block;
  }
}

#smartDataGuideway .philosophyDetail .philosophyImg .zoomBtn p {
  padding-right: 25px;
  position: relative;
  display: inline-block;
}

#smartDataGuideway .philosophyDetail .philosophyImg .zoomBtn p::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  background-image: url(/images/icons/icon_zoomin.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

#smartDataGuideway .philosophyDetail .philosophyImg.open {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  background-color: #fff;
  z-index: 99999999;
  overflow-x: scroll;
  transition: background-color .5s;
}

#smartDataGuideway .philosophyDetail .philosophyImg.open .modalImg {
  width: 200vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px 0;
}

#smartDataGuideway .philosophyDetail .philosophyImg.open .modalImg img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

#smartDataGuideway .philosophyDetail .philosophyImg.open .modalImg::before, #smartDataGuideway .philosophyDetail .philosophyImg.open .modalImg::after {
  content: "";
  width: 1px;
  height: 37px;
  background-color: #004092;
  position: fixed;
  right: 33px;
  top: 20px;
}

#smartDataGuideway .philosophyDetail .philosophyImg.open .modalImg::before {
  transform: rotate(45deg);
}

#smartDataGuideway .philosophyDetail .philosophyImg.open .modalImg::after {
  transform: rotate(-45deg);
}

#smartDataGuideway .philosophyDetail .philosophyImg.open .zoomBtn {
  display: none;
}

/************************************
グループ企業 / 提携企業
***********************************/
/*グループ企業*/
/* ================================================================================
グループ企業のご紹介
================================================================================ */
#groupCompanies .groupLayout > div:last-of-type {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout > div:last-of-type {
    margin-bottom: 16%;
  }
}

#groupCompanies .groupLayout .bg {
  background-color: #fbfcfd;
  padding: 80px 0;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .bg {
    padding: 15% 0;
    margin-bottom: 15%;
  }
}

#groupCompanies .groupLayout .bg .companyBox {
  margin-bottom: 0;
}

#groupCompanies .groupLayout .companyBox {
  max-width: 1020px;
  padding: 0 20px;
  margin: auto;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox {
    margin-bottom: 15%;
  }
}

#groupCompanies .groupLayout .companyBox .companyImg {
  margin: 0 auto 70px;
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox .companyImg {
    margin-bottom: 40px;
  }
}

#groupCompanies .groupLayout .companyBox .companyImg img {
  width: 100%;
}

#groupCompanies .groupLayout .companyBox .companyImg.asa {
  width: 12.9387%;
}

#groupCompanies .groupLayout .companyBox .companyImg.tax {
  width: 19.664%;
}

#groupCompanies .groupLayout .companyBox .companyImg.total {
  width: 20.597%;
}

#groupCompanies .groupLayout .companyBox .companyImg.sin {
  width: 14.5245%;
}

#groupCompanies .groupLayout .companyBox .companyImg.advice {
  width: 16.597%;
}

#groupCompanies .groupLayout .companyBox .companyImg.report {
  width: 30.597%;
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox .companyImg.asa {
    width: calc(12.9387% * 2.25);
  }
  #groupCompanies .groupLayout .companyBox .companyImg.tax {
    width: calc(19.664% * 2.4);
  }
  #groupCompanies .groupLayout .companyBox .companyImg.total {
    width: calc(20.597% * 2.35);
  }
  #groupCompanies .groupLayout .companyBox .companyImg.sin {
    width: calc(14.5245% * 2.4);
  }
  #groupCompanies .groupLayout .companyBox .companyImg.advice {
    width: calc(16.597% * 2.35);
  }
  #groupCompanies .groupLayout .companyBox .companyImg.report {
    width: calc(30.664% * 2.4);
  }
}

#groupCompanies .groupLayout .companyBox .companyName h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 1.66;
  color: #004092;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox .companyName h2 {
    font-size: 2rem;
  }
}

#groupCompanies .groupLayout .companyBox .companyName h2 span {
  font-size: 1.4rem;
  line-height: 1.43;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox .companyName h2 span {
    font-size: 1.2rem;
  }
}

#groupCompanies .groupLayout .companyBox .companyName h2 .chui {
  color: #000;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

#groupCompanies .groupLayout .companyBox .companyName p {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #000000;
}

#groupCompanies .groupLayout .companyBox .companyDetail dl {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 25px;
  padding: 15px 0;
  border: solid #004092;
  border-width: 1px 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox .companyDetail dl {
    display: block;
    padding: 0;
    margin-top: 8%;
    border-bottom: none;
  }
}

#groupCompanies .groupLayout .companyBox .companyDetail dl::before, #groupCompanies .groupLayout .companyBox .companyDetail dl::after {
  content: "";
  position: absolute;
  right: 0;
  width: 75%;
  border-bottom: 1px solid #d3d3d3;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #groupCompanies .groupLayout .companyBox .companyDetail dl::before, #groupCompanies .groupLayout .companyBox .companyDetail dl::after {
    width: 68%;
  }
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox .companyDetail dl::before, #groupCompanies .groupLayout .companyBox .companyDetail dl::after {
    display: none;
  }
}

#groupCompanies .groupLayout .companyBox .companyDetail dl::before {
  top: -1px;
}

#groupCompanies .groupLayout .companyBox .companyDetail dl::after {
  bottom: -1px;
}

#groupCompanies .groupLayout .companyBox .companyDetail dl dt,
#groupCompanies .groupLayout .companyBox .companyDetail dl dd {
  padding: 15px 0;
}

#groupCompanies .groupLayout .companyBox .companyDetail dl dt {
  flex-basis: 25%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #groupCompanies .groupLayout .companyBox .companyDetail dl dt {
    flex-basis: 32%;
  }
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox .companyDetail dl dt {
    padding: 30px 0 10px 0;
  }
}

#groupCompanies .groupLayout .companyBox .companyDetail dl dd {
  flex-basis: 75%;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #groupCompanies .groupLayout .companyBox .companyDetail dl dd {
    flex-basis: 68%;
  }
}

@media screen and (max-width: 767px) {
  #groupCompanies .groupLayout .companyBox .companyDetail dl dd {
    padding: 0 0 40px 0;
    border-bottom: 1px solid #004092;
  }
}

#groupCompanies .groupLayout .companyBox .companyDetail dl dd a {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #02377b;
}

#groupCompanies .groupLayout .companyBox .companyDetail dl dd h3 {
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #02377b;
  margin: 20px 0 0;
}

#groupCompanies .groupLayout .companyBox .companyDetail dl dd .book li {
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
  padding-left: 18px;
  margin-bottom: 10px;
  position: relative;
}

#groupCompanies .groupLayout .companyBox .companyDetail dl dd .book li::before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #b59738;
  position: absolute;
  top: 11px;
  left: 0px;
}

/*提携企業*/
/* ================================================================================
提携企業のご紹介
================================================================================ */
#partnerCompanies .partnerLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 120px;
}

@media screen and (max-width: 767px) {
  #partnerCompanies .partnerLayout {
    margin-bottom: 16%;
  }
}

#partnerCompanies .partnerLayout .partnerImg {
  margin: 0 auto 70px;
  width: 22.654%;
}

@media screen and (max-width: 767px) {
  #partnerCompanies .partnerLayout .partnerImg {
    width: 60%;
    margin: 40px auto;
  }
}

#partnerCompanies .partnerLayout .partnerImg img {
  width: 100%;
}

#partnerCompanies .partnerLayout .companyName h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 1.66;
  color: #004092;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  #partnerCompanies .partnerLayout .companyName h2 {
    font-size: 2rem;
  }
}

#partnerCompanies .partnerLayout .companyName h2 span {
  font-size: 1.4rem;
  line-height: 1.43;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #partnerCompanies .partnerLayout .companyName h2 span {
    font-size: 1.2rem;
  }
}

#partnerCompanies .partnerLayout .companyName p {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #000000;
}

#partnerCompanies .partnerLayout .companyDetail dl {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 25px;
  padding: 15px 0;
  border: solid #004092;
  border-width: 1px 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  #partnerCompanies .partnerLayout .companyDetail dl {
    display: block;
    border-bottom: none;
    padding: 0;
    margin-top: 8%;
  }
}

#partnerCompanies .partnerLayout .companyDetail dl::before, #partnerCompanies .partnerLayout .companyDetail dl::after {
  content: "";
  position: absolute;
  right: 0;
  width: 75%;
  border-bottom: 1px solid #d3d3d3;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #partnerCompanies .partnerLayout .companyDetail dl::before, #partnerCompanies .partnerLayout .companyDetail dl::after {
    width: 68%;
  }
}

@media screen and (max-width: 767px) {
  #partnerCompanies .partnerLayout .companyDetail dl::before, #partnerCompanies .partnerLayout .companyDetail dl::after {
    display: none;
  }
}

#partnerCompanies .partnerLayout .companyDetail dl::before {
  top: -1px;
}

#partnerCompanies .partnerLayout .companyDetail dl::after {
  bottom: -1px;
}

#partnerCompanies .partnerLayout .companyDetail dl dt,
#partnerCompanies .partnerLayout .companyDetail dl dd {
  padding: 15px 0;
}

#partnerCompanies .partnerLayout .companyDetail dl dt {
  flex-basis: 25%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #partnerCompanies .partnerLayout .companyDetail dl dt {
    flex-basis: 32%;
  }
}

@media screen and (max-width: 767px) {
  #partnerCompanies .partnerLayout .companyDetail dl dt {
    padding: 30px 0 10px 0;
  }
}

#partnerCompanies .partnerLayout .companyDetail dl dd {
  flex-basis: 75%;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #partnerCompanies .partnerLayout .companyDetail dl dd {
    flex-basis: 68%;
  }
}

@media screen and (max-width: 767px) {
  #partnerCompanies .partnerLayout .companyDetail dl dd {
    padding: 0 0 40px 0;
    border-bottom: 1px solid #004092;
  }
}

#partnerCompanies .partnerLayout .companyDetail dl dd a {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #02377b;
}

#partnerCompanies .partnerLayout .companyDetail dl dd a.windowIcon {
  position: relative;
}

#partnerCompanies .partnerLayout .companyDetail dl dd a.windowIcon::before {
  margin: auto;
  top: 4px;
}

/*シンガポールオフィス*/
/* ================================================================================
シンガポールオフィス概要
================================================================================ */
#singaporeOffice .officeDetail {
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 120px;
}

@media screen and (max-width: 767px) {
  #singaporeOffice .officeDetail {
    margin-bottom: 16%;
  }
}

#singaporeOffice .officeDetail .officeImg {
  margin: 0 auto 50px;
  width: 14.5%;
}

@media screen and (max-width: 767px) {
  #singaporeOffice .officeDetail .officeImg {
    width: 29%;
    margin-bottom: 11.2676%;
  }
}

#singaporeOffice .officeDetail .officeImg img {
  width: 100%;
}

#singaporeOffice .officeDetail dl {
  display: flex;
}

@media screen and (max-width: 767px) {
  #singaporeOffice .officeDetail dl {
    display: block;
  }
}

#singaporeOffice .officeDetail dl:first-of-type dt {
  border-top: solid 1px #004092;
}

#singaporeOffice .officeDetail dl:first-of-type dd {
  border-top: solid 1px #d3d3d3;
}

@media screen and (max-width: 767px) {
  #singaporeOffice .officeDetail dl:first-of-type dd {
    border-top: none;
  }
}

#singaporeOffice .officeDetail dl dt,
#singaporeOffice .officeDetail dl dd {
  padding: 30px 0;
  border-bottom: solid 1px;
}

#singaporeOffice .officeDetail dl dt {
  flex-basis: 20%;
  border-color: #004092;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #singaporeOffice .officeDetail dl dt {
    flex-basis: 28.5%;
  }
}

@media screen and (max-width: 767px) {
  #singaporeOffice .officeDetail dl dt {
    padding: 30px 0 10px;
    border-bottom: none;
  }
}

#singaporeOffice .officeDetail dl dd {
  flex-basis: 80%;
  border-color: #d3d3d3;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.4px;
  color: #4b4b4b;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #singaporeOffice .officeDetail dl dd {
    flex-basis: 71.5%;
  }
}

@media screen and (max-width: 767px) {
  #singaporeOffice .officeDetail dl dd {
    padding: 0 0 40px;
    border-bottom: solid 1px #004092;
  }
}

#singaporeOffice .officeDetail dl dd p {
  font-size: 1.4rem;
}

#singaporeOffice .officeDetail dl dd a {
  color: #02377b;
  word-break: break-word;
}

#singaporeOffice .officeDetail dl dd .moveMap {
  margin: 10px 0 5px;
  max-width: 700px;
}

#singaporeOffice .officeDetail dl dd .moveMap p {
  text-align: right;
}

#singaporeOffice .officeDetail dl dd .moveMap p a {
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 1.2px;
  color: #004092;
  padding-right: 19px;
  position: relative;
}

#singaporeOffice .officeDetail dl dd .moveMap p a::after {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-image: url("/images/icons/icon_scale_blue.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

#singaporeOffice .officeDetail dl dd .map {
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  max-width: 700px;
}

@media screen and (max-width: 767px) {
  #singaporeOffice .officeDetail dl dd .map {
    padding-bottom: 54.32595%;
  }
}

#singaporeOffice .officeDetail dl dd .map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/************************************
最新情報関連
***********************************/
/* ================================================================================
topics　トップページ
================================================================================ */
#topicsIdx .eachTopicsList {
  max-width: 1120px;
  padding: 80px 20px 0;
  width: 100%;
  margin: 0 auto;
}

#topicsIdx .eachTopicsList dl {
  display: block;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #topicsIdx .eachTopicsList dl {
    padding-bottom: 30px;
  }
}

#topicsIdx .eachTopicsList dl:first-of-type dd {
  display: block;
}

#topicsIdx .eachTopicsList dl:last-of-type {
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #topicsIdx .eachTopicsList dl:last-of-type {
    padding-bottom: 15px;
  }
}

#topicsIdx .eachTopicsList dl dt {
  border-bottom: 1px solid #a2a5a7;
  position: relative;
  cursor: pointer;
}

#topicsIdx .eachTopicsList dl dt::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 48px;
  height: 20px;
  background-image: url(/images/icons/icon_toggle_btn.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: 0.5s;
}

@media screen and (max-width: 767px) {
  #topicsIdx .eachTopicsList dl dt::after {
    width: 24px;
    height: 10px;
  }
}

#topicsIdx .eachTopicsList dl dt.open::after {
  transform: rotate(-180deg);
}

#topicsIdx .eachTopicsList dl dt span {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 4.8rem;
  line-height: 1;
  letter-spacing: 4.8px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #topicsIdx .eachTopicsList dl dt span {
    font-size: 3rem;
    padding-bottom: 10px;
  }
}

#topicsIdx .eachTopicsList dl dt span::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #02377b;
  bottom: -1px;
  left: 0;
}

#topicsIdx .eachTopicsList dl dd {
  padding-top: 35px;
  display: none;
}

@media screen and (max-width: 767px) {
  #topicsIdx .eachTopicsList dl dd {
    padding-top: 20px;
  }
}

#topicsIdx .eachTopicsList dl dd {
  padding-top: 35px;
  display: none;
}

@media screen and (max-width: 767px) {
  #topicsIdx .eachTopicsList dl dd {
    padding-top: 20px;
  }
}

#topicsIdx .eachTopicsList p.attention {
  text-align: right;
  font-size: 1.2rem;
  line-height: 2;
  color: #707070;
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #topicsIdx .eachTopicsList p.attention {
    padding-bottom: 60px;
  }
}

#topicsIdx .eachTopicsList .archiveBtnLayout {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #topicsIdx .eachTopicsList .archiveBtnLayout {
    margin-bottom: 60px;
  }
}

/* ================================================================================
topics テンプレート
================================================================================ */
#topicsTemplate .topicLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: 50px auto 120px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout {
    margin: 7% auto 16%;
  }
}

#topicsTemplate .topicLayout.wp strong {
  font-weight: bold;
}

#topicsTemplate .topicLayout.wp p {
  word-break: break-word;
}

#topicsTemplate .topicLayout.wp p a {
  word-break: break-word;
}

#topicsTemplate .topicLayout p,
#topicsTemplate .topicLayout dd,
#topicsTemplate .topicLayout li {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#topicsTemplate .topicLayout .topicTitle {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicTitle {
    margin-bottom: 4%;
  }
}

#topicsTemplate .topicLayout .topicTitle p {
  font-size: 1.6rem;
}

#topicsTemplate .topicLayout .topicTitle h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.8rem;
  line-height: 1.8;
  letter-spacing: 2.4px;
  color: #000;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicTitle h2 {
    font-size: 2.2rem;
    letter-spacing: 2.2px;
  }
}

#topicsTemplate .topicLayout .topicTitle span {
  font-size: 1.8rem;
  letter-spacing: 1.8px;
  color: #000;
  display: block;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicTitle span {
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
}

#topicsTemplate .topicLayout .topicPerson {
  padding: 20px;
  border-top: solid 1px #b59738;
  border-bottom: solid 1px #b59738;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicPerson {
    padding: 2.5% 10px;
  }
}

#topicsTemplate .topicLayout .topicPerson p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicPerson p {
    font-size: 1.6rem;
  }
}

#topicsTemplate .topicLayout .topicPerson span {
  font-size: 1.4rem;
  letter-spacing: 1.6px;
  display: block;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicPerson span {
    font-size: 1.2rem;
  }
}

#topicsTemplate .topicLayout .topicPerson span.name {
  display: inline-block;
  font-size: 1.6rem;
  color: #02377b;
}

#topicsTemplate .topicLayout .introTit,
#topicsTemplate .topicLayout .endTit {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 2.33;
  letter-spacing: 2.4px;
  color: #4b4b4b;
  text-align: center;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTit,
  #topicsTemplate .topicLayout .endTit {
    font-size: 2.4rem;
    margin: 8% auto;
  }
}

#topicsTemplate .topicLayout .introTit::before, #topicsTemplate .topicLayout .introTit::after,
#topicsTemplate .topicLayout .endTit::before,
#topicsTemplate .topicLayout .endTit::after {
  content: "";
  width: 20%;
  height: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #8d8d8d;
}

#topicsTemplate .topicLayout .introTit::before,
#topicsTemplate .topicLayout .endTit::before {
  right: calc(55% + 50px);
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTit::before,
  #topicsTemplate .topicLayout .endTit::before {
    right: calc(50% + 70px);
  }
}

#topicsTemplate .topicLayout .introTit::after,
#topicsTemplate .topicLayout .endTit::after {
  left: calc(55% + 50px);
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTit::after,
  #topicsTemplate .topicLayout .endTit::after {
    left: calc(50% + 70px);
  }
}

#topicsTemplate .topicLayout .introTxt {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTxt {
    display: block;
  }
}

#topicsTemplate .topicLayout .introTxt .txtBox {
  flex-basis: 67%;
}

#topicsTemplate .topicLayout .introTxt a {
  display: block;
  flex-basis: 27%;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTxt a {
    padding-top: 30px;
  }
}

#topicsTemplate .topicLayout .introTxt a img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTxt a img {
    width: 90%;
    margin: 0 auto;
  }
}

#topicsTemplate .topicLayout .topicContent {
  padding: 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent {
    padding: 0;
  }
}

#topicsTemplate .topicLayout .topicContent p {
  margin-bottom: 20px;
}

#topicsTemplate .topicLayout .topicContent.simple {
  padding-top: 30px;
  margin-bottom: 45px;
  border-top: solid 1px #b59738;
}

#topicsTemplate .topicLayout .topicContent.simple p {
  line-height: 2;
  color: #4b4b4b;
}

#topicsTemplate .topicLayout .topicContent.simple a {
  letter-spacing: 1.4px;
  color: #004092;
  text-decoration: underline;
  padding-bottom: 15px;
  display: inline-block;
}

#topicsTemplate .topicLayout .topicContent.simple .bold {
  letter-spacing: 1.4px;
  color: #4b4b4b;
  display: block;
}

#topicsTemplate .topicLayout .topicContent.simple .small {
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  color: #707070;
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable {
  margin-top: 20px;
  border: solid 1px #d3d3d3;
  border-left-width: 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable {
    margin-top: 0;
    border-right-width: 0;
    border-top-width: 0;
  }
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable thead {
  background-color: #f6f6f6;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable thead {
    display: none;
  }
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable thead p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  letter-spacing: 1.4px;
  color: #02377b;
  margin: 0px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable tbody {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable tbody tr {
    display: block;
    width: 100vw;
    margin: 0 -20px;
    padding: 20px 30px 0;
  }
  #topicsTemplate .topicLayout .topicContent.simple .topicTable tbody tr:nth-child(even) {
    background-color: #F8F9FB;
  }
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable tbody tr p {
  margin: 0px;
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable tbody tr p span {
  display: none;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable tbody tr p span {
    display: block;
    font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
    line-height: 1;
    letter-spacing: 1.4px;
    color: #02377b;
    padding-bottom: 10px;
  }
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable td {
  vertical-align: top;
  padding: 15px 1.5% 15px 3%;
  border: solid 1px #d3d3d3;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable td {
    display: block;
    width: 100%;
    border: 0;
    padding: 0 0 20px 0;
  }
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable td p {
    font-size: 1.3rem;
    letter-spacing: 1.3px;
  }
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable td a {
  font-size: 1.4rem;
  letter-spacing: .7px;
  color: #02377b;
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable td:first-child {
  width: 20%;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable td:first-child {
    width: 100%;
  }
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable td:nth-child(2) {
  min-width: 84px;
}

#topicsTemplate .topicLayout .topicContent.simple .topicTable td:nth-child(3) {
  width: 35%;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.simple .topicTable td:nth-child(3) {
    width: 100%;
  }
}

#topicsTemplate .topicLayout .topicContent.special {
  overflow: hidden;
}

#topicsTemplate .topicLayout .topicContent.special h3 {
  font-size: 2.4rem;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #000000;
  position: relative;
  margin: 60px 0 45px;
  position: relative;
  display: inline-block;
  color: #02377b;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.special h3 {
    margin: 50px 0 30px;
  }
}

#topicsTemplate .topicLayout .topicContent.special h3::before, #topicsTemplate .topicLayout .topicContent.special h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

#topicsTemplate .topicLayout .topicContent.special h3::before {
  width: 100px;
  transform: translate(20px, -3px);
}

#topicsTemplate .topicLayout .topicContent.special h3::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

#topicsTemplate .topicLayout .topicContent.special .minContent {
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.special .minContent {
    padding: 0 10px;
  }
}

#topicsTemplate .topicLayout .topicContent.special h4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #02377b;
  margin-bottom: 10px;
}

#topicsTemplate .topicLayout .topicContent.special span {
  font-weight: bold;
}

#topicsTemplate .topicLayout .topicContent.special img {
  margin: 40px auto;
}

#topicsTemplate .topicLayout .topicContent.special ul {
  background-color: #f8f9fb;
  padding: 20px 30px;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.special ul {
    padding: 20px;
  }
}

#topicsTemplate .topicLayout .topicContent.special ul li {
  margin-bottom: 5px;
  padding-left: 15px;
  text-indent: -15px;
}

#topicsTemplate .topicLayout .topicContent.special ul li:last-child {
  margin-bottom: 0;
}

#topicsTemplate .topicLayout .topicContent.special ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #4f96b1;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#topicsTemplate .topicLayout ol {
  margin: 30px 0;
  counter-reset: number 0;
}

#topicsTemplate .topicLayout ol li {
  padding-left: 36px;
  margin-bottom: 24px;
  position: relative;
}

#topicsTemplate .topicLayout ol li::before {
  counter-increment: number;
  content: counter(number, decimal-leading-zero) "・";
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #004092;
  position: absolute;
  left: 0;
  top: 0;
}

#topicsTemplate .topicLayout .dotList {
  margin: 30px 0;
  background-color: #f8f9fb;
  padding: 20px 20px 30px;
}

#topicsTemplate .topicLayout .dotList dt {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #02377b;
  padding-left: 20px;
  text-indent: -20px;
  margin-bottom: 10px;
}

#topicsTemplate .topicLayout .dotList dt::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 5px;
  height: 5px;
  background-color: #4f96b1;
  vertical-align: middle;
}

#topicsTemplate .topicLayout .dotList dd {
  padding-left: 20px;
  margin-bottom: 10px;
}

#topicsTemplate .topicLayout .dotList dd:last-child {
  margin-bottom: 0;
}

#topicsTemplate .topicLayout .listTit {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  letter-spacing: 2px;
  text-align: center;
  color: #000000;
  margin: 50px 0 30px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .listTit {
    font-size: 2rem;
    margin: 25px 0 15px;
  }
}

#topicsTemplate .topicLayout .simpleList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList {
    display: block;
  }
}

#topicsTemplate .topicLayout .simpleList dt,
#topicsTemplate .topicLayout .simpleList dd {
  padding-bottom: 40px;
}

#topicsTemplate .topicLayout .simpleList dt {
  flex-basis: 20%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dt {
    padding-bottom: 10px;
  }
}

#topicsTemplate .topicLayout .simpleList dt:first-of-type {
  border-top: 1px solid #004092;
  padding-top: 40px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dt:first-of-type {
    padding-top: 30px;
  }
}

#topicsTemplate .topicLayout .simpleList dt:last-of-type {
  border-bottom: 1px solid #004092;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dt:last-of-type {
    border-bottom: 0;
  }
}

#topicsTemplate .topicLayout .simpleList dd {
  flex-basis: 80%;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dd {
    padding-bottom: 30px;
  }
}

#topicsTemplate .topicLayout .simpleList dd:first-of-type {
  padding-top: 40px;
  border-top: 1px solid #d3d3d3;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dd:first-of-type {
    border: 0;
    padding-top: 0;
  }
}

#topicsTemplate .topicLayout .simpleList dd:last-of-type {
  border-bottom: 1px solid #d3d3d3;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dd:last-of-type {
    border-bottom: 1px solid #004092;
  }
}

#topicsTemplate .topicLayout .simpleList a {
  color: #004092;
}

#topicsTemplate .topicLayout .exList {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .exList {
    display: block;
    margin-left: -36px;
  }
}

#topicsTemplate .topicLayout .exList dt,
#topicsTemplate .topicLayout .exList dd {
  padding: 10px 0;
  margin-bottom: 0;
}

#topicsTemplate .topicLayout .exList dt:nth-of-type(odd),
#topicsTemplate .topicLayout .exList dd:nth-of-type(odd) {
  background-color: rgba(234, 239, 243, 0.5);
}

#topicsTemplate .topicLayout .exList dt {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #004092;
  flex-basis: 30%;
  padding-left: 20px;
}

#topicsTemplate .topicLayout .exList dt::before {
  display: none;
}

#topicsTemplate .topicLayout .exList dd {
  flex-basis: 70%;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .exList dd {
    padding-left: 20px;
    padding-top: 0;
  }
}

#topicsTemplate .topicLayout hr {
  border-color: #d3d3d3;
  margin: 30px 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout hr {
    margin: 4% 0;
  }
}

#topicsTemplate .topicLayout .personLayout {
  margin: 60px 20px 20px;
  padding: 30px;
  border: solid 1px #eaeff3;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .personLayout {
    margin: 20px 0;
  }
}

#topicsTemplate .topicLayout .personLayout hr {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .personLayout hr {
    margin-top: 8%;
  }
}

#topicsTemplate .topicLayout .personName {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.1rem;
  line-height: 2.33;
  letter-spacing: 2.1px;
  color: #02377b;
  margin-bottom: 10px;
}

#topicsTemplate .topicLayout .personName span {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: normal;
  color: #004092;
  margin-left: 20px;
}

#topicsTemplate .topicLayout .personJob {
  font-size: 15px;
  line-height: 1.87;
  letter-spacing: 1.5px;
  color: #000000;
  padding-left: 20px;
  border-left: solid 1px #004092;
  margin-bottom: 15px;
}

#topicsTemplate .topicLayout .attention {
  text-align: right;
  line-height: 2;
  letter-spacing: normal;
  color: #707070;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .attention {
    font-size: 1.2rem;
  }
}

#topicsTemplate .topicLayout .referenceLiterature {
  margin-top: 80px;
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .referenceLiterature {
    margin-top: 40px;
    padding: 0 10px;
  }
}

#topicsTemplate .topicLayout .referenceLiterature .mincontent {
  background-color: #f6f6f6;
  padding: 30px 40px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .referenceLiterature .mincontent {
    padding: 30px;
  }
}

#topicsTemplate .topicLayout .referenceLiterature h5 {
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: left;
  color: #000000;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

#topicsTemplate .topicLayout .referenceLiterature h5:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 200%;
  height: 1px;
  background-color: #b59738;
  transform-origin: center right;
}

#topicsTemplate .topicLayout .referenceLiterature p {
  padding: 20px 0 0;
  margin: 0;
}

#topicsTemplate .topicLayout .bgColumn {
  background-color: #f8f9fb;
  padding: 30px 20px;
  margin-bottom: 30px;
}

#topicsTemplate .topicLayout .bgColumn:last-of-type {
  margin-bottom: 0;
}

#topicsTemplate .topicLayout .bgColumn p {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .backToPageBtn {
    padding: 60px 0 0;
  }
}

/************************************
最新情報関連
***********************************/
/* ================================================================================
seminar　トップページ
================================================================================ */
#seminarIdx .newTit,
#seminarIdx .closedTit {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1.25;
  letter-spacing: 3.2px;
  text-align: center;
  color: #000000;
  padding-bottom: 45px;
  margin-bottom: 40px;
  border-bottom: 1px solid;
  position: relative;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newTit,
  #seminarIdx .closedTit {
    font-size: 2.4rem;
  }
}

#seminarIdx .newTit::after,
#seminarIdx .closedTit::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 5px;
}

#seminarIdx .newTit {
  border-color: #b59738;
}

#seminarIdx .newTit::after {
  background-color: #b59738;
}

#seminarIdx .closedTit {
  border-color: #707070;
}

#seminarIdx .closedTit::after {
  background-color: #707070;
}

#seminarIdx .newSeminar {
  max-width: 1120px;
  padding: 80px 20px 0;
  width: 100%;
  margin: 0 auto 120px;
}

#seminarIdx .newSeminar ul {
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar ul {
    margin-bottom: 50px;
    padding: 0;
  }
}

#seminarIdx .newSeminar li {
  background-color: rgba(250, 250, 250, 0.68);
  margin-bottom: 40px;
}

#seminarIdx .newSeminar li:last-child {
  margin-bottom: 0;
}

#seminarIdx .newSeminar a {
  padding: 30px;
  display: block;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar a {
    padding: 20px;
  }
}

#seminarIdx .newSeminar .column {
  display: flex;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar .column {
    display: block;
  }
}

#seminarIdx .newSeminar .column .seminarDate {
  flex-basis: 15%;
  padding-right: 35px;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar .column .seminarDate {
    padding-right: 0;
  }
}

#seminarIdx .newSeminar .column .seminarDate p {
  font-size: 2.2rem;
  line-height: 1.55;
  letter-spacing: 1.1px;
  color: #000;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar .column .seminarDate p {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
}

#seminarIdx .newSeminar .column .seminarDate p span {
  font-size: 1.6rem;
  line-height: .8px;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar .column .seminarDate p span {
    font-size: 1.4rem;
  }
}

#seminarIdx .newSeminar .column .seminarDate .full {
  font-size: 1.8rem;
  line-height: 1.89;
  letter-spacing: 0.9px;
  text-align: center;
  color: #ffffff;
  background-color: #b53232;
  margin-top: 20px;
  display: block;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar .column .seminarDate .full {
    font-size: 1.2rem;
    display: inline-block;
    margin-top: 0;
    margin-left: 20px;
    padding: 0 20px;
  }
}

#seminarIdx .newSeminar .column .seminarTit {
  flex-basis: 85%;
  padding-left: 40px;
  border-left: solid 1px #004092;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar .column .seminarTit {
    border: none;
    border-top: solid 1px #004092;
    padding-left: 0;
    padding-top: 10px;
  }
}

#seminarIdx .newSeminar .column .seminarTit h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 2.4px;
  color: #02377b;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  #seminarIdx .newSeminar .column .seminarTit h2 {
    font-size: 1.8rem;
  }
}

#seminarIdx .newSeminar .column .seminarTit p {
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #4b4b4b;
}

#seminarIdx .newSeminar .column .seminarTit .more {
  font-size: 1.3rem;
  position: relative;
  text-align: right;
  padding-right: 40px;
  margin-top: 20px;
}

#seminarIdx .closedList {
  max-width: 1120px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
}

#seminarIdx .closedList dl {
  display: block;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #seminarIdx .closedList dl {
    padding-bottom: 30px;
  }
}

#seminarIdx .closedList dl:first-of-type dd {
  display: block;
}

#seminarIdx .closedList dl:last-of-type {
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #seminarIdx .closedList dl:last-of-type {
    padding-bottom: 15px;
  }
}

#seminarIdx .closedList dl dt {
  border-bottom: 1px solid #A2A5A7;
  position: relative;
  cursor: pointer;
}

#seminarIdx .closedList dl dt::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 48px;
  height: 20px;
  background-image: url(/images/icons/icon_toggle_btn.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: .5s;
}

@media screen and (max-width: 767px) {
  #seminarIdx .closedList dl dt::after {
    width: 24px;
    height: 10px;
  }
}

#seminarIdx .closedList dl dt.open::after {
  transform: rotate(-180deg);
}

#seminarIdx .closedList dl dt span {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 4.8rem;
  line-height: 1;
  letter-spacing: 4.8px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #seminarIdx .closedList dl dt span {
    font-size: 3rem;
    padding-bottom: 10px;
  }
}

#seminarIdx .closedList dl dt span::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #02377b;
  bottom: -1px;
  left: 0;
}

#seminarIdx .closedList dl dd {
  padding-top: 35px;
  display: none;
}

@media screen and (max-width: 767px) {
  #seminarIdx .closedList dl dd {
    padding-top: 20px;
  }
}

#seminarIdx .closedList p.attention {
  text-align: right;
  font-size: 1.2rem;
  line-height: 2;
  color: #707070;
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #seminarIdx .closedList p.attention {
    padding-bottom: 60px;
  }
}

#seminarIdx .closedList .archiveBtnLayout {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #seminarIdx .closedList .archiveBtnLayout {
    margin-bottom: 60px;
  }
}

/* ================================================================================
seminar　トップページ
================================================================================ */
#seminarTemplate .seminarLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: 80px auto 120px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarLayout {
    margin: 7% auto 16%;
    overflow: hidden;
  }
}

#seminarTemplate .seminarLayout p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#seminarTemplate .seminarLayout .attention {
  font-size: 1.2rem;
  text-align: right;
  color: #707070;
  padding-top: 10px;
}

#seminarTemplate .seminarLayout h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 2.4px;
  color: #02377b;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarLayout h3 {
    font-size: 2rem;
    letter-spacing: 2px;
  }
}

#seminarTemplate .seminarLayout hr {
  margin: 100px 0 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarLayout hr {
    width: calc(100% - 40px);
    margin: 30px auto 0;
  }
}

#seminarTemplate .seminarLayout hr.borderNon {
  border: none;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarLayout hr + .attention {
    padding-right: 20px;
  }
}

#seminarTemplate .seminarHead {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead {
    margin-bottom: 50px;
    padding: 0 20px;
  }
}

#seminarTemplate .seminarHead h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 2;
  letter-spacing: 1.6px;
  color: #02377b;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead h2 {
    font-size: 2rem;
    letter-spacing: 2.2px;
  }
}

#seminarTemplate .seminarHead h2 span {
  font-size: 2.8rem;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead h2 span {
    font-size: 1.8rem;
  }
}

#seminarTemplate .seminarHead .type {
  color: #000;
  padding-left: 10px;
  border-left: solid 1px #707070;
  margin-bottom: 20px;
}

#seminarTemplate .seminarHead .seminar_ttl {
  color: #02377b;
  font-size: 2rem;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  letter-spacing: 1px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .seminar_ttl {
    font-size: 1.6rem;
    padding: 0 30px;
  }
}

#seminarTemplate .seminarHead dl.lecturer {
  display: flex;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead dl.lecturer {
    display: block;
  }
}

#seminarTemplate .seminarHead dl.lecturer dt,
#seminarTemplate .seminarHead dl.lecturer dd {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #000;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead dl.lecturer dt,
  #seminarTemplate .seminarHead dl.lecturer dd {
    font-size: 1.6rem;
  }
}

#seminarTemplate .seminarHead dl.lecturer dt span,
#seminarTemplate .seminarHead dl.lecturer dd span {
  font-size: 1.4rem;
  letter-spacing: 1.4px;
}

#seminarTemplate .seminarHead dl.lecturer dt {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead dl.lecturer dt {
    padding: 0;
  }
}

#seminarTemplate .seminarHead .intro {
  padding-left: 20px;
  margin-top: 30px;
  line-height: 2;
}

#seminarTemplate .seminarHead .intro strong {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1.8;
  letter-spacing: 1.8px;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .intro {
    padding: 0;
    margin-top: 4%;
  }
}

#seminarTemplate .seminarHead .fullTxt {
  text-align: center;
  margin: 60px 0;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .fullTxt {
    margin: 20px 0;
  }
}

#seminarTemplate .seminarHead .fullTxt .ttl {
  display: inline-block;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3rem;
  line-height: 1.87;
  color: #de1111;
  position: relative;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .fullTxt .ttl {
    font-size: 2rem;
  }
}

#seminarTemplate .seminarHead .fullTxt .ttl::before, #seminarTemplate .seminarHead .fullTxt .ttl::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #de1111;
  height: 1px;
  width: 18vw;
  max-width: 267px;
}

#seminarTemplate .seminarHead .fullTxt .ttl::before {
  right: 100%;
  transform: translateX(-30px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .fullTxt .ttl::before {
    transform: translateX(-20px);
  }
}

#seminarTemplate .seminarHead .fullTxt .ttl::after {
  left: 100%;
  transform: translateX(30px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .fullTxt .ttl::after {
    transform: translateX(20px);
  }
}

#seminarTemplate .seminarHead .fullTxt span {
  display: block;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.8px;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .fullTxt span {
    font-size: 1.4rem;
  }
}

#seminarTemplate .seminarHead .endTxt {
  text-align: center;
  margin: 60px 0;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .endTxt {
    margin: 30px 0;
  }
}

#seminarTemplate .seminarHead .endTxt p {
  display: inline-block;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  color: #de1111;
  position: relative;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .endTxt p {
    font-size: 1.4rem;
  }
}

#seminarTemplate .seminarHead .endTxt p::before, #seminarTemplate .seminarHead .endTxt p::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #de1111;
  height: 1px;
  width: 10vw;
  max-width: 142px;
}

#seminarTemplate .seminarHead .endTxt p::before {
  right: 100%;
  transform: translateX(-20px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .endTxt p::before {
    transform: translateX(-5px);
  }
}

#seminarTemplate .seminarHead .endTxt p::after {
  left: 100%;
  transform: translateX(20px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarHead .endTxt p::after {
    transform: translateX(5px);
  }
}

#seminarTemplate .seminarDetail {
  border-bottom: solid 1px #d3d3d3;
  position: relative;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail {
    padding: 0 20px;
    border-bottom: 0;
  }
}

#seminarTemplate .seminarDetail::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 20%;
  height: 1px;
  background-color: #004092;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail::after {
    width: calc(100% - 40px);
    left: 20px;
  }
}

#seminarTemplate .seminarDetail > dl {
  display: flex;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail > dl {
    display: block;
  }
}

#seminarTemplate .seminarDetail > dl > dt,
#seminarTemplate .seminarDetail > dl > dd {
  padding: 30px 0;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail > dl > dt,
  #seminarTemplate .seminarDetail > dl > dd {
    max-width: none;
  }
}

#seminarTemplate .seminarDetail > dl > dt {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
  flex-basis: 20%;
  max-width: 20%;
  border-top: solid 1px #004092;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail > dl > dt {
    padding: 30px 0 10px;
  }
}

#seminarTemplate .seminarDetail > dl > dd {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: .7px;
  color: #4b4b4b;
  flex-basis: 80%;
  max-width: 80%;
  border-top: solid 1px #d3d3d3;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail > dl > dd {
    border-top: 0;
    padding: 0 0 40px;
  }
}

#seminarTemplate .seminarDetail .access {
  display: flex;
  margin-top: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #seminarTemplate .seminarDetail .access {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail .access {
    display: block;
  }
}

#seminarTemplate .seminarDetail .accessTit {
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.15;
  letter-spacing: 1.3px;
  color: #004092;
  padding-left: 8px;
  padding-right: 25px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail .accessTit {
    margin-bottom: 10px;
  }
}

#seminarTemplate .seminarDetail .accessTit::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 1px;
  height: 18px;
  background-color: #02377b;
}

#seminarTemplate .seminarDetail .map {
  height: 0;
  overflow: hidden;
  padding-bottom: 54%;
  position: relative;
  margin-top: 30px;
}

#seminarTemplate .seminarDetail .map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#seminarTemplate .seminarDetail .program {
  display: flex;
  flex-wrap: wrap;
  /*****リンク部下線*****/
}

@media screen and (max-width: 767px) {
  #seminarTemplate .seminarDetail .program {
    display: block;
  }
}

#seminarTemplate .seminarDetail .program dt {
  padding-right: 20px;
  flex-basis: auto;
}

#seminarTemplate .seminarDetail .program dd {
  flex-basis: 80%;
  margin-bottom: 10px;
}

#seminarTemplate .seminarDetail .program a {
  border-bottom: 1px solid #0073aa;
  color: #0073aa;
}

#seminarTemplate .seminarDetail .dotList li {
  margin-bottom: 10px;
  padding-left: 15px;
  position: relative;
}

#seminarTemplate .seminarDetail .dotList li::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 5px;
  height: 5px;
  background-color: #004092;
}

#seminarTemplate .seminarDetail .dotList li:last-child {
  margin-bottom: 0;
}

#seminarTemplate .seminarDetail .dotList li p {
  display: inline;
}

#seminarTemplate .seminarDetail .dotList + p {
  margin-top: 10px;
}

#seminarTemplate .seminarDetail .seminarName {
  color: #004092;
  font-size: 1.6rem;
  margin-bottom: 10px;
}

#seminarTemplate .seminarDetail .warning {
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 0.6px;
  color: #de1111;
  margin-top: 10px;
}

#seminarTemplate .juku {
  /*特別顧問*/
  /*アドバイザーの皆さま*/
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku {
    padding: 0;
  }
}

#seminarTemplate .juku .seminarApply,
#seminarTemplate .juku .seminarProfile,
#seminarTemplate .juku .specialAdvisory,
#seminarTemplate .juku .adviserList {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApply,
  #seminarTemplate .juku .seminarProfile,
  #seminarTemplate .juku .specialAdvisory,
  #seminarTemplate .juku .adviserList {
    overflow: inherit;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApply .attention,
  #seminarTemplate .juku .seminarProfile .attention,
  #seminarTemplate .juku .specialAdvisory .attention,
  #seminarTemplate .juku .adviserList .attention {
    border-top: 1px solid #d3d3d3;
  }
}

#seminarTemplate .juku .seminarApply h3,
#seminarTemplate .juku .seminarProfile h3,
#seminarTemplate .juku .specialAdvisory h3,
#seminarTemplate .juku .adviserList h3 {
  position: relative;
  display: inline-block;
  margin: 80px 0 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApply h3,
  #seminarTemplate .juku .seminarProfile h3,
  #seminarTemplate .juku .specialAdvisory h3,
  #seminarTemplate .juku .adviserList h3 {
    margin: 50px 0;
  }
}

#seminarTemplate .juku .seminarApply h3::before, #seminarTemplate .juku .seminarApply h3::after,
#seminarTemplate .juku .seminarProfile h3::before,
#seminarTemplate .juku .seminarProfile h3::after,
#seminarTemplate .juku .specialAdvisory h3::before,
#seminarTemplate .juku .specialAdvisory h3::after,
#seminarTemplate .juku .adviserList h3::before,
#seminarTemplate .juku .adviserList h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApply h3::before, #seminarTemplate .juku .seminarApply h3::after,
  #seminarTemplate .juku .seminarProfile h3::before,
  #seminarTemplate .juku .seminarProfile h3::after,
  #seminarTemplate .juku .specialAdvisory h3::before,
  #seminarTemplate .juku .specialAdvisory h3::after,
  #seminarTemplate .juku .adviserList h3::before,
  #seminarTemplate .juku .adviserList h3::after {
    top: inherit;
    bottom: -15px;
    left: 0;
  }
}

#seminarTemplate .juku .seminarApply h3::before,
#seminarTemplate .juku .seminarProfile h3::before,
#seminarTemplate .juku .specialAdvisory h3::before,
#seminarTemplate .juku .adviserList h3::before {
  width: 100px;
  transform: translate(20px, -3px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApply h3::before,
  #seminarTemplate .juku .seminarProfile h3::before,
  #seminarTemplate .juku .specialAdvisory h3::before,
  #seminarTemplate .juku .adviserList h3::before {
    transform: translate(0, -3px);
  }
}

#seminarTemplate .juku .seminarApply h3::after,
#seminarTemplate .juku .seminarProfile h3::after,
#seminarTemplate .juku .specialAdvisory h3::after,
#seminarTemplate .juku .adviserList h3::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApply h3::after,
  #seminarTemplate .juku .seminarProfile h3::after,
  #seminarTemplate .juku .specialAdvisory h3::after,
  #seminarTemplate .juku .adviserList h3::after {
    transform: translate(0, 3px);
  }
}

#seminarTemplate .juku .seminarApply h3 span,
#seminarTemplate .juku .seminarProfile h3 span,
#seminarTemplate .juku .specialAdvisory h3 span,
#seminarTemplate .juku .adviserList h3 span {
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  color: #000000;
}

#seminarTemplate .juku .seminarApplyBox {
  background-color: #f8f9fb;
  padding: 20px 0 20px 40px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApplyBox {
    padding: 20px;
  }
}

#seminarTemplate .juku .seminarApplyBox .seminarForm {
  margin-top: 20px;
  display: flex;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApplyBox .seminarForm {
    display: block;
  }
}

#seminarTemplate .juku .seminarApplyBox .seminarForm a {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: center;
  color: #02377b;
  background-color: #fff;
  border: solid .5px #02377b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApplyBox .seminarForm a {
    padding: 12px;
    width: 100%;
  }
}

#seminarTemplate .juku .seminarApplyBox .seminarForm a::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 12px;
  margin-right: 10px;
  background-image: url("/images/icons/icon_mail_blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

#seminarTemplate .juku .seminarApplyBox .seminarForm span {
  font-size: 1.3rem;
  line-height: 1.62;
  letter-spacing: 0.65px;
  color: #707070;
  padding-left: 20px;
  border-left: solid 1px #707070;
  margin-left: 50px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarApplyBox .seminarForm span {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    border: none;
    display: block;
  }
}

#seminarTemplate .juku .seminarProfile {
  /****講師名余白****/
}

#seminarTemplate .juku .seminarProfile .lecturerName {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: 2.4px;
  color: #02377b;
  border-left: solid 1px #02377b;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarProfile .lecturerName {
    font-size: 1.9rem;
    letter-spacing: 1px;
  }
}

#seminarTemplate .juku .seminarProfile .lecturerName span {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.2rem;
  letter-spacing: normal;
  color: #004092;
  margin-left: 20px;
}

#seminarTemplate .juku .seminarProfile p {
  padding-left: 20px;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .seminarProfile p {
    padding-left: 10px;
  }
}

#seminarTemplate .juku .seminarProfile .teacherRepeatBox {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table,
  #seminarTemplate .juku .adviserList table {
    display: block;
    margin: 0 -20px;
    width: 100vw;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table tbody,
  #seminarTemplate .juku .adviserList table tbody {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table tbody tr,
  #seminarTemplate .juku .adviserList table tbody tr {
    display: block;
    position: relative;
  }
  #seminarTemplate .juku .specialAdvisory table tbody tr:nth-child(odd),
  #seminarTemplate .juku .adviserList table tbody tr:nth-child(odd) {
    background-color: #F8F9FB;
  }
}

#seminarTemplate .juku .specialAdvisory table tbody tr:first-child,
#seminarTemplate .juku .adviserList table tbody tr:first-child {
  background-color: #f6f6f6;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table tbody tr:first-child,
  #seminarTemplate .juku .adviserList table tbody tr:first-child {
    display: none;
  }
}

#seminarTemplate .juku .specialAdvisory table tbody tr:first-child td p,
#seminarTemplate .juku .adviserList table tbody tr:first-child td p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  letter-spacing: 1.4px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table tbody tr:nth-child(2):before,
  #seminarTemplate .juku .adviserList table tbody tr:nth-child(2):before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: calc(100% - 40px);
    height: 1px;
    background-color: #D3D3D3;
    margin: auto;
  }
}

#seminarTemplate .juku .specialAdvisory table tbody tr td,
#seminarTemplate .juku .adviserList table tbody tr td {
  padding: 15px 15px 15px 40px;
  border: 1px solid #F6F6F6;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table tbody tr td,
  #seminarTemplate .juku .adviserList table tbody tr td {
    display: block;
    border: 0;
    padding: 0 32px;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table tbody tr td:first-child,
  #seminarTemplate .juku .adviserList table tbody tr td:first-child {
    padding: 25px 32px 0;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table tbody tr td p,
  #seminarTemplate .juku .adviserList table tbody tr td p {
    padding-bottom: 25px;
  }
}

#seminarTemplate .juku .specialAdvisory table tbody tr td p span,
#seminarTemplate .juku .adviserList table tbody tr td p span {
  display: none;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .specialAdvisory table tbody tr td p span,
  #seminarTemplate .juku .adviserList table tbody tr td p span {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1.4px;
    text-align: left;
    color: #02377b;
    padding-bottom: 10px;
  }
}

#seminarTemplate .juku .adviserList .comment {
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: .8px;
  margin-bottom: 30px;
}

#seminarTemplate .juku .schoolLayout {
  margin-top: 100px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout {
    margin-top: 80px;
    padding: 0 20px;
  }
}

#seminarTemplate .juku .schoolLayout .schoolName {
  background-color: #eaeff3;
  padding: 20px 0;
  display: flex;
}

#seminarTemplate .juku .schoolLayout .schoolName h4 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.78;
  letter-spacing: 1.8px;
  text-indent: 1.8px;
  text-align: center;
  color: #000;
  display: inline-block;
  margin: auto;
  position: relative;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout .schoolName h4 {
    font-size: 1.6rem;
  }
}

#seminarTemplate .juku .schoolLayout .schoolName h4::before, #seminarTemplate .juku .schoolLayout .schoolName h4::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 1px;
  background-color: #707070;
  top: 0;
  bottom: 0;
  margin: auto;
}

#seminarTemplate .juku .schoolLayout .schoolName h4::before {
  left: -31px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout .schoolName h4::before {
    left: -21px;
  }
}

#seminarTemplate .juku .schoolLayout .schoolName h4::after {
  right: -26px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout .schoolName h4::after {
    right: -18px;
  }
}

#seminarTemplate .juku .schoolLayout .schoolName span {
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #4b4b4b;
  display: block;
}

#seminarTemplate .juku .schoolLayout .schoolDetail {
  padding: 40px 6%;
  background-color: #fff;
  border: solid 1px #d3d3d3;
  border-top: none;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout .schoolDetail {
    padding: 40px 20px;
  }
}

#seminarTemplate .juku .schoolLayout .schoolDetail dl {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout .schoolDetail dl {
    display: block;
  }
}

#seminarTemplate .juku .schoolLayout .schoolDetail dl dt,
#seminarTemplate .juku .schoolLayout .schoolDetail dl dd {
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout .schoolDetail dl dt,
  #seminarTemplate .juku .schoolLayout .schoolDetail dl dd {
    padding-bottom: 0;
    max-width: none;
  }
}

#seminarTemplate .juku .schoolLayout .schoolDetail dl dt:last-of-type,
#seminarTemplate .juku .schoolLayout .schoolDetail dl dd:last-of-type {
  padding-bottom: 0;
}

#seminarTemplate .juku .schoolLayout .schoolDetail dl dt {
  flex-basis: 18%;
  max-width: 18%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout .schoolDetail dl dt {
    font-size: 1.4rem;
    padding-bottom: 10px;
  }
}

#seminarTemplate .juku .schoolLayout .schoolDetail dl dd {
  flex-basis: 82%;
  max-width: 82%;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 6%;
  border-left: solid 1px #d3d3d3;
  word-break: break-all;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .juku .schoolLayout .schoolDetail dl dd {
    border-left: 0;
    padding-left: 0;
    padding-bottom: 40px;
  }
}

#seminarTemplate .juku .schoolLayout .schoolDetail dl a {
  color: #004092;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax {
    padding: 0;
  }
}

#seminarTemplate .tax .seminarApply {
  overflow: hidden;
  text-align: center;
  margin-top: 80px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarApply {
    margin: 10% 20px 0;
  }
}

#seminarTemplate .tax .seminarApply h3 {
  position: relative;
  display: inline-block;
}

#seminarTemplate .tax .seminarApply h3::before, #seminarTemplate .tax .seminarApply h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #b7b7b7;
  height: 1px;
  width: 50vw;
}

#seminarTemplate .tax .seminarApply h3::before {
  right: 100%;
  transform: translateX(-60px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarApply h3::before {
    width: 50%;
    transform: translateX(-15px);
  }
}

#seminarTemplate .tax .seminarApply h3::after {
  left: 100%;
  transform: translateX(60px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarApply h3::after {
    width: 50%;
    transform: translateX(15px);
  }
}

#seminarTemplate .tax .seminarApplyBox {
  background-color: #f8f9fb;
  padding: 50px 40px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarApplyBox {
    display: block;
    padding: 20px;
    margin-bottom: 10%;
  }
}

#seminarTemplate .tax .seminarApplyBox p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.8px;
  color: #4b4b4b;
  text-align: left;
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarApplyBox p {
    font-size: 1.4rem;
    margin: 0;
  }
}

#seminarTemplate .tax .seminarApplyBox .btn {
  padding: 0;
  margin: 0;
  border-left: solid 1px #02377b;
  padding-left: 40px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarApplyBox .btn {
    border: none;
    padding: 0;
    border-top: solid 1px #02377b;
    padding-top: 20px;
    margin-top: 20px;
    max-width: none;
  }
}

#seminarTemplate .tax .seminarApplyBox .btn a {
  width: 100%;
  display: inline-block;
  padding: 18px 24px;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: center;
  color: #fff;
  background-color: #02377b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarApplyBox .btn a {
    font-size: 1.4rem;
    padding: 12px;
  }
}

#seminarTemplate .tax .seminarApplyBox .btn a .moreBar::after {
  background-color: #fff;
}

#seminarTemplate .tax .seminarApplyBox .btn a.notHover {
  background-color: #ccc;
  color: #666;
  cursor: inherit;
}

#seminarTemplate .tax .seminarApplyBox .btn a.notHover .moreBar {
  display: none;
}

#seminarTemplate .tax .seminarProfile {
  overflow: hidden;
  text-align: center;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarProfile {
    margin: 0 20px 40px;
  }
}

#seminarTemplate .tax .seminarProfile h3 {
  position: relative;
  display: inline-block;
}

#seminarTemplate .tax .seminarProfile h3::before, #seminarTemplate .tax .seminarProfile h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #b7b7b7;
  height: 1px;
  width: 50vw;
}

#seminarTemplate .tax .seminarProfile h3::before {
  right: 100%;
  transform: translateX(-60px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarProfile h3::before {
    transform: translateX(-20px);
  }
}

#seminarTemplate .tax .seminarProfile h3::after {
  left: 100%;
  transform: translateX(60px);
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarProfile h3::after {
    transform: translateX(20px);
  }
}

#seminarTemplate .tax .seminarProfileBox {
  padding: 40px;
  background-color: #f8f9fb;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarProfileBox {
    padding: 20px;
  }
}

#seminarTemplate .tax .seminarProfile .lecturerNumber {
  font-size: 1.6rem;
  margin: 40px 0 10px;
  padding: 0;
}

#seminarTemplate .tax .seminarProfile .lecturerNumber:first-child {
  margin-top: 0;
}

#seminarTemplate .tax .seminarProfile .lecturerNumber + .lecturerName {
  margin-top: 10px;
}

#seminarTemplate .tax .seminarProfile .lecturerName {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: 2.4px;
  color: #02377b;
  margin: 40px 0 20px;
  padding: 0;
}

#seminarTemplate .tax .seminarProfile .lecturerName:first-child {
  margin-top: 0;
}

#seminarTemplate .tax .seminarProfile .post p {
  font-size: 1.5rem;
  line-height: 1.87;
  letter-spacing: 1.5px;
  color: #000;
  border-left: solid 1px #b59738;
  padding-left: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarProfile .post p {
    font-size: 1.4rem;
  }
}

#seminarTemplate .tax .seminarProfile p {
  line-height: 2;
  text-align: left;
}

#seminarTemplate .tax .seminarProfile .teacherProfile {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarProfile .teacherProfile {
    padding-left: 0;
  }
}

#seminarTemplate .tax .seminarOrganized {
  border: solid #d3d3d3;
  border-width: 1px 0;
  padding: 30px 0;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarOrganized {
    margin: 0 20px;
  }
}

#seminarTemplate .tax .seminarOrganized dl {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarOrganized dl {
    display: block;
  }
}

#seminarTemplate .tax .seminarOrganized dl dt {
  flex-basis: 20%;
  max-width: 20%;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarOrganized dl dt {
    max-width: none;
  }
}

#seminarTemplate .tax .seminarOrganized dl dt p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

#seminarTemplate .tax .seminarOrganized dl dd {
  flex-basis: 80%;
  max-width: 80%;
  border-left: solid 1px #707070;
  padding-left: 20px;
  margin-bottom: 40px;
  display: flex;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarOrganized dl dd {
    display: block;
    max-width: none;
    margin-bottom: 20px;
    border: none;
    border-top: solid 1px #707070;
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 0;
  }
}

#seminarTemplate .tax .seminarOrganized dl dd:last-of-type {
  margin-bottom: 0;
}

#seminarTemplate .tax .seminarOrganized dl dd p,
#seminarTemplate .tax .seminarOrganized dl dd .box {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.8px;
  color: #000000;
}

#seminarTemplate .tax .seminarOrganized dl dd .min p {
  color: #707070;
}

#seminarTemplate .tax .seminarOrganized a {
  margin-left: 60px;
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: center;
  color: #02377b;
  background-color: #fff;
  border: solid .5px #02377b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .tax .seminarOrganized a {
    margin: 0;
    margin-top: 20px;
    width: 100%;
    font-size: 1.4rem;
  }
}

#seminarTemplate .tax .seminarOrganized a::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 12px;
  margin-right: 10px;
  background-image: url(/images/icons/icon_mail_blue.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

#seminarTemplate .annual {
  /*講師・スピーカーのご紹介*/
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual {
    padding: 0;
  }
}

#seminarTemplate .annual .seminarHead {
  margin-bottom: 0;
}

#seminarTemplate .annual .endImage {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .endImage {
    display: block;
  }
}

#seminarTemplate .annual .endImage img {
  width: 24%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .endImage img {
    width: 100%;
    margin-bottom: 20px;
  }
}

#seminarTemplate .annual .annualTit {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2.4;
  letter-spacing: 1px;
  color: #02377b;
  display: inline-block;
  position: relative;
  padding: 0 50px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #seminarTemplate .annual .annualTit.twoRowTit {
    line-height: 1.6;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .annualTit {
    font-size: 1.6rem;
    padding: 0 30px;
  }
}

#seminarTemplate .annual .annualTit::before, #seminarTemplate .annual .annualTit::after {
  content: "";
  width: 30px;
  height: 1px;
  background-color: #b59738;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .annualTit::before, #seminarTemplate .annual .annualTit::after {
    width: 20px;
  }
}

#seminarTemplate .annual .annualTit::before {
  left: 0;
}

#seminarTemplate .annual .annualTit::after {
  right: 0;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .annualTit {
    line-height: 1.5;
    margin-bottom: 5px;
  }
}

#seminarTemplate .annual .annualSubTit {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #000;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .annualSubTit {
    font-size: 1.6rem;
  }
}

#seminarTemplate .annual .annualImg {
  margin: 60px 0;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .annualImg {
    padding-left: 0;
    width: 100%;
  }
}

#seminarTemplate .annual h3 {
  position: relative;
  display: inline-block;
  margin: 80px 0 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual h3 {
    margin: 50px 0 40px;
  }
}

#seminarTemplate .annual h3::before, #seminarTemplate .annual h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual h3::before, #seminarTemplate .annual h3::after {
    top: inherit;
    bottom: -15px;
    left: -20px;
  }
}

#seminarTemplate .annual h3::before {
  width: 100px;
  transform: translate(20px, -3px);
}

#seminarTemplate .annual h3::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

#seminarTemplate .annual h3 span {
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  color: #02377b;
}

#seminarTemplate .annual .seminarDetail,
#seminarTemplate .annual .seminarSpeaker,
#seminarTemplate .annual .seminarTheme,
#seminarTemplate .annual .entryForecast {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarDetail,
  #seminarTemplate .annual .seminarSpeaker,
  #seminarTemplate .annual .seminarTheme,
  #seminarTemplate .annual .entryForecast {
    padding: 0 20px;
  }
}

#seminarTemplate .annual .seminarDetail {
  border: none;
  margin-bottom: 0;
}

#seminarTemplate .annual .seminarDetail::after {
  display: none;
}

#seminarTemplate .annual .seminarDetail .seminarDetail_time {
  display: block;
  display: flex;
}

#seminarTemplate .annual .seminarDetail .seminarDetail_time .seminarDetail_time_ttl {
  white-space: nowrap;
}

#seminarTemplate .annual dl {
  flex-wrap: wrap;
  padding: 30px 10px;
  border: solid #d3d3d3;
  border-width: 1px 0;
  margin: 0 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual dl {
    display: block;
    margin: 0;
    padding: 20px 0;
  }
}

#seminarTemplate .annual dl dt,
#seminarTemplate .annual dl dd {
  padding-top: 0;
  border: none;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual dl dt,
  #seminarTemplate .annual dl dd {
    max-width: none;
  }
}

#seminarTemplate .annual dl dt:last-of-type,
#seminarTemplate .annual dl dd:last-of-type {
  padding-bottom: 0;
}

#seminarTemplate .annual dl dt {
  flex-basis: 25%;
  max-width: 25%;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual dl dt {
    padding: 0;
  }
}

#seminarTemplate .annual dl dt p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  color: #02377b;
}

#seminarTemplate .annual dl dd {
  flex-basis: 75%;
  max-width: 75%;
  border-left: 1px solid #d3d3d3;
  padding-left: 50px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual dl dd {
    padding-left: 0;
    padding-bottom: 20px;
    border: none;
  }
}

#seminarTemplate .annual ul {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual ul {
    padding-left: 10px;
  }
}

#seminarTemplate .annual ul li {
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
}

#seminarTemplate .annual ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #004092;
  position: absolute;
  top: 11px;
  left: 0;
}

#seminarTemplate .annual ul li.notMark::before {
  display: none;
}

#seminarTemplate .annual .other {
  font-size: 1.3rem;
}

#seminarTemplate .annual .other a {
  color: #02377b;
}

#seminarTemplate .annual hr {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual hr {
    width: 100%;
    margin: 60px 0 40px;
  }
}

#seminarTemplate .annual .seminarSpeaker h4 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 1.8px;
  color: #4b4b4b;
  padding-left: 20px;
  border-left: solid 1px #4b4b4b;
  margin-top: 50px;
}

#seminarTemplate .annual .seminarSpeaker h4:first-of-type {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker h4 {
    margin-top: 30px;
    padding-left: 10px;
  }
}

#seminarTemplate .annual .seminarSpeaker h5 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1;
  letter-spacing: 1.6px;
  color: #4b4b4b;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker h5 {
    margin-top: 30px;
    padding-left: 10px;
  }
}

#seminarTemplate .annual .seminarSpeaker table {
  margin-top: 20px;
  border: solid 1px #d3d3d3;
  border-left-width: 0;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table {
    margin-top: 0;
    border-right-width: 0;
    border-top-width: 0;
  }
}

#seminarTemplate .annual .seminarSpeaker table thead {
  background-color: #f6f6f6;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table thead {
    display: none;
  }
}

#seminarTemplate .annual .seminarSpeaker table thead p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  letter-spacing: 1.4px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table tbody {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table tbody tr {
    display: block;
    width: 100vw;
    margin: 0 -20px;
    padding: 20px 30px 0;
  }
  #seminarTemplate .annual .seminarSpeaker table tbody tr:nth-child(even) {
    background-color: #F8F9FB;
  }
}

#seminarTemplate .annual .seminarSpeaker table tbody tr p span {
  display: none;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table tbody tr p span {
    display: block;
    font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
    line-height: 1;
    letter-spacing: 1.4px;
    color: #02377b;
    padding-bottom: 10px;
  }
}

#seminarTemplate .annual .seminarSpeaker table td {
  vertical-align: top;
  padding: 15px 1.5% 15px 3%;
  border: solid 1px #d3d3d3;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table td {
    display: block;
    width: 100%;
    border: 0;
    padding: 0 0 20px 0;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table td p {
    font-size: 1.3rem;
    letter-spacing: 1.3px;
  }
}

#seminarTemplate .annual .seminarSpeaker table td a {
  font-size: 1.4rem;
  letter-spacing: .7px;
  color: #02377b;
}

#seminarTemplate .annual .seminarSpeaker table td:first-child {
  width: 20%;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table td:first-child {
    width: 100%;
  }
}

#seminarTemplate .annual .seminarSpeaker table td:nth-child(2) {
  min-width: 84px;
}

#seminarTemplate .annual .seminarSpeaker table td:nth-child(3) {
  width: 35%;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table td:nth-child(3) {
    width: 100%;
  }
}

#seminarTemplate .annual .seminarSpeaker table.seminarSpeaker2022 td:first-child {
  width: 35%;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table.seminarSpeaker2022 td:first-child {
    width: 100%;
  }
}

#seminarTemplate .annual .seminarSpeaker table.seminarSpeaker2022 td:nth-child(2) {
  min-width: 84px;
}

#seminarTemplate .annual .seminarSpeaker table.seminarSpeaker2022 td:nth-child(3) {
  width: 25%;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarSpeaker table.seminarSpeaker2022 td:nth-child(3) {
    width: 100%;
  }
}

#seminarTemplate .annual .seminarReception {
  border: solid 1px #b7b7b7;
  padding: 50px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception {
    margin: 0 20px;
    padding: 20px;
  }
}

#seminarTemplate .annual .seminarReception .topColumn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 40px;
  border-bottom: solid 1px #b7b7b7;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception .topColumn {
    display: block;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
}

#seminarTemplate .annual .seminarReception .topColumn p {
  flex-basis: 60%;
  max-width: 60%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  color: #000000;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception .topColumn p {
    max-width: none;
  }
}

#seminarTemplate .annual .seminarReception .topColumn .btn {
  width: 30%;
  margin: 0;
  padding-top: 30px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception .topColumn .btn {
    width: 100%;
  }
}

#seminarTemplate .annual .seminarReception .topColumn .btn a {
  background-color: #02377b;
  color: #fff;
}

#seminarTemplate .annual .seminarReception .topColumn .btn a .moreBar {
  width: 12px;
}

#seminarTemplate .annual .seminarReception .topColumn .btn a .moreBar::after {
  background-color: #fff;
}

#seminarTemplate .annual .seminarReception .topColumn .btn a.notHover {
  background-color: #ccc;
  color: #666;
  cursor: inherit;
}

#seminarTemplate .annual .seminarReception .topColumn .btn a.notHover .moreBar {
  display: none;
}

#seminarTemplate .annual .seminarReception .other {
  font-size: 1.3rem;
}

#seminarTemplate .annual .seminarReception .other a {
  color: #02377b;
}

#seminarTemplate .annual .seminarReception + .attention {
  padding-right: 20px;
}

#seminarTemplate .annual .seminarReception02 {
  border: solid 1px #b7b7b7;
  padding: 50px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception02 {
    padding: 20px;
  }
}

#seminarTemplate .annual .seminarReception02 .topColumn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception02 .topColumn {
    display: block;
  }
}

#seminarTemplate .annual .seminarReception02 .topColumn p {
  flex-basis: 60%;
  max-width: 60%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  color: #000000;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception02 .topColumn p {
    max-width: none;
  }
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn {
  width: 30%;
  margin: 0;
  padding-top: 0;
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn.notHover {
  background-color: #ccc;
  color: #666;
  cursor: inherit;
  font-size: 1.4rem;
  line-height: 1.3;
  letter-spacing: .7px;
  padding: 25px 0;
  position: relative;
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn.notHover a {
  display: contents;
  pointer-events: none;
  padding: 0;
  background-color: inherit;
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn.notHover .moreBar {
  display: none;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception02 .topColumn .btn.notHover {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception02 .topColumn .btn {
    width: 100%;
    padding-top: 30px;
  }
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn a {
  background-color: #02377b;
  color: #fff;
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn a .moreBar {
  width: 12px;
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn a .moreBar::after {
  background-color: #fff;
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn a.notHover {
  background-color: #ccc;
  color: #666;
  cursor: inherit;
}

#seminarTemplate .annual .seminarReception02 .topColumn .btn a.notHover .moreBar {
  display: none;
}

#seminarTemplate .annual .seminarReception03 {
  border: solid 1px #b7b7b7;
  padding: 50px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #seminarTemplate .annual .seminarReception03 {
    padding: 30px 25px;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception03 {
    padding: 20px;
  }
}

#seminarTemplate .annual .seminarReception03 .topColumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: solid 1px #b7b7b7;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception03 .topColumn {
    display: block;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
}

#seminarTemplate .annual .seminarReception03 .topColumn p {
  flex-basis: 54%;
  max-width: 54%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  color: #000000;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #seminarTemplate .annual .seminarReception03 .topColumn p {
    flex-basis: 53%;
    max-width: 53%;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception03 .topColumn p {
    max-width: none;
  }
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn {
  width: 36%;
  margin: 0;
  padding-top: 0;
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn.notHover {
  background-color: #ccc;
  color: #666;
  cursor: inherit;
  font-size: 1.4rem;
  line-height: 1.3;
  letter-spacing: .7px;
  padding: 25px 0;
  position: relative;
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn.notHover a {
  display: contents;
  pointer-events: none;
  padding: 0;
  background-color: inherit;
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn.notHover .moreBar {
  display: none;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception03 .topColumn .btn.notHover {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #seminarTemplate .annual .seminarReception03 .topColumn .btn {
    flex-basis: 42%;
    max-width: 42%;
  }
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception03 .topColumn .btn {
    width: 100%;
    padding-top: 30px;
    margin: 0 auto;
  }
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn a {
  background-color: #02377b;
  color: #fff;
  margin-bottom: 15px;
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn a .moreBar {
  width: 12px;
  right: 6%;
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn a .moreBar::after {
  background-color: #fff;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #seminarTemplate .annual .seminarReception03 .topColumn .btn a .moreBar {
    right: 4%;
  }
}

@media screen and (max-width: 320px) {
  #seminarTemplate .annual .seminarReception03 .topColumn .btn a .moreBar {
    right: 4%;
  }
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn a.notHover {
  background-color: #ccc;
  color: #666;
  cursor: inherit;
}

#seminarTemplate .annual .seminarReception03 .topColumn .btn a.notHover .moreBar {
  display: none;
}

#seminarTemplate .annual .seminarReception03 .other {
  font-size: 1.3rem;
}

#seminarTemplate .annual .seminarReception03 .other a {
  color: #02377b;
}

#seminarTemplate .annual .seminarReception03 + .attention {
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarReception03 + .attention {
    padding-right: 0;
  }
}

#seminarTemplate .annual .seminarTheme h4 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1;
  letter-spacing: 1.8px;
  color: #444;
  padding-left: 20px;
  border-left: solid 1px #4b4b4b;
  margin-top: 50px;
  margin-bottom: 15px;
}

#seminarTemplate .annual .seminarTheme h4:first-of-type {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarTheme h4 {
    line-height: 1.6;
    margin-top: 30px;
    padding-left: 10px;
  }
}

#seminarTemplate .annual .seminarTheme .seminarThemeTxt {
  padding-left: 39px;
}

@media screen and (max-width: 767px) {
  #seminarTemplate .annual .seminarTheme .seminarThemeTxt {
    padding-left: 10px;
  }
}

#seminarTemplate .annual .seminarTheme .aboutTxt span {
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1.85;
  letter-spacing: 0.65px;
  color: #707070;
  margin-top: 40px;
}

#seminarTemplate .annual .seminarTheme ul {
  padding-top: 30px;
}

#seminarTemplate .annual .seminarTheme ul li:last-child {
  padding-left: 0;
}

#seminarTemplate .annual .seminarTheme ul li:last-child::before {
  display: none;
}

#seminarTemplate .annual .seminarTheme ul li span {
  font-size: 1.3rem;
  line-height: 1.85;
  letter-spacing: 0.65px;
  color: #707070;
}

/************************************
プライバシーポリシーなど
***********************************/
/*個人情報保護方針*/
/* ================================================================================
個人情報保護方針
================================================================================ */
#privacypolicyTop .privacyLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 120px;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout {
    margin-bottom: 17%;
    overflow-x: hidden;
  }
}

#privacypolicyTop .privacyLayout h2 {
  margin-bottom: 30px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 3.2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout h2 {
    font-size: 2rem;
    letter-spacing: 2px;
    margin-bottom: 5%;
  }
}

#privacypolicyTop .privacyLayout h3 {
  margin: 50px 0 10px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout h3 {
    margin: 8.5% -0px 3%;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
}

#privacypolicyTop .privacyLayout h4 {
  margin: 30px 0 20px;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout h4 {
    margin: 4% 0 3%;
  }
}

#privacypolicyTop .privacyLayout p, #privacypolicyTop .privacyLayout a {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#privacypolicyTop .privacyLayout > p:last-of-type {
  margin-top: 30px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout > p:last-of-type {
    margin-top: 4.5%;
  }
}

#privacypolicyTop .privacyLayout ul {
  background: #f8f9fb;
  margin: 30px 0;
  padding: 20px;
}

#privacypolicyTop .privacyLayout ul li {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
}

#privacypolicyTop .privacyLayout ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #004092;
  position: absolute;
  top: 11px;
  left: 0;
}

#privacypolicyTop .privacyLayout ul li:last-child {
  margin-bottom: 0;
}

#privacypolicyTop .privacyLayout ul li span {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #02377b;
}

#privacypolicyTop .privacyLayout ol {
  counter-reset: number 0;
  margin-top: 20px;
}

#privacypolicyTop .privacyLayout ol > li {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 23px;
  position: relative;
  margin-bottom: 50px;
}

#privacypolicyTop .privacyLayout ol > li::before {
  counter-increment: number 1;
  content: counter(number) ".";
  position: absolute;
  left: 0;
  top: 0;
}

#privacypolicyTop .privacyLayout ol ul {
  background: none;
  padding: 0;
  margin: 20px 0;
}

#privacypolicyTop .privacyLayout dl {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  padding: 12px 0;
  border: solid #004092;
  border-width: 1px 0;
  position: relative;
}

#privacypolicyTop .privacyLayout dl:before, #privacypolicyTop .privacyLayout dl:after {
  content: "";
  position: absolute;
  right: 0;
  width: 80%;
  border-bottom: 1px solid #d3d3d3;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout dl:before, #privacypolicyTop .privacyLayout dl:after {
    width: 65%;
  }
}

#privacypolicyTop .privacyLayout dl:before {
  top: -1px;
}

#privacypolicyTop .privacyLayout dl:after {
  bottom: -1px;
}

#privacypolicyTop .privacyLayout dl dt,
#privacypolicyTop .privacyLayout dl dd {
  padding: 12px 0;
}

#privacypolicyTop .privacyLayout dl dt {
  flex-basis: 20%;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout dl dt {
    flex-basis: 35%;
  }
}

#privacypolicyTop .privacyLayout dl dt p {
  color: #02377b;
}

#privacypolicyTop .privacyLayout dl dd {
  flex-basis: 80%;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout dl dd {
    flex-basis: 65%;
  }
}

#privacypolicyTop .privacyLayout .reception {
  padding: 30px 40px;
  margin-top: 30px;
  background: #f8f9fb;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout .reception {
    padding: 20px;
  }
}

#privacypolicyTop .privacyLayout .reception h4 {
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.8px;
  margin: 0;
}

#privacypolicyTop .privacyLayout .reception p {
  margin-top: 10px;
  padding-left: 20px;
  border-left: solid 1px #b59738;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout .reception p {
    padding-left: 10px;
  }
}

#privacypolicyTop .privacyLayout .privacyUseList {
  display: flex;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout .privacyUseList {
    display: block;
    margin-top: 20px;
  }
}

#privacypolicyTop .privacyLayout .privacyUseList dl {
  display: block;
  border: solid 1px #D3D3D3;
  padding: 0px;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout .privacyUseList dl {
    margin-top: 0px;
  }
  #privacypolicyTop .privacyLayout .privacyUseList dl:nth-of-type(2) {
    border-left: solid 1px #D3D3D3;
  }
}

#privacypolicyTop .privacyLayout .privacyUseList dl:before, #privacypolicyTop .privacyLayout .privacyUseList dl:after {
  display: none;
}

#privacypolicyTop .privacyLayout .privacyUseList dl:nth-of-type(2) {
  border-left: none;
}

#privacypolicyTop .privacyLayout .privacyUseList dt {
  font-family: "Noto Serif JP","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Verdana,sans-serif;
  background-color: #F6F6F6;
  padding: 16px 40px;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout .privacyUseList dt {
    padding: 15px;
    letter-spacing: 1.5px;
  }
}

#privacypolicyTop .privacyLayout .privacyUseList dd ul {
  margin: 0px;
  background: #FFF;
  padding: 8px 40px 8px 40px;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout .privacyUseList dd ul {
    padding: 15px;
  }
}

#privacypolicyTop .privacyLayout .privacyUseTable {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  #privacypolicyTop .privacyLayout .privacyUseTable {
    display: block;
  }
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody {
  border: solid 1px #D3D3D3;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr {
  border: solid 1px #d3d3d3;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr th {
  background-color: #F6F6F6;
  text-align: center;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr th.privacyUseTtl {
  width: 340px;
  border-left: dashed 1px #D3D3D3;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr th.privacyUseTtl p {
  font-family: "Noto Serif JP","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Verdana,sans-serif;
  background-color: #F6F6F6;
  padding: 16px;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 1.6px;
  color: #02377b;
  font-weight: 400;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr th.privacyUseTtl p::before {
  display: inline-block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #004092;
  margin-right: 5px;
  vertical-align: middle;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr th.privacyUseTtl2 {
  padding: 20px 40px;
  width: 300px;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr th.privacyUseTtl2 p {
  text-align: left;
  font-weight: 400;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr td.privacyUseTxt {
  padding: 16px;
  border-left: dashed 1px #D3D3D3;
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: .7px;
  color: #4b4b4b;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr td.privacyUseTxt p.center {
  text-align: center;
}

#privacypolicyTop .privacyLayout .privacyUseTable tbody tr td.privacyUseTxt p.companyname {
  margin: 0 auto;
  width: 35%;
}

#privacypolicyTop .privacyLayout .privacyUseTable_sp h4 {
  font-size: 1.4rem;
  font-family: "Noto Serif JP","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Verdana,sans-serif;
}

#privacypolicyTop .privacyLayout .privacyUseTable_sp h4::before {
  display: inline-block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #004092;
  margin-right: 5px;
  vertical-align: middle;
}

#privacypolicyTop .privacyLayout .privacyUseTable_sp .privacyUseTablebox {
  display: block;
  border: none;
  margin-top: 0;
}

#privacypolicyTop .privacyLayout .privacyUseTable_sp .privacyUseTablebox dt {
  position: relative;
  z-index: 1;
  font-size: 1.4rem;
  line-height: 1.46;
  padding: 10px 0;
}

#privacypolicyTop .privacyLayout .privacyUseTable_sp .privacyUseTablebox dt::after {
  content: "";
  display: block;
  width: 100vw;
  height: 100%;
  background-color: #F6F6F6;
  position: absolute;
  top: 0;
  left: -20px;
  z-index: -1;
}

#privacypolicyTop .privacyLayout .privacyUseTable_sp .privacyUseTablebox dd {
  font-size: 1.4rem;
  line-height: 1.46;
  padding: 10px 0;
}

#privacypolicyTop .privacyLayout .privacyUseTable_sp .privacyUseTablebox::before, #privacypolicyTop .privacyLayout .privacyUseTable_sp .privacyUseTablebox::after {
  display: none;
}

/*ISMS/情報セキュリティへの取り組み*/
/* ================================================================================
ISMS / 情報セキュリティへの取り組み 
================================================================================ */
#ismsTop .ismsLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 120px;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout {
    margin-bottom: 17%;
  }
}

#ismsTop .ismsLayout h2 {
  margin-bottom: 30px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 3.2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout h2 {
    font-size: 2rem;
    letter-spacing: 2px;
    margin-bottom: 5%;
  }
}

#ismsTop .ismsLayout h3 {
  margin: 50px 0 10px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout h3 {
    margin: 8.5% -0px 3%;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
}

#ismsTop .ismsLayout p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#ismsTop .ismsLayout > p:last-of-type {
  margin-top: 60px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout > p:last-of-type {
    margin-top: 8.5%;
  }
}

#ismsTop .ismsLayout ol {
  counter-reset: number 0;
}

#ismsTop .ismsLayout ol > li {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 23px;
  padding-bottom: 20px;
  position: relative;
}

#ismsTop .ismsLayout ol > li::before {
  counter-increment: number 1;
  content: counter(number) "）";
  position: absolute;
  left: 0;
  top: 0;
  color: #02377b;
}

#ismsTop .ismsLayout ol > li:last-child {
  margin-bottom: 0;
}

#ismsTop .ismsLayout dl {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 30px 10px;
  border: solid #d3d3d3;
  border-width: 1px 0;
  margin: 0 20px 80px;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout dl {
    display: block;
    margin: 0 0 12%;
    padding: 20px 0;
  }
}

#ismsTop .ismsLayout dl dt,
#ismsTop .ismsLayout dl dd {
  padding-bottom: 30px;
}

#ismsTop .ismsLayout dl dt:last-of-type,
#ismsTop .ismsLayout dl dd:last-of-type {
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout dl dt,
  #ismsTop .ismsLayout dl dd {
    max-width: 100%;
  }
}

#ismsTop .ismsLayout dl dt {
  flex-basis: 20%;
  max-width: 20%;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout dl dt {
    padding-bottom: 0;
  }
}

#ismsTop .ismsLayout dl dt p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  color: #02377b;
}

#ismsTop .ismsLayout dl dd {
  flex-basis: 80%;
  max-width: 80%;
  border-left: 1px solid #d3d3d3;
  padding-left: 50px;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout dl dd {
    padding-left: 0;
    padding-bottom: 20px;
    border: none;
  }
}

#ismsTop .ismsLayout img {
  width: 52%;
  margin: 50px auto;
}

@media screen and (max-width: 767px) {
  #ismsTop .ismsLayout img {
    width: 75%;
    margin: 8.5% auto;
  }
}

/*SPC管理業務における内部管理体制*/
/* ================================================================================
SPC管理業務における内部管理体制 
================================================================================ */
#internalTop .internalLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 120px;
}

@media screen and (max-width: 767px) {
  #internalTop .internalLayout {
    margin-bottom: 17%;
  }
}

#internalTop .internalLayout h2 {
  margin-bottom: 30px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 3.2px;
  color: #004092;
}

#internalTop .internalLayout h2 span {
  display: block;
  font-size: 1.8rem;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #internalTop .internalLayout h2 span {
    font-size: 1.6rem;
    margin-top: 5%;
  }
}

@media screen and (max-width: 767px) {
  #internalTop .internalLayout h2 {
    font-size: 2rem;
    letter-spacing: 2px;
    margin-bottom: 5%;
  }
}

#internalTop .internalLayout h3 {
  margin: 50px 0 10px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #internalTop .internalLayout h3 {
    margin: 8.5% -0px 3%;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
}

#internalTop .internalLayout p, #internalTop .internalLayout a {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#internalTop .internalLayout a {
  text-decoration: underline;
  color: #004092;
}

#internalTop .internalLayout img {
  width: 70%;
  margin: 120px auto 0;
}

@media screen and (max-width: 767px) {
  #internalTop .internalLayout img {
    width: 100%;
    margin-top: 17%;
  }
}

/*リスクマネジメント*/
/* ================================================================================
リスクマネジメント
================================================================================ */
#complianceTop .complianceLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: 0 auto 120px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  #complianceTop .complianceLayout {
    margin-bottom: 17%;
  }
}

#complianceTop .complianceLayout.cancell {
  overflow: visible;
}

#complianceTop .complianceLayout h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 3.2px;
  color: #004092;
}

#complianceTop .complianceLayout h2 span {
  display: block;
  font-size: 1.8rem;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #complianceTop .complianceLayout h2 span {
    font-size: 1.4rem;
    margin-top: 5%;
  }
}

@media screen and (max-width: 767px) {
  #complianceTop .complianceLayout h2 {
    font-size: 2rem;
    letter-spacing: 2px;
  }
}

#complianceTop .complianceLayout h3 {
  margin: 60px 0 20px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 1.67;
  letter-spacing: 2.4px;
  color: #000000;
  position: relative;
  display: inline-block;
}

#complianceTop .complianceLayout h3::before, #complianceTop .complianceLayout h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

#complianceTop .complianceLayout h3::before {
  width: 100px;
  transform: translate(20px, -3px);
}

#complianceTop .complianceLayout h3::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

@media screen and (max-width: 767px) {
  #complianceTop .complianceLayout h3 {
    margin: 10% 0 3%;
    font-size: 1.8rem;
    letter-spacing: 1.8px;
  }
}

#complianceTop .complianceLayout h4 {
  margin: 60px 0 20px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #complianceTop .complianceLayout h4 {
    margin: 8.5% -0px 3%;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
}

#complianceTop .complianceLayout p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: .6px;
  color: #4b4b4b;
}

#complianceTop .complianceLayout .imgBox {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  #complianceTop .complianceLayout .imgBox {
    margin-top: 8.5%;
  }
}

#complianceTop .complianceLayout .imgBox .zoomBtn {
  display: none;
  margin-top: 10px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #complianceTop .complianceLayout .imgBox .zoomBtn {
    display: block;
  }
}

#complianceTop .complianceLayout .imgBox .zoomBtn p {
  padding-right: 25px;
  position: relative;
  display: inline-block;
}

#complianceTop .complianceLayout .imgBox .zoomBtn p::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  background-image: url(/images/icons/icon_zoomin.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

#complianceTop .complianceLayout .imgBox.open {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  z-index: 99999999;
  overflow-x: scroll;
  transition: background-color .5s;
}

#complianceTop .complianceLayout .imgBox.open .modalImg {
  width: 200vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: .5s;
}

#complianceTop .complianceLayout .imgBox.open .modalImg img {
  width: 100%;
}

#complianceTop .complianceLayout .imgBox.open .modalImg::before, #complianceTop .complianceLayout .imgBox.open .modalImg::after {
  content: "";
  width: 1px;
  height: 37px;
  background-color: #004092;
  position: fixed;
  right: 33px;
  top: 20px;
}

#complianceTop .complianceLayout .imgBox.open .modalImg::before {
  transform: rotate(45deg);
}

#complianceTop .complianceLayout .imgBox.open .modalImg::after {
  transform: rotate(-45deg);
}

#complianceTop .complianceLayout .imgBox.open .zoomBtn {
  display: none;
}

/************************************
青山塾
***********************************/
/*青山塾*/
/* ================================================================================
青山綜合経営塾（青山塾）
================================================================================ */
#jukuTop .jukuLayout {
  max-width: 1020px;
  margin: 80px auto 120px;
  padding: 0 20px;
  overflow: hidden;
}

#jukuTop .jukuLayout h3 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: 2.4px;
  color: #02377b;
  margin-top: 80px;
  position: relative;
  display: inline-block;
  margin: 80px 0 20px;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuLayout h3 {
    margin: 70px 0 50px;
    font-size: 2rem;
  }
}

#jukuTop .jukuLayout h3::before, #jukuTop .jukuLayout h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuLayout h3::before, #jukuTop .jukuLayout h3::after {
    top: inherit;
    bottom: -15px;
    left: 0;
  }
}

#jukuTop .jukuLayout h3::before {
  width: 100px;
  transform: translate(20px, -3px);
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuLayout h3::before {
    transform: translate(0, -3px);
  }
}

#jukuTop .jukuLayout h3::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuLayout h3::after {
    transform: translate(0, 3px);
  }
}

#jukuTop .jukuLayout p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#jukuTop .jukuLayout p span {
  font-weight: bold;
}

#jukuTop .jukuLayout p span.blue {
  font-size: 1.5rem;
  letter-spacing: 0.75px;
  color: #02377b;
  font-weight: normal;
}

#jukuTop .jukuLayout .jukuendTxt {
  text-align: center;
  margin-bottom: 60px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuLayout .jukuendTxt {
    margin-bottom: 30px;
  }
}

#jukuTop .jukuLayout .jukuendTxt p {
  display: inline-block;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  color: #000;
  position: relative;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuLayout .jukuendTxt p {
    font-size: 1.4rem;
  }
}

#jukuTop .jukuLayout .jukuendTxt p::before, #jukuTop .jukuLayout .jukuendTxt p::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #000;
  height: 1px;
  width: 10vw;
  max-width: 142px;
}

#jukuTop .jukuLayout .jukuendTxt p::before {
  right: 100%;
  transform: translateX(-20px);
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuLayout .jukuendTxt p::before {
    transform: translateX(-5px);
  }
}

#jukuTop .jukuLayout .jukuendTxt p::after {
  left: 100%;
  transform: translateX(20px);
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuLayout .jukuendTxt p::after {
    transform: translateX(5px);
  }
}

#jukuTop .jukuTit h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1.25;
  letter-spacing: 3.2px;
  color: #02377b;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuTit h2 {
    font-size: 2.4rem;
  }
}

#jukuTop .jukuTit p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  color: #000000;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuTit p {
    font-size: 1.6rem;
  }
}

#jukuTop .jukuMovie {
  display: block;
  position: relative;
  width: 100%;
  max-width: 640px;
  padding-top: 360px;
  margin: 60px auto 0;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuMovie {
    margin-top: 30px;
    padding-top: 56.25%;
  }
}

#jukuTop .jukuMovie iframe {
  background-color: gray;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#jukuTop .jukuPhilosophy h3 {
  color: #000;
}

#jukuTop .jukuPhilosophy img {
  width: 78%;
  max-width: 764px;
  margin: 40px auto 60px;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuPhilosophy img {
    width: 100%;
    margin: 20px auto 30px;
  }
}

#jukuTop .jukuDetail dl {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 30px 10px;
  border: solid #d3d3d3;
  border-width: 1px 0;
  margin: 20px 20px 0;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuDetail dl {
    display: block;
    margin: 0;
    padding: 0;
    border: none;
    border-top: solid 1px #004092;
  }
}

#jukuTop .jukuDetail dl dt,
#jukuTop .jukuDetail dl dd {
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuDetail dl dt,
  #jukuTop .jukuDetail dl dd {
    padding-bottom: 4%;
    max-width: 100%;
  }
}

#jukuTop .jukuDetail dl dt:last-of-type,
#jukuTop .jukuDetail dl dd:last-of-type {
  padding-bottom: 0;
}

#jukuTop .jukuDetail dl dt {
  flex-basis: 30%;
  max-width: 30%;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuDetail dl dt {
    padding: 30px 0 10px;
  }
}

#jukuTop .jukuDetail dl dt p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  color: #02377b;
}

#jukuTop .jukuDetail dl dd {
  flex-basis: 70%;
  max-width: 70%;
  border-left: 1px solid #d3d3d3;
  padding-left: 5.4%;
}

@media screen and (max-width: 767px) {
  #jukuTop .jukuDetail dl dd {
    padding: 0 0 40px;
    border: none;
    border-bottom: solid 1px #004092;
  }
  #jukuTop .jukuDetail dl dd:last-of-type {
    padding-bottom: 4%;
  }
}

#jukuTop .jukuDetail dl dd ul:first-of-type {
  margin-bottom: 15px;
}

#jukuTop .jukuDetail dl li {
  padding-left: 25px;
  position: relative;
}

#jukuTop .jukuDetail dl li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #02377b;
  position: absolute;
  top: 12px;
  left: 10px;
}

#jukuTop .specialAdvisory .tableList,
#jukuTop .executiveAdvisory .tableList,
#jukuTop .adviserList .tableList {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #jukuTop .specialAdvisory .tableList,
  #jukuTop .executiveAdvisory .tableList,
  #jukuTop .adviserList .tableList {
    margin-top: 0;
  }
}

#jukuTop .specialAdvisory .tableList thead,
#jukuTop .executiveAdvisory .tableList thead,
#jukuTop .adviserList .tableList thead {
  background-color: #f6f6f6;
}

@media screen and (max-width: 767px) {
  #jukuTop .specialAdvisory .tableList thead,
  #jukuTop .executiveAdvisory .tableList thead,
  #jukuTop .adviserList .tableList thead {
    display: none;
  }
}

#jukuTop .specialAdvisory .tableList thead p,
#jukuTop .executiveAdvisory .tableList thead p,
#jukuTop .adviserList .tableList thead p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  letter-spacing: 1.4px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #jukuTop .specialAdvisory .tableList tbody,
  #jukuTop .executiveAdvisory .tableList tbody,
  #jukuTop .adviserList .tableList tbody {
    display: block;
    width: 100%;
    border: #D3D3D3 solid;
    padding-bottom: 15px;
    border-width: 1px 0;
  }
}

@media screen and (max-width: 767px) {
  #jukuTop .specialAdvisory .tableList tbody tr,
  #jukuTop .executiveAdvisory .tableList tbody tr,
  #jukuTop .adviserList .tableList tbody tr {
    display: block;
    width: 100vw;
    margin: 0 -20px;
    padding: 20px 30px 0;
  }
  #jukuTop .specialAdvisory .tableList tbody tr:nth-child(even),
  #jukuTop .executiveAdvisory .tableList tbody tr:nth-child(even),
  #jukuTop .adviserList .tableList tbody tr:nth-child(even) {
    background-color: #F8F9FB;
  }
}

#jukuTop .specialAdvisory .tableList tbody p span,
#jukuTop .executiveAdvisory .tableList tbody p span,
#jukuTop .adviserList .tableList tbody p span {
  display: none;
}

@media screen and (max-width: 767px) {
  #jukuTop .specialAdvisory .tableList tbody p span,
  #jukuTop .executiveAdvisory .tableList tbody p span,
  #jukuTop .adviserList .tableList tbody p span {
    display: block;
    font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
    line-height: 1;
    font-weight: normal;
    letter-spacing: 1.4px;
    color: #02377b;
    padding-bottom: 10px;
  }
}

#jukuTop .specialAdvisory .tableList td,
#jukuTop .executiveAdvisory .tableList td,
#jukuTop .adviserList .tableList td {
  vertical-align: top;
  border: solid 1px #d3d3d3;
  padding: 15px 1.5% 15px 4%;
}

@media screen and (max-width: 767px) {
  #jukuTop .specialAdvisory .tableList td,
  #jukuTop .executiveAdvisory .tableList td,
  #jukuTop .adviserList .tableList td {
    display: block;
    border: 0;
    padding: 0 0 20px 0;
  }
}

#jukuTop .instructorList .tableList,
#jukuTop .presenterList .tableList {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList,
  #jukuTop .presenterList .tableList {
    margin-top: 0;
  }
}

#jukuTop .instructorList .tableList thead,
#jukuTop .presenterList .tableList thead {
  background-color: #f6f6f6;
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList thead,
  #jukuTop .presenterList .tableList thead {
    display: none;
  }
}

#jukuTop .instructorList .tableList thead p,
#jukuTop .presenterList .tableList thead p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  letter-spacing: 1.4px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList tbody,
  #jukuTop .presenterList .tableList tbody {
    display: block;
    width: 100%;
  }
}

#jukuTop .instructorList .tableList tbody tr,
#jukuTop .presenterList .tableList tbody tr {
  border-bottom: solid 1px #d3d3d3;
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList tbody tr,
  #jukuTop .presenterList .tableList tbody tr {
    display: block;
    width: 100vw;
    margin: 0 -20px;
    padding: 20px 30px 0;
    border: none;
  }
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList tbody tr:first-child,
  #jukuTop .presenterList .tableList tbody tr:first-child {
    padding-top: 0;
  }
}

#jukuTop .instructorList .tableList tbody tr p span,
#jukuTop .presenterList .tableList tbody tr p span {
  display: none;
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList tbody tr p span,
  #jukuTop .presenterList .tableList tbody tr p span {
    display: inline;
    font-weight: normal;
  }
}

#jukuTop .instructorList .tableList td,
#jukuTop .presenterList .tableList td {
  vertical-align: top;
  padding: 15px 1.5% 15px 3%;
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList td,
  #jukuTop .presenterList .tableList td {
    display: block;
    border: 0;
    padding: 0 0 20px 0;
    /********公演中止の場合*********/
  }
  #jukuTop .instructorList .tableList td.caseSuspension,
  #jukuTop .presenterList .tableList td.caseSuspension {
    background-color: #f6f6f6;
    margin: 0 -10px 15px;
    padding: 15px 10px;
  }
  #jukuTop .instructorList .tableList td.caseSuspension p,
  #jukuTop .presenterList .tableList td.caseSuspension p {
    font-family: "Noto Serif JP","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Verdana,sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 3.2px;
    color: #02377b;
  }
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList td p,
  #jukuTop .presenterList .tableList td p {
    font-size: 1.3rem;
    letter-spacing: 1.3px;
  }
}

#jukuTop .instructorList .tableList td a,
#jukuTop .presenterList .tableList td a {
  font-size: 1.4rem;
  letter-spacing: .7px;
  color: #02377b;
}

#jukuTop .instructorList .tableList td:nth-last-of-type(6),
#jukuTop .presenterList .tableList td:nth-last-of-type(6) {
  min-width: 91px;
}

#jukuTop .instructorList .tableList td:nth-last-of-type(5),
#jukuTop .presenterList .tableList td:nth-last-of-type(5) {
  min-width: 84px;
}

#jukuTop .instructorList .tableList td:nth-last-of-type(4),
#jukuTop .presenterList .tableList td:nth-last-of-type(4) {
  min-width: 122px;
}

#jukuTop .instructorList .tableList td:first-child {
  min-width: 91px;
}

#jukuTop .instructorList .tableList td:nth-child(2) {
  min-width: 84px;
}

#jukuTop .instructorList .tableList td:nth-child(3) {
  min-width: 122px;
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList td:nth-last-of-type(3) p {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: 1.6px;
  }
}

@media screen and (max-width: 767px) {
  #jukuTop .instructorList .tableList td:nth-last-of-type(5) {
    background-color: #f6f6f6;
    margin: 0 -10px 15px;
    padding: 15px 10px;
  }
  #jukuTop .instructorList .tableList td:nth-last-of-type(5) p {
    font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 3.2px;
    color: #02377b;
  }
}

@media screen and (max-width: 767px) {
  #jukuTop .presenterList .tableList td:nth-last-of-type(4) p {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: 1.6px;
  }
}

@media screen and (max-width: 767px) {
  #jukuTop .presenterList .tableList td:nth-last-of-type(6) {
    background-color: #f6f6f6;
    margin: 0 -10px 15px;
    padding: 15px 10px;
  }
  #jukuTop .presenterList .tableList td:nth-last-of-type(6) p {
    font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 3.2px;
    color: #02377b;
  }
}

@media screen and (max-width: 767px) {
  #jukuTop .presenterList .tableList td:last-child {
    border-bottom: solid 1px #b7b7b7;
  }
  #jukuTop .presenterList .tableList td:last-child p {
    font-size: 1.4rem;
    letter-spacing: .7px;
    color: #02377b;
  }
}

#jukuTop .presenterList .tableList td:nth-last-of-type(6) {
  min-width: 91px;
}

#jukuTop .presenterList .tableList td:nth-last-of-type(5) {
  min-width: 84px;
}

#jukuTop .presenterList .tableList td:nth-last-of-type(4) {
  min-width: 122px;
}

#jukuTop .presenterList .attention:not(:root) {
  margin-top: 20px;
}

#jukuTop .adviserList .comment:first-of-type {
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.8px;
  margin-bottom: 30px;
}

#jukuTop .schoolLayout {
  margin-top: 100px;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout {
    margin-top: 10%;
  }
}

#jukuTop .schoolLayout .schoolName {
  background-color: #eaeff3;
  padding: 20px 0;
  display: flex;
}

#jukuTop .schoolLayout .schoolName h4 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.78;
  letter-spacing: 1.8px;
  text-indent: 1.8px;
  text-align: center;
  color: #4b4b4b;
  display: inline-block;
  margin: auto;
  position: relative;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout .schoolName h4 {
    font-size: 1.6rem;
  }
}

#jukuTop .schoolLayout .schoolName h4::before, #jukuTop .schoolLayout .schoolName h4::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 1px;
  background-color: #707070;
  top: 0;
  bottom: 0;
  margin: auto;
}

#jukuTop .schoolLayout .schoolName h4::before {
  left: -31px;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout .schoolName h4::before {
    left: -21px;
  }
}

#jukuTop .schoolLayout .schoolName h4::after {
  right: -26px;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout .schoolName h4::after {
    right: -18px;
  }
}

#jukuTop .schoolLayout .schoolName span {
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #4b4b4b;
  display: block;
  font-weight: normal;
}

#jukuTop .schoolLayout .schoolDetail {
  padding: 40px 6%;
  background-color: #fff;
  border: solid 1px #d3d3d3;
  border-top: none;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout .schoolDetail {
    padding: 20px 15px;
  }
}

#jukuTop .schoolLayout .schoolDetail dl {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout .schoolDetail dl {
    display: block;
  }
}

#jukuTop .schoolLayout .schoolDetail dl dt,
#jukuTop .schoolLayout .schoolDetail dl dd {
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout .schoolDetail dl dt,
  #jukuTop .schoolLayout .schoolDetail dl dd {
    padding-bottom: 0;
    max-width: 100%;
  }
}

#jukuTop .schoolLayout .schoolDetail dl dt:last-of-type,
#jukuTop .schoolLayout .schoolDetail dl dd:last-of-type {
  padding-bottom: 0;
}

#jukuTop .schoolLayout .schoolDetail dl dt {
  flex-basis: 18%;
  max-width: 18%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout .schoolDetail dl dt {
    font-size: 1.4rem;
    padding-bottom: 10px;
  }
}

#jukuTop .schoolLayout .schoolDetail dl dd {
  flex-basis: 82%;
  max-width: 82%;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 6%;
  border-left: solid 1px #d3d3d3;
  word-break: break-all;
}

@media screen and (max-width: 767px) {
  #jukuTop .schoolLayout .schoolDetail dl dd {
    border-left: 0;
    padding-left: 0;
    padding-bottom: 40px;
  }
}

#jukuTop .schoolLayout .schoolDetail dl a {
  color: #004092;
}

#jukuTop .attention:not(:root) {
  text-align: right;
  letter-spacing: normal;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #jukuTop .attention:not(:root) {
    margin-top: 10px;
    font-size: 1.2rem;
  }
}

#jukuTop .attention:not(:root).gray {
  color: #707070;
}

/************************************
パートナー募集
***********************************/
/*パートナー募集*/
@media screen and (max-width: 767px) {
  #partnerTop .bassHeadVisual .mainVisual .titBox {
    width: 76% !important;
  }
  #partnerTop .bassHeadVisual .mainVisual .titBox h1 {
    letter-spacing: .5px;
    padding: 6.46464% 0 0 6.14045%;
  }
  #partnerTop .bassHeadVisual .mainVisual .titBox p {
    padding: 0 0 4.4444% 6.14045%;
  }
}

#partnerTop .philosophyDetail {
  overflow: hidden;
  max-width: 1020px;
  padding: 0 20px;
  margin: 50px auto 120px;
}

@media screen and (max-width: 767px) {
  #partnerTop .philosophyDetail {
    margin-bottom: 14.085%;
  }
}

#partnerTop .philosophyDetail h1 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  line-height: 1.75;
  letter-spacing: 3.2px;
  color: #004092;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #partnerTop .philosophyDetail h1 {
    font-size: 2rem;
    letter-spacing: 4px;
  }
}

#partnerTop .philosophyDetail p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: .5px;
}

#partnerTop .philosophyDetail .recruitingList {
  padding: 30px 40px;
  margin: 40px auto;
  background: #F8F9FB;
}

@media screen and (max-width: 767px) {
  #partnerTop .philosophyDetail .recruitingList {
    padding: 20px;
    margin: 30px auto 15px;
  }
}

#partnerTop .philosophyDetail .recruitingList li:not(:last-child) {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #partnerTop .philosophyDetail .recruitingList li:not(:last-child) {
    margin-bottom: 10px;
  }
}

#partnerTop .philosophyDetail .recruitingList p {
  padding-left: 1em;
  text-indent: -1em;
}

#partnerTop .philosophyDetail .recruitingList p:before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #004092;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#partnerTop .philosophyDetail .moveTopicsPage {
  display: block;
  max-width: 550px;
  margin: 90px auto 0;
}

@media screen and (max-width: 767px) {
  #partnerTop .philosophyDetail .moveTopicsPage {
    width: 77.46478%;
    margin: 35px auto 0;
  }
}

#partnerTop .philosophyDetail .moveTopicsPage a {
  display: block;
  border: solid 1px rgba(2, 55, 123, 0.85);
  padding: 30px 0;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 1.8px;
  color: #02377b;
  position: relative;
}

@media screen and (max-width: 767px) {
  #partnerTop .philosophyDetail .moveTopicsPage a {
    padding: 13px;
    font-size: 1.2rem;
    letter-spacing: 1.4px;
    line-height: 1;
  }
}

#partnerTop .philosophyDetail .moveTopicsPage a::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 145px;
  margin: auto;
  width: 30px;
  height: 2px;
  background-color: #02377b;
}

@media screen and (max-width: 767px) {
  #partnerTop .philosophyDetail .moveTopicsPage a::after {
    right: 50px;
    width: 15px;
    height: 1px;
  }
}

/************************************
お問い合わせフォーム
***********************************/
/*お問い合わせ*/
/* ================================================================================
お問い合わせ
================================================================================ */
#formBox {
  display: none;
}

#formBox.show {
  display: block;
}

#contactTop .formArea {
  max-width: 1020px;
  padding: 0 20px;
  margin: 80px auto;
}

#contactTop .formArea h3 {
  font-size: 1.6rem;
  color: #707070;
  margin-bottom: 20px;
}

#contactTop .formArea h4 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 1.8px;
  color: #000000;
}

#contactTop .formArea p {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #707070;
}

#contactTop .formArea p.errorTxt {
  font-size: 1.2rem;
  line-height: 2;
  color: #de1111;
}

#contactTop .formArea dl {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea dl {
    display: block;
  }
}

#contactTop .formArea dl dt {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

#contactTop .formArea dl dd {
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#contactTop .formArea .customersDivision {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .customersDivision {
    margin-bottom: 60px;
  }
}

#contactTop .formArea .customersDivision h3 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .customersDivision h3 {
    display: block;
  }
}

#contactTop .formArea .customersDivision .note {
  font-size: 1.4rem;
  line-height: 1.43;
  color: #8d8d8d;
}

#contactTop .formArea .customersDivision dl {
  margin-bottom: 20px;
}

#contactTop .formArea .customersDivision dl dt, #contactTop .formArea .customersDivision dl dd {
  padding: 30px 0;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .customersDivision dl dt, #contactTop .formArea .customersDivision dl dd {
    padding: 20px 0;
  }
}

#contactTop .formArea .customersDivision dl dt {
  flex-basis: 28%;
  border: solid #004092;
  border-width: 1px 0;
}

#contactTop .formArea .customersDivision dl dd {
  flex-basis: 72%;
  border: solid #d3d3d3;
  border-width: 1px 0;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .customersDivision dl dd {
    border-top: none;
  }
}

#contactTop .formArea .formBox h3 {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .formBox h3 {
    margin-bottom: 20px;
  }
}

#contactTop .formArea .formBox h4 {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .formBox h4 {
    margin-bottom: 20px;
  }
}

#contactTop .formArea .formBox dl {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .formBox dl {
    margin-bottom: 50px;
  }
}

#contactTop .formArea .formBox dl dt, #contactTop .formArea .formBox dl dd {
  padding-bottom: 40px;
}

#contactTop .formArea .formBox dl dt:last-of-type, #contactTop .formArea .formBox dl dd:last-of-type {
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .formBox dl dt, #contactTop .formArea .formBox dl dd {
    padding-bottom: 20px;
  }
  #contactTop .formArea .formBox dl dt:last-of-type, #contactTop .formArea .formBox dl dd:last-of-type {
    padding-bottom: 20px;
  }
}

#contactTop .formArea .formBox dl dt {
  flex-basis: 26%;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .formBox dl dt {
    border-top: solid 1px #d3d3d3;
    padding-top: 20px;
  }
}

#contactTop .formArea .formBox dl dd {
  flex-basis: 70%;
  padding-left: 4%;
  border-left: solid 1px #d3d3d3;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .formBox dl dd {
    padding-left: 0;
    border: none;
  }
  #contactTop .formArea .formBox dl dd:last-of-type {
    border-bottom: solid 1px #d3d3d3;
  }
}

#contactTop .formArea .formBox dl dd input {
  width: 58%;
  padding: 15px;
  border: solid 1px #b7b7b7;
  outline: 0;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .formBox dl dd input {
    width: 100%;
  }
}

#contactTop .formArea .formBox dl dd input.error {
  background-color: #ffe3e3;
}

#contactTop .formArea .formBox dl dd textarea {
  width: 100%;
  min-height: 200px;
  padding: 15px;
  border: solid 1px #b7b7b7;
  outline: 0;
}

#contactTop .formArea .formBox .ddList a {
  color: #02377b;
  display: inline-block;
  margin-bottom: 10px;
}

#contactTop .formArea .radioArea,
#contactTop .formArea .checkboxArea {
  margin-top: -20px;
}

#contactTop .formArea .radioArea input[type=radio],
#contactTop .formArea .radioArea input[type=checkbox],
#contactTop .formArea .checkboxArea input[type=radio],
#contactTop .formArea .checkboxArea input[type=checkbox] {
  display: none;
}

#contactTop .formArea .radioArea input[type=radio] + label,
#contactTop .formArea .radioArea input[type=checkbox] + label,
#contactTop .formArea .checkboxArea input[type=radio] + label,
#contactTop .formArea .checkboxArea input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  padding: 0 0 0 25px;
  margin-right: 30px;
  cursor: pointer;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .radioArea input[type=radio] + label,
  #contactTop .formArea .radioArea input[type=checkbox] + label,
  #contactTop .formArea .checkboxArea input[type=radio] + label,
  #contactTop .formArea .checkboxArea input[type=checkbox] + label {
    margin-left: 10px;
  }
}

#contactTop .formArea .radioArea input[type=radio] + label::before,
#contactTop .formArea .radioArea input[type=checkbox] + label::before,
#contactTop .formArea .checkboxArea input[type=radio] + label::before,
#contactTop .formArea .checkboxArea input[type=checkbox] + label::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #707070;
  border-radius: 50%;
  background: #fff;
}

#contactTop .formArea .radioArea input[type=radio] + label::after,
#contactTop .formArea .radioArea input[type=checkbox] + label::after,
#contactTop .formArea .checkboxArea input[type=radio] + label::after,
#contactTop .formArea .checkboxArea input[type=checkbox] + label::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0.5);
}

#contactTop .formArea .radioArea input[type=radio]:checked + label::after,
#contactTop .formArea .radioArea input[type=checkbox]:checked + label::after,
#contactTop .formArea .checkboxArea input[type=radio]:checked + label::after,
#contactTop .formArea .checkboxArea input[type=checkbox]:checked + label::after {
  transition: .3s;
  transform: scale(1);
  background: #02377b;
}

#contactTop .formArea .requireIcn {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.2rem;
  line-height: 1.75;
  letter-spacing: 1.2px;
  color: #de1111;
  display: inline-block;
}

#contactTop .formArea .atten {
  display: inline-block;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #707070;
  background-color: #efefef;
}

#contactTop .formArea .submit {
  margin: 120px auto;
  padding-top: 0;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .submit {
    margin: 60px auto;
  }
}

#contactTop .formArea .submit button {
  display: block;
  width: 100%;
  background-color: #02377b;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: .7px;
  padding: 25px 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  #contactTop .formArea .submit button {
    font-size: 1.2rem;
  }
}

#contactTop .formArea .submit button:disabled {
  background-color: #8d8d8d;
}

#contactTop .formArea .submit .moreBar::after {
  background-color: #fff;
}

#contactTop .formArea .btnColumn {
  width: 77.55%;
  max-width: 760px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
}

#contactTop .formArea .btnColumn .btn {
  width: 46%;
  max-width: 350px;
  margin: 0;
  padding-top: 40px;
}

#contactTop .formArea .btnColumn .btn.back a {
  border: solid 0.5px #b7b7b7;
  background-color: #e9eef5;
}

#contactTop .formArea .btnColumn .btn.submit {
  margin: 0;
}

#contactTop .formArea .btnColumn .btn.submit button {
  background-color: #02377b;
}

#contactTop .completion {
  max-width: 1020px;
  padding: 0 20px;
  margin: 80px auto;
}

#contactTop .completion h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 2;
  letter-spacing: 2.4px;
  color: #02377b;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #contactTop .completion h2 {
    margin-bottom: 4%;
  }
}

#contactTop .completion p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

/************************************
404ページ
***********************************/
/*404ページ*/
/* ================================================================================
404
================================================================================ */
#notFound .errorArea {
  max-width: 1020px;
  padding: 0 20px;
  margin: 80px auto 120px;
}

@media screen and (max-width: 767px) {
  #notFound .errorArea {
    margin: 10% auto 16%;
  }
}

#notFound .errorArea h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  line-height: 2;
  letter-spacing: 2.4px;
  color: #02377b;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #notFound .errorArea h2 {
    margin-bottom: 4%;
  }
}

#notFound .errorArea p {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#notFound .errorArea a {
  color: #02377b;
  text-decoration: underline;
}

/************************************
information
***********************************/
/*個人情報保護方針*/
/* ================================================================================
個人情報保護方針
================================================================================ */
#informationTop .informationLayout {
  max-width: 1020px;
  padding: 80px 20px 0;
  margin: 0 auto 120px;
}

@media screen and (max-width: 767px) {
  #informationTop .informationLayout {
    margin-bottom: 17%;
  }
}

#informationTop .informationLayout h2 {
  margin-bottom: 30px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 3.2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #informationTop .informationLayout h2 {
    font-size: 2rem;
    letter-spacing: 2px;
    margin-bottom: 5%;
  }
}

#informationTop .informationLayout h3 {
  margin: 50px 0 10px;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #informationTop .informationLayout h3 {
    margin: 8.5% -0px 3%;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
}

#informationTop .informationLayout h4 {
  margin: 30px 0 20px;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: #004092;
}

@media screen and (max-width: 767px) {
  #informationTop .informationLayout h4 {
    margin: 4% 0 3%;
  }
}

#informationTop .informationLayout p, #informationTop .informationLayout a {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#informationTop .informationLayout ul {
  background: #f8f9fb;
  margin: 30px 0;
  padding: 20px;
}

#informationTop .informationLayout ul li {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
}

#informationTop .informationLayout ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #4f96b1;
  position: absolute;
  top: 11px;
  left: 0;
}

#informationTop .informationLayout ul li:last-child {
  margin-bottom: 0;
}

#informationTop .informationLayout ul li span {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #02377b;
}

/************************************
ニュースレター
***********************************/
/* ================================================================================
topics　トップページ
================================================================================ */
#newsletterIdx .eachNewsletterList {
  max-width: 1120px;
  padding: 80px 20px 0;
  width: 100%;
  margin: 0 auto;
}

#newsletterIdx .eachNewsletterList dl {
  display: block;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl {
    padding-bottom: 30px;
  }
}

#newsletterIdx .eachNewsletterList dl:first-of-type dd {
  display: block;
}

#newsletterIdx .eachNewsletterList dl:last-of-type {
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl:last-of-type {
    padding-bottom: 15px;
  }
}

#newsletterIdx .eachNewsletterList dl dt {
  border-bottom: 1px solid #a2a5a7;
  position: relative;
  cursor: pointer;
}

#newsletterIdx .eachNewsletterList dl dt::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 48px;
  height: 20px;
  background-image: url(/images/icons/icon_toggle_btn.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: 0.5s;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl dt::after {
    width: 24px;
    height: 10px;
  }
}

#newsletterIdx .eachNewsletterList dl dt.open::after {
  transform: rotate(-180deg);
}

#newsletterIdx .eachNewsletterList dl dt span {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 4.8rem;
  line-height: 1;
  letter-spacing: 4.8px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl dt span {
    font-size: 3rem;
    padding-bottom: 10px;
  }
}

#newsletterIdx .eachNewsletterList dl dt span::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #02377b;
  bottom: -1px;
  left: 0;
}

#newsletterIdx .eachNewsletterList dl dd {
  padding-top: 35px;
  display: none;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl dd {
    padding-top: 20px;
  }
}

#newsletterIdx .eachNewsletterList dl .newsletterCard {
  background-color: #ffffff;
  width: 31.2962%;
  margin-right: 3.05555%;
  margin-bottom: 3.7%;
}

#newsletterIdx .eachNewsletterList dl .newsletterCard:nth-child(3n) {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard {
    width: 100%;
    margin-right: 0;
    min-height: inherit;
    margin-bottom: 30px;
  }
}

#newsletterIdx .eachNewsletterList dl .newsletterCard a {
  display: block;
  border: solid 1px #c4c4c4;
  position: relative;
  height: 100%;
}

#newsletterIdx .eachNewsletterList dl .newsletterCard a.notCursor {
  cursor: default;
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column {
  display: flex;
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column .typeColumn {
  max-width: 47%;
  width: 100%;
  padding: 8.9552% 9.26% 14.3283% 9.26%;
  word-break: break-all;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column .typeColumn {
    padding: 8.9552% 2.26% 14.3283% 6.26%;
  }
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column .typeColumn {
    padding: 7.22535% 5.22535% 7.22535% 5.22535%;
  }
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column .typeColumn time {
  display: block;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: .42px;
  color: #8d8d8d;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column .typeColumn time {
    font-size: 1.3rem;
  }
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.5;
  text-align: left;
  color: rgba(2, 55, 123, 0.85);
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column h2::after {
  content: "";
  display: block;
  width: 72px;
  height: 1px;
  background-color: #d3d3d3;
  margin: 8px 0 10px;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column h2 {
    font-size: 1.6rem;
  }
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column h2::after {
    width: 58px;
    margin: 8px 0 10px;
  }
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column .imageColumn {
  max-width: 53%;
  width: 100%;
  background-color: rgba(234, 239, 243, 0.4);
  padding: 8.9552% 7.396% 14.3283% 7.396%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column .imageColumn {
    padding: 8.9552% 6.396% 14.3283% 6.396%;
  }
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column .imageColumn {
    padding: 7.22535% 8.22535% 7.22535% 8.22535%;
  }
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column .imageColumn .img {
  margin: 0 auto;
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column .imageColumn img {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

#newsletterIdx .eachNewsletterList dl .newsletterCard .column hr {
  text-align: right;
  display: block;
  width: 18px;
  height: 2px;
  background-color: #8D8D8D;
  border-top: 0;
  position: absolute;
  bottom: 0;
  right: 10px;
  margin: 20px 15px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column hr {
    margin: 15px 10px;
  }
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList dl .newsletterCard .column hr {
    width: 15px;
    height: 1px;
    margin: 15px 0 30px;
    right: auto;
    left: calc(45% - 25px);
  }
}

#newsletterIdx .eachNewsletterList p.attention {
  text-align: right;
  font-size: 1.2rem;
  line-height: 2;
  color: #707070;
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #newsletterIdx .eachNewsletterList p.attention {
    padding-bottom: 60px;
  }
}

/* ================================================================================
topics テンプレート
================================================================================ */
#topicsTemplate .topicLayout {
  max-width: 1020px;
  padding: 0 20px;
  margin: 50px auto 120px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout {
    margin: 7% auto 16%;
  }
}

#topicsTemplate .topicLayout.wp strong {
  font-weight: bold;
}

#topicsTemplate .topicLayout.wp p {
  word-break: break-word;
}

#topicsTemplate .topicLayout.wp p a {
  word-break: break-word;
}

#topicsTemplate .topicLayout p,
#topicsTemplate .topicLayout dd,
#topicsTemplate .topicLayout li {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

#topicsTemplate .topicLayout .topicTitle {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicTitle {
    margin-bottom: 4%;
  }
}

#topicsTemplate .topicLayout .topicTitle p {
  font-size: 1.6rem;
}

#topicsTemplate .topicLayout .topicTitle h2 {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.8rem;
  line-height: 1.8;
  letter-spacing: 2.4px;
  color: #000;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicTitle h2 {
    font-size: 2.2rem;
    letter-spacing: 2.2px;
  }
}

#topicsTemplate .topicLayout .topicTitle span {
  font-size: 1.8rem;
  letter-spacing: 1.8px;
  color: #000;
  display: block;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicTitle span {
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
}

#topicsTemplate .topicLayout .topicPerson {
  padding: 20px;
  border-top: solid 1px #b59738;
  border-bottom: solid 1px #b59738;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicPerson {
    padding: 2.5% 10px;
  }
}

#topicsTemplate .topicLayout .topicPerson p {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 2;
  letter-spacing: 2px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicPerson p {
    font-size: 1.6rem;
  }
}

#topicsTemplate .topicLayout .topicPerson span {
  font-size: 1.4rem;
  letter-spacing: 1.6px;
  display: block;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicPerson span {
    font-size: 1.2rem;
  }
}

#topicsTemplate .topicLayout .topicPerson span.name {
  display: inline-block;
  font-size: 1.6rem;
  color: #02377b;
}

#topicsTemplate .topicLayout .introTit,
#topicsTemplate .topicLayout .endTit {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 2.33;
  letter-spacing: 2.4px;
  color: #4b4b4b;
  text-align: center;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTit,
  #topicsTemplate .topicLayout .endTit {
    font-size: 2.4rem;
    margin: 8% auto;
  }
}

#topicsTemplate .topicLayout .introTit::before, #topicsTemplate .topicLayout .introTit::after,
#topicsTemplate .topicLayout .endTit::before,
#topicsTemplate .topicLayout .endTit::after {
  content: "";
  width: 20%;
  height: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #8d8d8d;
}

#topicsTemplate .topicLayout .introTit::before,
#topicsTemplate .topicLayout .endTit::before {
  right: calc(55% + 50px);
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTit::before,
  #topicsTemplate .topicLayout .endTit::before {
    right: calc(50% + 70px);
  }
}

#topicsTemplate .topicLayout .introTit::after,
#topicsTemplate .topicLayout .endTit::after {
  left: calc(55% + 50px);
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTit::after,
  #topicsTemplate .topicLayout .endTit::after {
    left: calc(50% + 70px);
  }
}

#topicsTemplate .topicLayout .introTxt {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTxt {
    display: block;
  }
}

#topicsTemplate .topicLayout .introTxt .txtBox {
  flex-basis: 67%;
}

#topicsTemplate .topicLayout .introTxt a {
  display: block;
  flex-basis: 27%;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTxt a {
    padding-top: 30px;
  }
}

#topicsTemplate .topicLayout .introTxt a img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .introTxt a img {
    width: 90%;
    margin: 0 auto;
  }
}

#topicsTemplate .topicLayout .topicContent {
  padding: 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent {
    padding: 0;
  }
}

#topicsTemplate .topicLayout .topicContent p {
  margin-bottom: 20px;
}

#topicsTemplate .topicLayout .topicContent.simple {
  padding-top: 30px;
  margin-bottom: 45px;
  border-top: solid 1px #b59738;
}

#topicsTemplate .topicLayout .topicContent.simple p {
  line-height: 2;
  color: #4b4b4b;
}

#topicsTemplate .topicLayout .topicContent.simple a {
  letter-spacing: 1.4px;
  color: #004092;
  text-decoration: underline;
  padding-bottom: 15px;
  display: inline-block;
}

#topicsTemplate .topicLayout .topicContent.simple .bold {
  letter-spacing: 1.4px;
  color: #4b4b4b;
  display: block;
}

#topicsTemplate .topicLayout .topicContent.simple .small {
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  color: #707070;
}

#topicsTemplate .topicLayout .topicContent.special {
  overflow: hidden;
}

#topicsTemplate .topicLayout .topicContent.special h3 {
  font-size: 2.4rem;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #000000;
  position: relative;
  margin: 60px 0 45px;
  position: relative;
  display: inline-block;
  color: #02377b;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.special h3 {
    margin: 50px 0 30px;
  }
}

#topicsTemplate .topicLayout .topicContent.special h3::before, #topicsTemplate .topicLayout .topicContent.special h3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100%;
  background-color: #b59738;
  height: 1px;
}

#topicsTemplate .topicLayout .topicContent.special h3::before {
  width: 100px;
  transform: translate(20px, -3px);
}

#topicsTemplate .topicLayout .topicContent.special h3::after {
  width: 100vw;
  transform: translate(20px, 3px);
}

#topicsTemplate .topicLayout .topicContent.special .minContent {
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.special .minContent {
    padding: 0 10px;
  }
}

#topicsTemplate .topicLayout .topicContent.special h4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #02377b;
  margin-bottom: 10px;
}

#topicsTemplate .topicLayout .topicContent.special span {
  font-weight: bold;
}

#topicsTemplate .topicLayout .topicContent.special img {
  margin: 40px auto;
}

#topicsTemplate .topicLayout .topicContent.special ul {
  background-color: #f8f9fb;
  padding: 20px 30px;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .topicContent.special ul {
    padding: 20px;
  }
}

#topicsTemplate .topicLayout .topicContent.special ul li {
  margin-bottom: 5px;
  padding-left: 15px;
  text-indent: -15px;
}

#topicsTemplate .topicLayout .topicContent.special ul li:last-child {
  margin-bottom: 0;
}

#topicsTemplate .topicLayout .topicContent.special ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #4f96b1;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#topicsTemplate .topicLayout ol {
  margin: 30px 0;
  counter-reset: number 0;
}

#topicsTemplate .topicLayout ol li {
  padding-left: 36px;
  margin-bottom: 24px;
  position: relative;
}

#topicsTemplate .topicLayout ol li::before {
  counter-increment: number;
  content: counter(number, decimal-leading-zero) "・";
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #004092;
  position: absolute;
  left: 0;
  top: 0;
}

#topicsTemplate .topicLayout .dotList {
  margin: 30px 0;
  background-color: #f8f9fb;
  padding: 20px 20px 30px;
}

#topicsTemplate .topicLayout .dotList dt {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #02377b;
  padding-left: 20px;
  text-indent: -20px;
  margin-bottom: 10px;
}

#topicsTemplate .topicLayout .dotList dt::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 5px;
  height: 5px;
  background-color: #4f96b1;
  vertical-align: middle;
}

#topicsTemplate .topicLayout .dotList dd {
  padding-left: 20px;
  margin-bottom: 10px;
}

#topicsTemplate .topicLayout .dotList dd:last-child {
  margin-bottom: 0;
}

#topicsTemplate .topicLayout .listTit {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  letter-spacing: 2px;
  text-align: center;
  color: #000000;
  margin: 50px 0 30px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .listTit {
    font-size: 2rem;
    margin: 25px 0 15px;
  }
}

#topicsTemplate .topicLayout .simpleList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList {
    display: block;
  }
}

#topicsTemplate .topicLayout .simpleList dt,
#topicsTemplate .topicLayout .simpleList dd {
  padding-bottom: 40px;
}

#topicsTemplate .topicLayout .simpleList dt {
  flex-basis: 20%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 1.6px;
  color: #02377b;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dt {
    padding-bottom: 10px;
  }
}

#topicsTemplate .topicLayout .simpleList dt:first-of-type {
  border-top: 1px solid #004092;
  padding-top: 40px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dt:first-of-type {
    padding-top: 30px;
  }
}

#topicsTemplate .topicLayout .simpleList dt:last-of-type {
  border-bottom: 1px solid #004092;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dt:last-of-type {
    border-bottom: 0;
  }
}

#topicsTemplate .topicLayout .simpleList dd {
  flex-basis: 80%;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #4b4b4b;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dd {
    padding-bottom: 30px;
  }
}

#topicsTemplate .topicLayout .simpleList dd:first-of-type {
  padding-top: 40px;
  border-top: 1px solid #d3d3d3;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dd:first-of-type {
    border: 0;
    padding-top: 0;
  }
}

#topicsTemplate .topicLayout .simpleList dd:last-of-type {
  border-bottom: 1px solid #d3d3d3;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .simpleList dd:last-of-type {
    border-bottom: 1px solid #004092;
  }
}

#topicsTemplate .topicLayout .simpleList a {
  color: #004092;
}

#topicsTemplate .topicLayout .exList {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .exList {
    display: block;
    margin-left: -36px;
  }
}

#topicsTemplate .topicLayout .exList dt,
#topicsTemplate .topicLayout .exList dd {
  padding: 10px 0;
  margin-bottom: 0;
}

#topicsTemplate .topicLayout .exList dt:nth-of-type(odd),
#topicsTemplate .topicLayout .exList dd:nth-of-type(odd) {
  background-color: rgba(234, 239, 243, 0.5);
}

#topicsTemplate .topicLayout .exList dt {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.7px;
  color: #004092;
  flex-basis: 30%;
  padding-left: 20px;
}

#topicsTemplate .topicLayout .exList dt::before {
  display: none;
}

#topicsTemplate .topicLayout .exList dd {
  flex-basis: 70%;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .exList dd {
    padding-left: 20px;
    padding-top: 0;
  }
}

#topicsTemplate .topicLayout hr {
  border-color: #d3d3d3;
  margin: 30px 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout hr {
    margin: 4% 0;
  }
}

#topicsTemplate .topicLayout .personLayout {
  margin: 60px 20px 20px;
  padding: 30px;
  border: solid 1px #eaeff3;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .personLayout {
    margin: 20px 0;
  }
}

#topicsTemplate .topicLayout .personLayout hr {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .personLayout hr {
    margin-top: 8%;
  }
}

#topicsTemplate .topicLayout .personName {
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.1rem;
  line-height: 2.33;
  letter-spacing: 2.1px;
  color: #02377b;
  margin-bottom: 10px;
}

#topicsTemplate .topicLayout .personName span {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: normal;
  color: #004092;
  margin-left: 20px;
}

#topicsTemplate .topicLayout .personJob {
  font-size: 15px;
  line-height: 1.87;
  letter-spacing: 1.5px;
  color: #000000;
  padding-left: 20px;
  border-left: solid 1px #004092;
  margin-bottom: 15px;
}

#topicsTemplate .topicLayout .attention {
  text-align: right;
  line-height: 2;
  letter-spacing: normal;
  color: #707070;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .attention {
    font-size: 1.2rem;
  }
}

#topicsTemplate .topicLayout .referenceLiterature {
  margin-top: 80px;
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .referenceLiterature {
    margin-top: 40px;
    padding: 0 10px;
  }
}

#topicsTemplate .topicLayout .referenceLiterature .mincontent {
  background-color: #f6f6f6;
  padding: 30px 40px;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .referenceLiterature .mincontent {
    padding: 30px;
  }
}

#topicsTemplate .topicLayout .referenceLiterature h5 {
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: left;
  color: #000000;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

#topicsTemplate .topicLayout .referenceLiterature h5:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 200%;
  height: 1px;
  background-color: #b59738;
  transform-origin: center right;
}

#topicsTemplate .topicLayout .referenceLiterature p {
  padding: 20px 0 0;
  margin: 0;
}

#topicsTemplate .topicLayout .bgColumn {
  background-color: #f8f9fb;
  padding: 30px 20px;
  margin-bottom: 30px;
}

#topicsTemplate .topicLayout .bgColumn:last-of-type {
  margin-bottom: 0;
}

#topicsTemplate .topicLayout .bgColumn p {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #topicsTemplate .topicLayout .backToPageBtn {
    padding: 60px 0 0;
  }
}
