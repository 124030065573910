@charset "utf-8";
/* ================================================================================
個人情報保護方針
================================================================================ */

#informationTop {
	.informationLayout {
		max-width: 1020px;
		padding: 80px 20px 0;
		margin: 0 auto 120px;
		@include mq(md) {
			margin-bottom: 17%;
		}
		h2 {
			margin-bottom: 30px;
			font-family: $NotoSerifJP;
			font-size: 3.2rem;
			font-weight: 500;
			line-height: 1.25;
			letter-spacing: 3.2px;
			color: #004092;
			@include mq(md) {
				font-size: 2rem;
				letter-spacing: 2px;
				margin-bottom: 5%;
			}
		}
		h3 {
			margin: 50px 0 10px;
			font-family: $NotoSerifJP;
			font-size: 2rem;
			line-height: 2;
			letter-spacing: 2px;
			color: #004092;
			@include mq(md) {
				margin: 8.5% -0px 3%;
				font-size: 1.6rem;
				letter-spacing: 1.6px;
			}
		}
		h4 {
			margin: 30px 0 20px;
			font-size: 14px;
			letter-spacing: 1.4px;
			color: #004092;
			@include mq(md) {
				margin: 4% 0 3%;
			}
		}
		p,a {
			font-size: 1.4rem;
			line-height: 2;
			letter-spacing: 0.7px;
			color: #4b4b4b;
		}
		ul {
			background: #f8f9fb;
			margin: 30px 0;
			padding: 20px;
			li {
				font-size: 1.4rem;
				line-height: 2;
				letter-spacing: 0.7px;
				color: #4b4b4b;
				padding-left: 20px;
				margin-bottom: 10px;
				position: relative;
				&::before {
					content: "";
					width: 5px;
					height: 5px;
					background-color: #4f96b1;
					position: absolute;
					top: 11px;
					left: 0;
				}
        &:last-child {
          margin-bottom: 0;
        }
				span {
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: 0.8px;
					color: #02377b;
				}
			}
		}
	}
}