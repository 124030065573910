@charset "utf-8";
/* ================================================================================
提携企業のご紹介
================================================================================ */

#partnerCompanies{
  .partnerLayout {
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto 120px;
    @include mq(md) {
      margin-bottom: 16%;
    }
    .partnerImg {
      margin: 0 auto 70px;
      width: 22.654%;
      @include mq(md) {
        width: 60%;
        margin: 40px auto;
      }
      img {
        width: 100%;
      }
    }
    .companyName {
      h2 {
        font-family: $NotoSerifJP;
        font-size: 2.4rem;
        line-height: 1.66;
        color: #004092;
        margin-bottom: 10px;
        @include mq(md) {
          font-size: 2rem;
        }
        span {
          font-size: 1.4rem;
          line-height: 1.43;
          color: #02377b;
          @include mq(md) {
            font-size: 1.2rem;
          }
        }
      }
      p {
        font-size: 14px;
        line-height: 2;
        letter-spacing: 1.4px;
        color: #000000;
      }
    }
    .companyDetail {
      dl {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-top: 25px;
        padding: 15px 0;
        border: solid #004092;
        border-width: 1px 0;
        position: relative;
        @include mq(md) {
          display: block;
          border-bottom: none;
          padding: 0;
          margin-top: 8%;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          right: 0;
          width: 75%;
          border-bottom: 1px solid #d3d3d3;
          @include mq(tab) {
            width: 68%;
          }
          @include mq(md) {
            display: none;
          }
        }
        &::before {
          top: -1px;
        }
        &::after {
          bottom: -1px;
        }
        dt,
        dd {
          padding: 15px 0;
        }
        dt {
          flex-basis: 25%;
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          line-height: 1.75;
          letter-spacing: 1.6px;
          color: #02377b;
          @include mq(tab) {
            flex-basis: 32%;
          }
          @include mq(md) {
            padding: 30px 0 10px 0;
          }
        }
        dd {
          flex-basis: 75%;
          font-size: 1.4rem;
          line-height: 2;
          letter-spacing: 1.4px;
          color: #4b4b4b;
          @include mq(tab) {
            flex-basis: 68%;
          }
          @include mq(md) {
            padding: 0 0 40px 0;
            border-bottom: 1px solid #004092;
          }
          a {
            font-size: 1.4rem;
            line-height: 2;
            letter-spacing: 1.4px;
            color: #02377b;
            &.windowIcon{
              position: relative;
              &::before{
                margin: auto;
                top: 4px;
              }
            }
          }
        }
      }
    }
  }
}