@charset "utf-8";
/* ================================================================================
経営理念
================================================================================ */
#corporatePhilosophy{
  .philosophyDetail {
    overflow: hidden;
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto 100px;
    @include mq(md) {
      margin-bottom: 14.085%;
    }

    h1 {
      position: relative;
      display: inline-block;
      margin: 60px 0 40px;
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: 3.2px;
      color: #004092;
      @include mq(md) {
        font-size: 2.5rem;
        letter-spacing: 4px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100%;
        background-color: #b59738;
        height: 1px;
        @include mq(md) {
          top: inherit;
          bottom: -15px;
          left: 0;
        }
      }
      &::before {
        width: 100px;
        transform: translate(20px, -3px);
        @include mq(md) {
          transform: translate(0, -3px);
        }
      }
      &::after {
        width: 100vw;
        transform: translate(20px, 3px);
        @include mq(md) {
          transform: translate(0, 3px);
        }
      }

      span{
        font-size: 2.4rem;
        color: #004092;
        letter-spacing: 2.5px;
        @include mq(md) {
            font-size: 2rem;
          }
      }
    }
    h3 {
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 1.6px;
      color: #004092;
      margin-bottom: 50px;
      @include mq(md) {
        font-size: 1.6rem;
        padding: 0;
        margin-bottom: 35px;
      }
    }
    p {
      font-family: inherit;
      font-size: 1.4rem;
      line-height: 1.71;
      letter-spacing: 0.7px;
      color: #4b4b4b;
      @include mq(md) {
        padding-left: 0;
      }
      &.bold {
        font-weight: 600;
        margin-bottom: 10px;
      }
      b{
        font-weight: 500;
        color: #000;
      }
      span {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #707070;
        margin-top: 20px;
        display: block;
      }
    }
    ul {
      background-color: #f8f9fb;
      padding: 20px 30px;
      margin-top: 40px;
      @include mq(md) {
        padding: 20px;
      }
      li {
        font-size: 1.4rem;
        line-height: 2.5;
        letter-spacing: 0.7px;
        color: #4b4b4b;
        padding-left: 15px;
        text-indent: -15px;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #004092;
          display: inline-block;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      &.personnel {
        li {
          &::before {
            background-color: #46887a;
          }
        }
      }
    }
    .signture{
        text-align: right;
        line-height: 2.5;
        margin-top: 80px;
    }
  }
}
#sustainability {
  .philosophyDetail {
    overflow: hidden;
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto 100px;
    @include mq(md) {
      margin-bottom: 14.085%;
    }

    h1 {
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      line-height: 1.75;
      letter-spacing: 3.2px;
      color: #004092;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 2rem;
        letter-spacing: 4px;
      }
    }
    h2 {
      position: relative;
      display: inline-block;
      margin: 20px 0 20px;
      font-family: $NotoSerifJP;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 1.67;
      letter-spacing: 2.4px;
      color: #000;
      @include mq(md) {
        font-size: 1.7rem;
        margin: 20px 0 40px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100%;
        background-color: #b59738;
        height: 1px;
        @include mq(md) {
          top: inherit;
          bottom: -15px;
          left: 0;
        }
      }
      &::before {
        width: 100px;
        transform: translate(20px, -3px);
        @include mq(md) {
          transform: translate(0, -3px);
        }
      }
      &::after {
        width: 100vw;
        transform: translate(20px, 3px);
        @include mq(md) {
          transform: translate(0, 3px);
        }
      }
      span {
        font-size: 1.6rem;
        letter-spacing: 1.6px;
        color: #000000;
      }
      &.notIntro {
        margin-top: 40px;
        @include mq(md) {
          margin-top: 20px;
        }
      }
    }
    h3 {
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 1.6;
      letter-spacing: 2px;
      color: #004092;
      margin-top: 60px;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 1.6rem;
        padding: 0;
        margin-top: 40px;
        margin-bottom: 10px;
      }
      &+h2 {
        margin-top: 30px;
        @include mq(md) {
          margin-top: 40px;
        }
      }
    }
    p {
      font-family: inherit;
      font-size: 1.4rem;
      line-height: 1.71;
      letter-spacing: 0.7px;
      color: #4b4b4b;
      @include mq(md) {
        padding-left: 0;
      }
      &.bold {
        font-weight: 600;
        margin-bottom: 10px;
      }
      b{
        font-weight: 500;
        color: #000;
      }
      span {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #707070;
        margin-top: 20px;
        display: block;
      }
      &.philosophyBox{
        padding: 20px;
        margin-bottom: 40px;
        background-color: #f8f9fb;

        &.last{
          margin-bottom: 0;
        }
      }
    }
  }
}