@charset "utf-8";
/* ================================================================================
404
================================================================================ */

#notFound {
  .errorArea {
    max-width: 1020px;
    padding: 0 20px;
    margin: 80px auto 120px;
    @include mq(md) {
      margin: 10% auto 16%;
    }
    h2 {
      font-family: $NotoSerifJP;
      font-size: 2.4rem;
      line-height: 2;
      letter-spacing: 2.4px;
      color: #02377b;
      margin-bottom: 30px;
      @include mq(md) {
        margin-bottom: 4%;
      }
    }
    p {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
    }
    a {
      color: #02377b;
      text-decoration: underline;
    }
  }
}