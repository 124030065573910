@charset "utf-8";
/* ================================================================================
SPC管理業務における内部管理体制 
================================================================================ */
#internalTop {
  .internalLayout {
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto 120px;
    @include mq(md) {
      margin-bottom: 17%;
    }
    h2 {
      margin-bottom: 30px;
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: 3.2px;
      color: #004092;
      span {
        display: block;
        font-size: 1.8rem;
        margin-top: 20px;
        @include mq(md) {
          font-size: 1.6rem;
          margin-top: 5%;
        }
      }
      @include mq(md) {
        font-size: 2rem;
        letter-spacing: 2px;
        margin-bottom: 5%;
      }
    }
    h3 {
      margin: 50px 0 10px;
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 2px;
      color: #004092;
      @include mq(md) {
        margin: 8.5% -0px 3%;
        font-size: 1.6rem;
        letter-spacing: 1.6px;
      }
    }
    p, a {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
    }
    a {
      text-decoration: underline;
      color: #004092;
    }
    img {
      width: 70%;
      margin: 120px auto 0;
      @include mq(md) {
        width: 100%;
        margin-top: 17%;
      }
    }
  }
}