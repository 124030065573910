@charset "utf-8";
/* ================================================================================
コーポレートサービス
================================================================================ */
#serviceCorporate {
  h1 {
    color: #0076a2;
  }
  .introTxt {
    a {
      color: #1e51a4;
      &::after {
        content: "";
        width: 14px;
        height: 11px;
        display: inline-block;
        margin-left: 4px;
        background-image: url(/images/icons/icon_out_link_blue.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  .serviceDetail {
    overflow: hidden;
    margin-bottom: 120px;
    @include mq(md) {
      overflow: inherit;
      margin-bottom: 60px;
    }
    h2 {
      position: relative;
      display: inline-block;
      margin: 80px 0 20px;
      @include mq(md) {
        margin: 50px 0 40px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100%;
        background-color: #b59738;
        height: 1px;
        @include mq(md) {
          top: inherit;
          bottom: -15px;
          left: 0;
        }
      }
      &::before {
        width: 100px;
        transform: translate(20px, -3px);
        @include mq(md) {
          transform: translate(0, -3px);
        }
      }
      &::after {
        width: 100vw;
        transform: translate(20px, 3px);
        @include mq(md) {
          transform: translate(0, 3px);
        }
      }
      span {
        font-size: 1.6rem;
        letter-spacing: 1.6px;
        color: #000000;
      }
      &.notIntro {
        margin-top: 40px;
        @include mq(md) {
          margin-top: 20px;
        }
      }
    }
    h3 {
      font-family: $NotoSerifJP;
      font-size: 1.6rem;
      line-height: 2;
      letter-spacing: 1.6px;
      color: #0076a2;
      margin-bottom: 10px;
      padding-left: 20px;
      @include mq(md) {
        font-size: 1.5rem;
        padding: 0;
      }
      &+h2 {
        margin-top: 60px;
        @include mq(md) {
          margin-top: 40px;
        }
      }
    }
    p {
      font-family: inherit;
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
      padding-left: 20px;
      @include mq(md) {
        padding-left: 0;
      }
      &.bold {
        font-weight: 600;
        margin-bottom: 10px;
      }
      span {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #707070;
        margin-top: 20px;
        display: block;
      }
    }
    ul {
      background-color: #f8f9fb;
      padding: 20px 30px;
      margin-top: 40px;
      @include mq(md) {
        padding: 20px;
      }
      li {
        font-size: 1.4rem;
        line-height: 2.5;
        letter-spacing: 0.7px;
        color: #4b4b4b;
        padding-left: 15px;
        text-indent: -15px;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #004092;
          display: inline-block;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      &.personnel {
        li {
          &::before {
            background-color: #46887a;
          }
        }
      }
    }
    .serviceTxtBox {
      background-color: #f8f9fb;
      padding: 15px 0;
      padding-right: 20px;
      margin-bottom: 30px;
      margin-left: 20px;
      @include mq(md) {
        padding: 20px;
        margin-left: 0;
      }
      &+h2 {
        margin-top: 50px;
        @include mq(md) {
          margin-top: 20px;
        }
      }
    }
    .serviceColumn {
      display: flex;
      justify-content: space-between;
      @include mq(md) {
        display: block;
      }
      &Box {
        flex-basis: 32%;
        border: solid 1px #d3d3d3;
        margin-bottom: 40px;
        position: relative;
        @include mq(md) {
          margin-bottom: 20px;
        }
        &::after {
          content: "";
          background-image: url(/images/icons/icon_next_gold.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 27%;
          height: 5px;
          position: absolute;
          bottom: 30px;
          right: -17%;
          z-index: 1;
          @include mq(md) {
            transform: rotate(90deg);
            width: 20%;
            right: 0;
            bottom: -10px;
          }
        }
        &:last-of-type {
          &::after {
            display: none;
          }
        }
        dt {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.5;
          text-align: center;
          color: #0076a2;
          border-bottom: solid 1px #d3d3d3;
          background-color: #f4f9f9;
          padding: 20px 0;
        }
        ul {
          margin: 0;
          padding: 20px 15px;
          background-color: #fff;
          @include mq(md) {
            padding: 20px;
          }
          li {
            &:last-child {
              margin-bottom: 40px;
              @include mq(md) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      &+p {
        padding-left: 0;
      }
    }
    .secretaryBox {
      background-color: #f8f9fb;
      padding: 30px;
      margin-bottom: 30px;
      display: flex;
      &:first-child {
        margin-top: 40px;
        @include mq(md) {
          margin-top: 20px;
        }
      }
      @include mq(md) {
        padding: 20px;
        display: block;
      }
      dt {
        flex-basis: 17%;
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        line-height: 2.5;
        letter-spacing: 1.6px;
        text-align: center;
        color: #000000;
        padding-right: 20px;
        @include mq(md) {
          padding-left: 20px;
          text-align: left;
        }
      }
      dd {
        flex-basis: 83%;
      }
      ul {
        padding: 0 20px;
        margin: 0;
        border-left: solid 1px #b59738;
        @include mq(md) {
          padding-top: 10px;
          border: none;
          border-top: solid 1px #b59738;
        }
      }
      &+p {
        padding: 0;
      }
    }
    &.asian {
      h3 {
        margin-top: 60px;
        @include mq(md) {
          margin-top: 30px;
        }
      }
      ul {
        margin-top: 20px;
        margin-left: 20px;
        @include mq(md) {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
    .countryList {
      thead {
        border: solid 1px #cbe0e0;
        background-color: #cbe0e0;
        th {
          font-family: $NotoSerifJP;
          font-weight: normal;
          text-align: center;
          color: #000000;
          &:nth-child(2),
          &:nth-child(3) {
            border-left: dashed 1px #707070;
          }
        }
      }
      tbody {
        border: solid 1px #d3d3d3;
        tr {
          border-top: solid 1px #d3d3d3;
        }
        td {
          text-align: center;
          color: #333333;
          &:first-of-type {
            background-color: #f4f9f9;
          }
          &:nth-child(2),
          &:nth-child(3) {
            color: #0076a2;
            border-left: dashed 1px #707070;
            font-size: 1.2rem;
          }
        }
      }
      tr {
        height: 50px;
        font-size: 1.4rem;
        letter-spacing: 1.4px;
        @include mq(md) {
          font-size: 1.3rem;
          letter-spacing: 0;
        }
      }
    }
  }
}