@charset "utf-8";

/* ================================================================================
フッター
================================================================================ */

footer#footer {
  .footerBg {
    background-color: #f2f2f2;
    padding: 60px 0 100px;
    @include mq(md) {
      padding: 30px 0;
    }
    .sitemapLayout {
      max-width: 1135px + 40px;
      padding: 0 20px 30px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @include mq(md) {
        display: block;
        padding-bottom: 5px;
      }
      .listTr{
        display: table-row;
        width: 100%;
      }
      .listBox {
        flex-basis: 25%;
        padding-bottom: 30px;
        @include mq(md) {
          padding-bottom: 10px;
        }
        &:last-child {
          @include mq(md) {
            padding-bottom: 0;
          }
        }
        &.oneColumn{
          @include mq(tabH) {
            flex-basis: 23%;
          }
          @include mq(tabV) {
            flex-basis: 23%;
          }
        }
        &.twoColumn{
          @include mq(tabH) {
            flex-basis: 22%;
          }
          @include mq(tabV) {
            flex-basis: 20%;
          }
        }
        &.treeColumn{
          flex-basis: 23%;
          padding: 0 2% 30px 0;
          
          @include mq(fixed) {
            flex-basis: 25%;
            padding: 0 0 30px;
          }
          @include mq(tabH) {
            flex-basis: 29%;
          }
          @include mq(tabV) {
            flex-basis: 28%;
            padding: 0 3% 30px;
          }
          @include mq(md) {
            padding: 0 0 10px;
          }
        }
        &.fourColumn{
          @include mq(tabH) {
            flex-basis: 26%;
          }
          @include mq(tabV) {
            flex-basis: 22%;
          }
        }
        dl {
          dt {
            font-family: $NotoSerifJP;
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.43;
            letter-spacing: 1.4px;
            color: #000000;
            margin-bottom: 15px;
            position: relative;
            span{
              color: #000;
              display: inline-block;
            }
          }
          dd {
            ul {
              li {
                a {
                  display: block;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0.6px;
                  color: #767676;
                  padding-left: 17px;
                  margin-bottom: 10px;
                  position: relative;
                  &::before {
                    content: "";
                    position: absolute;
                    width: 6px;
                    height: 1px;
                    background-color: #b7b7b7;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                  }
                  @include mq(md) {
                    font-size: 1.3rem;
                    margin-bottom: 15px;
                  }
                }
              }
            }
          }
        }
        .accordionSp {
          @include mq(md) {
            dt {
              &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                z-index: 2;
                width: 12px;
                height: 12px;
                background: url(/images/icons/icon_footer_toggle.svg) no-repeat;
                background-size: contain;
                background-position: center;
                transition: .3s transform;
              }
              span{
                display: inline-block;
                position: relative;
                color: #000;
                &::before{
                   top: 5px;
                }
              }
            }
            a {
              overflow: hidden;
              line-height: 0;
              margin-bottom: 0;
              transition: .3s;
            }
            &.open {
              dt {
                &::after {
                  transform: rotate(-180deg);
                }
              }
              a {
                overflow: visible;
                line-height: 1.5;
                margin-bottom: 15px;
              }
            }
          }
        }
        .primeLink {
          a {
            display: block;
            @include mq(md) {
              display: flex;
              justify-content:space-between;
              align-items: center;
              background-color: #fff;
              padding: 10px;
            }
            img {
              max-width: 130px;
              @include mq(md) {
                max-width: 120px;
                margin: auto 0;
                padding: 10px;
              }
            }
            p {
              font-size: 1.2rem;
              line-height: 1.5;
              color: #707070;
              padding-top: 5px;
              @include mq(md) {
                font-size: 1rem;
                padding: 0;
              }
            }
          }
        }
      }
    }
    .topLinkLayout {
      max-width: 1040px;
      margin: auto;
      padding: 0 20px 80px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include mq(md) {
        display: block;
        padding-bottom: 40px;
      }
      & > div {
        background-color: #fff;
        position: relative;
        @include mq(tab) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        a {
          display: block;
          font-family: $NotoSerifJP;
          font-size: 1.8rem;
          line-height: 1.5;
          letter-spacing: 1.8px;
          text-align: center;
          color: #4b4b4b;
          padding: 20px 0;
          @include mq(tab) {
            font-size: 1.4rem;
          }
          @include mq(md) {
            font-size: 1.5rem;
          }
          span {
            display: block;
            font-size: 1.2rem;
            letter-spacing: 1.2px;
            font-size: 1rem;
          }
        }
      }
      .jukuBox {
        flex-basis: 34%;
        background-color: inherit;
        @include mq(tab) {
          display: block;
        }
        @include mq(md) {
          margin-bottom: 15px;
        }
        a {
          background-color: #fff;
          display: flex;
          justify-content: center;
          &::before {
            content: "";
            width: 28px;
            height: 15px;
            background: url(/images/common/partner_icon.svg);
            background-size: contain;
            display: inline-block;
            margin: auto 15px auto 0;
            vertical-align: middle;
            @include mq(md){
              margin: auto 20px auto 0;
              width: 34.5px;
              height: 18.47px;
            }
          }
          p{
            color: #004092;
          }
          span {
            color: #004092;
            font-size: 1rem;
          }
        }
      }
      hr {
        height: 82px;
        width: 1px;
        border: none;
        border-right: solid 1px #d3d3d3;
        margin: 0;

        @include mq(tab) {
          height: 76px;
        }
        @include mq(md) {
          height: auto;
        }
      }
      .companyInfoBox,
      .contactBox {
        flex-basis: 28%;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
          content: "";
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          padding-right: 15px;
        }

        @include mq(tab) {
          max-height: 76px;
        }
      }
      .companyInfoBox {
        @include mq(md) {
          margin-bottom: 15px;
        }
        .small {
          display: inline-block;
          font-size: 1.4rem;
          @include mq(tab) {
            font-size: 1.2rem;
          }
          @include mq(md) {
            font-size: 1.3rem;
          }
        }
        a{
          @include mq(tab) {
            padding: 13px 0;
          }
        }
        &::before {
          width: 16px;
          height: 20px;
          background-image: url(/images/icons/icon_pdf.svg);
          @include mq(md) {
            width: 25px;
            height: 31px;
          }
        }
      }
      .contactBox {
        &::before {
          width: 17px;
          height: 13px;
          background-image: url(/images/icons/icon_mail.svg);
          @include mq(md) {
            width: 27.6px;
            height: 20px;
          }
        }
      }
    }
    .bottomLinkLayout {
      max-width: 600px;
      margin: auto;
      display: flex;
    //   justify-content: space-between;
    justify-content: center;
      @include mq(md) {
        display: block;
      }
      & > div {
        padding: 0 15px;
        a {
          display: block;
        }
        img {
          width: 100%;
          @include mq(md) {
            width: 18.1%;
            margin: auto;
          }
        }
      }
      .firmBox {
        @include mq(md) {
          padding-bottom: 30px;
        }
      }
    }
  }
  .copy {
    padding: 25px 0;
    @include mq(md) {
      padding: 10px 0;
    }
    p {
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 1px;
      color: #707070;
      text-align: center;
      padding: 0 20px;
      @include mq(md) {
        text-align: left;
      }
    }
  }
}