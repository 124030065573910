@charset "utf-8";

/* 見出し装飾
------------------------------------------------------------- */

h2.titTop {
  color: #b59738;
  font-size: 9rem;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: $Cormorant;
  font-weight: bold;
  @include mq(md) {
    font-size: 4rem;
    letter-spacing:4px;
  }
}

h4.sideDecoration {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  color: #555;
  text-align: center;
  letter-spacing: 1.8px;

  @include mq(md) {
    font-size: 1.6rem;
  }

  span {
    position: relative;
    display: inline-block;
    padding: 0 2.9em 0 3.2em;

    @include mq(md) {
      padding: 0 1.9em 0 2.3em;
    }

    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 41px;
      height: 42px;
      margin: auto;
      content: "";
      background-repeat: no-repeat;
      background-size: contain;

      @include mq(md) {
        width: 20px;
        height: 20px;
      }
    }

    &::before {
      left: 0;
      transform: scale(-1, 1);
    }

    &::after {
      right: 0;
    }

    &.black {
      &::before,
      &::after {
        background-image: url("/asset/images/icons/icon_line_black.svg");
      }
    }

    &.pink {
      &::before,
      &::after {
        background-image: url("/asset/images/icons/icon_line_pink.svg");
      }
    }

    &.green {
      &::before,
      &::after {
        background-image: url("/asset/images/icons/icon_line_green.svg");
      }
    }
  }
}

.underlineTitCommon {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 4px;
  text-align: left;
  color: #424648;
  position: relative;
  @include mq(md) {
    font-size: 1.6rem;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 344px;
    padding-top: 2px;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 100%;
  }
  &.orange {
    &:before {
      background-image: url("/images/icons/icon_shar_underline_orange.svg");
    }
  }
}