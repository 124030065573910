@charset "utf-8";
/* ================================================================================
シンガポールオフィス概要
================================================================================ */

#singaporeOffice {
  .officeDetail {
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto 120px;
    @include mq(md) {
      margin-bottom: 16%;
    }
    .officeImg {
      margin: 0 auto 50px;
      width: 14.5%;
      @include mq(md) {
        width: 29%;
        margin-bottom: 11.2676%;
      }
      img {
        width: 100%;
      }
    }
    dl {
      display: flex;
      @include mq(md) {
        display: block;
      }
      &:first-of-type {
        dt {
          border-top: solid 1px #004092;
        }
        dd {
          border-top: solid 1px #d3d3d3;
          @include mq(md) {
            border-top: none;
          }
        }
      }
      dt,
      dd {
        padding: 30px 0;
        border-bottom: solid 1px;
      }
      dt {
        flex-basis: 20%;
        border-color: #004092;
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 1.6px;
        color: #02377b;
        @include mq(tab) {
          flex-basis: 28.5%;
        }
        @include mq(md) {
          padding: 30px 0 10px;
          border-bottom: none;
        }
      }
      dd {
        flex-basis: 80%;
        border-color: #d3d3d3;
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 1.4px;
        color: #4b4b4b;
        @include mq(tab) {
          flex-basis: 71.5%;
        }
        @include mq(md) {
          padding: 0 0 40px;
          border-bottom: solid 1px #004092;
        }
        p {
          font-size: 1.4rem;
        }
        a {
          color: #02377b;
          word-break: break-word;
        }
				.moveMap {
					margin: 10px 0 5px;
					max-width: 700px;
					p {
						text-align: right;
						a {
							font-size: 1.2rem;
							line-height: 2;
							letter-spacing: 1.2px;
							color: #004092;
							padding-right: 19px;
							position: relative;
							&::after {
								content: "";
								width: 14px;
								height: 14px;
								position: absolute;
								top: 0;
								bottom: 0;
								right: 0;
								margin: auto;
								background-image: url('/images/icons/icon_scale_blue.svg');
								background-repeat: no-repeat;
								background-size: contain;
							}
						}
					}
				}
        .map {
          height: 0;
          overflow: hidden;
          padding-bottom: 50%;
          position: relative;
          max-width: 700px;
          @include mq(md) {
            padding-bottom: 54.32595%;
          }
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}