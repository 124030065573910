@charset "utf-8";
/* ================================================================================
SDG（Smart Data Guideway）計画
================================================================================ */
#smartDataGuideway{
  .philosophyDetail {
    overflow: hidden;
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto 100px;
    @include mq(md) {
      margin-bottom: 14.085%;
    }

    h1 {
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      line-height: 1.75;
      letter-spacing: 3.2px;
      color: #004092;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 2rem;
        letter-spacing: 1px;
      }
    }
    .introTxt {
      font-size: 1.4rem;
      line-height: 1.71;
      letter-spacing: .7px;
      color: #000;

      b{
        font-weight: 500;
      }
    }

    h2 {
      position: relative;
      display: inline-block;
      margin: 60px 0 20px;
      font-family: $NotoSerifJP;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 1.67;
      letter-spacing: 2.4px;
      color: #000;
      @include mq(md) {
        font-size: 1.7rem;
        margin: 50px 0 40px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100%;
        background-color: #b59738;
        height: 1px;
        @include mq(md) {
          top: inherit;
          bottom: -15px;
          left: 0;
        }
      }
      &::before {
        width: 100px;
        transform: translate(20px, -3px);
        @include mq(md) {
          transform: translate(0, -3px);
        }
      }
      &::after {
        width: 100vw;
        transform: translate(20px, 3px);
        @include mq(md) {
          transform: translate(0, 3px);
        }
      }
      span {
        font-size: 1.6rem;
        letter-spacing: 1.6px;
        color: #000000;
      }
      &.notIntro {
        margin-top: 40px;
        @include mq(md) {
          margin-top: 20px;
        }
      }
    }
    h3 {
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 1.6;
      letter-spacing: 2px;
      color: #004092;
      margin-top: 60px;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 1.6rem;
        padding: 0;
        margin-top: 40px;
        margin-bottom: 10px;
      }
      &:first-of-type{
        margin-top: 30px;
        @include mq(md) {
          margin-top: 40px;
        }
      }
      &+h2 {
        margin-top: 30px;
        @include mq(md) {
          margin-top: 40px;
        }
      }
    }
    h4 {
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 1.6;
      letter-spacing: 2px;
      color: #4b4b4b;
      text-align: center;
      padding: 0 20px;
      margin-top: 60px;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 1.6rem;
        letter-spacing: 0;
        padding: 0;
        margin-top: 40px;
        margin-bottom: 5px;

        &.mb{
          margin-bottom:20px;
        }
      }
    }
    p {
      font-family: inherit;
      font-size: 1.4rem;
      line-height: 1.71;
      letter-spacing: 0.7px;
      color: #4b4b4b;
      @include mq(md) {
        padding-left: 0;
      }
      &.bold {
        font-weight: 600;
        margin-bottom: 10px;
      }
      b{
        font-weight: 500;
        color: #000;
      }
      span {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #707070;
        margin-top: 20px;
        display: block;
      }
      &.philosophyBox{
        padding: 20px;
        margin-bottom: 40px;
        background-color: #f8f9fb;

        &.last{
          margin-bottom: 0;
        }
      }
    }
    ul {
      background-color: #f8f9fb;
      padding: 20px 30px;
      margin-top: 40px;
      @include mq(md) {
        padding: 20px;
      }
      li {
        font-size: 1.4rem;
        line-height: 2.5;
        letter-spacing: 0.7px;
        color: #4b4b4b;
        padding-left: 15px;
        text-indent: -15px;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #004092;
          display: inline-block;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      &.personnel {
        li {
          &::before {
            background-color: #46887a;
          }
        }
      }
    }
    .secretaryBox {
      background-color: #f8f9fb;
      padding: 30px;
      margin-top: 30px;
      display: flex;

      @include mq(md) {
        padding: 20px;
        display: block;
      }
      dt {
        flex: 1 1 auto;
        max-width: 19%;
        width: 100%;
        font-family: $NotoSerifJP;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.3;
        letter-spacing: 0.56px;
        color: #004092;
        text-align: left;
        word-wrap: break-word;
        padding-right: 20px;
        @include mq(tab) {
          max-width: 25%;
        }
        @include mq(md) {
          max-width: 100%;
          padding: 0;
          margin-bottom: 15px;
        }
      }
      dd {
        flex: 1 1 auto;
        max-width: 81%;
        width: 100%;
        border-left: solid 1px #b59738;

        p{
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.3;
          letter-spacing: 1.6px;
          text-align: left;
          color: #004092;
          padding: 0 16px;
          margin-bottom: 7px;
        }

        @include mq(tab) {
          max-width: 75%;
        }

        @include mq(md) {
          max-width: 100%;
          border: none;
          border-top: solid 1px #b59738;
          padding-top: 15px;

          p{
            padding: 0;
            margin-bottom: 0;
          }
        }
      }
      ul {
        padding: 0 20px;
        margin: 0;
        @include mq(md) {
          padding: 10px 0 0;
        }
      }
      &+p {
        padding: 0;
      }
    }
    .philosophyImg{
      max-width: 980px;
      width: 100%;
      padding: 0 20px;
      margin: 0px auto 20px;

      @include mq(md) {
        max-width: 100%;
        padding: 0;
        margin: 0 auto 10px;
      }

      .zoomBtn {
        display: none;
        margin: 10px 0 40px;
        text-align: right;
        @include mq(md) {
          display: block;
        }
        p {
          padding-right: 25px;
          position: relative;
          display: inline-block;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 16px;
            height: 16px;
            background-image: url(/images/icons/icon_zoomin.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
      &.open {
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        height: 100%;
        background-color: #fff;
        z-index: 99999999;
        overflow-x: scroll;
        transition: background-color .5s;
        .modalImg {
          width: 200vw;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          //transition: .5s;
          padding: 50px 0;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          &::before,
          &::after {
            content: "";
            width: 1px;
            height: 37px;
            background-color: #004092;
            position: fixed;
            right: 33px;
            top: 20px;
          }
          &::before {
            transform: rotate(45deg);
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
        .zoomBtn {
          display: none;
        }
      }
    }
  }
}