@charset "utf-8";
#partnerTop{
	.bassHeadVisual{
		.mainVisual{
			.titBox{
				@include mq(md){
					width: 76% !important;
					h1{
						letter-spacing: .5px;
						padding: 6.46464% 0 0 6.14045%;
					}
					p{
						padding: 0 0 4.4444% 6.14045%;
					}
				}
			}
		}
	}
	.philosophyDetail{
		overflow: hidden;
		max-width: 1020px;
		padding: 0 20px;
		margin: 50px auto 120px;
		@include mq(md) {
			margin-bottom: 14.085%;
		}

		h1 {
			font-family: $NotoSerifJP;
			font-size: 3.2rem;
			line-height: 1.75;
			letter-spacing: 3.2px;
			color: #004092;
			margin-bottom: 20px;
			@include mq(md) {
				font-size: 2rem;
				letter-spacing: 4px;
			}
		}
		p{
			font-size: 1.4rem;
			line-height: 2;
			letter-spacing: .5px;
		}
		.recruitingList{
			padding: 30px 40px;
			margin: 40px auto;
			background: #F8F9FB;
			@include mq(md){
				padding: 20px;
				margin: 30px auto 15px;
			}
			li{
				&:not(:last-child){
					margin-bottom: 20px;
					@include mq(md){
						margin-bottom: 10px;
					}
				}
			}
			p{
				padding-left: 1em;
				text-indent: -1em;
				&:before{
					content: "";
					width: 5px;
					height: 5px;
					background-color: #004092;
					display: inline-block;
					margin-right: 10px;
					vertical-align: middle;
				}
			}
		}
		.moveTopicsPage {
			display: block;
			max-width: 550px;
			margin: 90px auto 0;
			@include mq(md) {
				width: 77.46478%;
				margin: 35px auto 0;
			}
			a {
				display: block;
				border: solid 1px rgba(2, 55, 123, 0.85);
				padding: 30px 0;
				text-align: center;
				font-size: 1.8rem;
				line-height: 1.5;
				letter-spacing: 1.8px;
				color: #02377b;
				position: relative;
				@include mq(md) {
					padding: 13px;
					font-size: 1.2rem;
					letter-spacing: 1.4px;
					line-height: 1;
				}
				&::after {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: 145px;
					margin: auto;
					width: 30px;
					height: 2px;
					background-color: #02377b;
					@include mq(md) {
						right: 50px;
						width: 15px;
						height: 1px;
					}
				}
			}
		}
	}
}