@charset "utf-8";
/* ================================================================================
会社情報
================================================================================ */
#aboutIdx {
  overflow: hidden;
  .companyInfo {
    max-width: 1120px;
    padding: 0 20px;
    margin: 0 auto 120px;
    display: flex;
    justify-content: space-between;
    @include mq(md) {
      display: block;
      padding: 0 30px;
      margin-bottom: 12%;
    }
    &Img {
      flex-basis: 37.5%;
      z-index: 1;
      img {
        width: 100%;
        padding: 0 17.5% 0 22.5%;
      }
    }
    &Txt {
      flex-basis: 62.5%;
      h2 {
        font-family: $NotoSerifJP;
        font-size: 4rem;
        line-height: 1;
        letter-spacing: 4px;
        color: #02377b;
        margin-bottom: 20px;
        @include mq(md) {
          font-size: 1.8rem;
          margin-bottom: 10px;
        }
      }
      h3 {
        font-family: $NotoSerifJP;
        font-size: 1.8rem;
        line-height: 1;
        letter-spacing: 1.8px;
        color: #4b4b4b;
        padding: 15px 0 25px;
        @include mq(md) {
          font-size: 1.2rem;
          padding-top: 0;
        }
      }
      p {
        font-size: 1.8rem;
        line-height: 2;
        letter-spacing: 0.9px;
        color: #4b4b4b;
        margin-left: 60px;
        padding-left: 40px;
        border-left: solid 1px #b59738;
        margin-bottom: 70px;
        @include mq(md) {
          font-size: 1.4rem;
          margin: 20px 0;
          padding-left: 0;
          border: none;
        }
      }
      img {
        @include mq(md) {
          padding: 0 30px;
        }
      }
      .downloadBtn {
        padding-bottom: 30px;
        text-align: right;
        
          &:last-of-type{
              padding-bottom: 80px;
          }
          
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: solid 1px #c4c4c4;
          background-color: #fff;
          font-size: 1.4rem;
          line-height: 1;
          letter-spacing: 0.7px;
          color: #4b4b4b;
          padding: 25px 45px;
          width: 375px;
            
          &::before,
          &::after {
            content: "";
            width: 18px;
            height: 1px;
            background: #707070;
            display: inline-block;
            margin: 0 19px;
          }
          @include mq(md) {
            font-size: 1.2rem;
            padding: 20px 0;
            width: 100%;
          }
        }
      }
      .bg {
        position: relative;
        &::after {
          content: "";
          width: 200%;
          height: 100%;
          background-color: #f8f9fb;
          position: absolute;
          bottom: 0;
          left: calc(-60% - 70px);
          z-index: -1;
          @include mq(md) {
            height: 110%;
          }
        }
      }
    }
  }
}
/* ================================================================================
会社概要
================================================================================ */
#companyProfile {
  .profileDetail {
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto 100px;
    @include mq(md) {
      margin-bottom: 14.085%;
    }
    .profileImg {
      margin: 0 auto 75px;
      width: 19.653%;
      @include mq(md) {
        width: 46.845%;
        margin-bottom: 40px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    dl {
      display: flex;
      @include mq(md) {
        display: block;
      }
      &:first-of-type {
        dt {
          border-top: solid 1px #004092;
        }
        dd {
          border-top: solid 1px #d3d3d3;
          @include mq(md) {
            border-top: none;
          }
        }
      }
      dt,
      dd {
        padding: 30px 0;
        border-bottom: solid 1px;
      }
      dt {
        flex-basis: 20%;
        border-color: #004092;
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 1.6px;
        color: #02377b;
        @include mq(tab) {
          flex-basis: 28.5%;
        }
        @include mq(md) {
          padding: 30px 0 10px;
          border-bottom: none;
        }
      }
      dd {
        flex-basis: 80%;
        border-color: #d3d3d3;
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 1.4px;
        color: #4b4b4b;
        @include mq(tab) {
          flex-basis: 71.5%;
        }
        @include mq(md) {
          padding: 0 0 40px;
          border-bottom: solid 1px #004092;
        }
        p {
          font-size: 1.4rem;
          color: #000;
        }
        span {
          display: block;
          font-family: $NotoSerifJP;
          font-size: 1.5rem;
          letter-spacing: 1.5px;
          color: #02377b;
        }
        a {
          color: #02377b;
          position: relative;
          padding-right: 20px;
          &::after {
            content: "";
            position: absolute;
            width: 13px;
            height: 1px;
            background-color: #02377b;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
          }
        }
        .chui{
            text-decoration: underline;
            &::after{
                content: none;
            }
          }
        .accessBox {
          span {
            color: #004092;
            padding-left: 15px;
            margin-bottom: 10px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              height: 50%;
              width: 2px;
              background-color: #02377b;
              @include mq(md) {
                width: 1px;
              }
            }
          }
          p {
            color: #4b4b4b;
          }
          .moveMap {
            width: 63%;
            @include mq(md) {
              width: 100%;
              margin: 15px 0 0;
            }
            p {
              text-align: right;
              a {
                font-size: 1.2rem;
                line-height: 2;
                letter-spacing: 1.2px;
                color: #004092;
                padding-right: 19px;
                position: relative;
                &::after {
                  content: "";
                  width: 14px;
                  height: 14px;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  margin: auto;
                  background-color: transparent;
                  background-image: url('/images/icons/icon_scale_blue.svg');
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
            }
          }
          .zoomBtn{
            display: none;
            width: 63%;
            @include mq(md) {
              display: block;
              width: 100%;
              margin: 0 0 10px;
            }
            p {
              text-align: right;
              font-size: 1.2rem;
              line-height: 2;
              letter-spacing: 1.2px;
              color: #004092;
              padding-right: 19px;
              position: relative;
              &::after {
                content: "";
                width: 14px;
                height: 14px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                background-color: transparent;
                background-image: url('/images/icons/icon_scale_blue.svg');
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
          .accessDetail {
            display: block;
            justify-content: space-between;
            @include mq(md) {
              display: block;
            }
            @include mq(tab) {
              display: block;
            }
            .map {
              height: 0;
              overflow: hidden;
              padding-bottom: 36%;
              position: relative;
              flex-basis: 63%;
              @include mq(md) {
                margin-bottom: 15px;
                padding-bottom: 56%;
              }
              @include mq(tab) {
                margin-bottom: 30px;
                padding-bottom: 56%;
              }
              iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }
            .imgBox {
              margin:30px 0 17px;
              @include mq(md) {
                margin:20px 0 15px;
              }
              .hide{
                display: none;
              }
              .attention {
                display: none;
                font-size: 1.2rem;
                line-height: 1.67;
                letter-spacing: 1.2px;
                position: relative;
                padding: 12px 20px;

                span{
                  display: inline;
                  font-family: $base-font;
                  font-size: 1.2rem;
                  line-height: 2;
                  letter-spacing: 1.2px;
                  text-decoration: underline;
                  position: relative;
                  color: #4b4b4b;
                  padding: 0;
                  cursor: pointer;

                  &::before{
                    display: none;
                  }
                }
              }
              .backMapBtn{
                width: 100%;
                padding: 12px 20px;
                font-size: 1.2rem;
                line-height: 1.67;
                letter-spacing: 1.2px;
                position: relative;
                cursor: pointer;

                &::after{
                  content: "";
                  display: inline-block;
                  width: 17px;
                  height: 1px;
                  background-color: #004092;
                  margin: 0 0 4px 5px;
                  vertical-align: middle;
                }
              }
              &.open {
                position: fixed;
                top: 0;
                left: 0;
                margin: 0;
                width: 100vw;
                height: 100%;
                background-color: #fff;
                z-index: 99999999;
                overflow-x: scroll;
                transition: background-color .5s;
                .modalImg {
                  width: 100vw;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  position: relative;
                  transition: .5s;
                  img {
                    width: 100%;
                    flex: 0 0 auto;
                  }
                  &::before,
                  &::after {
                    content: "";
                    width: 1px;
                    height: 37px;
                    background-color: #004092;
                    position: fixed;
                    right: 33px;
                    top: 20px;
                  }
                  &::before {
                    transform: rotate(45deg);
                  }
                  &::after {
                    transform: rotate(-45deg);
                  }
                }
                .zoomBtn {
                  display: none;
                }
                .attention {
                  width: 100%;
                  display: block;
                  align-self: baseline;
                  flex: 0 0 auto;

                  &.hide{
                    display: none;
                  }
                }
              }
            }
            .imgModalBox {
              .modalImg02 {
                display: none;
              }
              &.open {
                position: fixed;
                top: 0;
                left: 0;
                margin: 0;
                width: 100vw;
                height: 100%;
                background-color: #fff;
                z-index: 99999999;
                overflow-x: scroll;
                transition: background-color .5s;
                .modalImg02 {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  transition: .5s;
                  img {
                    max-width: 750px;
                    width: 100%;
                  }
                  &::before,
                  &::after {
                    content: "";
                    width: 1px;
                    height: 37px;
                    background-color: #004092;
                    position: fixed;
                    right: 33px;
                    top: 20px;
                    cursor: pointer;
                  }
                  &::before {
                    transform: rotate(45deg);
                  }
                  &::after {
                    transform: rotate(-45deg);
                  }
                }
                .modalBtn,
                p {
                  display: none;
                }
              }
            }
            .station {
              flex-basis: 34%;
              span {
                font-size: 1.2rem;
                padding-left: 8px;
                margin-bottom: 0;
                &::before {
                  width: 1px;
                }
              }
              p {
                font-size: 1.2rem;
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 10px;
                &::after {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  border-bottom: solid 1px #d3d3d3;
                  width: 60px;
                }
                &:last-child {
                  padding: 0;
                  margin: 0;
                  &::after {
                    display: none;
                  }
                }
              }
            }
            .attention {
              margin: 30px 0 0;
              @include mq(md) {
                margin: 12px 0 0;
              }

              p {
                font-size: 1.2rem;
                line-height: 2;
                letter-spacing: 1.2px;
                position: relative;
                margin-bottom: 12px;
                &:last-child {
                  padding: 0;
                  margin: 0;
                }

                span{
                  display: inline;
                  font-family: $base-font;
                  font-size: 1.2rem;
                  line-height: 2;
                  letter-spacing: 1.2px;
                  text-decoration: underline;
                  position: relative;
                  color: #4b4b4b;
                  padding: 0;
                  cursor: pointer;

                  &::before{
                    display: none;
                  }
                }

                @include mq(md) {
                  line-height: 1.67;
                  margin-bottom: 6px;

                  span{
                    line-height: 1.67;
                  }
                }
              }
            }
          }
        }
      }
      &.access {
        dt {
          border-color: #d3d3d3;
        }
        dd {
          @include mq(md) {
            border-bottom-color: #d3d3d3;
          }
        }
      }
    }
  }
}