@charset "utf-8";
/* ================================================================================
トップページ
================================================================================ */
#indexTop {
  padding-top: 0;
  .loaderBg {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 999;
    .loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 20%;
      height: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      img {
        width: 188px;
        display: inline-block;
      }
      span {
        position: relative;
        display: inline-block;
        overflow: hidden;
        width: 50%;
        height: 2px;
        border-radius: 2px;
        &::after {
          content: "";
          width: 100%;
          height: 2px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #02377b;
          animation: scroll 2.4s infinite normal;
        }
      }
    }
  }
  .mainVisualLayout {
    display: block;
    width: 100%;
    padding-top: 60.76134699%;
    @include mq(fixed) {
      height: 830px;
      padding-top: 0;
    }
    @include mq(tabH) {
      padding-top: 58.3125%;
    }
    @include mq(original) {
      padding-top: 95.3125%;
    }
    @include mq(md) {
      padding-top: 161.7333%;
      margin-bottom: 44.6666%;//margin-bottom: 22.6666%;
    }
    .setPosTxt {
      position: absolute;
      top: 0;
      left: 0;
      width: 50vw;
      z-index: 100;
      padding-right: 7.754612%;
      display: none;
      @include mq(fixed) {
        width: 100%;
        max-width: 1366px;
        margin: auto;
        right: 0;
        padding: 257px 0 0 157px;
      }
      @include mq(point2) {
        margin-top: 0;
      }
      @include mq(original) {
        max-width: 250px;
        margin-top: 20.833333%;
        padding-right: 15px;
        left: 35px;
      }
      @include mq(md) {
        padding-right: 0;
        width: 57.866666%;
        height: 100%;
        background: rgba(#fff, .6);
      }
    }
    .txtBox {
      box-sizing: inherit;
      width: 100%;
      max-width: 335px;
      margin-left: auto;
      margin-right: 14.641288%;
      margin-top: 36.603221%;
      @include mq(fixed) {
        margin: 0;
      }
      @include mq(original) {
        margin-top: 0;
      }
      @include mq(md) {
        max-width: none;
        margin: 42.6666% 0 0 6%;
      }
      h1 {
        font-family: $YuMin;
        font-size: 6rem;
        font-weight: 500;
        line-height: 1.17;
        letter-spacing: 6px;
        color: #004092;
        @include mq(original) {
          font-size: 4.2rem;
          letter-spacing: 4.2px;
        }
        @include mq(md) {
          font-size: 4rem;
        }
      }
      p {
        padding-top: 50px;
        max-width: 320px;
        font-size: 2rem;
        line-height: 2;
        letter-spacing: 2px;
        color: #000000;
        font-family: $NotoSerifJP;
        @include mq(original) {
          font-size: 1.8rem;
        }
        @include mq(md) {
          font-size: 1.6rem;
          line-height: 1.75;
          letter-spacing: 1.5px;
          max-width: 390px;
          padding-top: 6.22119%;
          padding-right: 30px;
        }
      }
    }
    .keyVisualPos {
      position: absolute;
      top: 0;
      right: 0;
      width: 56.954612%;
      height: 100%;
      transform: translateY(30%);
      z-index: 2;
      @include mq(original) {
        width: 62.109375%;
      }
      @include mq(md) {
        width: 85.73333%;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
      }
      .slide {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        &.bg2 {
          background-image: url("/images/index/index_img_main_02@2x.jpg");
          @include mq(md) {
            background-image: url("/images/index/index_img_main_sp_02.jpg");
          }
        }
        &.bg3 {
          background-image: url("/images/index/index_img_main_03@2x.jpg");
          @include mq(md) {
            background-image: url("/images/index/index_img_main_sp_03.jpg");
          }
        }
      }
    }
    .postSeminarBox {
      position: absolute;
      max-width: 476px;
      width: 100%;
      bottom: 0;
      transform: translateY(50%);
      right: 0;
      z-index: 101;
      box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      opacity: 0;
      @include mq(tabH) {
        max-width: 385px;
      }
      @include mq(tabV) {
        max-width: 385px;
        bottom: 30px;
      }
      @include mq(md) {
        width: 82.66666%;
        max-width: none;
        margin-left: auto;
        bottom: 20px;
      }
      a {
        display: flex;
        padding: 15px;
        @include mq(md) {
          padding: 3.636363%;
        }
        .thumb {
          width: 110px;
          display: flex;
          align-items: center;
          @include mq(tabH) {
            width: 90px;
          }
          @include mq(tabV) {
            width: 90px;
          }
          @include mq(md) {
            width: 27.6292%;
          }
        }
        .titBox {
          padding: 4px 4px 4px 15px;
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          @include mq(md) {
            align-content: center;
            padding: 0 12px 0;
          }
          .postTime {
            width: 100%;
            padding-bottom: 10px;
            @include mq(md) {
              display: flex;
              align-items: center;
              padding-bottom: 12px;
            }
            span {
              border: solid 1px #004092;
              display: inline-block;
              padding: 2px 10px;
              color: #004092;
              font-size: 1.4rem;
              @include mq(tabH) {
                font-size: 1.2rem;
              }
              @include mq(tabV) {
                font-size: 1.2rem;
              }
              @include mq(md) {
                font-size: 1.1rem;
                padding: 2px 5px;
              }
            }
            time {
              display: inline-block;
              font-size: 1.2rem;
              line-height: 1.5;
              letter-spacing: 0.6px;
              color: #767676;
              padding-left: 10px;
              @include mq(md) {
                padding-left: 11px;
              }
            }
          }
          h2 {
            width: 100%;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.25;
            letter-spacing: 0.8px;
            color: #004092;
            @include mq(tabH) {
              font-size: 1.4rem;
            }
            @include mq(tabV) {
              font-size: 1.4rem;
            }
            @include mq(md) {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .posWaveWrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      overflow: hidden;
      @include mq(md) {
        z-index: 2;
      }
      .waveMax {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 156.5885%;
        left: -30.014641%;
        opacity: 0;
        @include mq(md) {
          top: 0;
          margin-top: 108%;
          width: 285.2%;
          left: -80.93333%;
          bottom: inherit;
        }
        object {
          width: 100%;
        }
      }
    }
  }
  /*プライムグローバル*/
  .setSeminarLayout {
    max-width: 1366px;
    margin: 0 auto;
    @include mq(md) {
      margin-bottom: 25.33333%;
      margin-top: -14.000%;
      z-index: 100;
    }
    .primeLink {
      max-width: 580px+100px;
      width: 42.4908424%;
      padding-left: 5.85651%;
      margin-bottom: 15.37335%;//margin-bottom: 6.37335%;
      opacity: 0;
      transition: 2s opacity;
      &.visible {
        opacity: 1;
        visibility: visible;
      }
      @include mq(fixed) {
        width: 100%;
        max-width: none;
        margin-bottom: 210px;//margin-bottom: 60px;
        margin-left: auto;
        padding-left: 157px;
      }
      @include mq(tabH) {
        width: 100%;
      }
      @include mq(tabV) {
        padding: 30px 0 0 50px;
        width: 100%;
      }
      @include mq(md) {
        display: none;
      }
      a {
        display: flex;
        align-items: center;
        padding: 14px 0 0;
        img {
          max-width: 140px;
        }
        p {
          padding-left: 14px;
          font-size: 1.2rem;
          line-height: 1;
          color: #707070;
          @include mq(tabH) {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  
  /*infoBnr*/
  .infoBnrLayout{
    padding: 0 20px;

    @include mq(md) {
      padding: 0;
    }

    .infoBnr{
      max-width: 859px;
      width: 100%;
      margin: 0 auto 15.37335%;
      background-color: #f8f9fb;
      position: relative;

      @include mq(fixed) {
        margin: 0 auto 185px;
      }

      @include mq(md) {
        margin: 0 auto 20.37335%;
      }

      a{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 32px;

        @include mq(md) {
          padding: 16px;
        }
      }

      .moreBar{
        right: 32px;

        @include mq(md) {
          right: 8px;
        }
      }
    }

    .infoBnrTit{
      font-family: $NotoSerifJP;
      font-weight: 500;
      font-size: 1.5rem;
      letter-spacing: 1.5px;
      line-height: 1;
      color: #fff;
      padding: 15px 16px;
      margin: 0 32px 0 0;
      background-color: #02377b;

      @include mq(md) {
        font-size: 1rem;
        padding: 8px;
        margin: 0 12px 0 0;
        max-width: 100%;
      }
    }

    .infomation{
      display: flex;
      align-items: center;

      @include mq(tab) {
        padding: 0 32px 0 0;
      }

      @include mq(md) {
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0 8px 0 0;
        max-width: 100%;
      }

      .date{
        font-size: 1.4rem;
        letter-spacing: 1.4px;
        line-height: 1.4;
        color: #8d8d8d;
        padding: 0 16px 0 0;
        margin: 0 16px 0 0;
        border-right: solid 1px #c4c4c4;

        @include mq(md) {
         font-size: 1rem;
         border: none;
         margin: 0 0 5px;
         width: 100%;
         max-width: 100%;
        }
      }

      .content{
        font-family: $NotoSerifJP;
        font-weight: 500;
        font-size: 1.6rem;
        letter-spacing: 1.6px;
        line-height: 1.4;
        color: #4b4b4b;

        @include mq(md) {
          font-size: 1.4rem;
          width: 100%;
          max-width: 100%;
         }
      }
    }
  }

  /*事業内容*/
  .serviceMovesLayout {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 90.04392%;
      height: 100%;
      background-color: #e9eef5;
      z-index: 0;
      @include mq(md) {
        width: 90.221642%;
      }
    }
    h2,
    .businessContentTxt {
      position: relative;
      z-index: 1;
    }
    .heading {
      position: relative;
      @include mq(fixed) {
        max-width: 1200px;
        margin: 0 auto;
      }
      h2 {
        position: absolute;
        line-height: 1;
        letter-spacing: 9px;
        top: -64px;
        left: 8.41874%;
        @include mq(fixed) {
          left: 0;
        }
        @include mq(md) {
          font-size: 4rem;
          top: -.6em;
          left: 5.21512%;
          letter-spacing: 5px;
          font-weight: 500;
        }
      }
    }
    hr.headingTop {
      position: absolute;
      top: 50px;
      max-width: 500px;
      border-top: 0;
      margin: 0;
      border-bottom: 1px solid #c4c4c4;
      transition: 3s all;
      width: 100%;
      @include mq(fixed) {
        max-width: none;
        width: 36.60322%;
      }
      @include mq(md) {
        top: 22px;
        max-width: 54.75880%;
      }
    }
    .businessContentTxt {
      max-width: 1150px+40px;
      padding: 60px 20px 0;
      width: 100%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      @include mq(point1) {
        padding: 8% 0 0 8.41874%;
      }
      @include mq(md) {
        padding: 6.66666% 20px 0;
      }
      .contentTxt {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include mq(point1) {
          display: block;
        }
        @include mq(md) {
          display: block;
        }
        h3 {
          font-size: 3.2rem;
          line-height: 1.75;
          letter-spacing: 2.2px;
          color: #02377b;
          font-family: $NotoSerifJP;
          @include mq(tabV) {
            padding-left: 22px;
            font-size: 2.3rem;
          }
          @include mq(md) {
            font-size: 1.8rem;
            letter-spacing: 1.3px;
            line-height: 1.7;
            padding-bottom: 10.5633%;
            padding-left: 2.81690%;
          }
        }
        p {
          font-family: $NotoSerifJP;
          font-size: 1.8rem;
          line-height: 2.22;
          letter-spacing: 1.8px;
          color: #000000;
          max-width: 913px;
          padding: 25px 20px 0;
          @include mq(point1) {
            padding-left: 80px;
          }
          @include mq(tabH) {
            max-width: 740px;
          }
          @include mq(tabV) {
            max-width: 500px;
          }
          @include mq(md) {
            padding: 0 0 0 14.0845%;
            font-size: 1.5rem;
          }
        }
      }
      .LearnMoreLayout {
        text-align: right;
        padding-top: 20px;
      }
    }
    .posWaveWrap {
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 0;
      overflow: hidden;
      padding-top: 8.78477%;
      @include mq(fixed) {
        padding-top: 120px;
      }
      @include mq(md) {
        padding-top: 30.6666%;
      }
      .waveMax {
        position: absolute;
        bottom: -131.71303%;
        left: -10%;
        width: 166.5885%;
        opacity: 0;
        @include mq(fixed) {
          bottom: -160px;
        }
        @include mq(md) {
          width: 203.3333%;
          bottom: inherit;
          top: 0;
          margin-top: 10.6666%;
        }
        object {
          width: 100%;
        }
      }
    }
    .transitionEachPage {
      display: block;
      position: relative;
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      @include mq(md) {
        padding-top: 53.33333%;
      }
      &.fund {
        background-image: url("/images/index/service_bg1_pc.jpg");
        @include media-retina {
          background-image: url("/images/index/service_bg1_pc@2x.jpg");
        }
        @include mq(md) {
          background-image: url("/images/index/service_bg1_sp@2x.jpg");
        }
      }
      &.corporate {
        background-image: url("/images/index/service_bg2_pc@2x.jpg");
        @include mq(md) {
          background-image: url("/images/index/service_bg2_sp@2x.jpg");
        }
        .setPosLayout {
          margin-left: auto;
        }
      }
      .setPosLayout {
        width: 50%;
        background: rgba(#000, 0.5);
        color: #fff;
        @include mq(tabV) {
          width: 60%;
        }
        @include mq(md) {
          width: 100%;
        }
        .sizeAdjustment {
          @include mq(fixed) {
            max-width: 600px;
            margin-left: auto;
          }
          &.corpS {
            max-width: 512px;
            @include mq(fixed) {
              margin-left: 85px;
            }
          }
        }
        .titBox {
          padding: 7.32064% 11.71303% 0 12.44509%;
          @include mq(fixed) {
            padding: 7.32064% 11.71303% 0 0;
          }
          @include mq(md) {
            padding: 5.33333% 5.33333% 0 8%;
          }
          h3 {
            font-size: 3.6rem;
            line-height: 1;
            letter-spacing: 3.6px;
            color: #ffffff;
            font-family: $Baskerville;
            @include mq(tabH) {
              font-size: 3.2rem;
            }
            @include mq(tabV) {
              font-size: 2.8rem;
            }
            @include mq(md) {
              font-size: 2rem;
              letter-spacing: 2px;
            }
          }
          span {
            padding-top: 8px;
            display: block;
            font-size: 1.8rem;
            line-height: 1;
            letter-spacing: 1.8px;
            color: #ffffff;
            font-family: $NotoSerifJP;
            @include mq(md) {
              padding-top: 10px;
              font-size: 1.2rem;
              letter-spacing: 1.2px;
            }
          }
          hr {
            margin: 13px 0 0;
            border-top: 2px solid #b59738;
            max-width: 185px;
            width: 21.5384%;
            display: block;
            padding: 0;
          }
        }
        .txtList {
          padding: 3.66032% 11.71303% 12.44509% 12.44509%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          @include mq(fixed) {
            padding: 3.66032% 11.71303% 12.44509% 0;
          }
          @include mq(tabV) {
            padding: 3.66032% 11.71303% 10.06032% 12.44509%;
          }
          @include mq(tabV) {
            padding: 3.66032% 8.71303% 10.06032% 10.44509%;
          }
          @include mq(md) {
            display: block;
            padding: 7.333333% 5.33333% 13.33333% 8%;
          }
          p.txt {
            width: 100%;
            font-size: 1.4rem;
            @include mq(md) {
              line-height: 2;
            }
          }
          ul {
            padding-top: 9.6525%;
            @include mq(md) {
              padding-top: 10.76923%;
            }
            li {
              position: relative;
              font-family: $NotoSerifJP;
              font-weight: 500;
              line-height: 1;
              letter-spacing: 1.6px;
              transform: translateY(0);
              margin-bottom: 20px;
              opacity: 0;
              &:last-child {
                margin-bottom: 0;
              }
              a {
                display: block;
                color: #fff;
                padding: 0 105px 0 0;
                position: relative;
                @include mq(tabV) {
                  padding: 0 85px 0 0;
                }
                @include mq(md) {
                  padding-right: 0;
                  font-size: 1.4rem;
                }
                &::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  width: 18px;
                  height: 1px;
                  background: #fff;
                  margin: auto;
                }
                .moreBar {
                  width: 18px;
                  height: 1px;
                  @include mq(md) {
                    width: 11px;
                  }
                  &::after {
                    background-color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*会社情報 & グループ企業・提携企業*/
  .aboutAndGroupMovesLayout {
    width: 100%;
    background-image: url("/images/index/decoration_background.jpg");
    @include mq(md) {
      padding-bottom: 14.6666%;
    }
    /*会社情報*/
    .aboutBox {
      position: relative;
      .setMaxWidth {
        width: 100%;
        padding: 13.54319% 0 0 0;
        @include mq(fixed) {
          padding: 185px 0 0 0;
        }
        @include mq(md) {
          padding: 25.33333% 0 0;
        }
        .posSetWrap {
          display: flex;
          width: 100%;
          position: relative;
          @include mq(md) {
            flex-wrap: wrap;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 25px;
              background-color: #e9eef5;
              width: 92%;
              padding-top: 100%;
              z-index: 0;
            }
          }
          .photo {
            width: 58.56515%;
            padding-top: 41.36163%;
            background-image: url("/images/index/about_thumbnail@2x.jpg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            @include mq(fixed) {
              height: 565px;
              padding-top: 0;
            }
            @include mq(md) {
              background-image: url("/images/index/about_thumbnail_sp.jpg");
              width: 100%;
              padding-top: 70.66666%;
              order: 2;
              position: relative;
              z-index: 1;
            }
          }
          .txtColumn {
            display: block;
            flex: 1;
            flex-wrap: wrap;
            padding: 3.6603221% 45px 0 6.5885797%;
            @include mq(fixed) {
              padding-top: 50px;
            }
            @include mq(md) {
              flex: none;
              width: 100%;
              padding: 0 0 17.3333% 20px;
              order: 1;
              position: relative;
            }
            h2 {
              font-family: $Cormorant;
              line-height: 1;
              font-weight: 500;
              letter-spacing: 5px;
              color: #b59738;
              width: 100%;
              text-align: right;
              padding-bottom: 6.557377%;
              @include mq(fixed) {
                padding-bottom: 50px;
              }
              @include mq(tabV) {
                font-size: 7.5rem;
              }
              @include mq(md) {
                padding-bottom: 3.52112%;
                padding-right: 20px;
              }
            }
            hr {
              position: absolute;
              top: 0;
              right: 0;
              margin: 0 auto;
              margin-top: 11.71303%;
              width: 49.78038%;
              transition: 2s all;
              border-top: 1px solid #c4c4c4;
              @include mq(tabV) {
                width: 59.78038%;
              }
              @include mq(fixed) {
                margin-top: 170px;
              }
              @include mq(point1) {
                margin-top: 140px;
              }
              @include mq(tabH) {
                margin-top: 13.71303%;
              }
              @include mq(tabV) {
                margin-top: 14.71303%;
              }
              @include mq(md) {
                position: relative;
                margin: 0 0 3.52112% auto;
                width: 36.66666%;
              }
            }
            h3 {
              width: 100%;
              font-family: $NotoSerifJP;
              font-size: 3.2rem;
              line-height: 1.75;
              letter-spacing: 3.2px;
              text-align: right;
              color: #02377b;
              padding-bottom: 5.46448%;
              @include mq(tabV) {
                font-size: 2.3rem;
              }
              @include mq(md) {
                font-size: 1.8rem;
                padding-bottom: 9.85915%;
                padding-right: 20px;
              }
            }
            p.txt {
              font-family: $NotoSerifJP;
              font-size: 1.8rem;
              line-height: 2.22;
              letter-spacing: 1.8px;
              color: #000000;
              @include mq(tabV) {
                font-size: 1.7rem;
                line-height: 2;
              }
              @include mq(md) {
                font-size: 1.5rem;
                line-height: 2;
                width: 100%;
                margin: 0 auto;
                padding-right: 40px;
              }
            }
            p.learnSp {
              display: none;
              @include mq(md) {
                display: block;
                text-align: right;
                padding-top: 8.45070%;
                width: 97.46478%;
                a {
                  font-size: 1.4rem;
                  color: #707070;
                  position: relative;
                  display: inline-block;
                  margin-right: 8.670520%;
                  padding-right: 1.5em;
                  @include mq(md) {
                    font-size: 1.2rem;
                    letter-spacing: 1.4px;
                    margin-right: 14.67052%;
                  }
                }
              }
            }
          }
        }
      }
      .LearnMoreLayout {
        width: 100%;
        text-align: right;
        padding-right: 8.0527086%;
        a {
          &:before {
            width: 0;
            transition: 1s;
          }
          &.ampA {
            &:before {
              width: 20px;
            }
          }
        }
        @include mq(tabH) {
          margin-top: 1em;
        }
        @include mq(tabV) {
          margin-top: 2em;
        }
        @include mq(md) {
          display: none;
        }
      }
      #beta {
        &.posWaveWrap {
          width: 100%;
          position: relative;
          padding-top: 12.44509%;
          bottom: 0;
          @include mq(fixed) {
            padding-top: 150px;
          }
          @include mq(md) {
            padding-top: 9.51683%;
          }
          .waveMax {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            object {
              width: 100%;
            }
          }
          #waveVueAnimeBetaA {
            margin-top: -4.392386%;
          }
          #waveVueAnimeBetaB {
            margin-top: -2.9282%;
          }
          #waveVueAnimeBetaC {
            margin-top: -30px;
            @include mq(fixed) {
              margin-top: -30px;
            }
          }
          #waveVueAnimeBetaD {
            margin-top: -45px;
            @include mq(fixed) {
              margin-top: -45px;
            }
          }
        }
      }
    }
    /*グループ企業・提携企業*/
    .groupAndPartnersBox {
      padding-bottom: 14.6412%;
      padding-right: 80px;
      @include mq(fixed) {
        padding-bottom: 200px;
      }
      @include mq(md) {
        padding: 0;
      }
      .setMaxWidthGroup {
        background: rgba(#fff, .6);
        padding: 22px 40px 75px 0;
        @include mq(tabV) {
          padding: 22px 20px 75px 0;
        }
        @include mq(md) {
          padding: 0 0;
          background: rgba(#fff, 0);
          position: relative;
          &::before {
            position: absolute;
            content: '';
            width: 90.66666%;
            height: calc(100% - 22px);
            z-index: 0;
            background-color: #fff;
            top: 22px;
            right: 0;
          }
        }
        .titBox {
          position: relative;
          @include mq(fixed) {
            max-width: 1115px;
            margin: 0 auto;
          }
          @include mq(md) {
            padding-bottom: 12px
          }
          h2 {
            font-family: $Cormorant;
            line-height: 1.2;
            font-weight: bold;
            letter-spacing: 9px;
            color: #b59738;
            width: 100%;
            padding-left: 8.942457%;
            @include mq(fixed) {
              padding-left: 35px;
            }
            @include mq(tabV) {
              font-size: 6.5rem;
              padding-left: 45px;
            }
            @include mq(md) {
              padding: 0;
              font-size: 4rem;
              letter-spacing: 5px;
              font-weight: 500;
              padding-left: 20px;
            }
          }
        }
        hr {
          max-width: 849px;
          margin: 30px 0 0;
          border-top: 1px solid #c4c4c4;
          width: 100%;
          display: block;
          @include mq(fixed) {
            max-width: none;
            width: 62.225475%;
          }
          @include mq(tabV) {
            width: 91%;
          }
          @include mq(md) {
            width: 92%;
          }
          @include mq(md) {
            border-top: 1px solid #c4c4c4;
            margin: 2px 0 12px;
            position: relative;
          }
        }
        .txtBox {
          display: flex;
          justify-content: space-between;
          padding-left: 8.942457%;
          padding-top: 30px;
          @include mq(fixed) {
            max-width: 1366px;
            margin: 0 auto;
          }
          @include mq(tabV) {
            padding-left: 45px;
          }
          @include mq(md) {
            padding: 0;
            position: relative;
            display: block;
          }
          .txtArea {
            width: 51.101321%;
            @include mq(md) {
              width: 100%;
            }
            h3 {
              font-family: $NotoSerifJP;
              font-size: 3.2rem;
              line-height: 1.2;
              letter-spacing: 3.2px;
              color: #02377b;
              padding-bottom: 6.89655%;
              @include mq(tabV) {
                font-size: 2.3rem;
              }
              @include mq(md) {
                font-size: 1.8rem;
                padding: 0 0 9.33333% 5.33333%;
              }
            }
            p {
              font-family: $NotoSerifJP;
              font-size: 1.8rem;
              line-height: 2;
              letter-spacing: 1.8px;
              color: #000000;
              max-width: 473px;
              @include mq(tabV) {
                font-size: 1.7rem;
              }
              @include mq(md) {
                max-width: none;
                width: 78.6666%;
                margin-left: auto;
                padding-right: 20px;
                font-size: 1.5rem;
                line-height: 2;
              }
            }
            .LearnMoreLayout {
              max-width: 473px;
              text-align: right;
              padding-top: 7.75862%;
              @include mq(md) {
                max-width: none;
                margin-left: auto;
                padding: 9.33333% 11.6000% 0 0;
                width: 100%;
              }
            }
          }
          .photo {
            padding-top: 10px;
            width: 45.81497%;
            @include mq(md) {
              padding-top: 11.3333%;
              width: 100%;
              padding: 11.3333% 20px 15.33333%;
              img {
                width: 83.0985%;
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }
  /*採用情報*/
  .recruitMovesLayout {
    .setFlexBox {
      position: relative;
      z-index: 10;
      display: flex;
      width: 100%;
      @include mq(md) {
        display: block;
        padding-bottom: 25.6666%;
      }
      .txtBox,
      .moveBox {
        width: 50%;
        @include mq(md) {
          width: 100%;
        }
      }
      .txtBox {
        padding: 8.0527% 6.588579% 0 8.0527%;
        position: relative;
        @include mq(fixed) {
          padding: 0;
        }
        @include mq(tabV) {
          padding: 8.0527% 2.588579% 0 45px;
        }
        @include mq(md) {
          padding: 25px 0 0;
        }
        .sizeAdjustment {
          @include mq(fixed) {
            max-width: 684px;
            margin-left: auto;
            padding: 110px 85px 0 111px;
          }
        }
        h2 {
          font-family: $Cormorant;
          line-height: 0.7;
          font-weight: 700;
          letter-spacing: 9px;
          color: #b59738;
          width: 100%;
          padding-bottom: 14.49275%;
          @include mq(fixed) {
            padding-bottom: 50px;
          }
          @include mq(tabV) {
            padding-bottom: 10.49275%;
          }
          @include mq(tabV) {
            font-size: 7.2rem;
          }
          @include mq(md) {
            padding-bottom: 25px;
            padding-left: 20px;
            font-weight: 500;
            letter-spacing: 5px;
          }
        }
        h3 {
          font-family: $NotoSerifJP;
          font-size: 3.2rem;
          line-height: 1;
          letter-spacing: 3.2px;
          color: #02377b;
          padding-bottom: 8.281573%;
          @include mq(tabV) {
            font-size: 2.3rem;
          }
          @include mq(md) {
            padding-left: 20px;
            font-size: 1.8rem;
            letter-spacing: 1.5px;
            padding-bottom: 9.33333%;
          }
        }
        p {
          font-family: $NotoSerifJP;
          font-size: 1.8rem;
          line-height: 2.22;
          letter-spacing: 1.8px;
          color: #000000;
          padding-bottom: 17.598343%;
          @include mq(tabV) {
            font-size: 1.7rem;
          }
          @include mq(md) {
            max-width: none;
            padding-left: 10%;
            margin-left: auto;
            padding-right: 20px;
            font-size: 1.5rem;
            line-height: 2;
            padding-bottom: 0;
          }
        }
        .moveBoxLayout {
          display: block;
          text-align: right;
          position: relative;
          padding-top: 18px;
          @include mq(fixed) {
            flex: 1;
            width: auto;
          }
          @include mq(md) {
            padding: 36.26666% 8.93333% 13.33333% 0;
          }
          &:before {
            content: "";
            position: absolute;
            width: 76.6045%;
            padding-top: 36.23188%;
            background-image: url("/images/index/logo_img@2x.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            left: 0;
            top: 0;
            margin-left: -15.5279%;
            @include mq(md) {
              width: 55.73333%;
              padding-top: 26.4000%;
              margin: 5.33333% 0 0 0;
              left: 12px;
            }
          }
          a {
            display: inline-block;
            line-height: 1.5;
            letter-spacing: 1.6px;
            color: #707070;
            position: relative;
            padding-right: 2em;
            @include mq(tabV) {
              font-size: 1.4rem;
            }
            @include mq(md) {
              font-size: 1.3rem;
              padding-right: 25px;
            }
            &.windowIcon {
              &:before {
                width: 21px;
                height: 18px;
                top: 3px;
                right: 0;
                @include mq(md) {
                  width: 15px;
                  height: 14px;
                  top: 0px;
                }
              }
            }
          }
        }
      }
      hr.recruitTop {
        max-width: 440px;
        margin: 15.7467% 0 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        border-top: 1px solid #c4c4c4;
        @include mq(fixed) {
          margin: 200px 0 0;
          left: 0;
          width: 32.2108%;
          max-width: none;
        }
        @include mq(point1) {
          margin-top: 170px;
        }
        @include mq(tabH) {
          margin: 175px 0 0;
          width: 80%;
        }
        @include mq(tabV) {
          width: 40%;
          margin: 130px 0 0;
        }
        @include mq(md) {
          margin: 66px 0 0;
          border-top: 1px solid #c4c4c4;
          max-width: 375px;
          width: 50%;
        }
      }
      .moveBox {
        .thumbnailMoveBox {
          @include mq(md) {
            padding: 0 20px;
          }
          &:first-child {
            @include mq(md) {
              padding-bottom: 15px;
            }
          }
        }
        a {
          position: relative;
          display: block;
          .txtArea {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            padding: 20.4978% 0 0 0;
            h4 {
              font-size: 2.2rem;
              line-height: 1.5;
              letter-spacing: 2.2px;
              text-align: center;
              color: #ffffff;
              padding-bottom: 20px;
              @include mq(md) {
                font-size: 1.4rem;
                padding-bottom: 5px;
              }
            }
            hr {
              max-width: 215px;
              margin: 0 auto 4.6852%;
              transition: 2s;
              width: 100%;
              border-top: 2px solid #fff;
              @include mq(md) {
                max-width: none;
                width: 38.0281690%;
                border-top: 1px solid #fff;
                margin-bottom: 5px;
              }
            }
            p {
              font-size: 1.8rem;
              line-height: 1.5;
              letter-spacing: 1.8px;
              text-align: center;
              color: #ffffff;
              @include mq(md) {
                font-size: 1.2rem;
                letter-spacing: 1.2px;
                line-height: 1;
              }
            }
          }
        }
        img {
          width: 100%;
        }
      }
    }
    #gamma {
      &.posWaveWrap {
        width: 100%;
        position: relative;
        padding-top: 14.64128%;
        @include mq(fixed) {
          padding-top: 200px;
        }
        @include mq(md) {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: inherit;
          padding-top: 28.0000%;
          margin-top: 100%;
        }
        #waveVueAnimeGammaA {
          width: 41.72767%;
          margin-bottom: 6.22254%;
          @include mq(fixed) {
            margin-bottom: 85px;
          }
          @include mq(md) {
            left: -140px;
          }
        }
        #waveVueAnimeGammaB {
          width: 100%;
          margin-bottom: 0;
          @include mq(md) {
            margin-bottom: 0;
          }
        }
        .waveMax {
          position: absolute;
          bottom: 0;
          left: 0%;
          opacity: 0;
          z-index: 0;
          @include mq(md) {
            transform: rotate(0deg);
            bottom: -20px;
          }
          object {
            width: 100%;
          }
        }
      }
    }
  }
  /*トピックス*/
  .topicsMovesLayout {
    max-width: 1080px + 40px;
    padding: 0 20px;
    margin: 0 auto;
    @include mq(tabH) {
      padding: 0 50px 0 8.0527%;
    }
    @include mq(tabV) {
      padding: 0 20px 0 45px;
    }
    .titBox {
      position: relative;
      @include mq(md) {
        padding-bottom: 12.0140%;
      }
      h2 {
        font-family: $Cormorant;
        font-size: 7.2rem;
        font-weight: normal;
        line-height: 1.3;
        letter-spacing: 7.2px;
        text-align: center;
        color: #02377b;
        padding-bottom: 50px;
        @include mq(tabV) {
          font-size: 5.2rem;
          padding-bottom: 40px;
        }
        @include mq(md) {
          font-size: 3rem;
          line-height: 1;
          letter-spacing: 3px;
          padding-bottom: 9px;
        }
      }
      hr {
        max-width: 534px;
        margin: 0 0 0;
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: 2s;
        width: 0;
        border-top: 1px solid #c4c4c4;
        @include mq(tabV) {
          top: 40px;
        }
        @include mq(md) {
          position: static;
          border-top: 1px solid #c4c4c4;
          margin-bottom: 9px;
        }
        &.ampA {
          width: 100%;
          @include mq(md) {
            width: 75.0704%;
          }
        }
      }
      h3 {
        font-family: $NotoSerifJP;
        font-size: 2.4rem;
        font-weight: normal;
        line-height: 1;
        letter-spacing: 2.4px;
        text-align: center;
        color: #02377b;
        @include mq(tabV) {
          font-size: 2.3rem;
        }
        @include mq(md) {
          font-size: 1.6rem;
          letter-spacing: 1.5px;
        }
      }
    }
    .postInfoList {
      display: block;
      width: 100%;
      padding-top: 5.555555%;
      padding-bottom: 23.148148%;
      @include mq(md) {
        padding-top: 0;
        padding-bottom: 30.333333%;
      }
      ul {
        display: flex;
        width: 100%;
        margin-bottom: 7.407407%;
        @include mq(md) {
          display: block;
          margin-bottom: 14.08450%;
        }
        li {
          width: 31.296296%;
          margin-right: 3.05555%;
          @include mq(md) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
          }
          &:last-child {
            margin-right: 0;
            &:last-child {
              margin-bottom: 0;
            }
          }
          a {
            border: solid 1px #c4c4c4;
            display: block;
            width: 100%;
            height: 100%;
            padding: 8.92857% 8.92857% 13.39285%;
            position: relative;
            @include mq(tabV) {
              padding: 8.92857% 3.92857% 13.39285%;
            }
            @include mq(md) {
              padding: 5.63380% 5.63380% 11.97183%;
            }
            &.notCursor{
              cursor:default;
              &:before{
                display: none;
              }
            }
            &:before {
              position: absolute;
              content: "";
              width: 19px;
              height: 3px;
              background-color: #8d8d8d;
              bottom: 25px;
              right: 24px;
              @include mq(md) {
                width: 15px;
                height: 1px;
                right: 5.63380%;
              }
            }
            .typeBox {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 10px;
              p {
                font-size: 1.8rem;
                line-height: 1.5;
                letter-spacing: 1.8px;
                @include mq(tabV) {
                  font-size: 1.4rem;
                }
                @include mq(md) {
                  font-size: 1.4rem;
                  letter-spacing: 1.4px;
                  line-height: 1;
                }
                &.seminar {
                  color: #b59738;
                }
                &.topic {
                  color: #1f9a9a;
                }
              }
              time {
                font-size: 1.4rem;
                line-height: 1.43;
                letter-spacing: 1.4px;
                color: #8d8d8d;
                @include mq(tabV) {
                  font-size: 1.2rem;
                }
                @include mq(md) {
                  font-size: 1.2rem;
                  letter-spacing: 1.2px;
                  line-height: 1;
                }
              }
            }
            .postTxtColumn {
              h4 {
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1.5;
                letter-spacing: 1.8px;
                color: #4b4b4b;
                padding-bottom: 12px;
                @include mq(tabH) {
                  font-size: 1.7rem;
                }
                @include mq(tabV) {
                  font-size: 1.4rem;
                }
                @include mq(md) {
                  font-size: 1.4rem;
                  padding-bottom: 14px;
                }
              }
              p {
                letter-spacing: 1.6px;
                color: #767676;
                @include mq(tabH) {
                  font-size: 1.5rem;
                }
                @include mq(tabV) {
                  font-size: 1.4rem;
                }
                @include mq(md) {
                  font-size: 1.3rem;
                }
              }
            }
          }
        }
      }
      .moveTopicsPage {
        display: block;
        max-width: 550px;
        margin: 0 auto;
        @include mq(md) {
          width: 77.46478%;
        }
        a {
          display: block;
          border: solid 1px rgba(2, 55, 123, 0.85);
          padding: 30px 0;
          text-align: center;
          font-size: 1.8rem;
          line-height: 1.5;
          letter-spacing: 1.8px;
          color: #02377b;
          position: relative;
          @include mq(md) {
            padding: 13px;
            font-size: 1.2rem;
            letter-spacing: 1.4px;
            line-height: 1;
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 145px;
            margin: auto;
            width: 30px;
            height: 2px;
            background-color: #02377b;
            @include mq(md) {
              right: 50px;
              width: 15px;
              height: 1px;
            }
          }
        }
      }
    }
  }
  /*青山綜合経営塾（青山塾）*/
  .jukuMoveLayout {
    .setMaxColumn {
      width: 100%;
      max-width: 1080px + 40px;
      padding: 0 20px 125px;
      margin: 0 auto;
      @include mq(tabH) {
        padding: 0 50px 125px 8.0527%;
      }
      @include mq(tabV) {
        padding: 0 20px 125px 45px;
      }
      @include mq(md) {
        padding: 0 0 52%;
      }
      .titBox {
        display: block;
        font-family: $NotoSerifJP;
        color: #4b4b4b;
        padding-bottom: 4.16666666%;
        width: 96.2962%;
        @include mq(md) {
          width: 100%;
          padding-bottom: 5.3333%;
        }
        h2 {
          font-size: 4.2rem;
          line-height: 1;
          letter-spacing: 4.2px;
          padding-bottom: 13px;
          @include mq(tabV) {
            font-size: 3.2rem;
          }
          @include mq(md) {
            font-size: 2.1rem;
            line-height: 1;
            letter-spacing: 2.1px;
            padding-left: 20px;
          }
        }
        h3 {
          font-size: 2rem;
          line-height: 1;
          letter-spacing: 2px;
          position: relative;
          @include mq(tabV) {
            font-size: 1.6rem;
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #707070;
            z-index: 0;
            @include mq(md) {
              top: .5em;
            }
          }
          span {
            display: inline-block;
            position: relative;
            line-height: 1.4;
            padding-right: 5.09259%;
            background-color: #fff;
            z-index: 1;
            @include mq(md) {
              font-size: 1.1rem;
              line-height: 1;
              letter-spacing: 1.1px;
              padding-left: 20px;
            }
          }
        }
      }
      .moveColumn {
        a {
          display: flex;
          // align-items: center;
          background-color: rgba(189, 211, 240, 0.21);
          box-shadow: 6px 3px 6px 0 rgba(0, 0, 0, 0.16);
          @include mq(md) {
            box-shadow: 3px 1px 3px 0 rgba(0, 0, 0, 0.08);
          }
        }
        .thumb {
          width: 62.5925%;
          @include mq(tabV) {
            width: 57.4075%;
          }
          @include mq(md) {
            width: 50%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .txtBox {
          width: 37.4075%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          @include mq(tabV) {
            width: 42.5925%;
          }
          @include mq(md) {
            width: 50%;
            justify-content: center;
          }
          p {
            padding: 0 4.95049%;
            font-family: $NotoSerifJP;
            font-size: 2.2rem;
            line-height: 1.64;
            letter-spacing: 2px;
            color: #4b4b4b;
            text-align: center;
            @include mq(tabH) {
              font-size: 1.8rem;
              padding: 0 5%;
            }
            @include mq(tabV) {
              font-size: 1.6rem;
              line-height: 1.54;
              letter-spacing: 1px;
            }
            @include mq(original){
              font-size: 1.6rem;
            }
            @include mq(md) {
              font-size: 1.4rem;
              letter-spacing: 1.4px;
              padding: 10.66% 8.4507% 0 8.4507%;
              text-align: left;
            }
          }
          .workCall{
            font-size: 1.8rem;
            color: #B59738;
            padding: 0 4.95049%;
            line-height: 1.4;
            display: block;
            text-align: center;
            letter-spacing: 1px;
            margin-top: 4.95%;
            @include mq(tabH) {
              font-size: 1.6rem;
              padding: 0 5%;
            }
            @include mq(tabV) {
              font-size: 1.4rem;
            }
            @include mq(original){
              font-size: 1.4rem;
            }
            @include mq(md) {
              font-size: 1.3rem;
              text-align: left;
              margin-top: 8%;
              padding: 0 8.4507% 10.66%;
            }
          }
          .LearnMoreLayout {
            width: 100%;
            text-align: right;
            padding-right: 4.95049%;
            padding-top: 17.32%;
            padding-bottom: 9.9009%;
            @include mq(fixed) {
              padding-top: 70px;
            }
            @include mq(original){
              padding-top: 9.9009%;
            }
            @include mq(md) {
              padding: 0 6.33802% 9.85915%;
            }
          }
        }
      }
    }
  }
}