@charset "utf-8";
/* ================================================================================
seminar　トップページ
================================================================================ */
#seminarIdx {
  .newTit,
  .closedTit {
    font-family: $NotoSerifJP;
    font-size: 3.2rem;
    line-height: 1.25;
    letter-spacing: 3.2px;
    text-align: center;
    color: #000000;
    padding-bottom: 45px;
    margin-bottom: 40px;
    border-bottom: 1px solid;
    position: relative;
    @include mq(md) {
      font-size: 2.4rem;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 5px;
    }
  }
  .newTit {
    border-color: #b59738;
    &::after {
      background-color: #b59738;
    }
  }
  .closedTit {
    border-color: #707070;
    &::after {
      background-color: #707070;
    }
  }
  .newSeminar {
    max-width: 1080px + 40px;
    padding: 80px 20px 0;
    width: 100%;
    margin: 0 auto 120px;
    ul {
      padding: 0 20px;
      @include mq(md) {
        margin-bottom: 50px;
        padding: 0;
      }
    }
    li {
      background-color: rgba(250, 250, 250, 0.68);
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      padding: 30px;
      display: block;
      @include mq(md) {
        padding: 20px;
      }
    }
    .column {
      display: flex;
      @include mq(md) {
        display: block;
      }
      .seminarDate {
        flex-basis: 15%;
        padding-right: 35px;
        @include mq(md) {
          padding-right: 0;
        }
        p {
          font-size: 2.2rem;
          line-height: 1.55;
          letter-spacing: 1.1px;
          color: #000;
          @include mq(md) {
            font-size: 1.6rem;
            margin-bottom: 10px;
          }
          span {
            font-size: 1.6rem;
            line-height: .8px;
            @include mq(md) {
              font-size: 1.4rem;
            }
          }
        }
        .full {
          font-size: 1.8rem;
          line-height: 1.89;
          letter-spacing: 0.9px;
          text-align: center;
          color: #ffffff;
          background-color: #b53232;
          margin-top: 20px;
          display: block;
          @include mq(md) {
            font-size: 1.2rem;
            display: inline-block;
            margin-top: 0;
            margin-left: 20px;
            padding: 0 20px;
          }
        }
      }
      .seminarTit {
        flex-basis: 85%;
        padding-left: 40px;
        border-left: solid 1px #004092;
        @include mq(md) {
          border: none;
          border-top: solid 1px #004092;
          padding-left: 0;
          padding-top: 10px;
        }
        h2 {
          font-family: $NotoSerifJP;
          font-size: 2.4rem;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 2.4px;
          color: #02377b;
          margin-bottom: 10px;
          @include mq(md) {
            font-size: 1.8rem;
          }
        }
        p {
          font-size: 1.6rem;
          line-height: 1.5;
          letter-spacing: 0.8px;
          color: #4b4b4b;
        }
        .more {
          font-size: 1.3rem;
          position: relative;
          text-align: right;
          padding-right: 40px;
          margin-top: 20px;
        }
      }
    }
  }
  .closedList {
    max-width: 1080px + 40px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    dl {
      display: block;
      padding-bottom: 80px;
      @include mq(md) {
        padding-bottom: 30px;
      }
      &:first-of-type {
        dd {
          display: block;
        }
      }
      &:last-of-type {
        padding-bottom: 30px;
        @include mq(md) {
          padding-bottom: 15px;
        }
      }
      dt {
        border-bottom: 1px solid #A2A5A7;
        position: relative;
        cursor: pointer;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 48px;
          height: 20px;
          background-image: url(/images/icons/icon_toggle_btn.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          transition: .5s;
          @include mq(md) {
            width: 24px;
            height: 10px;
          }
        }
        &.open {
          &::after {
            transform: rotate(-180deg);
          }
        }
        span {
          display: inline-block;
          padding-bottom: 20px;
          position: relative;
          font-family: $NotoSerifJP;
          font-size: 4.8rem;
          line-height: 1;
          letter-spacing: 4.8px;
          color: #02377b;
          @include mq(md) {
            font-size: 3rem;
            padding-bottom: 10px;
          }
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: #02377b;
            bottom: -1px;
            left: 0;
          }
        }
      }
      dd {
        padding-top: 35px;
        display: none;
        @include mq(md) {
          padding-top: 20px;
        }
      }
    }
    p.attention {
      text-align: right;
      font-size: 1.2rem;
      line-height: 2;
      color: #707070;
      padding-bottom: 120px;
      @include mq(md) {
        padding-bottom: 60px;
      }
    }
    .archiveBtnLayout {
      margin-bottom: 120px;
      @include mq(md) {
        margin-bottom: 60px;
      }
    }
  }
}