@charset "utf-8";
/* ================================================================================
リスクマネジメント
================================================================================ */

#complianceTop {
	.complianceLayout {
		max-width: 1020px;
		padding: 0 20px;
		margin: 0 auto 120px;
		overflow: hidden;
		@include mq(md) {
			margin-bottom: 17%;
		}
		&.cancell {
			overflow: visible;
		}
		h2 {;
			font-family: $NotoSerifJP;
			font-size: 3.2rem;
			font-weight: 500;
			line-height: 1.25;
			letter-spacing: 3.2px;
			color: #004092;
			span {
				display: block;
				font-size: 1.8rem;
				margin-top: 20px;
				@include mq(md) {
					font-size: 1.4rem;
					margin-top: 5%;
				}
			}
			@include mq(md) {
				font-size: 2rem;
				letter-spacing: 2px;
			}
		}
		h3 {
			margin: 60px 0 20px;
			font-family: $NotoSerifJP;
			font-size: 2.4rem;
			line-height: 1.67;
			letter-spacing: 2.4px;
			color: #000000;
			position: relative;
			display: inline-block;
			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				left: 100%;
				background-color: #b59738;
				height: 1px;
			}
			&::before {
				width: 100px;
				transform: translate(20px, -3px);
			}
			&::after {
				width: 100vw;
				transform: translate(20px, 3px);
			}
			@include mq(md) {
				margin: 10% 0 3%;
				font-size: 1.8rem;
				letter-spacing: 1.8px;
			}
		}
		h4 {
			margin: 60px 0 20px;
			font-family: $NotoSerifJP;
			font-size: 2rem;
			line-height: 2;
			letter-spacing: 2px;
			color: #004092;
			@include mq(md) {
				margin: 8.5% -0px 3%;
				font-size: 1.6rem;
				letter-spacing: 1.6px;
			}
		}
		p {
			font-size: 1.4rem;
			line-height: 2;
			letter-spacing: .6px;
			color: #4b4b4b;
		}
		.imgBox {
			margin-top: 60px;
			@include mq(md) {
				margin-top: 8.5%;
			}
			.zoomBtn {
				display: none;
				margin-top: 10px;
				text-align: right;
				@include mq(md) {
					display: block;
				}
				p {
					padding-right: 25px;
					position: relative;
					display: inline-block;
					&::after {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						margin: auto;
						width: 16px;
						height: 16px;
						background-image: url(/images/icons/icon_zoomin.svg);
						background-repeat: no-repeat;
						background-size: contain;
					}
				}
			}
			&.open {
				position: fixed;
				top: 0;
				left: 0;
				margin: 0;
				width: 100vw;
				height: 100%;
				background-color: #fff;
				z-index: 99999999;
				overflow-x: scroll;
				transition: background-color .5s;
				.modalImg {
					width: 200vw;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					transition: .5s;
					img {
						width: 100%;
					}
					&::before,
					&::after {
						content: "";
						width: 1px;
						height: 37px;
						background-color: #004092;
						position: fixed;
						right: 33px;
						top: 20px;
					}
					&::before {
						transform: rotate(45deg);
					}
					&::after {
						transform: rotate(-45deg);
					}
				}
				.zoomBtn {
					display: none;
				}
			}
		}
	}
}