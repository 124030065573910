@charset "utf-8";
/* ================================================================================
seminar　トップページ
================================================================================ */
#seminarTemplate {
  .seminarLayout {
    max-width: 1020px;
    padding: 0 20px;
    margin: 80px auto 120px;
    @include mq(md) {
      margin: 7% auto 16%;
      overflow: hidden;
    }
    p {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
    }
    .attention {
      font-size: 1.2rem;
      text-align: right;
      color: #707070;
      padding-top: 10px;
    }
    h3 {
      font-family: $NotoSerifJP;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 2;
      letter-spacing: 2.4px;
      color: #02377b;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 2rem;
        letter-spacing: 2px;
      }
    }
    hr {
      margin: 100px 0 20px;
      @include mq(md) {
        width: calc(100% - 40px);
        margin: 30px auto 0;
      }
      &.borderNon{
          border: none;
      }
      &+.attention {
        @include mq(md) {
          padding-right: 20px;
        }
      }
    }
  }
  .seminarHead {
    margin-bottom: 100px;
    @include mq(md) {
      margin-bottom: 50px;
      padding: 0 20px;
    }
    h2 {
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      line-height: 2;
      letter-spacing: 1.6px;
      color: #02377b;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 2rem;
        letter-spacing: 2.2px;
      }
      span {
        font-size: 2.8rem;
        color: #02377b;
        @include mq(md) {
          font-size: 1.8rem;
        }
      }
    }
    .type {
      color: #000;
      padding-left: 10px;
      border-left: solid 1px #707070;
      margin-bottom: 20px;
    }
    .seminar_ttl{
        color: #02377b;
        font-size: 2rem;
        font-family: $NotoSerifJP;
        font-size: 2rem;
        letter-spacing: 1px;
        @include mq(md) {
            font-size: 1.6rem;
            padding: 0 30px;
          }
    }
    dl.lecturer {
      display: flex;
      @include mq(md) {
        display: block;
      }
      dt,
      dd {
        font-family: $NotoSerifJP;
        font-size: 2rem;
        line-height: 2;
        letter-spacing: 2px;
        color: #000;
        @include mq(md) {
          font-size: 1.6rem;
        }
        span {
          font-size: 1.4rem;
          letter-spacing: 1.4px;
        }
      }
      dt {
        padding-left: 20px;
        @include mq(md) {
          padding: 0;
        }
      }
    }
    .intro {
      padding-left: 20px;
      margin-top: 30px;
      line-height: 2;
        
      strong{
          display: inline-block;
          font-size: 1.6rem;
          line-height: 1.8;
          letter-spacing: 1.8px;
          color: #4b4b4b;
      }
        
      @include mq(md) {
        padding: 0;
        margin-top: 4%;
      }
    }
    .fullTxt {
      text-align: center;
      margin: 60px 0;
      @include mq(md) {
        margin: 20px 0;
      }
      .ttl {
        display: inline-block;
        font-family: $NotoSerifJP;
        font-size: 3rem;
        line-height: 1.87;
        color: #de1111;
        position: relative;
        @include mq(md) {
          font-size: 2rem;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #de1111;
          height: 1px;
          width: 18vw;
          max-width: 267px;
        }
        &::before {
          right: 100%;
          transform: translateX(-30px);
          @include mq(md) {
            transform: translateX(-20px);
          }
        }
        &::after {
          left: 100%;
          transform: translateX(30px);
          @include mq(md) {
            transform: translateX(20px);
          }
        }
      }
      span {
        display: block;
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 0.8px;
        color: #4b4b4b;
        @include mq(md) {
          font-size: 1.4rem;
        }
      }
    }
    .endTxt {
      text-align: center;
      margin: 60px 0;
      overflow: hidden;
      @include mq(md) {
        margin: 30px 0;
      }
      p {
        display: inline-block;
        font-family: $NotoSerifJP;
        font-size: 2rem;
        line-height: 1.4;
        color: #de1111;
        position: relative;
        @include mq(md) {
          font-size: 1.4rem;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #de1111;
          height: 1px;
          width: 10vw;
          max-width: 142px;
        }
        &::before {
          right: 100%;
          transform: translateX(-20px);
          @include mq(md) {
            transform: translateX(-5px);
          }
        }
        &::after {
          left: 100%;
          transform: translateX(20px);
          @include mq(md) {
            transform: translateX(5px);
          }
        }
      }
    }
  }
  .seminarDetail {
    border-bottom: solid 1px #d3d3d3;
    position: relative;
    @include mq(md) {
      padding: 0 20px;
      border-bottom: 0;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 20%;
      height: 1px;
      background-color: #004092;
      @include mq(md) {
        width: calc(100% - 40px);
        left: 20px;
      }
    }
    &>dl {
      display: flex;
      @include mq(md) {
        display: block;
      }
      &>dt,
      &>dd {
        padding: 30px 0;
        @include mq(md) {
          max-width: none;
        }
      }
      &>dt {
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 1.6px;
        color: #02377b;
        flex-basis: 20%;
        max-width: 20%;
        border-top: solid 1px #004092;
        @include mq(md) {
          padding: 30px 0 10px;
        }
      }
      &>dd {
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: .7px;
        color: #4b4b4b;
        flex-basis: 80%;
        max-width: 80%;
        border-top: solid 1px #d3d3d3;
        @include mq(md) {
          border-top: 0;
          padding: 0 0 40px;
        }
      }
    }
    .access {
      display: flex;
      margin-top: 20px;
      @include mq(tab) {
        display: block;
      }
      @include mq(md) {
        display: block;
      }
      &Tit {
        display: block;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 2.15;
        letter-spacing: 1.3px;
        color: #004092;
        padding-left: 8px;
        padding-right: 25px;
        position: relative;
        @include mq(md) {
          margin-bottom: 10px;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          width: 1px;
          height: 18px;
          background-color: #02377b;
        }
      }
    }
    .map {
      height: 0;
      overflow: hidden;
      padding-bottom: 54%;
      position: relative;
      margin-top: 30px;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .program {
      display: flex;
      flex-wrap: wrap;
      @include mq(md) {
        display: block;
      }
      dt {
        padding-right: 20px;
        flex-basis: auto;
      }
      dd {
        flex-basis: 80%;
        margin-bottom: 10px;
      }
      /*****リンク部下線*****/
      a {
          border-bottom: 1px solid #0073aa;
          color: #0073aa;
      }
    }
    .dotList {
      li {
        margin-bottom: 10px;
        padding-left: 15px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 5px;
          height: 5px;
          background-color: #004092;
        }
        &:last-child {
          margin-bottom: 0;
        }
        p {
          display: inline;
        }
      }
      &+p {
        margin-top: 10px;
      }
    }
    .seminarName {
      color: #004092;
      font-size: 1.6rem;
      margin-bottom: 10px;
    }
    .warning {
      font-size: 1.2rem;
      line-height: 2;
      letter-spacing: 0.6px;
      color: #de1111;
      margin-top: 10px;
    }
  }
  .juku {
    @include mq(md) {
      padding: 0;
    }
    .seminarApply,
    .seminarProfile,
    .specialAdvisory,
    .adviserList {
      overflow: hidden;
      @include mq(md) {
        overflow: inherit;
        padding: 0 20px;
      }
      .attention {
        @include mq(md) {
          border-top: 1px solid #d3d3d3;
        }
      }
      h3 {
        position: relative;
        display: inline-block;
        margin: 80px 0 20px;
        @include mq(md) {
          margin: 50px 0;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 100%;
          background-color: #b59738;
          height: 1px;
          @include mq(md) {
            top: inherit;
            bottom: -15px;
            left: 0;
          }
        }
        &::before {
          width: 100px;
          transform: translate(20px, -3px);
          @include mq(md) {
            transform: translate(0, -3px);
          }
        }
        &::after {
          width: 100vw;
          transform: translate(20px, 3px);
          @include mq(md) {
            transform: translate(0, 3px);
          }
        }
        span {
          font-size: 1.6rem;
          letter-spacing: 1.6px;
          color: #000000;
        }
      }
    }
    .seminarApply {
      &Box {
        background-color: #f8f9fb;
        padding: 20px 0 20px 40px;
        @include mq(md) {
          padding: 20px;
        }
        .seminarForm {
          margin-top: 20px;
          display: flex;
          @include mq(md) {
            display: block;
          }
          a {
            display: inline-block;
            padding: 12px 24px;
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: 1.6px;
            text-align: center;
            color: #02377b;
            background-color: #fff;
            border: solid .5px #02377b;
            @include mq(md) {
              padding: 12px;
              width: 100%;
            }
            &::before {
              content: "";
              display: inline-block;
              width: 16px;
              height: 12px;
              margin-right: 10px;
              background-image: url('/images/icons/icon_mail_blue.svg');
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
          span {
            font-size: 1.3rem;
            line-height: 1.62;
            letter-spacing: 0.65px;
            color: #707070;
            padding-left: 20px;
            border-left: solid 1px #707070;
            margin-left: 50px;
            @include mq(md) {
              padding: 0;
              margin: 0;
              margin-top: 20px;
              border: none;
              display: block;
            }
          }
        }
      }
    }
    .seminarProfile {
      .lecturerName {
        font-family: $NotoSerifJP;
        font-size: 2.4rem;
        line-height: 1;
        letter-spacing: 2.4px;
        color: #02377b;
        border-left: solid 1px #02377b;
        margin-bottom: 20px;
        @include mq(md) {
          font-size: 1.9rem;
          letter-spacing: 1px;
        }
        span {
          font-family: $base-font;
          font-size: 1.2rem;
          letter-spacing: normal;
          color: #004092;
          margin-left: 20px;
        }
      }
      p {
        padding-left: 20px;
        line-height: 2;
        @include mq(md) {
          padding-left: 10px;
        }
      }
      /****講師名余白****/
      .teacherRepeatBox {
          margin-bottom: 40px;
      }
    }
    /*特別顧問*/
    .specialAdvisory,
    .adviserList {
      table {
        @include mq(md) {
          display: block;
          margin: 0 -20px;
          width: 100vw;
        }
        tbody {
          @include mq(md) {
            display: block;
            width: 100%;
          }
          tr {
            @include mq(md) {
              display: block;
              position: relative;
              &:nth-child(odd) {
                background-color: #F8F9FB;
              }
            }
            &:first-child {
              background-color: #f6f6f6;
              @include mq(md) {
                display: none;
              }
              td {
                p {
                  font-family: $NotoSerifJP;
                  font-size: 1.4rem;
                  letter-spacing: 1.4px;
                  color: #02377b;
                }
              }
            }
            &:nth-child(2) {
              &:before {
                @include mq(md) {
                  content: '';
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  width: calc(100% - 40px);
                  height: 1px;
                  background-color: #D3D3D3;
                  margin: auto;
                }
              }
            }
            td {
              padding: 15px 15px 15px 40px;
              border: 1px solid #F6F6F6;
              @include mq(md) {
                display: block;
                border: 0;
                padding: 0 32px;
              }
              &:first-child {
                @include mq(md) {
                  padding: 25px 32px 0;
                }
              }
              p {
                @include mq(md) {
                  padding-bottom: 25px;
                }
                span {
                  display: none;
                  @include mq(md) {
                    display: block;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 1;
                    letter-spacing: 1.4px;
                    text-align: left;
                    color: #02377b;
                    padding-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    /*アドバイザーの皆さま*/
    .adviserList {
      .comment {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: .8px;
        margin-bottom: 30px;
      }
    }
    .schoolLayout {
      margin-top: 100px;
      @include mq(md) {
        margin-top: 80px;
        padding: 0 20px;
      }
      .schoolName {
        background-color: #eaeff3;
        padding: 20px 0;
        display: flex;
        h4 {
          font-family: $NotoSerifJP;
          font-size: 2rem;
          line-height: 1.78;
          letter-spacing: 1.8px;
          text-indent: 1.8px;
          text-align: center;
          color: #000;
          display: inline-block;
          margin: auto;
          position: relative;
          @include mq(md) {
            font-size: 1.6rem;
          }
          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 1px;
            background-color: #707070;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &::before {
            left: -31px;
            @include mq(md) {
              left: -21px;
            }
          }
          &::after {
            right: -26px;
            @include mq(md) {
              right: -18px;
            }
          }
        }
        span {
          font-size: 1.2rem;
          line-height: 1.5;
          letter-spacing: 1px;
          color: #4b4b4b;
          display: block;
        }
      }
      .schoolDetail {
        padding: 40px 6%;
        background-color: #fff;
        border: solid 1px #d3d3d3;
        border-top: none;
        @include mq(md) {
          padding: 40px 20px;
        }
        dl {
          display: flex;
          flex-wrap: wrap;
          @include mq(md) {
            display: block;
          }
          dt,
          dd {
            padding-bottom: 40px;
            @include mq(md) {
              padding-bottom: 0;
              max-width: none;
            }
            &:last-of-type {
              padding-bottom: 0;
            }
          }
          dt {
            flex-basis: 18%;
            max-width: 18%;
            font-family: $NotoSerifJP;
            font-size: 1.6rem;
            line-height: 1.75;
            letter-spacing: 1.6px;
            color: #02377b;
            @include mq(md) {
              font-size: 1.4rem;
              padding-bottom: 10px;
            }
          }
          dd {
            flex-basis: 82%;
            max-width: 82%;
            font-size: 1.4rem;
            line-height: 1.71;
            letter-spacing: 0.7px;
            color: #4b4b4b;
            padding-left: 6%;
            border-left: solid 1px #d3d3d3;
            word-break: break-all;
            @include mq(md) {
              border-left: 0;
              padding-left: 0;
              padding-bottom: 40px;
            }
          }
          a {
            color: #004092;
          }
        }
      }
    }
  }
  .tax {
    @include mq(md) {
      padding: 0;
    }
    .seminarApply {
      overflow: hidden;
      text-align: center;
      margin-top: 80px;
      @include mq(md) {
        margin: 10% 20px 0;
      }
      h3 {
        position: relative;
        display: inline-block;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #b7b7b7;
          height: 1px;
          width: 50vw;
        }
        &::before {
          right: 100%;
          transform: translateX(-60px);
          @include mq(md) {
            width: 50%;
            transform: translateX(-15px);
          }
        }
        &::after {
          left: 100%;
          transform: translateX(60px);
          @include mq(md) {
            width: 50%;
            transform: translateX(15px);
          }
        }
      }
      &Box {
        background-color: #f8f9fb;
        padding: 50px 40px;
        margin-bottom: 100px;
        display: flex;
        justify-content: space-between;
        @include mq(md) {
          display: block;
          padding: 20px;
          margin-bottom: 10%;
        }
        p {
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          line-height: 1.75;
          letter-spacing: 0.8px;
          color: #4b4b4b;
          text-align: left;
          margin-right: 40px;
          @include mq(md) {
            font-size: 1.4rem;
            margin: 0;
          }
        }
        .btn {
          padding: 0;
          margin: 0;
          border-left: solid 1px #02377b;
          padding-left: 40px;
          @include mq(md) {
            border: none;
            padding: 0;
            border-top: solid 1px #02377b;
            padding-top: 20px;
            margin-top: 20px;
            max-width: none;
          }
          a {
            width: 100%;
            display: inline-block;
            padding: 18px 24px;
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: 1.6px;
            text-align: center;
            color: #fff;
            background-color: #02377b;
            @include mq(md) {
              font-size: 1.4rem;
              padding: 12px;
            }
            .moreBar {
              &::after {
                background-color: #fff;
              }
            }
            &.notHover {
              background-color: #ccc;
              color: #666;
              cursor: inherit;
              .moreBar {
                display: none;
              }
            }
          }
        }
      }
    }
    .seminarProfile {
      overflow: hidden;
      text-align: center;
      margin-bottom: 80px;
      @include mq(md) {
        margin: 0 20px 40px;
      }
      h3 {
        position: relative;
        display: inline-block;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #b7b7b7;
          height: 1px;
          width: 50vw;
        }
        &::before {
          right: 100%;
          transform: translateX(-60px);
          @include mq(md) {
            transform: translateX(-20px);
          }
        }
        &::after {
          left: 100%;
          transform: translateX(60px);
          @include mq(md) {
            transform: translateX(20px);
          }
        }
      }
      &Box {
        padding: 40px;
        background-color: #f8f9fb;
        @include mq(md) {
          padding: 20px;
        }
      }
      .lecturerNumber {
        font-size: 1.6rem;
        margin: 40px 0 10px;
        padding: 0;
        &:first-child {
          margin-top: 0;
        }
        &+.lecturerName {
          margin-top: 10px;
        }
      }
      .lecturerName {
        font-family: $NotoSerifJP;
        font-size: 2.4rem;
        line-height: 1;
        letter-spacing: 2.4px;
        color: #02377b;
        margin: 40px 0 20px;
        padding: 0;
        &:first-child {
          margin-top: 0;
        }
      }
      .post {
        p {
          font-size: 1.5rem;
          line-height: 1.87;
          letter-spacing: 1.5px;
          color: #000;
          border-left: solid 1px #b59738;
          padding-left: 20px;
          margin-bottom: 20px;
          @include mq(md) {
            font-size: 1.4rem;
          }
        }
      }
      p {
        line-height: 2;
        text-align: left;
      }
      .teacherProfile {
        padding-left: 20px;
        @include mq(md) {
          padding-left: 0;
        }
      }
    }
    .seminarOrganized {
      border: solid #d3d3d3;
      border-width: 1px 0;
      padding: 30px 0;
      @include mq(md) {
        margin: 0 20px;
      }
      dl {
        display: flex;
        flex-wrap: wrap;
        @include mq(md) {
          display: block;
        }
        dt {
          flex-basis: 20%;
          max-width: 20%;
          @include mq(md) {
            max-width: none;
          }
          p {
            font-family: $NotoSerifJP;
            font-size: 1.6rem;
            line-height: 1.75;
            letter-spacing: 1.6px;
            color: #02377b;
          }
        }
        dd {
          flex-basis: 80%;
          max-width: 80%;
          border-left: solid 1px #707070;
          padding-left: 20px;
          margin-bottom: 40px;
          display: flex;
          @include mq(md) {
            display: block;
            max-width: none;
            margin-bottom: 20px;
            border: none;
            border-top: solid 1px #707070;
            margin-top: 10px;
            padding-top: 10px;
            padding-left: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          p,
          .box {
            font-size: 1.4rem;
            line-height: 2;
            letter-spacing: 0.8px;
            color: #000000;
          }
          .min {
            p {
              color: #707070;
            }
          }
        }
      }
      a {
        margin-left: 60px;
        display: inline-block;
        padding: 12px 24px;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 1.6px;
        text-align: center;
        color: #02377b;
        background-color: #fff;
        border: solid .5px #02377b;
        @include mq(md) {
          margin: 0;
          margin-top: 20px;
          width: 100%;
          font-size: 1.4rem;
        }
        &::before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 12px;
          margin-right: 10px;
          background-image: url(/images/icons/icon_mail_blue.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .annual {
    @include mq(md) {
      padding: 0;
    }
    .seminarHead {
      margin-bottom: 0;
    }
    //開催終了後追加画像
    .endImage {
      display: flex;
      justify-content: space-between;
      @include mq(md) {
        display: block;
      }
      img {
        width: 24%;
        height: 100%;
        @include mq(md) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    .annualTit {
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 2.4;
      letter-spacing: 1px;
      color: #02377b;
      display: inline-block;
      position: relative;
      padding: 0 50px;
      @include mq(tab) {
        &.twoRowTit{
          line-height: 1.6;
          margin-bottom: 20px;
        }
      }
      @include mq(md) {
        font-size: 1.6rem;
        padding: 0 30px;
      }
      &::before,
      &::after {
        content: "";
        width: 30px;
        height: 1px;
        background-color: #b59738;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        @include mq(md) {
          width: 20px;
        }
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
        
      @include mq(md) {
          line-height: 1.5;
          margin-bottom: 5px;
      }
    }
    .annualSubTit {
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 2px;
      color: #000;
      @include mq(md) {
        font-size: 1.6rem;
      }
    }
    .annualImg {
      margin: 60px 0;
      padding-left: 20px;
      @include mq(md) {
        padding-left: 0;
        width: 100%;
      }
    }
    h3 {
      position: relative;
      display: inline-block;
      margin: 80px 0 20px;
      @include mq(md) {
        margin: 50px 0 40px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100%;
        background-color: #b59738;
        height: 1px;
        @include mq(md) {
          top: inherit;
          bottom: -15px;
          left: -20px;
        }
      }
      &::before {
        width: 100px;
        transform: translate(20px, -3px);
      }
      &::after {
        width: 100vw;
        transform: translate(20px, 3px);
      }
      span {
        display: inline-block;
        font-size: 1.6rem;
        letter-spacing: 1.6px;
        color: #02377b;
      }
    }
    .seminarDetail,
    .seminarSpeaker,
    .seminarTheme,
    .entryForecast {
      overflow: hidden;
      @include mq(md) {
        padding: 0 20px;
      }
    }
    .seminarDetail {
      border: none;
      margin-bottom: 0;
      &::after {
        display: none;
      }
      .seminarDetail_time{
        display: block;
        display: flex;
        .seminarDetail_time_ttl{
            white-space: nowrap;
        }
    }
    }
    dl {
      flex-wrap: wrap;
      padding: 30px 10px;
      border: solid #d3d3d3;
      border-width: 1px 0;
      margin: 0 20px;
      @include mq(md) {
        display: block;
        margin: 0;
        padding: 20px 0;
      }
      dt,
      dd {
        padding-top: 0;
        border: none;
        @include mq(md) {
          max-width: none;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
      }
      dt {
        flex-basis: 25%;
        max-width: 25%;
        @include mq(md) {
          padding: 0;
        }
        p {
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          color: #02377b;
        }
      }
      dd {
        flex-basis: 75%;
        max-width: 75%;
        border-left: 1px solid #d3d3d3;
        padding-left: 50px;
        @include mq(md) {
          padding-left: 0;
          padding-bottom: 20px;
          border: none;
        }
      }
    }
    ul {
      padding-left: 20px;
      @include mq(md) {
        padding-left: 10px;
      }
      li {
        padding-left: 20px;
        margin-bottom: 10px;
        position: relative;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #004092;
          position: absolute;
          top: 11px;
          left: 0;
        }
        &.notMark {
          &::before {
            display: none;
          }
        }
      }
    }
    .other {
        font-size: 1.3rem;

        a {
            color: #02377b;
        }
    }
    hr {
      margin-bottom: 50px;
      @include mq(md) {
        width: 100%;
        margin: 60px 0 40px;
      }
    }
    /*講師・スピーカーのご紹介*/
    .seminarSpeaker {
      h4 {
        font-family: $NotoSerifJP;
        font-size: 1.8rem;
        line-height: 1;
        letter-spacing: 1.8px;
        color: #4b4b4b;
        padding-left: 20px;
        border-left: solid 1px #4b4b4b;
        margin-top: 50px;
        &:first-of-type {
          margin-top: 10px;
        }
        @include mq(md) {
          margin-top: 30px;
          padding-left: 10px;
        }
      }
      h5 {
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        line-height: 1;
        letter-spacing: 1.6px;
        color: #4b4b4b;
        margin-top: 30px;
        @include mq(md) {
          margin-top: 30px;
          padding-left: 10px;
        }
      }
      table {
        margin-top: 20px;
        border: solid 1px #d3d3d3;
        border-left-width: 0;
        @include mq(md) {
          margin-top: 0;
          border-right-width: 0;
          border-top-width: 0;
        }
        thead {
          background-color: #f6f6f6;
          @include mq(md) {
            display: none;
          }
          p {
            font-family: $NotoSerifJP;
            letter-spacing: 1.4px;
            color: #02377b;
          }
        }
        tbody {
          @include mq(md) {
            display: block;
            width: 100%;
          }
          tr {
            @include mq(md) {
              display: block;
              width: 100vw;
              margin: 0 -20px;
              padding: 20px 30px 0;
              &:nth-child(even) {
                background-color: #F8F9FB;
              }
            }
            p {
              span {
                display: none;
                @include mq(md) {
                  display: block;
                  font-family: $NotoSerifJP;
                  line-height: 1;
                  letter-spacing: 1.4px;
                  color: #02377b;
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
        td {
          vertical-align: top;
          padding: 15px 1.5% 15px 3%;
          border: solid 1px #d3d3d3;
          @include mq(md) {
            display: block;
            width: 100%;
            border: 0;
            padding: 0 0 20px 0;
          }
          p {
            @include mq(md) {
              font-size: 1.3rem;
              letter-spacing: 1.3px;
            }
          }
          a {
            font-size: 1.4rem;
            letter-spacing: .7px;
            color: #02377b;
          }
          &:first-child {
            width: 20%;
            @include mq(md) {
              width: 100%
            }
          }
          &:nth-child(2) {
            min-width: 84px;
          }
          &:nth-child(3) {
            width: 35%;
            @include mq(md) {
              width: 100%;
            }
          }
        }
		&.seminarSpeaker2022{
			td {
          &:first-child {
            width: 35%;
            @include mq(md) {
              width: 100%
            }
          }
          &:nth-child(2) {
            min-width: 84px;
          }
          &:nth-child(3) {
            width: 25%;
            @include mq(md) {
              width: 100%;
            }
          }
        }
		}
      }
    }
    .seminarReception {
      border: solid 1px #b7b7b7;
      padding: 50px;
      @include mq(md) {
        margin: 0 20px;
        padding: 20px;
      }
      .topColumn {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 40px;
        border-bottom: solid 1px #b7b7b7;
        margin-bottom: 30px;
        @include mq(md) {
          display: block;
          padding-bottom: 30px;
          margin-bottom: 20px;
        }
        p {
          flex-basis: 60%;
          max-width: 60%;
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          color: #000000;
          @include mq(md) {
            max-width: none;
          }
        }
        .btn {
          width: 30%;
          margin: 0;
          padding-top: 30px;
          @include mq(md) {
            width: 100%;
          }
          a {
            background-color: #02377b;
            color: #fff;
            .moreBar {
              width: 12px;
              &::after {
                background-color: #fff;
              }
            }
            &.notHover {
              background-color: #ccc;
              color: #666;
              cursor: inherit;
              .moreBar {
                display: none;
              }
            }
          }
        }
      }
      .other {
        font-size: 1.3rem;
        a {
          color: #02377b;
        }
      }
      &+.attention {
        padding-right: 20px;
      }
    }
    .seminarReception02 {
      border: solid 1px #b7b7b7;
      padding: 50px;
      @include mq(md) {
        padding: 20px;
      }
      .topColumn {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include mq(md) {
          display: block;
        }
        p {
          flex-basis: 60%;
          max-width: 60%;
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          color: #000000;
          @include mq(md) {
            max-width: none;
          }
        }
        .btn {
          width: 30%;
          margin: 0;
          padding-top: 0;
            
          &.notHover {
              background-color: #ccc;
              color: #666;
              cursor: inherit;
              font-size: 1.4rem;
              line-height: 1.3;
              letter-spacing: .7px;
              padding: 25px 0;
              position: relative;
              
              a{
                  display: contents;
                  pointer-events: none;
                  padding: 0;
                  background-color: inherit;
              }
              
              .moreBar {
                  display: none;
              }
              
              @include mq(md) {
                margin-top: 30px;
              }
          }
            
          @include mq(md) {
            width: 100%;
            padding-top: 30px;
          }
          a {
            background-color: #02377b;
            color: #fff;
            .moreBar {
              width: 12px;
              &::after {
                background-color: #fff;
              }
            }
            &.notHover {
              background-color: #ccc;
              color: #666;
              cursor: inherit;
              .moreBar {
                display: none;
              }
            }
          }
        }
      }
    }
    .seminarReception03 {
      border: solid 1px #b7b7b7;
      padding: 50px;
      @include mq(tab) {
        padding: 30px 25px;
      }
      @include mq(md) {
        padding: 20px;
      }
      .topColumn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 40px;
        border-bottom: solid 1px #b7b7b7;
        margin-bottom: 30px;
        @include mq(md) {
          display: block;
          padding-bottom: 30px;
          margin-bottom: 20px;
        }
        p {
          flex-basis: 54%;
          max-width: 54%;
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          color: #000000;
          @include mq(tab) {
            flex-basis: 53%;
            max-width: 53%;
          }
          @include mq(md) {
            max-width: none;
          }
        }
        .btn {
          width: 36%;
          margin: 0;
          padding-top: 0;
            
          &.notHover {
              background-color: #ccc;
              color: #666;
              cursor: inherit;
              font-size: 1.4rem;
              line-height: 1.3;
              letter-spacing: .7px;
              padding: 25px 0;
              position: relative;
              
              a{
                  display: contents;
                  pointer-events: none;
                  padding: 0;
                  background-color: inherit;
              }
              
              .moreBar {
                  display: none;
              }
              
              @include mq(md) {
                margin-top: 30px;
              }
          }
          @include mq(tab) {
            flex-basis: 42%;
            max-width: 42%;
          }
          @include mq(md) {
            width: 100%;
            padding-top: 30px;
            margin: 0 auto;
          }
          a {
            background-color: #02377b;
            color: #fff;
            margin-bottom: 15px;
            .moreBar {
              width: 12px;
              right: 6%;
              &::after {
                background-color: #fff;
              }

              @include mq(tab) {
                right: 4%;
              }
              @include mq(sm) {
                right: 4%;
              }
            }
            &.notHover {
              background-color: #ccc;
              color: #666;
              cursor: inherit;
              .moreBar {
                display: none;
              }
            }
          }
        }
      }
      .other {
        font-size: 1.3rem;
        a {
          color: #02377b;
        }
      }
      &+.attention {
        padding-right: 20px;

        @include mq(md) {
          padding-right: 0;
        }
      }
    }
    .seminarTheme {
      h4 {
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        line-height: 1;
        letter-spacing: 1.8px;
        color: #444;
        padding-left: 20px;
        border-left: solid 1px #4b4b4b;
        margin-top: 50px;
        margin-bottom: 15px;
        &:first-of-type {
          margin-top: 10px;
        }
        @include mq(md) {
          line-height: 1.6;
          margin-top: 30px;
          padding-left: 10px;
        }
      }
      .seminarThemeTxt {
          padding-left: 39px;

          @include mq(md) {
              padding-left: 10px;
          }
      }
      .aboutTxt {
          span {
              display: inline-block;
              font-size: 1.3rem;
              line-height: 1.85;
              letter-spacing: 0.65px;
              color: #707070;
              margin-top: 40px;
          }
      }
      ul {
        padding-top: 30px;
        li {
          &:last-child {
            padding-left: 0;
            &::before {
              display: none;
            }
          }
          span {
            font-size: 1.3rem;
            line-height: 1.85;
            letter-spacing: 0.65px;
            color: #707070;
          }
        }
      }
    }
  }
}