@charset "utf-8";
/* ================================================================================
グループ企業のご紹介
================================================================================ */

#groupCompanies{
	.groupLayout {
		&>div {
			&:last-of-type {
				margin-bottom: 120px;
				@include mq(md) {
					margin-bottom: 16%;
				}
			}
		}
		.bg {
			background-color: #fbfcfd;
			padding: 80px 0;
			margin-bottom: 80px;
			@include mq(md) {
				padding: 15% 0;
				margin-bottom: 15%;
			}
			.companyBox {
				margin-bottom: 0;
			}
		}
		.companyBox {
			max-width: 1020px;
			padding: 0 20px;
			margin: auto;
			margin-bottom: 80px;
			@include mq(md) {
				margin-bottom: 15%;
			}
			.companyImg {
				margin: 0 auto 70px;
				@include mq(md) {
					margin-bottom: 40px;
				}
				img {
					width: 100%;
				}
				&.asa {
					width: 12.9387%;
				}
				&.tax {
					width: 19.664%;
				}
				&.total {
					width: 20.597%;
				}
				&.sin {
					width: 14.5245%;
				}
				&.advice{
					width: 16.597%;
				}
				&.report{
					width: 30.597%;
				}
				@include mq(md) {
					&.asa {
						width: calc(12.9387% * 2.25);
					}
					&.tax {
						width: calc(19.664% * 2.4);
					}
					&.total {
						width: calc(20.597% * 2.35);
					}
					&.sin {
						width: calc(14.5245% * 2.4);
					}
					&.advice{
						width: calc(16.597% * 2.35);
					}
					&.report{
						width: calc(30.664% * 2.4);
					}
				}
			}
			.companyName {
				h2 {
					font-family: $NotoSerifJP;
					font-size: 2.4rem;
					line-height: 1.66;
					color: #004092;
					margin-bottom: 10px;
					@include mq(md) {
						font-size: 2rem;
					}
					span {
						font-size: 1.4rem;
						line-height: 1.43;
						color: #02377b;
						@include mq(md) {
							font-size: 1.2rem;
						}
					}
                    .chui{
                        color: #000;
                        font-family: $base-font;
                    }
				}
				p {
					font-size: 14px;
					line-height: 2;
					letter-spacing: 1.4px;
					color: #000000;
				}
			}
			.companyDetail {
				dl {
					display: flex;
					flex-wrap: wrap;
					align-items: baseline;
					margin-top: 25px;
					padding: 15px 0;
					border: solid #004092;
					border-width: 1px 0;
					position: relative;
					@include mq(md) {
						display: block;
						padding: 0;
						margin-top: 8%;
						border-bottom: none;
					}
					&::before,
					&::after {
						content: "";
						position: absolute;
						right: 0;
						width: 75%;
						border-bottom: 1px solid #d3d3d3;
						@include mq(tab) {
							width: 68%;
						}
						@include mq(md) {
							display: none;
						}
					}
					&::before {
						top: -1px;
					}
					&::after {
						bottom: -1px;
					}
					dt,
					dd {
						padding: 15px 0;
					}
					dt {
						flex-basis: 25%;
						font-family: $NotoSerifJP;
						font-size: 1.6rem;
						line-height: 1.75;
						letter-spacing: 1.6px;
						color: #02377b;
						@include mq(tab) {
							flex-basis: 32%;
						}
						@include mq(md) {
							padding: 30px 0 10px 0;
						}
					}
					dd {
						flex-basis: 75%;
						font-size: 1.4rem;
						line-height: 2;
						letter-spacing: 1.4px;
						color: #4b4b4b;
						@include mq(tab) {
							flex-basis: 68%;
						}
						@include mq(md) {
							padding: 0 0 40px 0;
							border-bottom: 1px solid #004092;
						}
						a {
							font-size: 1.4rem;
							line-height: 2;
							letter-spacing: 1.4px;
							color: #02377b;
						}
						h3{
							font-size: 1.2rem;
							line-height: 2;
							letter-spacing: 1.4px;
							color: #02377b;
							margin: 20px 0 0;
						}
						.book {
							li {
								font-size: 1.2rem;
								line-height: 2;
								letter-spacing: 1.4px;
								color: #4b4b4b;
								padding-left: 18px;
								margin-bottom: 10px;
								position: relative;

								&::before {
									content: "";
									width: 7px;
									height: 7px;
									background-color: #b59738;
									position: absolute;
									top: 11px;
									left: 0px;
								}
							}
						}
					}
				}
			}
		}
	}
}