@charset "utf-8";
/* ================================================================================
役員紹介、顧問紹介
================================================================================ */

#partners,
#advisors {
  .memberDetail {
    max-width: 1020px;
    padding: 0 20px;
    margin: auto;
    margin-bottom: 100px;

    @include mq(md) {
      margin-bottom: 14.085%;
    }

    h2 {
      font-family: $NotoSerifJP;
      font-size: 4rem;
      line-height: 1.4;
      letter-spacing: 4px;
      color: #02377b;
      margin-bottom: 30px;

      @include mq(md) {
        font-size: 2.4rem;
        letter-spacing: 2.4px;
        margin-bottom: 4.226%;
      }

      span {
        font-family: $base-font;
        font-size: 1.4rem;
        letter-spacing: normal;
        margin-left: 18px;
        color: #004092;

        @include mq(md) {
          margin-left: 4.226%;
        }
      }
    }

    .jobtitles {
      font-size: 1.5rem;
      line-height: 1.87;
      letter-spacing: 1.5px;
      color: #000000;
      margin-bottom: 35px;
    }

    .post {
      font-size: 1.5rem;
      line-height: 1.87;
      letter-spacing: 1.5px;
      color: #000000;
      border-left: solid 1px #004092;
      padding-left: 20px;
      margin-bottom: 35px;

      @include mq(md) {
        margin-bottom: 4.226%;
        padding-left: 2.817%;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 1.4px;
      color: #4b4b4b;
    }

    h3 {
      font-family: $NotoSerifJP;
      font-size: 1.8rem;
      line-height: 2.22;
      letter-spacing: 1.8px;
      color: #004092;
      margin: 30px 0 20px;

      @include mq(md) {
        font-size: 1.4rem;
        margin: 5.635% 0 2.82%;
      }
    }

    .book {
      li {
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 1.4px;
        color: #4b4b4b;
        padding-left: 27px;
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: "";
          width: 7px;
          height: 7px;
          background-color: #b59738;
          position: absolute;
          top: 11px;
          left: 10px;
        }
      }
    }

    .careerToggle {
      display: none;
      border: solid 1px #d3d3d3;
      border-top: none;

      &Btn {
        margin-top: 40px;
        padding: 33px 0;
        background-color: #eaeff3;
        width: 100%;
        text-align: center;
        font-family: $NotoSerifJP;
        font-size: 1.8rem;
        line-height: 1.44;
        letter-spacing: 1.8px;
        color: #004092;
        position: relative;
        cursor: pointer;

        @include mq(md) {
          margin-top: 5.635%;
          padding: 20px 0;
          font-size: 1.4rem;
        }

        &::after {
          content: "";
          background-image: url("/images/icons/icon_toggle_btn.svg");
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 25px;
          margin: auto;
          width: 28px;
          height: 13px;
          transition: .5s;

          @include mq(md) {
            width: 14px;
            height: 6.5px;
          }
        }

        &.open {
          &::after {
            transform: rotate(180deg);
          }
        }
      }

      dl {
        background-color: #f4f7fa;
        padding: 30px 70px;
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 1.4px;
        color: #4b4b4b;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mq(md) {
          padding: 5.635% 4.226%;
          font-size: 1.3rem;
        }

        dt,
        dd {
          padding-bottom: 25px;

          @include mq(md) {
            padding-bottom: 3.732%;
          }

          &:last-of-type {
            padding-bottom: 0;
          }
        }

        dt {
          flex-basis: 24%;

          @include mq(md) {
            flex-basis: 100px;
          }
        }

        dd {
          flex-basis: 76%;

          @include mq(md) {
            flex-basis: calc(100% - 100px);
          }
        }
      }
    }
  }

  .otherMenber {
    background: #fbfcfd;
    padding-top: 100px;
    margin-bottom: 100px;

    @include mq(md) {
      background: none;
      padding: 0;
      margin-bottom: 14.085%;
    }

    .columnLayout {
      max-width: 1020px;
      padding: 0 20px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include mq(md) {
        display: block;
      }

      .memberDetail {
        width: 47%;
        background: #fff;
        padding: 30px;
        margin: 0 0 80px;

        @include mq(md) {
          width: 100%;
          padding: 0;
          margin-bottom: 14.085%;
        }
      }

      hr {
        border-top-color: #a5c5d6;
        width: 100%;
        margin: 0 0 40px;
        transform: translateY(-20px);
      }
    }
  }
}