@charset "utf-8";
/* ================================================================================
ISMS / 情報セキュリティへの取り組み 
================================================================================ */

#ismsTop {
  .ismsLayout {
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto 120px;
    @include mq(md) {
      margin-bottom: 17%;
    }
    h2 {
      margin-bottom: 30px;
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: 3.2px;
      color: #004092;
      @include mq(md) {
        font-size: 2rem;
        letter-spacing: 2px;
        margin-bottom: 5%;
      }
    }
    h3 {
      margin: 50px 0 10px;
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 2px;
      color: #004092;
      @include mq(md) {
        margin: 8.5% -0px 3%;
        font-size: 1.6rem;
        letter-spacing: 1.6px;
      }
    }
    p {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
    }
    & > p {
      &:last-of-type {
        margin-top: 60px;
        text-align: right;
        @include mq(md) {
          margin-top: 8.5%;
        }
      }
    }
    ol {
      counter-reset: number 0;
      & > li {
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 0.7px;
        color: #4b4b4b;
        padding-left: 23px;
        padding-bottom: 20px;
        position: relative;
        &::before {
          counter-increment: number 1;
          content: counter(number) "）";
          position: absolute;
          left: 0;
          top: 0;
          color: #02377b;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 30px 10px;
      border: solid #d3d3d3;
      border-width: 1px 0;
      margin: 0 20px 80px;
      @include mq(md) {
        display: block;
        margin:0 0 12%;
        padding: 20px 0;
      }
      dt,
      dd {
        padding-bottom: 30px;
        &:last-of-type {
          padding-bottom: 0;
        }
        @include mq(md) {
          max-width: 100%;
        }
      }
      dt {
        flex-basis: 20%;
        max-width: 20%;
        @include mq(md) {
          padding-bottom: 0;
        }
        p {
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          color: #02377b;
        }
      }
      dd {
        flex-basis: 80%;
        max-width: 80%;
        border-left: 1px solid #d3d3d3;
        padding-left: 50px;
        @include mq(md) {
          padding-left: 0;
          padding-bottom: 20px;
          border: none;
        }
      }
    }
    img {
      width: 52%;
      margin: 50px auto;
      @include mq(md) {
        width: 75%;
        margin: 8.5% auto;
      }
    }
  }
}