@charset "utf-8";

/* ================================================================================
topics　トップページ
================================================================================ */
#newsletterIdx {
  .eachNewsletterList {
    max-width: 1080px + 40px;
    padding: 80px 20px 0;
    width: 100%;
    margin: 0 auto;

    dl {
      display: block;
      padding-bottom: 80px;

      @include mq(md) {
        padding-bottom: 30px;
      }

      &:first-of-type {
        dd {
          display: block;
        }
      }

      &:last-of-type {
        padding-bottom: 30px;

        @include mq(md) {
          padding-bottom: 15px;
        }
      }

      dt {
        border-bottom: 1px solid #a2a5a7;
        position: relative;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 48px;
          height: 20px;
          background-image: url(/images/icons/icon_toggle_btn.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          transition: 0.5s;

          @include mq(md) {
            width: 24px;
            height: 10px;
          }
        }

        &.open {
          &::after {
            transform: rotate(-180deg);
          }
        }

        span {
          display: inline-block;
          padding-bottom: 20px;
          position: relative;
          font-family: $NotoSerifJP;
          font-size: 4.8rem;
          line-height: 1;
          letter-spacing: 4.8px;
          color: #02377b;

          @include mq(md) {
            font-size: 3rem;
            padding-bottom: 10px;
          }

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #02377b;
            bottom: -1px;
            left: 0;
          }
        }
      }

      dd {
        padding-top: 35px;
        display: none;

        @include mq(md) {
          padding-top: 20px;
        }
      }

      .newsletterCard {
        background-color: #ffffff;
        width: 31.2962%;
        margin-right: 3.05555%;
        margin-bottom: 3.7%;

        &:nth-child(3n) {
          margin-right: 0;
        }

        @include mq(md) {
          width: 100%;
          margin-right: 0;
          min-height: inherit;
          margin-bottom: 30px;
        }

        a {
          display: block;
          border: solid 1px #c4c4c4;
          position: relative;
          height: 100%;

          &.notCursor {
            cursor: default;
          }
        }

        .column {
          display: flex;

          .typeColumn {
            max-width: 47%;
            width: 100%;
            padding: 8.9552% 9.26% 14.3283% 9.26%;
            word-break: break-all;

            @include mq(original) {
              padding: 8.9552% 2.26% 14.3283% 6.26%;
            }

            @include mq(md) {
              padding: 7.22535% 5.22535% 7.22535% 5.22535%;
            }

            time {
              display: block;
              font-size: 1.4rem;
              line-height: 1;
              letter-spacing: .42px;
              color: #8d8d8d;

              @include mq(md) {
                font-size: 1.3rem;
              }
            }
          }

          h2 {
            font-family: $NotoSerifJP;
            font-size: 2rem;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 1.5;
            text-align: left;
            color: rgba(2, 55, 123, 0.85);

            &::after {
              content: "";
              display: block;
              width: 72px;
              height: 1px;
              background-color: #d3d3d3;
              margin: 8px 0 10px;
            }

            @include mq(md) {
              font-size: 1.6rem;

              &::after {
                width: 58px;
                margin: 8px 0 10px;
              }
            }
          }

          .imageColumn {
            max-width: 53%;
            width: 100%;
            background-color: rgba(234, 239, 243, 0.4);
            padding: 8.9552% 7.396% 14.3283% 7.396%;

            @include mq(original) {
              padding: 8.9552% 6.396% 14.3283% 6.396%;
            }

            @include mq(md) {
              padding: 7.22535% 8.22535% 7.22535% 8.22535%;
            }

            .img {
              margin: 0 auto;
            }

            img {
              width: 100%;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
            }
          }

          hr {
            text-align: right;
            display: block;
            width: 18px;
            height: 2px;
            background-color: #8D8D8D;
            border-top: 0;
            position: absolute;
            bottom: 0;
            right: 10px;
            margin: 20px 15px;

            @include mq(original) {
              margin: 15px 10px;
            }

            @include mq(md) {
              width: 15px;
              height: 1px;
              margin: 15px 0 30px;
              right: auto;
              left: calc(45% - 25px);
            }
          }
        }
      }
    }

    p.attention {
      text-align: right;
      font-size: 1.2rem;
      line-height: 2;
      color: #707070;
      padding-bottom: 120px;

      @include mq(md) {
        padding-bottom: 60px;
      }
    }
  }
}