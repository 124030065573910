@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */
// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;
// 基本カラー
$basicColor: #555;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #f0f0f0;
$mainColor: #98c043;
// 基本フォント
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
$base-font:"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;
//はんなり
$yakuhan:YakuHanJP_Noto,
"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;
//NotoSerifJP
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP&:400,500,700&display=swap&subset=japanese");
$NotoSerifJP:'Noto Serif JP',
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;
//YuMincho
$YuMin: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
;
//Baskerville
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700&display=swap");
$Baskerville:"Libre Baskerville";
//Cormorant
@import url("https://fonts.googleapis.com/css?family=Cormorant:400,500,600&display=swap");
$Cormorant:"Cormorant";
// ブレークポイント定義
$breakpoints: ("tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "sm": "screen and (max-width : 320px)",
  "ms": "screen and (max-width : 640px)",
  "md": "screen and (max-width : 767px)",
  "tabV": "screen and (min-width : 768px) and (max-width : 768px)",
  "tabH": "screen and (min-width : 1024px) and (max-width : 1024px)",
  "fixed": "screen and (min-width : 1366px)",
  "original": "screen and (min-width : 768px) and (max-width :1024px)",
  "originalBr": "screen and (min-width : 768px) and (max-width :1320px)",
  "point1": "screen and (min-width : 769px) and (max-width :1110px)",
  "point2": "screen and (min-width : 1025px) and (max-width :1320px)",
) !default;
// ブレークポイント処理
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
// PCサイズ Retina画像出し分け用
// 使用例：@include media-retina;
@mixin media-retina() {
  @media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5) {
    @content;
  }
}
/* ================================================================================
importファイル
================================================================================ */
// YakuhanGochic
@import "lib/yakuhanjp-noto";
// @mixin定義
@import "foundation/mixin";
// @extend定義
@import "foundation/extend";
// リセット
@import "foundation/reset";
// 印刷
@import "foundation/print";
// 基本
@import "layout/bace";
// ヘッダー
@import "layout/header";
// フッター
@import "layout/footer";
// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/card";
//波アニメーション
@import "lib/wave";
/************************************
トップページ
***********************************/
/*トップページ*/
@import "page/index";
/************************************
事業内容
***********************************/
/*事業内容*/
@import "page/service/index";
/*ファンドサービス*/
@import "page/service/fund";
/*コーポレートサービス*/
@import "page/service/corporate";
/************************************
会社情報
***********************************/
/*会社概要*/
@import "page/about_us/companyProfile";
/*役員・パートナー紹介 顧問紹介*/
@import "page/about_us/partnersAdvisors";
/*経営理念・サステナビリティ方針*/
@import "page/about_us/corporatePhilosophy";
/*SDG（Smart Data Guideway）計画*/
@import "page/about_us/smartDataGuideway";
/************************************
グループ企業 / 提携企業
***********************************/
/*グループ企業*/
@import "page/group/groupCompanies";
/*提携企業*/
@import "page/group/partnerCompanies";
/*シンガポールオフィス*/
@import "page/group/singaporeOffice";
/************************************
最新情報関連
***********************************/
@import "page/topic/index";
@import "page/topic/tmp";
/************************************
最新情報関連
***********************************/
@import "page/seminar/index";
@import "page/seminar/tmp";
/************************************
プライバシーポリシーなど
***********************************/
/*個人情報保護方針*/
@import "page/privacypolicy/index";
/*ISMS/情報セキュリティへの取り組み*/
@import "page/isms/index";
/*SPC管理業務における内部管理体制*/
@import "page/internal/index";
/*リスクマネジメント*/
@import "page/compliance/index";
/************************************
青山塾
***********************************/
/*青山塾*/
@import "page/juku/index";
/************************************
パートナー募集
***********************************/
/*パートナー募集*/
@import "page/partner/index";
/************************************
お問い合わせフォーム
***********************************/
/*お問い合わせ*/
@import "page/contact/index";
/************************************
404ページ
***********************************/
/*404ページ*/
@import "page/404/index";
/************************************
information
***********************************/
/*個人情報保護方針*/
@import "page/information/index";
/************************************
ニュースレター
***********************************/
@import "page/newsletter/index";
@import "page/newsletter/tmp";