@charset "utf-8";
/* ================================================================================
topics テンプレート
================================================================================ */
#topicsTemplate {
  .topicLayout {
    max-width: 1020px;
    padding: 0 20px;
    margin: 50px auto 120px;
    @include mq(md) {
      margin: 7% auto 16%;
    }
    &.wp {
      strong {
        font-weight: bold;
      }
      p {
        word-break: break-word;
        a {
          word-break: break-word;
        }
      }
    }
    p,
    dd,
    li {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
    }
    .topicTitle {
      margin-bottom: 30px;
      @include mq(md) {
        margin-bottom: 4%;
      }
      p {
        font-size: 1.6rem;
      }
      h2 {
        font-family: $NotoSerifJP;
        font-size: 2.8rem;
        line-height: 1.8;
        letter-spacing: 2.4px;
        color: #000;
        @include mq(md) {
          font-size: 2.2rem;
          letter-spacing: 2.2px;
        }
      }
      span {
        font-size: 1.8rem;
        letter-spacing: 1.8px;
        color: #000;
        display: block;
        @include mq(md) {
          font-size: 1.6rem;
          letter-spacing: 1.6px;
        }
      }
    }
    .topicPerson {
      padding: 20px;
      border-top: solid 1px #b59738;
      border-bottom: solid 1px #b59738;
      @include mq(md) {
        padding: 2.5% 10px;
      }
      p {
        font-family: $NotoSerifJP;
        font-size: 2rem;
        line-height: 2;
        letter-spacing: 2px;
        color: #02377b;
        @include mq(md) {
          font-size: 1.6rem;
        }
      }
      span {
        font-size: 1.4rem;
        letter-spacing: 1.6px; //color: #02377b;
        display: block;
        @include mq(md) {
          font-size: 1.2rem;
        }
        &.name {
          display: inline-block;
          font-size: 1.6rem;
          color: #02377b;
        }
      }
    }
    .introTit,
    .endTit {
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 2.33;
      letter-spacing: 2.4px;
      color: #4b4b4b;
      text-align: center;
      margin: 50px auto;
      position: relative;
      overflow: hidden;
      @include mq(md) {
        font-size: 2.4rem;
        margin: 8% auto;
      }
      &::before,
      &::after {
        content: "";
        width: 20%;
        height: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: #8d8d8d;
      }
      &::before {
        right: calc(55% + 50px);
        @include mq(md) {
          right: calc(50% + 70px);
        }
      }
      &::after {
        left: calc(55% + 50px);
        @include mq(md) {
          left: calc(50% + 70px);
        }
      }
    }
    .introTxt {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      @include mq(md) {
        display: block;
      }
      .txtBox {
        flex-basis: 67%;
      }
      a {
        display: block;
        flex-basis: 27%;
        @include mq(md) {
          padding-top: 30px;
        }
        img {
          width: 100%;
          @include mq(md) {
            width: 90%;
            margin: 0 auto;
          }
        }
      }
    }
    .topicContent {
      padding: 0;
      @include mq(md) {
        padding: 0;
      }
      p {
        margin-bottom: 20px;
      }
      &.simple {
        padding-top: 30px;
        margin-bottom: 45px;
        border-top: solid 1px #b59738;
        p {
          line-height: 2;
          color: #4b4b4b;
        }
        a {
          letter-spacing: 1.4px;
          color: #004092;
          text-decoration: underline;
          padding-bottom: 15px;
          display: inline-block;
        }
        .bold {
          letter-spacing: 1.4px;
          color: #4b4b4b;
          display: block;
        }
        .small {
          font-size: 1.2rem;
          letter-spacing: 1.2px;
          color: #707070;
        }
      }
      &.special {
        overflow: hidden;
        h3 {
          font-size: 2.4rem;
          line-height: 1.2;
          letter-spacing: 1px;
          color: #000000;
          position: relative;
          margin: 60px 0 45px;
          position: relative;
          display: inline-block;
          color: #02377b;
          font-family: $NotoSerifJP;
          @include mq(md) {
            margin: 50px 0 30px;
          }
          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 100%;
            background-color: #b59738;
            height: 1px;
          }
          &::before {
            width: 100px;
            transform: translate(20px, -3px);
          }
          &::after {
            width: 100vw;
            transform: translate(20px, 3px);
          }
        }
        .minContent {
          padding: 0 20px;
          @include mq(md) {
            padding: 0 10px;
          }
        }
        h4 {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.8px;
          color: #02377b;
          margin-bottom: 10px;
        }
        span {
          font-weight: bold;
        }
        img {
          margin: 40px auto;
        }
        ul {
          background-color: #f8f9fb;
          padding: 20px 30px;
          margin-top: 20px;
          @include mq(md) {
            padding: 20px;
          }
          li {
            margin-bottom: 5px;
            padding-left: 15px;
            text-indent: -15px;
            &:last-child {
              margin-bottom: 0;
            }
            &::before {
              content: "";
              width: 5px;
              height: 5px;
              background-color: #4f96b1;
              display: inline-block;
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    ol {
      margin: 30px 0;
      counter-reset: number 0;
      li {
        padding-left: 36px;
        margin-bottom: 24px;
        position: relative;
        &::before {
          counter-increment: number;
          content: counter(number, decimal-leading-zero) '・';
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1.75;
          color: #004092;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .dotList {
      margin: 30px 0;
      background-color: #f8f9fb;
      padding: 20px 20px 30px;
      dt {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.8px;
        color: #02377b;
        padding-left: 20px;
        text-indent: -20px;
        margin-bottom: 10px;
        &::before {
          content: "";
          display: inline-block;
          margin-right: 10px;
          width: 5px;
          height: 5px;
          background-color: #4f96b1;
          vertical-align: middle;
        }
      }
      dd {
        padding-left: 20px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .listTit {
      font-family: $NotoSerifJP;
      font-size: 2.4rem;
      letter-spacing: 2px;
      text-align: center;
      color: #000000;
      margin: 50px 0 30px;
      @include mq(md) {
        font-size: 2rem;
        margin: 25px 0 15px;
      }
    }
    .simpleList {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      @include mq(md) {
        display: block;
      }
      dt,
      dd {
        padding-bottom: 40px;
      }
      dt {
        flex-basis: 20%;
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 1.6px;
        color: #02377b;
        @include mq(md) {
          padding-bottom: 10px;
        }
        &:first-of-type {
          border-top: 1px solid #004092;
          padding-top: 40px;
          @include mq(md) {
            padding-top: 30px;
          }
        }
        &:last-of-type {
          border-bottom: 1px solid #004092;
          @include mq(md) {
            border-bottom: 0;
          }
        }
      }
      dd {
        flex-basis: 80%;
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 0.7px;
        color: #4b4b4b;
        @include mq(md) {
          padding-bottom: 30px;
        }
        &:first-of-type {
          padding-top: 40px;
          border-top: 1px solid #d3d3d3;
          @include mq(md) {
            border: 0;
            padding-top: 0;
          }
        }
        &:last-of-type {
          border-bottom: 1px solid #d3d3d3;
          @include mq(md) {
            border-bottom: 1px solid #004092;
          }
        }
      }
      a {
        color: #004092;
      }
    }
    .exList {
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0;
      @include mq(md) {
        display: block;
        margin-left: -36px;
      }
      dt,
      dd {
        padding: 10px 0;
        margin-bottom: 0;
        &:nth-of-type(odd) {
          background-color: rgba(234, 239, 243, 0.5);
        }
      }
      dt {
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 0.7px;
        color: #004092;
        flex-basis: 30%;
        padding-left: 20px;
        &::before {
          display: none;
        }
      }
      dd {
        flex-basis: 70%;
        @include mq(md) {
          padding-left: 20px;
          padding-top: 0;
        }
      }
    }
    hr {
      border-color: #d3d3d3;
      margin: 30px 0;
      @include mq(md) {
        margin: 4% 0;
      }
    }
    .personLayout {
      margin: 60px 20px 20px;
      padding: 30px;
      //box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #eaeff3;
      background-color: #fff;
      @include mq(md) {
        margin: 20px 0;
      }
      hr {
        margin-top: 40px;
        @include mq(md) {
          margin-top: 8%;
        }
      }
    }
    .personName {
      font-family: $NotoSerifJP;
      font-size: 2.1rem;
      line-height: 2.33;
      letter-spacing: 2.1px;
      color: #02377b;
      margin-bottom: 10px;
      span {
        font-family: $base-font;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: normal;
        color: #004092;
        margin-left: 20px;
      }
    }
    .personJob {
      font-size: 15px;
      line-height: 1.87;
      letter-spacing: 1.5px;
      color: #000000;
      padding-left: 20px;
      border-left: solid 1px #004092;
      margin-bottom: 15px;
    }
    .attention {
      text-align: right;
      line-height: 2;
      letter-spacing: normal;
      color: #707070;
      @include mq(md) {
        font-size: 1.2rem;
      }
    }
    .referenceLiterature {
      margin-top: 80px;
      padding: 0 20px;
      @include mq(md) {
        margin-top: 40px;
        padding: 0 10px;
      }
      .mincontent {
        background-color: #f6f6f6;
        padding: 30px 40px;
        @include mq(md) {
          padding: 30px;
        }
      }
      h5 {
        font-size: 2rem;
        line-height: 1.2;
        letter-spacing: 1px;
        text-align: left;
        color: #000000;
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: -100%;
          width: 200%;
          height: 1px;
          background-color: #b59738;
          transform-origin: center right;
        }
      }
      p {
        padding: 20px 0 0;
        margin: 0;
      }
    }
    .bgColumn {
      background-color: #f8f9fb;
      padding: 30px 20px;
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
      }
    }
    .backToPageBtn {
      @include mq(md) {
        padding: 60px 0 0;
      }
    }
  }
}