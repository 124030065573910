@charset "utf-8";

/* アニメーション関連
------------------------------------------------------------- */

.uaJsIcon {
  &:hover {
    .inner {
      .titBox {
        .posLinkBtn {
          &::before {
            right: 13px !important;
          }
        }
      }
    }
    &::before {
      right: 13px !important;
    }
    p {
      &::after {
        right: -60px !important;
      }
    }
    .LearnMoreLayout {
      .moreBtn {
        &:before {
          right: 13px;
        }
      }
    }
    .moveColumn {
      &:before {
        right: 13px;
      }
    }
    .cardColumn {
      .txtDetailCard {
        &:before {
          right: 13px !important;
        }
      }
    }
    .moveCard {
      .LearnMoreLayout {
        .moreBtn {
          &:before {
            right: 13px;
          }
        }
      }
    }
    .moveBtn {
      span {
        &:before {
          right: 13px !important;
        }
      }
    }
    .joiningY {
      &:before {
        right: -13px !important;
      }
    }
    &.linkBox {
      &:hover {
        a {
          &::before {
            right: 10px;
          }
        }
      }
    }
    &.subNavUl {
      li {
        &:hover {
          a {
            &::after {
              right: -13px;
            }
          }
        }
      }
    }
    &.news {
      a {
        &::after {
          right: 10px !important;
        }
      }
    }
    &.blog {
      .blogBox {
        &::after {
          right: 5px !important;
        }
      }
    }
    .archiveContent {
      &::after {
        right: 5px !important;
      }
    }
    &.blogLinkBtn {
      a {
        &::after {
          right: 20px !important;
        }
      }
    }
  }
}

.uaPageTop {
  &:hover {
    &::before {
      animation: arrow 0.5s 0s infinite alternate;
    }
  }
}

@keyframes arrow {
  0% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0.5;
    top: -13px;
  }
}