@charset "utf-8";
/* ================================================================================
ファンドサービス
================================================================================ */
#serviceFund {
  h1 {
    color: #02377b;
  }
  .serviceProcess {
    margin-top: 80px;
    @include mq(tab) {
      padding-left: 10px;
    }
    @include mq(md) {
      margin-top: 40px;
    }
    h2 {
      padding-left: 40px;
      margin-bottom: 40px;
      position: relative;
      @include mq(md) {
        padding-left: 30px;
        margin-bottom: 20px;
      }
      &::before {
        content: "";
        background-image: url(/images/service/fund/text_number_01.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 4%;
        height: 117.5%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -2%;
        margin: auto;
        @include mq(md) {
          width: 6%;
        }
      }
      &:nth-of-type(2) {
        &::before {
          background-image: url(/images/service/fund/text_number_02.svg);
          height: 123.25%;
        }
      }
      &:nth-of-type(3) {
        &::before {
          background-image: url(/images/service/fund/text_number_03.svg);
          height: 205%;
        }
      }
    }
    dl {
      background-color: #f8f9fb;
      padding: 25px;
      margin-left: 45px;
      margin-bottom: 80px;
      position: relative;
      @include mq(md) {
        padding: 20px;
        margin-left: 20px;
        margin-bottom: 40px;
      }
      &::before,
      &::after {
        content: "";
        background-color: #d3d3d3;
        width: 1px;
        position: absolute;
        left: -45px;
        top: 0;
        @include mq(md) {
          left: -20px;
        }
      }
      &::before {
        height: calc(100% + 40px);
        @include mq(md) {
          height: calc(100% + 30px);
        }
      }
      &::after {
        height: 4vw;
        max-height: 50px;
        top: inherit;
        bottom: -40px;
        transform: rotate(23deg);
        transform-origin: bottom;
        @include mq(md) {
          bottom: -30px;
        }
      }
      &:last-of-type {
        &::after {
          height: 1px;
          width: calc(100% + 45px);
          transform: none;
          @include mq(md) {
            width: calc(100% + 20px);
          }
        }
      }
      dt, dd {
        padding-left: 25px;
      }
      dt {
        font-family: $NotoSerifJP;
        font-size: 1.5rem;
        line-height: 2.67;
        letter-spacing: 1.5px;
        color: #000000;
        position: relative;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #4f96b1;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
      dd {
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 0.7px;
        color: #4b4b4b;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
        span {
          display: block;
          font-size: 1.3rem;
          line-height: 1.85;
          letter-spacing: 1.3px;
          color: #8d8d8d;
          @include mq(md) {
            font-size: 1.2rem;
          }
        }
      }
    }
    a {
      display: block;
      font-size: 1.3rem;
      line-height: 1.5;
      text-align: right;
      color: #1e51a4;
      margin-top: 10px;
      @include mq(md) {
        text-align: left;
      }
    }
  }
  .plusService {
    border: solid 1px #d3d3d3;
    padding: 40px 35px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 210px 0 120px;
    position: relative;
    @include mq(md) {
      display: block;
      padding: 30px 20px 0;
      margin: 150px 0 60px;
    }
    &::before,
    &::after {
      content: "";
      background-color: #8d8d8d;
      width: 2px;
      height: 60px;
      position: absolute;
      top: -120px;
      left: 0;
      right: 0;
      margin: auto;
      @include mq(md) {
        width: 1px;
        height: 30px;
        top: -70px;
      }
    }
    &::after {
      transform: rotate(90deg);
    }
    dl {
      flex-basis: 45%;
      margin-bottom: 50px;
      padding-right: 5%;
      @include mq(md) {
        margin-bottom: 30px;
      }
      dt {
        font-family: $NotoSerifJP;
        font-size: 2.4rem;
        line-height: 1;
        letter-spacing: 2.4px;
        color: #000;
        margin-bottom: 10px;
        padding-left: 20px;
        border-left: solid 1px #b59738;
        @include mq(md) {
          font-size: 1.6rem;
          padding-left: 10px;
        }
        span {
          font-size: 1.6rem;
          line-height: 1.5;
          letter-spacing: 1.6px;
          color: #000;
          display: inline-block;
          @include mq(md) {
            font-size: 1.2rem;
          }
        }
      }
      dd {
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 0.7px;
        color: #4b4b4b;
        padding-left: 20px;
        @include mq(md) {
          padding-left: 10px;
        }
      }
    }
  }
  .serviceDetail {
    overflow: hidden;
    margin-bottom: 120px;
    @include mq(md) {
      overflow: visible;
      margin-bottom: 60px;
    }
    h2 {
      position: relative;
      display: inline-block;
      margin: 80px 0 20px;
      @include mq(md) {
        margin: 50px 0 40px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100%;
        background-color: #b59738;
        height: 1px;
        @include mq(md) {
          top: inherit;
          bottom: -15px;
          left: 0;
        }
      }
      &::before {
        width: 100px;
        transform: translate(20px, -3px);
        @include mq(md) {
          transform: translate(0, -3px);
        }
      }
      &::after {
        width: 100vw;
        transform: translate(20px, 3px);
        @include mq(md) {
          transform: translate(0, 3px);
        }
      }
      span {
        font-size: 1.6rem;
        letter-spacing: 1.6px;
        color: #000000;
      }
      &+ul {
        margin-top: 0;
      }
    }
    h3 {
      font-family: $NotoSerifJP;
      font-size: 1.6rem;
      line-height: 2.5;
      letter-spacing: 1.6px;
      color: #02377b;
      margin: 60px 0 30px;
      @include mq(md) {
        font-size: 1.5rem;
        margin: 50px 0 25px;
      }
    }
    p {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
      padding-left: 20px;
      @include mq(md) {
        padding-left: 0;
      }
      span {
        color: #004092;
        text-decoration: underline;
      }
      &.attention {
        margin-top: 80px;
      }
    }
    .annotationTxt{
      font-size: 1.3rem;
      line-height: 1.8;
      color: #707070;
      margin-top: 20px;
      display: block;
      a {
        color: #1e51a4;
        &::after {
          content: "";
          width: 14px;
          height: 11px;
          display: inline-block;
          margin-left: 4px;
          background-image: url(/images/icons/icon_out_link_blue.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
    a {
      color: #004092;
      text-decoration: underline;
    }
    ul {
      background-color: #f8f9fb;
      padding: 20px 30px;
      margin-top: 20px;
      @include mq(md) {
        padding: 20px;
      }
      li {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          padding-left: 15px;
          text-indent: -15px;
          &::before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: #4f96b1;
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
      }
    }
    .imgBox {
      @include mq(md) {
        width: 100vw;
        margin: 0 -20px;
      }
      .zoomBtn {
        display: none;
        padding: 0 20px;
        margin: 10px 0 40px;
        text-align: right;
        @include mq(md) {
          display: block;
        }
        p {
          padding-right: 25px;
          position: relative;
          display: inline-block;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 16px;
            height: 16px;
            background-image: url(/images/icons/icon_zoomin.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
      &.open {
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        height: 100%;
        background-color: #fff;
        z-index: 99999999;
        overflow-x: scroll;
        transition: background-color .5s;
        .modalImg {
          width: 200vw;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          transition: .5s;
          img {
            width: 100%;
          }
          &::before,
          &::after {
            content: "";
            width: 1px;
            height: 37px;
            background-color: #004092;
            position: fixed;
            right: 33px;
            top: 20px;
          }
          &::before {
            transform: rotate(45deg);
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
        .zoomBtn {
          display: none;
        }
      }
    }
  }
}