@charset "utf-8";
/* ================================================================================
青山綜合経営塾（青山塾）
================================================================================ */
#jukuTop {
  .jukuLayout {
    max-width: 1020px;
    margin: 80px auto 120px;
    padding: 0 20px;
    overflow: hidden;
    h3 {
      font-family: $NotoSerifJP;
      font-size: 2.4rem;
      line-height: 1;
      letter-spacing: 2.4px;
      color: #02377b;
      margin-top: 80px;
      position: relative;
      display: inline-block;
      margin: 80px 0 20px;
      @include mq(md) {
        margin: 70px 0 50px;
        font-size: 2rem;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100%;
        background-color: #b59738;
        height: 1px;
        @include mq(md) {
          top: inherit;
          bottom: -15px;
          left: 0;
        }
      }
      &::before {
        width: 100px;
        transform: translate(20px, -3px);
        @include mq(md) {
          transform: translate(0, -3px);
        }
      }
      &::after {
        width: 100vw;
        transform: translate(20px, 3px);
        @include mq(md) {
          transform: translate(0, 3px);
        }
      }
    }
    p {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
      span {
        font-weight: bold;
        &.blue {
          font-size: 1.5rem;
          letter-spacing: 0.75px;
          color: #02377b;
          font-weight: normal;
        }
      }
    }
  .jukuendTxt {
      text-align: center;
      margin-bottom: 60px;
      overflow: hidden;
      @include mq(md) {
        margin-bottom: 30px;
      }
      p {
        display: inline-block;
        font-family: $NotoSerifJP;
        font-size: 2rem;
        line-height: 1.4;
        color: #000;
        position: relative;
        @include mq(md) {
          font-size: 1.4rem;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #000;
          height: 1px;
          width: 10vw;
          max-width: 142px;
        }
        &::before {
          right: 100%;
          transform: translateX(-20px);
          @include mq(md) {
            transform: translateX(-5px);
          }
        }
        &::after {
          left: 100%;
          transform: translateX(20px);
          @include mq(md) {
            transform: translateX(5px);
          }
        }
      }
    }
  }
  .jukuTit {
    h2 {
      font-family: $NotoSerifJP;
      font-size: 3.2rem;
      line-height: 1.25;
      letter-spacing: 3.2px;
      color: #02377b;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 2.4rem;
      }
    }
    p {
      font-family: $NotoSerifJP;
      font-size: 2rem;
      line-height: 1.5;
      color: #000000;
      @include mq(md) {
        font-size: 1.6rem;
      }
    }
  }
  .jukuMovie {
    display: block;
    position: relative;
    width: 100%;
    max-width: 640px;
    padding-top: 360px;
    margin: 60px auto 0;
    @include mq(md) {
      margin-top: 30px;
      padding-top: 56.25%;
    }
    iframe {
      background-color: gray;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .jukuPhilosophy {
    h3 {
      color: #000;
    }
    img {
      width: 78%;
      max-width: 764px;
      margin: 40px auto 60px;
      @include mq(md) {
        width: 100%;
        margin: 20px auto 30px;
      }
    }
  }
  .jukuDetail {
    dl {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 30px 10px;
      border: solid #d3d3d3;
      border-width: 1px 0;
      margin: 20px 20px 0;
      @include mq(md) {
        display: block;
        margin: 0;
        padding: 0;
        border: none;
        border-top: solid 1px #004092;
      }
      dt,
      dd {
        padding-bottom: 20px;
        @include mq(md) {
          padding-bottom: 4%;
          max-width: 100%;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
      }
      dt {
        flex-basis: 30%;
        max-width: 30%;
        @include mq(md) {
          padding: 30px 0 10px;
        }
        p {
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          color: #02377b;
        }
      }
      dd {
        flex-basis: 70%;
        max-width: 70%;
        border-left: 1px solid #d3d3d3;
        padding-left: 5.4%;
        @include mq(md) {
          padding: 0 0 40px;
          border: none;
          border-bottom: solid 1px #004092;
          &:last-of-type {
            padding-bottom: 4%;
          }
        }
        ul {
          &:first-of-type {
            margin-bottom: 15px;
          }
        }
      }
      li {
        padding-left: 25px;
        position: relative;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #02377b;
          position: absolute;
          top: 12px;
          left: 10px;
        }
      }
    }
  }
  .specialAdvisory,
  .executiveAdvisory,
  .adviserList {
    .tableList {
      margin-top: 20px;
      @include mq(md) {
        margin-top: 0;
      }
      thead {
        background-color: #f6f6f6;
        @include mq(md) {
          display: none;
        }
        p {
          font-family: $NotoSerifJP;
          letter-spacing: 1.4px;
          color: #02377b;
        }
      }
      tbody {
        @include mq(md) {
          display: block;
          width: 100%;
          border: #D3D3D3 solid;
          padding-bottom: 15px;
          border-width: 1px 0;
        }
        tr {
          @include mq(md) {
            display: block;
            width: 100vw;
            margin: 0 -20px;
            padding: 20px 30px 0;
            &:nth-child(even) {
              background-color: #F8F9FB;
            }
          }
        }
        p {
          span {
            display: none;
            @include mq(md) {
              display: block;
              font-family: $NotoSerifJP;
              line-height: 1;
              font-weight: normal;
              letter-spacing: 1.4px;
              color: #02377b;
              padding-bottom: 10px;
            }
          }
        }
      }
      td {
        vertical-align: top;
        border: solid 1px #d3d3d3;
        padding: 15px 1.5% 15px 4%;
        @include mq(md) {
          display: block;
          border: 0;
          padding: 0 0 20px 0;
        }
      }
    }
  }
  .instructorList,
  .presenterList {
    .tableList {
      margin-top: 20px;
      @include mq(md) {
        margin-top: 0;
      }
      thead {
        background-color: #f6f6f6;
        @include mq(md) {
          display: none;
        }
        p {
          font-family: $NotoSerifJP;
          letter-spacing: 1.4px;
          color: #02377b;
        }
      }
      tbody {
        @include mq(md) {
          display: block;
          width: 100%;
        }
        tr {
          border-bottom: solid 1px #d3d3d3;
          @include mq(md) {
            display: block;
            width: 100vw;
            margin: 0 -20px;
            padding: 20px 30px 0;
            border: none;
          }
          &:first-child {
            @include mq(md) {
              padding-top: 0;
            }
          }
          p {
            span {
              display: none;
              @include mq(md) {
                display: inline;
                font-weight: normal;
              }
            }
          }
        }
      }
      td {
        vertical-align: top;
        padding: 15px 1.5% 15px 3%;
        @include mq(md) {
          display: block;
          border: 0;
          padding: 0 0 20px 0;
          /********公演中止の場合*********/
          &.caseSuspension {
                background-color: #f6f6f6;
                margin: 0 -10px 15px;
                padding: 15px 10px;
            p {
                  font-family: "Noto Serif JP","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Verdana,sans-serif;
                  font-size: 1.6rem;
                  line-height: 1.5;
                  letter-spacing: 3.2px;
                  color: #02377b;
            }
          }
        }
        p {
          @include mq(md) {
            font-size: 1.3rem;
            letter-spacing: 1.3px;
          }
        }
        a {
          font-size: 1.4rem;
          letter-spacing: .7px;
          color: #02377b;
        }
        &:nth-last-of-type(6) {
          min-width: 91px;
        }
        &:nth-last-of-type(5) {
          min-width: 84px;
        }
        &:nth-last-of-type(4) {
          min-width: 122px;
        }
      }
    }
  }
  .instructorList {
    .tableList {
      td {
        &:first-child {
          min-width: 91px;
        }
        &:nth-child(2) {
          min-width: 84px;
        }
        &:nth-child(3) {
          min-width: 122px;
        }
        &:nth-last-of-type(3) {
          @include mq(md) {
            p {
              font-size: 1.6rem;
              font-weight: bold;
              line-height: 1.75;
              letter-spacing: 1.6px;
            }
          }
        }
        &:nth-last-of-type(5) {
          @include mq(md) {
            background-color: #f6f6f6;
            margin: 0 -10px 15px;
            padding: 15px 10px;
            p {
              font-family: $NotoSerifJP;
              font-size: 1.6rem;
              line-height: 1.5;
              letter-spacing: 3.2px;
              color: #02377b;
            }
          }
        }
      }
    }
  }
  .presenterList {
    .tableList {
      td {
        &:nth-last-of-type(4) {
          @include mq(md) {
            p {
              font-size: 1.6rem;
              font-weight: bold;
              line-height: 1.75;
              letter-spacing: 1.6px;
            }
          }
        }
        &:nth-last-of-type(6) {
          @include mq(md) {
            background-color: #f6f6f6;
            margin: 0 -10px 15px;
            padding: 15px 10px;
            p {
              font-family: $NotoSerifJP;
              font-size: 1.6rem;
              line-height: 1.5;
              letter-spacing: 3.2px;
              color: #02377b;
            }
          }
        }
        &:last-child {
          @include mq(md) {
            border-bottom: solid 1px #b7b7b7;
            p {
              font-size: 1.4rem;
              letter-spacing: .7px;
              color: #02377b;
            }
          }
        }
        &:nth-last-of-type(6) {
          min-width: 91px;
        }
        &:nth-last-of-type(5) {
          min-width: 84px;
        }
        &:nth-last-of-type(4) {
          min-width: 122px;
        }
      }
    }
    .attention:not(:root) {
      margin-top: 20px;
    }
  }
  .adviserList {
    .comment {
      &:first-of-type {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.8px;
        margin-bottom: 30px;
      }
    }
  }
  .schoolLayout {
    margin-top: 100px;
    @include mq(md) {
      margin-top: 10%;
    }
    .schoolName {
      background-color: #eaeff3;
      padding: 20px 0;
      display: flex;
      h4 {
        font-family: $NotoSerifJP;
        font-size: 2rem;
        line-height: 1.78;
        letter-spacing: 1.8px;
        text-indent: 1.8px;
        text-align: center;
        color: #4b4b4b;
        display: inline-block;
        margin: auto;
        position: relative;
        @include mq(md) {
          font-size: 1.6rem;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 16px;
          height: 1px;
          background-color: #707070;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &::before {
          left: -31px;
          @include mq(md) {
            left: -21px;
          }
        }
        &::after {
          right: -26px;
          @include mq(md) {
            right: -18px;
          }
        }
      }
      span {
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 1px;
        color: #4b4b4b;
        display: block;
        font-weight: normal;
      }
    }
    .schoolDetail {
      padding: 40px 6%;
      background-color: #fff;
      border: solid 1px #d3d3d3;
      border-top: none;
      @include mq(md) {
        padding: 20px 15px;
      }
      dl {
        display: flex;
        flex-wrap: wrap;
        @include mq(md) {
          display: block;
        }
        dt,
        dd {
          padding-bottom: 40px;
          @include mq(md) {
            padding-bottom: 0;
            max-width: 100%;
          }
          &:last-of-type {
            padding-bottom: 0;
          }
        }
        dt {
          flex-basis: 18%;
          max-width: 18%;
          font-family: $NotoSerifJP;
          font-size: 1.6rem;
          line-height: 1.75;
          letter-spacing: 1.6px;
          color: #02377b;
          @include mq(md) {
            font-size: 1.4rem;
            padding-bottom: 10px;
          }
        }
        dd {
          flex-basis: 82%;
          max-width: 82%;
          font-size: 1.4rem;
          line-height: 1.71;
          letter-spacing: 0.7px;
          color: #4b4b4b;
          padding-left: 6%;
          border-left: solid 1px #d3d3d3;
          word-break: break-all;
          @include mq(md) {
            border-left: 0;
            padding-left: 0;
            padding-bottom: 40px;
          }
        }
        a {
          color: #004092;
        }
      }
    }
  }
  .attention:not(:root) {
    text-align: right;
    letter-spacing: normal;
    margin-top: 20px;
    @include mq(md) {
      margin-top: 10px;
      font-size: 1.2rem;
    }
    &.gray {
      color: #707070;
    }
  }
}