@charset "utf-8";

// ブレークポイント処理
// 使用例：@include mq(md) {}
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// clearfix
// 使用例：@include clearfix;
@mixin clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

// PCサイズ Retina画像出し分け用
// 使用例：@include media-retina;
@mixin media-retina() {
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    @content;
  }
}

//
@mixin nextIconPos($width:20%, $icon:url('/images/icons/icon_next_long_orange.svg')) {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width:$width;
  background-image:$icon;
  background-position: center center;
  background-repeat: no-repeat;
  background-size:contain;
  transition: .3s;
}