@charset "utf-8";
/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}
/* レスポンシブ チェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0; // 横幅768pxまで
  @include mq(md) {
    width: 2px;
  }
}
/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}
.spOnly {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}
.tabOnly {
  display: none;
  @include mq(tab) {
    display: block !important;
  }
}
.originalBr {
  display: none;
  @include mq(originalBr) {
    display: block;
  }
}
html {
  font-size: $mainRemFontSize;
}
body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
}
.fixedBody {
  position: fixed !important;
  width: 100%;
  height: 100%;
}
/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}
.notPointer {
  cursor: default;
}
main {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 97px;
  @include mq(md) {
    padding-top: 80px;
  }
  article {
    section {
      display: block;
      width: 100%;
      position: relative;
      p {
        font-size: 1.6rem;
        @include mq(md) {
          font-size: 1.6rem;
        }
      }
      &.test {
        h5 {
          font-size: 3rem;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
}
a,
span {
  color: #555;
  &.windowIcon {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: -20px;
      margin: auto;
      width: 13px;
      height: 12px;
      background-image: url("/images/icons/icon_out_link_blue.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &.black {
      &::before {
        background-image: url("/images/icons/icon_out_link_black.png");
      }
    }
  }
  &.chainLink {
    &::after {
      content: "";
      width: 19px;
      height: 14px;
      display: inline-block;
      margin: 0 3px;
      background-image: url(/images/icons/icon_chain_link.svg);
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
    }
  }
}
button {
  outline: none;
}
.devMode {
  position: fixed;
  z-index: 999999;
  font-size: 1.8rem;
  font-weight: bold;
  color: navy;
  top: 30px;
}
.xdebug-var-dump {
  position: relative;
  z-index: 9999999;
}
/* 共通デザイン
------------------------------------------------------------- */
/*各ページ見出しキービジュアル*/
section {
  &.eachPagePrimeG {
    width: 100%;
    max-width: 1366px;
    padding: 0 3.14787% 20px;
    margin: auto;
    @include mq(fixed) {
      padding: 0 42px 20px;
    }
    @include mq(md) {
      display: none;
    }
    a {
      display: flex;
      max-width: 330px;
      align-items: flex-start;
      margin-left: auto;
      img {
        width: 100px;
        height: auto;
      }
      p {
        flex: 1;
        padding-left: 10px;
        font-size: 1rem;
        line-height: 1.5;
        text-align: left;
        color: #8d8d8d;
      }
    }
  }
  &.bassHeadVisual {
    position: relative;
    padding-top: 19.54612%;
    @include mq(fixed) {
      padding-top: 267px;
    }
    @include mq(md) {
      padding-top: 74%;
    }
    &.notImg {
      @include mq(md) {
        padding-top: 67.3333%;
      }
    }
    .mainVisual {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
      width: 100%;
      display: flex;
      align-items: center;
      .photo {
        width: 59.80966%;
        &.notImg {
          opacity: 0.54;
          background-image: linear-gradient(to left, #eaeff3 82%, rgba(246, 248, 250, 0.81) 46%, rgba(250, 250, 250, 0.68) 21%);
          padding-top: 19.54612%;
          @include mq(fixed) {
            padding-top: 267px;
          }
          @include mq(md) {
            padding: 67.3333% 0 0 0;
            background-image: linear-gradient(to right, #FFFFFF, #eaeff3 50%);
          }
        }
        @include mq(md) {
          width: 94.66666%;
        }
      }
      .titBox {
        font-family: $NotoSerifJP;
        color: #02377b;
        text-align: left;
        width: 40.19034%;
        position: relative;
        @include mq(md) {
          position: absolute;
          bottom: 0;
          right: 0;
          background: rgba(#fff, .65);
          width: 66%;
          margin-bottom: 8.9333%;
        }
        h1,
        .serviceTit {
          font-size: 3.2rem;
          line-height: 1.3;
          letter-spacing: 3.2px;
          padding-left: 14.5985%;
          @include mq(md) {
            font-size: 2rem;
            letter-spacing: 2px;
            padding: 6.46464% 0 0 8.08080%;
          }
        }
        .sdgTit{
          @include mq(md) {
            line-height: 1.4;
            letter-spacing: -0.4px;
          }
        }
        hr {
          width: 72.9927%;
          display: block;
          margin: 15px 0;
          position: relative;
          left: -29.19708%;
          border-top: solid 1px #b59738;
          @include mq(md) {
            margin: 11px 0;
            width: 60.6060%;
            left: -10.5050%;
          }
        }
        p {
          font-size: 1.8rem;
          line-height: 1;
          letter-spacing: 1.8px;
          color: #02377b;
          padding-left: 14.5985%;
          @include mq(md) {
            font-size: 1.5rem;
            letter-spacing: 1.5px;
            padding: 0 0 4.4444% 8.08080%;
          }
        }
        &.center {
          @include mq(md) {
            top: 23.76237%;
            bottom: inherit;
            right: 0;
            margin: auto;
          }
        }
        &.notletS {
          h1 {
            letter-spacing: 0;
          }
        }
      }
    }
    .posWaveWrap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 1;
      object {
        width: 100%;
      }
    }
    .waveMax {
      width: 89.60468%;
      opacity: 0;
      right: -9.88286%;
      bottom: 0;
      @include mq(md) {
        right: inherit;
        left: -40.53333%;
        width: 203.733333%;
        bottom: -8.64864%;
      }
    }
    #waveVueAnime1 {
      position: absolute;
      z-index: 0;
    }
    #waveVueAnime2 {
      position: absolute;
      z-index: 0;
    }
    #waveVueAnime3 {
      position: absolute;
      z-index: 0;
    }
  }
}
/*サブナビゲーション*/
nav.eachPageSubNavigation {
  display: block;
  width: 100%;
  max-width: 1406px;
  margin: 0 auto 80px;
  padding: 0 20px;
  @include mq(md) {
    margin-bottom: 8%;
  }
  dl.eachPageBox {
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
    @include mq(md) {
      flex-wrap: wrap;
      height: auto;
    }
    &.service {
      max-width: 445px;
    }
    &.about {
      max-width: 685px;
    }
    &.group {
      max-width: 820px;
    }
    &.privacy {
      max-width: 980px;
    }
    dt {
      display: none;
      width: 100%;
      margin-bottom: 2px;
      @include mq(md) {
        display: block;
        width: 100%;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.46;
        letter-spacing: 1.4px;
        text-align: center;
        color: #004092;
        padding: 15px 0;
        border-bottom: 1px solid #B7B7B7;
        border-top: 1px solid #B7B7B7;
        position: relative;
        &::after {
          content: "";
          background-image: url(/images/icons/icon_toggle_btn.svg);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          margin: auto;
          width: 16px;
          height: 8px;
          transition: .5s transform;
        }
        &.open {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
    dd {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      @include mq(tabV) {
        padding: 0 10px;
      }
      @include mq(md) {
        display: none;
        width: 100%;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        a {
          text-align: center;
          &.currentOn {
            display: none;
          }
        }
      }
    }
    a {
      display: block;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.47;
      letter-spacing: 1.5px;
      color: #707070;
      @include mq(tabV) {
        font-size: 1.2rem;
        letter-spacing: 1.5px;
      }
      &.currentOn {
        color: #004092;
      }
      @include mq(md) {
        font-size: 1.4rem;
        padding: 15px 0;
      }
    }
  }
}
.eachPageLink {
  max-width: 1120px;
  padding: 0 20px;
  margin: auto;
  margin-bottom: 150px;
  @include mq(md) {
    padding: 0;
    margin-bottom: 20%;
  }
  &Intro {
    font-family: $NotoSerifJP;
    font-size: 1.8rem;
    line-height: 1.73;
    letter-spacing: 1.8px;
    color: #000000;
    padding: 0 65px;
    margin-bottom: 95px;
    @include mq(md) {
      font-size: 1.5rem;
      padding: 0 20px;
      margin-bottom: 14.666%;
    }
  }
  a {
    display: block;
  }
  &Box {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: solid 1px #d3d3d3;
    @include mq(md) {
      display: block;
      margin-bottom: 15%;
      padding-bottom: 28.84%;
      border: none;
    }
  }
  &Img {
    flex-basis: 55%;
    @include mq(md) {
      margin-left: 20px;
    }
    img {
      width: 100%;
    }
  }
  &Txt {
    flex-basis: 45%;
    background-color: #fff;
    padding-left: 50px;
    @include mq(md) {
      position: absolute;
      bottom: 0;
      right: 8%;
      width: 78%;
      height: 42%;
      padding-top: 5.6%;
      padding-left: 4.8%;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    h2 {
      font-family: $Baskerville;
      font-size: 3.6rem;
      line-height: 1.12;
      letter-spacing: 0.9px;
      color: #004092;
      position: relative;
      padding-bottom: 12.5px;
      @include mq(md) {
        font-size: 1.8rem;
      }
      @include mq(tab) {
        line-height: 1.4;
      }
      //      &::after {
      //        content: "";
      //        border-bottom: solid 1px #b59738;
      //        position: absolute;
      //        bottom: 0;
      //        left: -130px;
      //        width: 100%;
      //        transition: all 2s ease-in-out scale(0);
      //        @include mq(md) {
      //          left: calc(-26% + 20px);
      //        }
      //      }
      &.ampA {
        &::after {
          transform: scale(1);
        }
      }
    }
    hr {
      position: relative;
      left: -130px;
      width: 100%;
      max-width: 260px;
      margin: 0;
      border-top: 0px;
      &.ampA {
        &::before,
        &::after {
          width: 50%;
        }
      }
      &::before,
      &::after {
        position: absolute;
        bottom: 0;
        content: "";
        width: 0%;
        border-top: solid 1px #b59738;
        transition: 1s width;
      }
      &::before {
        left: 50%;
      }
      &::after {
        right: 50%;
      }
    }
    p {
      font-family: $NotoSerifJP;
      font-size: 1.8rem;
      line-height: 1.43;
      letter-spacing: 1.8px;
      color: #4b4b4b;
      padding-top: 12.5px;
      @include mq(md) {
        font-size: 1.4rem;
      }
    }
    .moreBtn {
      font-family: $base-font;
      font-size: 1.4rem;
      letter-spacing: 1.4px;
      position: absolute;
      bottom: 30px;
      right: 0;
      padding-top: 0;
      padding-right: 40px;
      @include mq(md) {
        font-size: 1.2rem;
        right: 6%;
        bottom: 8.2746%;
        padding-right: 20px;
      }
    }
  }
}
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
.mostBottomLayout {
  display: block;
  text-align: center;
  max-width: 1175px;
  margin: 0 auto;
  #topmove {
    display: inline-block;
    margin-bottom: 16.66666%;
    cursor: pointer;
    @include mq(md) {
      margin-bottom: 16%;
    }
    hr {
      margin: 0 auto;
      width: 1px;
      height: 75px;
      border: none;
      overflow: hidden;
      position: relative;
      @include mq(md) {
        height: 40px;
      }
      &::after {
        content: "";
        width: 1px;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #707070;
        animation: scrollTop 5s infinite normal;
      }
    }
    p {
      padding-top: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 1.6px;
      text-align: center;
      color: #707070;
      @include mq(md) {
        padding-top: 20px;
        font-size: 1.2rem;
        line-height: 1;
      }
    }
  }
}
@keyframes scrollTop {
  0% {
    transform: translateY(100%);
  }
  15%,
  85% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto 20px;
  clear: both;
  @include mq(tabH) {
    padding: 0 25px;
  }
  @include mq(tabV) {
    padding: 0 45px;
  }
  ol {
    @include clearfix;
    position: relative;
    width: 100%;
    padding-left: 2em;
    word-break: break-all;
    text-align: left;
    line-height: 1;
    color: #acabab;
    @include mq(md) {
      max-width: initial;
      padding: 0 0 0 2em;
      margin-bottom: 13px;
    }
    &::before {
      position: absolute;
      top: 2px;
      bottom: 0;
      left: 0;
      width: 16px;
      height: 13px;
      margin: auto;
      content: "";
      background-image: url("/images/icons/icon_home.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      @include mq(md) {
        top: 0;
        width: 12px;
        height: 12px;
      }
    }
    &>li {
      display: inline;
      font-size: 1.4rem;
      line-height: 1.5;
      letter-spacing: 1.6px;
      text-align: left;
      color: #8d8d8d;
      @include mq(md) {
        font-size: 1.1rem;
      }
      a {
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 1.6px;
        text-align: left;
        color: #4b4b4b;
        @include mq(md) {
          font-size: 1.1rem;
        }
      }
      &+li::before {
        padding: 0 7px 0 5px;
        color: #ACABAB;
        content: ">";
      }
    }
  }
}