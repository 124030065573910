@charset "utf-8";
/* ボタン装飾
------------------------------------------------------------- */
// Learn More ボタン
.LearnMoreLayout {
  display: block;
  width: 100%;
  a, .moreBtn {
    display: inline-block;
    font-size: 1.4rem;
    letter-spacing: 1.4px;
    color: #4b4b4b;
    position: relative;
    padding-right: 40px;
    z-index: 1;
    @include mq(md) {
      font-size: 1.2rem;
      padding-right: 20px;
    }
    &.topMore {
      font-size: 1.6rem;
      @include mq(md) {
        font-size: 1.2rem;
      }
    }
  }
}
// Learn More アニメーション
.moreBar {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 20px;
  height: 1px;
  @include mq(md) {
    width: 10px;
  }
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #4b4b4b;
    animation: scroll 3s infinite normal;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  15%, 85% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
// 戻るボタン
.btn {
  display: block;
  max-width: 450px;
  width: 100%;
  text-align: center;
  padding-top: 60px;
  margin: 0 auto;
  a {
    display: block;
    font-size: 1.4rem;
    line-height: 1.3;
    letter-spacing: .7px;
    padding: 25px 0;
    position: relative;
    @include mq(md) {
      font-size: 1.2rem;
    }
  }
  .moreBar {
    right: 13%;
    @include mq(md) {
      right: 8%;
    }
  }
}
.backToPageBtn {
  padding-top: 120px;
  @include mq(md) {
    padding: 120px 20px 0;
  }
  a {
    color: #4b4b4b;
    background: #fff;
    border: solid 1px #707070;
    @include mq(md) {
      font-size: 1.4rem;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 13%;
      margin: auto;
      width: 24px;
      height: 24px;
      background-image: url('/images/icons/icon_back_page.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      @include mq(md) {
        width: 18px;
        height: 18px;
        left: 8%;
      }
    }
  }
}
.schoolBtn {
  @include mq(md) {
    padding: 40px 20px 0;
  }
  a {
    color: #fff;
    background-color: #02377b;
  }
  .moreBar {
    &::after {
      background-color: #fff;
    }
  }
}
// トピック内リンクボタン
.topicLinkBtn {
  display: inline-block;
  border: solid .5px #707070;
  background-color: #ffffff;
  margin-bottom: 20px;
  a {
    font-size: 1.4rem;
    padding: 6px 35px !important;
    display: block !important;
    position: relative;
    text-decoration: none !important;
  }
  .moreBar {
    right: 15px;
    width: 10px;
  }
}
.archiveBtnLayout {
  max-width: none;
  background-color: #f8f9fb;
  padding: 5.555%;
  display: flex;
  justify-content: space-between;
  @include mq(tab) {
    flex-direction: column;
  }
  @include mq(md) {
    flex-direction: column;
    padding: 20px;
  }
  p {
    font-family: $NotoSerifJP;
    font-size: 4.6rem;
    letter-spacing: 4.6px;
    color: #02377b;
    border-right: solid 1px #8d8d8d;
    padding-right: 8%;
    min-width: 64%;
    span {
      font-size: 3.6rem;
      letter-spacing: 3.6px;
      color: #02377b;
      @include mq(md) {
        font-size: 1.4rem;
      }
    }
    @include mq(tab) {
      padding-right: 0;
      border: none;
      border-bottom: solid 1px #8d8d8d;
      padding-bottom: 5.555%;
      margin-bottom: 5.555%;
    }
    @include mq(md) {
      font-size: 2.5rem;
      padding-right: 0;
      border: none;
      border-bottom: solid 1px #8d8d8d;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
  a {
    width: 30%;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    background-color: #02377b;
    padding: 20px 0;
    margin-left: 6%;
    @include mq(tab) {
      margin: auto;
      width: 50%;
    }
    @include mq(md) {
      font-size: 1.4rem;
      padding: 15px 0;
      margin: auto;
      width: 80%;
    }
    .moreBar::after {
      background-color: #fff;
    }
  }
}