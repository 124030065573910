@charset "utf-8";
/* ================================================================
ヘッダー
================================================================ */
header#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  transition: 2s opacity, 2s background;
  //transition: 0s opacity, 0s background;
  opacity: 0;
  @include mq(md) {
    transition: 1s opacity, 1s background;
  }
  &.visible {
    opacity: 1;
  }
  &.nonTop {
    transition: 0s opacity, 0s background;
  }
  &.topDer {
    .enclosingWrap {
      #spNavBtn {
        span {
          background-color: #fff;
        }
        &.open {
          span {
            background-color: #4b4b4b;
          }
        }
      }
    }
  }
  &.addBgCor {
    background: rgba(#fff, .9);
    .enclosingWrap {
      #spNavBtn {
        span {
          background-color: #4b4b4b;
        }
      }
      .trackingMoveContact {
        .langeBox {
          a,
          span {
            color: #4b4b4b;
          }
          .notHover {
            cursor: default;
          }
        }
        &.topDer {
          .contactMoveBox {
              a {
                  &::before {
                      width: 14px;
                      height: 10px;
                      
                      @include mq(fixed) {
                          width: 12px;
                          height: 16px;
                      }
                  }
              }
          }
      }
        .contactMoveBox {
          a {
            color: #4b4b4b;
            &::before {
              background-image: url(/images/icons/icon_mail.png);
              width: 14px;
              height: 10px;
            }
          }
        }
      }
    }
  }
  .enclosingWrap {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    opacity: 0;
  }
  &.visible {
    .enclosingWrap {
      opacity: 1;
    }
  }
  .enclosing {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.19619% 3.14787% 1.59619%;
    @include mq(original) {
      padding: 30px 20px;
    }
    @include mq(md) {
      padding: 6% 5.33333% 7.06666% 3.33333%;
    }
    .partnerbox{
      width: 100%;
      text-align: right;
      a{
        transition: .5s color;
        color: #4b4b4b;
        font-size: 1.2rem;
        svg{
          margin-right: 6px;
          path{
            transition: .5s fill;
            fill: #4b4b4b;
          }
        }
        &.topCol{
          color: #fff;
          svg{
            path{
              fill: #fff;
            }
          }
        }
        &:hover{
          color: #004092;
          svg{
            path{
              fill: #004092;
            }
          }
        }
      }
    }
    .logoBox {
      display: block;
      width: 151px;
      @include mq(original) {
        width: 151px;
      }
      @include mq(md) {
        position: relative;
        z-index: 100;
        width: 143px;
      }
      a {
        display: block;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  nav#globalNavigations {
    display: block;
    width: 62.954612%;
    max-width: 700px;
    margin-left: auto;
    margin-top: 7px;
    @include mq(original) {
      width: 63.609375%;
      padding-left: 5px;
    }
    @include mq(md) {
      width: 100%;
      max-width: none;
      position: fixed;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      height: 100%;
      background-color: #fff;
      border-right: 1px solid #fff;
      padding-top: 14.39999%;
      margin-top: 0;
    }
    .navWrap {
      @include mq(md) {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
      }
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mq(md) {
        display: block;
        padding-bottom: 15px;
        position: relative;
        z-index: 101;
        padding-top: 16%;
        background-color: #fff;
      }
      li {
        padding: 0 5px;
        @include mq(original) {
          padding: 0;
        }
        @include mq(md) {
          padding: 0 20px 9.33333% 14.00000%;
          opacity: 0;
        }
        &:first-child {
          padding-left: 0;
          @include mq(md) {
            padding: 0 20px 9.33333% 14.00000%;
          }
        }
        &:last-child {
          padding: 0;
          @include mq(md) {
            padding: 0;
          }
        }
        &:nth-child(4) {
          padding: 0 25px 0 8px;
          @include mq(original) {
            padding: 0 0 0 0;
          }
          @include mq(md) {
            padding: 0 20px 9.33333% 14%;
          }
        }
        a {
          display: block;
          font-size: 1.5rem;
          line-height: 1.47;
          color: #4b4b4b;
          font-weight: 500;
          transition: .5s color;
          text-align: center;
          @include mq(original) {
            font-size: 1.2rem;
          }
          @include mq(md) {
            display: inline-block;
            font-size: 1.6rem;
            line-height: 1;
            letter-spacing: normal;
            color: #333333;
            position: relative;
          }
          &.uaJs {
            &:hover {
              color: #004092;
            }
          }
          &.topCol {
            color: #fff;
            //color: red;
            @include mq(md) {
              color: #333333;
            }
            &.windowIcon {
              &::before {
                background-image: url(/images/icons/icon_out_link_white.png);
                @include mq(md) {
                  background-image: url(/images/icons/icon_out_link_black.svg);
                }
              }
            }
          }
          &.current {
            color: #004092;
          }
          &.windowIcon {
            @include mq(original) {
              padding-right: 10px;
            }
            &::before {
              transition: .5s;
              width: 13px;
              height: 13px;
              right: -18px;
              @include mq(original) {
                width: 11px;
                height: 11px;
                right: -3px;
              }
            }
          }
        }
        &.navEnd {
          @include mq(md) {
            padding-bottom: 9.33333%;
          }
        }
        &.spOnly {
          @include mq(md) {
            padding: 0 20px;
          }
        }
        .partnerContact {
          width: 100%;
          a {
            display: flex;
            justify-content: center;
            border-top: 1px solid #C4C4C4;
            padding: 15px 0;
            text-align: center;
            &::before {
              content: "";
              background: url(/images/common/header_partner_icon_sp.svg);
              background-size: contain;
              display: inline-block;
              vertical-align: middle;
              margin: auto 13px auto 0;
              width: 24.8px;
              height: 13px;
            }
            .box {
              display: block;
              font-family: $NotoSerifJP;
              p {
                font-size: 1.5rem;
                line-height: 1;
                letter-spacing: 1.5px;
                text-align: left;
                color: #000000;
                padding-bottom: 8px;
              }
              span {
                display: block;
                font-size: 1rem;
                line-height: 1;
                letter-spacing: 1px;
                color: #4b4b4b;
                text-align: left;
              }
            }
          }
        }
        .navContact {
          width: 100%;
          a {
            display: block;
            border-top: 1px solid #C4C4C4;
            border-bottom: 1px solid #C4C4C4;
            padding: 15px 0;
            text-align: center;
            .box {
              display: block;
              width: 100%;
              font-family: $NotoSerifJP;
              p {
                font-size: 1.5rem;
                line-height: 1;
                letter-spacing: 1.5px;
                text-align: center;
                color: #000000;
                padding-bottom: 8px;
              }
              span {
                display: block;
                font-size: 1rem;
                line-height: 1;
                letter-spacing: 1px;
                color: #4b4b4b;
                text-align: center;
              }
            }
          }
        }
        .lang {
          display: block;
          padding-top: 6.66666%;
          padding-bottom: 6.66666%;
          text-align: center;
          font-family: $YuMin;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 1;
          letter-spacing: 1.3px;
          color: #666666;
          a {
            display: inline-block;
            font-family: $YuMin;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 1;
            letter-spacing: 1.3px;
            color: #666666;
          }
        }
        .primeGlobalColumn {
          display: block;
          width: 100%;
          a {
            display: flex;
            align-items: center;
            background-color: #efefef;
            padding: 15px;
            img {
              width: 36.5671%;
            }
            p {
              flex: 1;
              padding-left: 7.46268%;
              font-size: 1rem;
              text-align: left;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
  .trackingMoveContact {
    width: 100%;
    padding-right: 10px;
    padding-top: 1%;
    bottom: -20%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @include mq(original) {
      padding-top: 20px;
    }
    &.topDer {
      @include mq(fixed) {
        position: absolute;
        top: 200px;
        right: inherit;
        left: 33px;
        writing-mode: vertical-rl;
        height: 100vh;
        width: auto;
        flex-direction: row;
        padding-top: 0;
      }
      .langeBox {
        @include mq(fixed) {
          padding-bottom: 35px;
        }
        a {
          @include mq(fixed) {
            color: #707070;
            font-size: 1.9rem;
          }
        }
        span {
          @include mq(fixed) {
            color: #707070;
            padding: .5em 0;
          }
        }
      }
      .contactMoveBox {
        @include mq(fixed) {
          padding-right: 0;
        }
        a {
          @include mq(fixed) {
            color: #707070;
            padding-top: 28px;
            font-size: 1.9rem;
            &::after {
              width: 1px;
              height: 90px;
              content: '';
              position: absolute;
              bottom: -120px;
              left: 0;
              right: 0;
              margin: auto;
              background-color: #707070;
            }
          }
          &::before {
            @include mq(fixed) {
              left: 0;
              right: 0;
              bottom: inherit;
              background-image: url(/images/icons/icon_mail_black.svg) !important;
              width: 12px;
              height: 16px;
              background-size: contain;
            }
          }
        }
      }
    }
    @include mq(md) {
      display: none;
    }
    .langeBox {
      display: flex;
      align-items: center;
      a {
        display: block;
        font-family: $YuMin;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.16;
        letter-spacing: 1.9px;
        color: #fff;
        transition: .5s color;
        @include mq(original) {
          font-size: 1.3rem;
        }
      }
      span {
        display: inline-block;
        padding: 0 .5em;
        color: #fff;
        transition: .5s color;
      }
    }
    .contactMoveBox {
      padding-right: 45px;
      a {
        position: relative;
        display: block;
        font-family: $YuMin;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.16;
        letter-spacing: 1.9px;
        color: #fff;
        transition: .5s;
        @include mq(original) {
          font-size: 1.3rem;
        }
        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -24px;
          margin: auto;
          content: '';
          background-image: url(/images/icons/icon_mail_white.png);
          width: 14px;
          height: 10px;
          background-size: contain;
          background-repeat: no-repeat;
          transition: .5s;
          @include mq(original) {
            right: -18px;
          }
        }
      }
    }
  }
  #spNavBtn {
    display: none;
    @include mq(md) {
      display: block;
      width: 33px;
      height: 21px;
      position: relative;
      .box {
        position: relative;
        z-index: 9999999;
        width: 100%;
        height: 100%;
        span {
          width: 100%;
          background-color: #4B4B4B;
          position: absolute;
          right: 0;
          height: 1px;
          transition: 1s;
          &:nth-child(1) {
            top: 0;
            width: 20px;
          }
          &:nth-child(2) {
            top: 0;
            bottom: 0;
            margin: auto;
            width: 26px;
          }
          &:nth-child(3) {
            bottom: 0;
          }
        }
      }
      .closeBox {
        position: absolute;
        z-index: 9999999;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        span {
          width: 100%;
          background-color: #4B4B4B;
          position: absolute;
          right: 0;
          height: 1px;
          transition: 1s;
          &:nth-child(1) {
            top: -10px;
            width: 100%;
            transform: translateY(20px) rotate(-45deg) scale(0);
          }
          &:nth-child(2) {
            top: 40px;
            bottom: 0;
            margin: auto;
            width: 100%;
            transform: translateY(-20px) rotate(45deg) scale(0);
          }
        }
      }
      &.open {
        .box {
          span {
            width: 0;
          }
        }
        .closeBox {
          span {
            &:nth-child(1) {
              transform: translateY(20px) rotate(-45deg) scale(1);
            }
            &:nth-child(2) {
              transform: translateY(-20px) rotate(45deg) scale(1);
            }
          }
        }
      }
    }
  }
}