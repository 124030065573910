@charset "utf-8";
/* ================================================================================
個人情報保護方針
================================================================================ */

#privacypolicyTop {
	.privacyLayout {
		max-width: 1020px;
		padding: 0 20px;
		margin: 0 auto 120px;
		@include mq(md) {
			margin-bottom: 17%;
            overflow-x: hidden;
		}
		h2 {
			margin-bottom: 30px;
			font-family: $NotoSerifJP;
			font-size: 3.2rem;
			font-weight: 500;
			line-height: 1.25;
			letter-spacing: 3.2px;
			color: #004092;
			@include mq(md) {
				font-size: 2rem;
				letter-spacing: 2px;
				margin-bottom: 5%;
			}
		}
		h3 {
			margin: 50px 0 10px;
			font-family: $NotoSerifJP;
			font-size: 2rem;
			line-height: 2;
			letter-spacing: 2px;
			color: #004092;
			@include mq(md) {
				margin: 8.5% -0px 3%;
				font-size: 1.6rem;
				letter-spacing: 1.6px;
			}
		}
		h4 {
			margin: 30px 0 20px;
			font-size: 14px;
			letter-spacing: 1.4px;
			color: #004092;
			@include mq(md) {
				margin: 4% 0 3%;
			}
		}
		p,a {
			font-size: 1.4rem;
			line-height: 2;
			letter-spacing: 0.7px;
			color: #4b4b4b;
		}
		& > p {
			&:last-of-type {
				margin-top: 30px;
				text-align: right;
				@include mq(md) {
					margin-top: 4.5%;
				}
			}
		}
		ul {
			background: #f8f9fb;
			margin: 30px 0;
			padding: 20px;
			li {
				font-size: 1.4rem;
				line-height: 2;
				letter-spacing: 0.7px;
				color: #4b4b4b;
				padding-left: 20px;
				margin-bottom: 10px;
				position: relative;
				&::before {
					content: "";
					width: 5px;
					height: 5px;
					background-color: #004092;
					position: absolute;
					top: 11px;
					left: 0;
				}
        &:last-child {
          margin-bottom: 0;
        }
				span {
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: 0.8px;
					color: #02377b;
				}
			}
		}
		ol {
			counter-reset: number 0;
			margin-top: 20px;
			& > li {
				font-size: 1.4rem;
				line-height: 2;
				letter-spacing: 0.7px;
				color: #4b4b4b;
				padding-left: 23px;
				position: relative;
				margin-bottom: 50px;
				&::before {
					counter-increment: number 1;
  				content: counter(number) ".";
					position: absolute;
					left: 0;
					top: 0;
				}
			}
			ul {
				background: none;
				padding: 0;
				margin: 20px 0;
			}
		}
		dl {
			display: flex;
			flex-wrap: wrap;
			margin-top: 30px;
			padding: 12px 0;
			border: solid #004092;
			border-width: 1px 0;
			position: relative;
			&:before,
			&:after {
				content: "";
				position: absolute;
				right: 0;
				width: 80%;
				border-bottom: 1px solid #d3d3d3;
				@include mq(md) {
					width: 65%;
				}
			}
			&:before {
				top: -1px;
			}
			&:after {
				bottom: -1px;
			}
			dt,
			dd {
				padding: 12px 0;
			}
			dt {
				flex-basis: 20%;
				@include mq(md) {
					flex-basis: 35%;
				}
				p {
					color: #02377b;
				}
			}
			dd {
				flex-basis: 80%;
				@include mq(md) {
					flex-basis: 65%;
				}
			}
		}
		.reception {
			padding: 30px 40px;
			margin-top: 30px;
			background: #f8f9fb;
			@include mq(md) {
				padding: 20px;
			}
			h4 {
				font-size: 1.6rem;
				line-height: 1.5;
				letter-spacing: 0.8px;
				margin: 0;
			}
			p {
				margin-top: 10px;
				padding-left: 20px;
				border-left: solid 1px #b59738;
				line-height: 1.5;
				@include mq(md) {
					padding-left: 10px;
				}
			}
		}
      
//      ここから表作成
        .privacyUseList {
              display: flex;

              @include mq(md) {
                display: block;
                margin-top: 20px;
              }

              dl {
                display: block;
                border: solid 1px #D3D3D3;
                padding: 0px;
                
                @include mq(md) {
                margin-top: 0px;
                  
                   &:nth-of-type(2){
                    border-left:solid 1px #D3D3D3;
                    }
              }
                
                &:before,
                &:after {
                  display: none;
                }
                
                &:nth-of-type(2){
                  border-left:none;
                }
              }

              dt {
                font-family: "Noto Serif JP","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Verdana,sans-serif;
                background-color: #F6F6F6;
                padding: 16px 40px;
                font-size: 1.4rem;
                line-height: 2;
                letter-spacing: 1.6px;
                color: #02377b;
                
                @include mq(md) {
                padding: 15px;
                letter-spacing: 1.5px;
                }
              }

              dd {
                ul {
                  margin: 0px;
                  background: #FFF;
                  padding: 8px 40px 8px 40px;
                  
                  @include mq(md) {
                  padding: 15px;
                  }
                }
              }
       }
      
      .privacyUseTable {
        margin-top: 30px;
        
        @include mq(md) {
          display: block;
              }

        tbody {
          border: solid 1px #D3D3D3;

          tr {
            border: solid 1px #d3d3d3;

            th {
              background-color: #F6F6F6;
              text-align: center;
              
              &.privacyUseTtl{
                width: 340px;
                border-left: dashed 1px #D3D3D3;
                
                  p{
                    font-family: "Noto Serif JP","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Verdana,sans-serif;
                    background-color: #F6F6F6;
                    padding: 16px;
                    font-size: 1.4rem;
                    line-height: 2;
                    letter-spacing: 1.6px;
                    color: #02377b;
                    font-weight: 400;
                    
                    &::before{
                      display: inline-block;
                      content: "";
                      width: 5px;
                      height: 5px;
                      background-color: #004092;
                      margin-right: 5px;
                      vertical-align: middle;
                    }
                }
              }
              
              &.privacyUseTtl2 {
                padding: 20px 40px;
                width: 300px;
                
                p {
                  text-align: left;
                  font-weight: 400;
                }
              }
            }
            
            td{
              
              &.privacyUseTxt{
                padding: 16px;
                border-left: dashed 1px #D3D3D3;
                font-size: 1.4rem;
                line-height: 1.6;
                letter-spacing: .7px;
                color: #4b4b4b;
                
                p{
                    &.center{
                    text-align: center;
                  }
                    &.companyname{
                    margin: 0 auto;
                    width: 35%;
                  }
                }
              }
            }

          }
        }

      }
      
      .privacyUseTable_sp{
        h4{
          font-size: 1.4rem;
          font-family: "Noto Serif JP","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","ヒラギノ角ゴシック","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Verdana,sans-serif;
          
            &::before{
            display: inline-block;
            content: "";
            width: 5px;
            height: 5px;
            background-color: #004092;
            margin-right: 5px;
            vertical-align: middle;
            }
        }
        .privacyUseTablebox{
          display: block;
          border: none;
          margin-top: 0;
          
          dt{
            position: relative;
            z-index: 1;
            font-size: 1.4rem;
            line-height: 1.46;
            padding: 10px 0;
            
            &::after{
              content: "";
              display: block;
              width: 100vw;
              height: 100%;
              background-color: #F6F6F6;
              position: absolute;
              top: 0;
              left: -20px;
              z-index: -1;
            }
          }
          dd{
            font-size: 1.4rem;
            line-height: 1.46;
            padding: 10px 0;
          }
          
          &::before,
          &::after{
            display: none;
          }
        }
      }
//      ここまで表作成
      
	}
}