@charset "utf-8";

/* ================================================================
波アニメーション
================================================================ */

.testUrl {
  display: block;
  h2{
    display: block;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 3em;
  }
  ul {
    li {
      a {
        display: block;
        text-align: center;
        font-size: 2rem;
      }
    }
  }
}

.scrTest {
  width: 100%;
  height: 2500px;
  background-color: beige;
  .box1,
  .box2 {
    height: 600px;
    width: 80%;
    margin: 0 auto;
    h2 {
      color: #fff;
      font-size: 5rem;
    }
    hr {
      margin: 1em;
      max-width: 90%;
      margin: 3em 0;
      border-top: 0;
      background-color: #000;
      transition: 1.5s;
      width: 0;
      &.animate {
        width: 100%;
      }
    }
  }
  .box1 {
    background-color: forestgreen;
  }
  .box2 {
    background-color: darkgoldenrod;
  }
}