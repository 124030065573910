@charset "utf-8";
/* ================================================================================
お問い合わせ
================================================================================ */
#formBox {
  display: none;
  &.show {
    display: block;
  }
}
#contactTop {
  .formArea {
    max-width: 1020px;
    padding: 0 20px;
    margin: 80px auto;
    h3 {
      font-size: 1.6rem;
      color: #707070;
      margin-bottom: 20px;
    }
    h4 {
      font-family: $NotoSerifJP;
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: 1.8px;
      color: #000000;
    }
    p {
      font-size: 1.6rem;
      line-height: 1.5;
      color: #707070;
      &.errorTxt {
        font-size: 1.2rem;
        line-height: 2;
        color: #de1111;
      }
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      @include mq(md) {
        display: block;
      }
      dt {
        font-family: $NotoSerifJP;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.75;
        letter-spacing: 1.6px;
        color: #02377b;
      }
      dd {
        font-size: 1.4rem;
        line-height: 1.71;
        letter-spacing: 0.7px;
        color: #4b4b4b;
      }
    }
    .customersDivision {
      margin-bottom: 80px;
      @include mq(md) {
        margin-bottom: 60px;
      }
      h3 {
        display: flex;
        justify-content: space-between;
        @include mq(md) {
          display: block;
        }
      }
      .note {
        font-size: 1.4rem;
        line-height: 1.43;
        color: #8d8d8d;
      }
      dl {
        margin-bottom: 20px;
        dt, dd {
          padding: 30px 0;
          @include mq(md) {
            padding: 20px 0;
          }
        }
        dt {
          flex-basis: 28%;
          border: solid #004092;
          border-width: 1px 0;
        }
        dd {
          flex-basis: 72%;
          border: solid #d3d3d3;
          border-width: 1px 0;
          @include mq(md) {
            border-top: none;
          }
        }
      }
    }
    .formBox {
      h3 {
        margin-bottom: 60px;
        @include mq(md) {
          margin-bottom: 20px;
        }
      }
      h4 {
        margin-bottom: 50px;
        @include mq(md) {
          margin-bottom: 20px;
        }
      }
      dl {
        margin-bottom: 80px;
        @include mq(md) {
          margin-bottom: 50px;
        }
        dt, dd {
          padding-bottom: 40px;
          &:last-of-type {
            padding-bottom: 0;
          }
          @include mq(md) {
            padding-bottom: 20px;
            &:last-of-type {
              padding-bottom: 20px;
            }
          }
        }
        dt {
          flex-basis: 26%;
          @include mq(md) {
            border-top: solid 1px #d3d3d3;
            padding-top: 20px;
          }
        }
        dd {
          flex-basis: 70%;
          padding-left: 4%;
          border-left: solid 1px #d3d3d3;
          @include mq(md) {
            padding-left: 0;
            border: none;
            &:last-of-type {
              border-bottom: solid 1px #d3d3d3;
            }
          }
          input {
            width: 58%;
            padding: 15px;
            border: solid 1px #b7b7b7;
            outline: 0;
            @include mq(md) {
              width: 100%;
            }
            &.error {
              background-color: #ffe3e3;
            }
          }
          textarea {
            width: 100%;
            min-height: 200px;
            padding: 15px;
            border: solid 1px #b7b7b7;
            outline: 0;
          }
        }
      }
      .ddList {
        a {
          color: #02377b;
          display: inline-block;
          margin-bottom: 10px;
        }
      }
    }
    .radioArea,
    .checkboxArea {
      margin-top: -20px;
      input[type=radio],
      input[type=checkbox] {
        display: none;
        &+label {
          display: inline-block;
          position: relative;
          padding: 0 0 0 25px;
          margin-right: 30px;
          cursor: pointer;
          margin-top: 20px;
          @include mq(md) {
            margin-left: 10px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -8px;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid #707070;
            border-radius: 50%;
            background: #fff;
          }
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -5px;
            left: 3px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            transform: scale(.5);
          }
        }
        &:checked {
          &+label {
            &::after {
              transition: .3s;
              transform: scale(1);
              background: #02377b;
            }
          }
        }
      }
    }
    .requireIcn {
      font-family: $NotoSerifJP;
      font-size: 1.2rem;
      line-height: 1.75;
      letter-spacing: 1.2px;
      color: #de1111;
      display: inline-block;
    }
    .atten {
      display: inline-block;
      font-family: $base-font;
      font-size: 1rem;
      line-height: 1.5;
      color: #707070;
      background-color: #efefef;
    }
    .submit {
      margin: 120px auto;
      padding-top: 0;
      @include mq(md) {
        margin: 60px auto;
      }
      button {
        display: block;
        width: 100%;
        background-color: #02377b;
        color: #fff;
        font-size: 1.4rem;
        line-height: 1;
        letter-spacing: .7px;
        padding: 25px 0;
        position: relative;
        @include mq(md) {
          font-size: 1.2rem;
        }
        &:disabled {
          background-color: #8d8d8d;
        }
      }
      .moreBar {
        &::after {
          background-color: #fff;
        }
      }
    }
    .btnColumn {
      width: 77.55%;
      max-width: 760px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;
      .btn {
        width: 46%;
        max-width: 350px;
        margin: 0;
        padding-top: 40px;
        &.back {
          a {
            border: solid 0.5px #b7b7b7;
            background-color: #e9eef5;
          }
        }
        &.submit {
          margin: 0;
          button {
            background-color: #02377b;
          }
        }
      }
    }
  }
  .completion {
    max-width: 1020px;
    padding: 0 20px;
    margin: 80px auto;
    h2 {
      font-family: $NotoSerifJP;
      font-size: 2.4rem;
      line-height: 2;
      letter-spacing: 2.4px;
      color: #02377b;
      margin-bottom: 30px;
      @include mq(md) {
        margin-bottom: 4%;
      }
    }
    p {
      font-size: 1.4rem;
      line-height: 2;
      letter-spacing: 0.7px;
      color: #4b4b4b;
    }
  }
}