@charset "utf-8";
/* ================================================================================
topics　トップページ
================================================================================ */
#topicsIdx {
  .eachTopicsList {
    max-width: 1080px + 40px;
    padding: 80px 20px 0;
    width: 100%;
    margin: 0 auto;
    dl {
      display: block;
      padding-bottom: 80px;
      @include mq(md) {
        padding-bottom: 30px;
      }
      &:first-of-type {
        dd {
          display: block;
        }
      }
      &:last-of-type {
        padding-bottom: 30px;
        @include mq(md) {
          padding-bottom: 15px;
        }
      }
      dt {
        border-bottom: 1px solid #a2a5a7;
        position: relative;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 48px;
          height: 20px;
          background-image: url(/images/icons/icon_toggle_btn.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          transition: 0.5s;
          @include mq(md) {
            width: 24px;
            height: 10px;
          }
        }
        &.open {
          &::after {
            transform: rotate(-180deg);
          }
        }
        span {
          display: inline-block;
          padding-bottom: 20px;
          position: relative;
          font-family: $NotoSerifJP;
          font-size: 4.8rem;
          line-height: 1;
          letter-spacing: 4.8px;
          color: #02377b;
          @include mq(md) {
            font-size: 3rem;
            padding-bottom: 10px;
          }
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #02377b;
            bottom: -1px;
            left: 0;
          }
        }
      }
      dd {
        padding-top: 35px;
        display: none;
        @include mq(md) {
          padding-top: 20px;
        }
      }
      dd {
        padding-top: 35px;
        display: none;
        @include mq(md) {
          padding-top: 20px;
        }
      }
    }
    p.attention {
      text-align: right;
      font-size: 1.2rem;
      line-height: 2;
      color: #707070;
      padding-bottom: 120px;
      @include mq(md) {
        padding-bottom: 60px;
      }
    }
    .archiveBtnLayout {
      margin-bottom: 120px;
      @include mq(md) {
        margin-bottom: 60px;
      }
    }
  }
}